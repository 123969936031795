import { createSlice } from '@reduxjs/toolkit'
import { Usuario, TIPO_USUARIO_PLURAL } from './Usuario'
import axios, { IQueryParams, ICounter } from '../../axios'

export interface IRequest {
  errorCode: number
  message: string
  fetching: boolean
}
export interface IState {
  store: any
}

export const initialState: IState = {
  store: {},
}

export const storeInitialState = {
  usuarios: [],
  usuariosCount: 0,
  request: {
    fetching: false,
    errorCode: null,
    message: '',
  },
  query: { limit: 10, offset: 0 },
}

const reducers = {
  initStore(state: IState, { payload }: any) {
    state.store[payload.target] = storeInitialState
  },
  requestAsync(state: IState, { payload }: any) {
    state.store[payload.target].request = storeInitialState.request
    state.store[payload.target].fetching = true
  },
  requestClean(state: IState, { payload }: any) {
    state.store[payload.target].request = storeInitialState.request
  },
  requestError(state: IState, { payload }: any) {
    state.store[payload.target].request = {
      errorCode: payload.errorCode,
      message: payload.message,
      fetching: false,
    }
  },
  usuariosGetSuccess(state: IState, { payload }: any) {
    state.store[payload.target].usuarios = payload.rows
    state.store[payload.target].usuariosCount = payload.count
    state.store[payload.target].request = storeInitialState.request
  },
  usuariosCreateOrUpdateSuccess(state: IState, { payload }: any) {
    state.store[payload.target].usuarios = state[payload.target].usuarios.filter(
      (c) => payload.id !== c.id
    )
    state.store[payload.target].usuarios.push(payload)
  },
  usuariosDeleteSuccess(state: IState, { payload }: any) {
    state.store[payload.target].usuarios = state.store[payload.target].usuarios.filter(
      (c) => +c.id !== payload.id
    )
  },
  setQueryParams(state: IState, { payload }: any) {
    state.store[payload.target].query = { ...state.store[payload.target].query, ...payload }
  },
}

const { reducer: usuariosReducer, actions } = createSlice({
  name: 'usuariosCurso',
  initialState: initialState,
  reducers,
})

const usuariosActions = {
  ...actions,
  usuariosFetch({ cursoId, papel, target }: { cursoId: number; papel: string; target: string }) {
    return async (dispatch, getState) => {
      dispatch(actions.requestAsync({ target } as any))
      try {
        const usuarios = await axios.UsuariosCursos.get({
          query: getState().usuarios.store[target].query,
          cursoId,
          papel,
        })
        dispatch(actions.usuariosGetSuccess({ ...usuarios, target } as any))
      } catch (error) {
        dispatch(
          actions.requestError({
            target,
            errorCode: 400,
            message: 'Falha a consultar ' + TIPO_USUARIO_PLURAL[papel].toLowerCase(),
          } as any)
        )
      }
    }
  },
  deleteUsuario(usuarioId: number, cursoId: number, papel: string, target: string) {
    return async (dispatch) => {
      dispatch(actions.requestAsync({ target } as any))
      try {
        await axios.UsuariosCursos.deletePermissao(usuarioId, cursoId, papel)
        dispatch(actions.usuariosDeleteSuccess({ id: usuarioId, target } as any))
      } catch (error) {
        dispatch(
          actions.requestError({
            target,
            errorCode: 400,
            message: 'Falha ao remover usuario',
          } as any)
        )
      }
    }
  },
  createOrUpdateUsuariosCursos(values: Partial<Usuario>, target: string) {
    return async (dispatch) => {
      try {
        const usuario = await axios.UsuariosCursos.post(values)
        dispatch(actions.usuariosCreateOrUpdateSuccess({ ...usuario, target } as any))
      } catch (e) {
        dispatch(
          actions.requestError({
            target,
            errorCode: 400,
            message: 'Falha ao salvar o usuario',
          } as any)
        )
      }
    }
  },
  createUsuariosPermissao(values: Partial<Usuario>, target: string) {
    return async (dispatch) => {
      try {
        const usuario = await axios.UsuariosCursos.postPermissao(values)
        dispatch(actions.usuariosCreateOrUpdateSuccess({ ...usuario, target } as any))
        return
      } catch (e) {
        dispatch(
          actions.requestError({
            target,
            errorCode: 400,
            message: 'Falha ao salvar o usuario',
          } as any)
        )
      }
    }
  },
}

export { usuariosReducer, usuariosActions }
