import { useEffect, useState } from 'react'
import { toast } from 'react-toastify'
import { Button, Table as TableComp } from 'semantic-ui-react'
import axios from '../../axios'
import { MatriculaModel } from '../../models/MatriculaModel'
import styled from '@emotion/styled'
const Table = TableComp as any

export function CertificadosPendentesEnvio() {
  const { Layout, Info } = CertificadosPendentesEnvio

  const [loading, setLoading] = useState(false)
  const [matriculas, setMatriculas] = useState([])
  const getCertificados = async () => {
    try {
      setLoading(true)
      const response = await axios.Certificados.getPendentes()
      setMatriculas(response)
    } catch (error) {
      const message =
        error.response?.data?.message ??
        'Ocorreu um erro ao buscar os certificados pendentes de envio'
      toast(message)
    } finally {
      setLoading(false)
    }
  }

  useEffect(() => {
    getCertificados()
  }, [])

  if (loading)
    return (
      <Layout>
        <Info>
          <span>Carregando ...</span>
        </Info>
      </Layout>
    )

  if (!matriculas.length)
    return (
      <Layout>
        <Info>
          <span>Nenhum certificado para ser enviado</span>
        </Info>
      </Layout>
    )

  return (
    <Layout>
      <h1>Certificados Pendentes de Envio</h1>

      <Table>
        <thead>
          <tr>
            <th>Nome</th>
            <th style={{ width: 130 }}>Cpf</th>
            <th>Curso</th>
            <th>UF</th>
            <th>Código do certificado</th>
            <th></th>
          </tr>
        </thead>
        <tbody>
          {/* {JSON.stringify(certificados)} */}
          {matriculas.map((matricula) => (
            <Matricula matricula={matricula} key={matricula.id} getCertificados={getCertificados} />
          ))}
        </tbody>
      </Table>
    </Layout>
  )
}
CertificadosPendentesEnvio.Layout = styled.div`
  max-width: 1024px;
  margin: 0 auto;
  padding-top: 48px;
`
CertificadosPendentesEnvio.Info = styled.div`
  display: grid;
  place-content: center;
  height: 200px;
  span {
    padding-bottom: 4px;
    border-bottom: 2px solid var(--primary-color);
  }
`

function Matricula({ matricula, getCertificados }: { matricula: MatriculaModel; getCertificados }) {
  async function handleClick() {
    if (!confirm('Confirma o envio do certificado?')) return
    try {
      await axios.Certificados.updateConfirmacaoEnvio(matricula.id)
      getCertificados()
      toast('Certificado enviado com sucesso!')
    } catch (error) {
      const message =
        error.response?.data?.message ?? 'Ocorreu um erro ao confirmar o envio do certificado'
      toast(message)
    }
  }

  return (
    <tr>
      <td>{matricula.aluno.nome}</td>
      <td>{matricula.aluno.cpf}</td>
      <td>{matricula.curso.nome}</td>
      <td>{matricula.uf}</td>
      <td>{matricula.identificacaoCertificadoRetornado}</td>
      <td>
        <Button onClick={handleClick}>Confirmar envio</Button>
      </td>
    </tr>
  )
}
