import { Input, Select as SelectCmp } from 'semantic-ui-react'
import { useField } from 'formik'
import React from 'react'
import InputMask from 'react-input-mask'
const Select: any = SelectCmp

export const TextField = React.memo((props: any) => {
  const [field, meta] = useField(props.name)
  const { label, ...rootProps } = props
  return (
    <div>
      <label>
        {label}
        <Input
          {...field}
          {...rootProps}
          onChange={(e) => {
            if (props.onChange) props.onChange(e)
            field.onChange(e)
          }}
        />
      </label>
      {meta.touched && meta.error ? (
        <div style={{ textAlign: 'right', color: 'red' }}>{meta.error}</div>
      ) : null}
    </div>
  )
})

export const SelectField = React.memo((props: any) => {
  const [field, meta] = useField(props.name)
  return (
    <div>
      <label>
        {props.label}
        <Select
          {...field}
          {...props}
          onChange={(e, t) => {
            if (props.onChange) props.onChange(e, t)
            field.onChange(e)
          }}
        />
      </label>
      {meta.touched && meta.error ? (
        <div style={{ textAlign: 'right', color: 'red' }}>{meta.error}</div>
      ) : null}
    </div>
  )
})

export const MaskedTextField = ({ mask, onChange, onBlur, ...props }: any) => {
  switch (mask) {
    case 'cpf':
      mask = '999.999.999-99'
      break
    case 'horas':
      mask = '99:99'
      break
    case 'data':
      mask = '99/99/9999'
      break
    case 'celular':
      mask = '(99) 99999-9999'
      break
    default:
      break
  }
  return (
    <InputMask mask={mask} onBlur={onBlur} onChange={onChange}>
      {(inputProps) => <TextField {...props} {...inputProps} />}
    </InputMask>
  )
}
