import React, { useState, useRef, useEffect } from 'react'
import styled from '@emotion/styled'
import { useSpring, animated } from 'react-spring'
import useMeasure from 'react-use-measure'
import { QuestaoModel } from '../../../../models/QuestaoModel'
import { useDecorateBlobWithSas } from '../../../../components/UseDecorateBlobWithSas'

const LETRAS = 'ABCDEFGHIJKLMNOPQ'.split('')

export const RelacionarItens = ({ questao }: { questao: QuestaoModel }) => {
  const alternativas = React.useMemo(() => {
    return questao.questoesAlternativas.map((x) => ({ ...x, ordenacao: Math.random() }))
    // .sort((a, b) => a.ordenacao < b.ordenacao ? 1 : -1)
  }, questao.questoesAlternativas || [])
  const posicoes = useRef<Array<{ alternativaId; lado; posicao }>>([])
  const alternativasEsquerda = alternativas
  const alternativasDireita = [...alternativas].sort((a, b) => (a.ordenacao < b.ordenacao ? 1 : -1))

  const [ref, { top: alternativasContainerTop }] = useMeasure()
  function handleMeasureItem(alternativaId, lado, posicao) {
    posicoes.current = [
      ...posicoes.current.filter((x) => x.alternativaId !== alternativaId || x.lado !== lado),
      { alternativaId, lado, posicao },
    ]
  }

  return (
    <Styles.AlternativasContainer ref={ref}>
      <div>
        {alternativasEsquerda.map((alternativaEsquerda, i) => (
          <AlternativaContainer
            key={i}
            alternativa={alternativaEsquerda}
            lado="esquerda"
            index={i}
            status="marcada"
            onMeasure={handleMeasureItem}
            alternativasContainerTop={alternativasContainerTop}
          />
        ))}
      </div>

      <Linhas posicoes={posicoes.current} alternativas={alternativas} />

      <div>
        {alternativasDireita.map((alternativaDireita, i) => (
          <AlternativaContainer
            key={i}
            alternativa={alternativaDireita}
            lado="direita"
            index={i}
            status="marcada"
            onMeasure={handleMeasureItem}
            alternativasContainerTop={alternativasContainerTop}
          />
        ))}
      </div>
    </Styles.AlternativasContainer>
  )
}

function Linhas({ posicoes, alternativas }) {
  const [ref, { width }] = useMeasure()
  const linhas: Array<{ y1; y2; stroke }> = []
  alternativas.forEach((r) => {
    const posicaoEsquerda = posicoes.find((x) => x.alternativaId === r.id && x.lado === 'esquerda')
    // const posicaoEsquerda = posicoes.find(x => Math.random() < .5)
    const posicaoDireita = posicoes.find((x) => x.alternativaId === r.id && x.lado === 'direita')
    // const posicaoDireita = posicoes.find(x => Math.random() < .5)

    if (posicaoEsquerda && posicaoDireita) {
      linhas.push({
        y1: posicaoEsquerda.posicao,
        y2: posicaoDireita.posicao,
        stroke: 'rgb(77, 77, 77)',
      })
    }
  })

  return (
    <div style={{ flex: 1, minWidth: 100 }} ref={ref}>
      <svg width="100%" height="100%">
        {linhas.map((l, i) => (
          <line
            key={i}
            x1="0"
            y1={l.y1}
            x2={width}
            y2={l.y2}
            style={{ stroke: l.stroke, strokeWidth: 3 }}
          />
        ))}
      </svg>
    </div>
  )
}

function AlternativaContainer({
  alternativa,
  lado,
  status,
  index,
  onMeasure,
  alternativasContainerTop,
}) {
  let item = lado === 'esquerda' ? alternativa.conteudoAlternativa : alternativa.respostaEsperada
  const indicador = lado === 'esquerda' ? LETRAS[index] : index + 1
  item = JSON.parse(item)

  const background = {
    '': '#BDBDBD',
    'incorreta-marcada': '#EB5757',
    'correta-marcada': '#27AE60',
    marcada: '#4D4D4D',
  }[status]
  const borderColor = {
    '': '#E0E0E0',
    'incorreta-marcada': '#EB5757',
    'correta-marcada': '#27AE60',
    marcada: '#4D4D4D',
  }[status]
  const springLetra = useSpring({ background })
  const springDescricao = useSpring({ borderColor })
  const [ref, { top, height }] = useMeasure()
  useEffect(() => {
    if (top && alternativasContainerTop) {
      onMeasure(alternativa.id, lado, top - alternativasContainerTop + height / 2)
    }
  }, [top, height, alternativasContainerTop])

  const imagem = useDecorateBlobWithSas({ blobPath: item.tipo === 'IMAGEM' ? item.item : '' })

  if (!alternativasContainerTop) return null

  return (
    <Styles.Item ref={ref}>
      <animated.div style={springLetra} className="alternativa-letra">
        {' '}
        {indicador}{' '}
      </animated.div>
      <animated.div style={springDescricao} className="alternativa-descricao">
        {item.tipo === 'IMAGEM' ? <img src={imagem} /> : item.item}
      </animated.div>
    </Styles.Item>
  )
}

const Styles = {
  AlternativasContainer: styled('div')`
    display: flex;
    justify-content: space-between;
    overflow: auto;
    margin-bottom: 40px;
    @media screen and (max-width: 425px) {
      margin-bottom: 20px;
    }
  `,
  Item: styled('div')`
    display: flex;
    align-items: strech;
    background: white;
    cursor: pointer;
    --border-radius: 3px;
    box-shadow: 4px 4px 4px rgba(0, 0, 0, 0.08);
    &:not(:last-child) {
      margin-bottom: 16px;
      @media screen and (max-width: 425px) {
        margin-bottom: 12px;
      }
    }
    width: 100%;
    background: #ffffff;
    .alternativa-descricao {
      border-top: 2px solid;
      border-right: 2px solid;
      border-bottom: 2px solid;
      border-color: #e0e0e0;
      border-top-right-radius: var(--border-radius);
      border-bottom-right-radius: var(--border-radius);
      font-family: Rubik;
      font-style: normal;
      font-weight: normal;
      font-size: 18px;
      line-height: 21px;
      color: #4d4d4d;
      flex: 1;
      padding-left: 24px;
      padding-right: 24px;
      display: flex;
      align-items: center;
    }
    .alternativa-letra {
      display: flex;
      font-weight: bold;
      justify-content: center;
      align-items: center;
      width: 64px;
      min-height: 64px;
      background: rgb(77, 77, 77);
      border-top-left-radius: var(--border-radius);
      border-bottom-left-radius: var(--border-radius);
      font-family: Rubik;
      font-style: normal;
      font-weight: 500;
      font-size: 32px;
      line-height: 38px;
      display: flex;
      color: #ffffff;
    }
    &:hover {
      background: #f7f7f7;
    }
    @media screen and (max-width: 425px) {
      .alternativa-descricao {
        font-size: 16px;
        line-height: 19px;
        padding: 4px 12px 4px 12px;
      }
      .alternativa-letra {
        width: 52px;
        min-height: 52px;
        font-size: 28px;
        line-height: 34px;
      }
    }
  `,
}
