import { Button, Container, Icon, Segment as SegmentComp, SemanticCOLORS } from 'semantic-ui-react'
import { FormikProvider, useFormik } from 'formik'
import { bindActionCreators } from 'redux'
import { toast } from 'react-toastify'
import { useDispatch, useSelector } from 'react-redux'
import React, { useEffect, useState } from 'react'
import * as Yup from 'yup'

import * as _ from 'lodash'

import { IStateRedux } from '../../ReduxStore'
import { TextField, MaskedTextField, SelectField } from '../../components/FormikInput'
import { Usuario } from './'
import { usuariosActions } from './UsuariosCursosStore'
import axios from '../../axios'
import useAxios from 'axios-hooks'
const Segment = SegmentComp as any

interface RouterProps {
  match: any
  location: any
  history: any
}
export const UsuariosCursosEditar: React.FC<RouterProps> = ({ history, location }) => {
  const { state } = location
  const [submited, setSubmited] = useState(false)
  if (submited) {
    setTimeout(() => setSubmited(false), 1)
    return null
  }
  return (
    <Container>
      <BotaoVoltar handler={() => history.goBack()} />
      <Formulario
        setSubmited={setSubmited}
        cursoId={_.get(state, 'cursoId', null)}
        perfil={_.get(state, 'perfil', null)}
      />
    </Container>
  )
}

interface FormularioProps {
  cursoId: number
  perfil: string
  setSubmited: (state: boolean) => any
}
const Formulario: React.FC<FormularioProps> = ({ cursoId, perfil, setSubmited }) => {
  const dispatch = useDispatch()
  const [cursoActive, setCursoActive] = useState(null)
  const [cursos, setCursos] = useState([])

  const actions = bindActionCreators(
    {
      ...usuariosActions,
    },
    dispatch
  )

  useEffect(() => {
    if (cursoId && !cursoActive) {
      axios.Cursos.get(null, cursoId).then((curso) => {
        console.log({ curso: curso.rows[0] })
        setCursoActive(curso.rows[0])
        setCursos(curso.rows)
      })
    }
    if (!cursoId) {
      axios.Cursos.get(null, cursoId).then((cursos) => {
        setCursos(cursos.rows)
      })
    }
  }, [cursos, cursoActive])

  const FormularioSchema = Yup.object().shape({
    nome: Yup.string().required('O nome é obrigatório'),
    cpf: Yup.string().min(14, 'CPF inválido').required('O cpf é obrigatório'),
    celular: Yup.string().required('Celular é obrigatório'),
    cursoId: Yup.number().moreThan(0).required('O curso é obrigatório'),
    perfil: Yup.string()
      .matches(/^(?!.*DEFAULT)/)
      .required('O perfil é obrigatório'),
    email: Yup.string().email('Email inválido').required('O Email é obrigatório'),
  })

  const formik = useFormik({
    initialValues: {
      id: '',
      nome: '',
      cpf: '',
      email: '',
      celular: '',
      perfil: perfil || 'DEFAULT',
      cursoId: cursoId || 0,
    },
    validationSchema: FormularioSchema,
    onSubmit: async (values) => {
      const usuario = {
        id: values.id,
        nome: values.nome,
        cpf: values.cpf,
        email: values.email,
        celular: values.celular,
        permissoes: [{ perfil: values.perfil, cursoId: values.cursoId }],
      } as any
      await axios.UsuariosCursos.postPermissao(usuario)
      toast.success('Usuario cadastrado com sucesso')
      setSubmited(true)
    },
  })

  return (
    <BlocoConteudo color="purple">
      <h3>Editar usuário</h3>
      <FormikProvider value={formik}>
        <form onSubmit={formik.handleSubmit}>
          <TextField
            label="ID"
            fluid
            disabled
            name="id"
            placeholder="id"
            className="input-uppercase"
          />
          <br />
          <TextField
            label="Nome"
            fluid
            name="nome"
            placeholder="Nome"
            className="input-uppercase"
          />
          <br />
          <MaskedTextField label="CPF" mask="cpf" fluid name="cpf" placeholder="CPF" />
          <br />
          <TextField fluid label="E-mail" name="email" placeholder="E-mail" />
          <br />
          <MaskedTextField
            label="Celular"
            mask="celular"
            fluid
            name="celular"
            placeholder="Celular"
          />
          <br />
          <SelectField
            onChange={async (_, { name, value }) => await formik.setFieldValue(name, value)}
            fluid
            label="Perfil"
            name="perfil"
            disabled={!!perfil}
            options={[
              { key: 'DEFAULT', value: 'DEFAULT', text: 'Escolha o perfil do usuário' },
              { key: 'TUTOR', value: 'TUTOR', text: 'TUTOR' },
              { key: 'ALUNO', value: 'ALUNO', text: 'ALUNO' },
            ]}
          />
          <br />
          <SelectField
            onChange={async (_, { name, value }) => await formik.setFieldValue(name, value)}
            fluid
            disabled={!!cursoId}
            label="Curso"
            name="cursoId"
            options={cursos
              .map((c) => ({ key: c.id, value: c.id, text: c.nome }))
              .concat([{ key: 0, value: 0, text: 'Escolha o curso do usuário' }])}
          />
          <br />
          <div style={{ display: 'table', width: '100%' }}>
            <div style={{ display: 'inline-block', float: 'right' }}>
              <Button fluid primary type="submit">
                Criar Usuário
              </Button>
            </div>
          </div>
        </form>
      </FormikProvider>
    </BlocoConteudo>
  )
}

interface BlocoConteudoProps {
  color: SemanticCOLORS
  children: React.ReactNode
}
const BlocoConteudo: React.FC<BlocoConteudoProps> = ({ color, children }) => (
  <Segment color={color} style={{ margin: '25px' }}>
    {children || null}
  </Segment>
)

const BotaoVoltar = ({ handler }) => (
  <Button
    secondary
    basic
    style={{ marginTop: '25px', marginLeft: '25px' }}
    onClick={handler}
    circular
  >
    <Icon name="arrow left" />
    Voltar
  </Button>
)
