import React, { useState, useRef, useCallback } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { IStateRedux } from '../../../ReduxStore'
import { bindActionCreators } from 'redux'
import { questaoEditorActions } from '../QuestaoEditorStore'
import { Button } from 'semantic-ui-react'
import { QuestaoAlternativaModel } from '../../../models/QuestaoAlternativaModel'
import { Util, Show, ContainersArquivos } from '../../Util'
import styled from '@emotion/styled'
import { filesUrl } from '../../../index'
import { QuestaoModel } from '../../../models/QuestaoModel'
import { ItemAlternativa } from './ItemAlternativa'
import { createSelector } from 'reselect'

interface IEstiloMarcador {
  backgroundColor?: string
  borderColor?: string
  borderWidth?: number
  width?: number
  circle?: boolean
  top?: number
  left?: number
  imageSize?
  grabable?
}

interface IConfiguracaoAdicional {
  estiloMarcador?: IEstiloMarcador
  imagemQuestao?: string
}

const selectQuestao = createSelector(
  (state: IStateRedux) => state.questaoEditor,
  (_, questaoId) => questaoId,
  (questaoEditor, questaoId) => {
    return questaoEditor[questaoId as any]
      ? questaoEditor[questaoId as any].questao
      : { questoesAlternativas: [] }
  }
) as any

const selectConfiguracaoAdicional = createSelector(selectQuestao, (questao: QuestaoModel) =>
  questao.configuracaoAdicional
    ? JSON.parse(questao.configuracaoAdicional)
    : {
        imagemQuestao: null,
        estiloMarcador: {
          backgroundColor: 'transparent',
          borderColor: 'white',
          borderWidth: 6,
          circle: true,
          width: 4,
          left: 50,
          top: 50,
        },
      }
) as any

export function RelacionarItemImagem({ questaoId }: any) {
  const dispatch = useDispatch()
  const questao: QuestaoModel = useSelector((state) => selectQuestao({ state, questaoId }))
  const actions = bindActionCreators(questaoEditorActions, dispatch)

  function handleClickAdicionarAlternativa() {
    actions.addAlternativa({ questaoId })
  }

  return (
    <div>
      <ImagemQuestaoContainer questaoId={questaoId} />

      <ConfiguracaoEstiloMarcadorGeral questaoId={questaoId} />

      <Button onClick={handleClickAdicionarAlternativa}>Adicionar Alternativa</Button>

      {questao.questoesAlternativas.map((alternativa, index) => (
        <Alternativa
          questaoId={questaoId}
          questaoAlternativa={alternativa}
          index={index}
          key={alternativa.id}
        />
      ))}
    </div>
  )
}

function ImagemQuestaoContainer({ questaoId }: { questaoId: string; alternativaId?: number }) {
  const dispatch = useDispatch()
  const configuracaoAdicional = useSelector((state) =>
    selectConfiguracaoAdicional({ state, questaoId })
  )
  const questao: QuestaoModel = useSelector((state) => selectQuestao({ state, questaoId }))
  const actions = bindActionCreators(questaoEditorActions, dispatch)

  async function handleClickSelecionarImagem() {
    const imagemQuestao = await Util.requestUploadImagem({
      containerName: ContainersArquivos.QUESTAO,
    })
    if (!imagemQuestao) return
    const newConfiguracaoAdicional = JSON.stringify({ ...configuracaoAdicional, imagemQuestao })
    actions.setConfiguracaoAdicional({ questaoId, configuracaoAdicional: newConfiguracaoAdicional })
  }
  function handleChangePosition(left, top, alternativaId) {
    const alternativa = questao.questoesAlternativas.find((x) => x.id === alternativaId)
    const respostaEsperada = JSON.parse(alternativa.respostaEsperada || '{}')
    const newRespostaEsperada: IEstiloMarcador = { ...respostaEsperada, left, top }
    const newAlternativa: QuestaoAlternativaModel = {
      ...alternativa,
      respostaEsperada: JSON.stringify(newRespostaEsperada),
    }
    actions.changeAlternativaById({ questaoId, alternativa: newAlternativa })
  }

  const marcadores = questao.questoesAlternativas.map((x) => {
    return { estiloMarcador: x.respostaEsperada ? JSON.parse(x.respostaEsperada) : null, id: x.id }
  })
  return (
    <div>
      <Button onClick={handleClickSelecionarImagem}>Selecionar imagem</Button>
      <Show condition={configuracaoAdicional.imagemQuestao}>
        <ImagemQuestao
          marcadores={marcadores}
          imagemQuestao={configuracaoAdicional.imagemQuestao}
          estiloMarcadorGeral={configuracaoAdicional.estiloMarcador}
          onEndChangePosition={handleChangePosition}
          onClick={undefined}
          showIndex={true}
        />
      </Show>
    </div>
  )
}

function ImagemQuestao({
  marcadores,
  imagemQuestao,
  estiloMarcadorGeral,
  onEndChangePosition,
  showIndex,
  onClick,
}) {
  const [imageWidth, setImageWidth] = useState(0)
  const measuredRef = useCallback((node) => {
    if (node !== null) setImageWidth(node.getBoundingClientRect().width)
  }, [])

  return (
    <Style.Imagem>
      <img ref={measuredRef} src={`${filesUrl}/${imagemQuestao}`} onClick={onClick} />
      {marcadores.map((marcador, index) => (
        <Show condition={marcador.estiloMarcador} key={marcador.id}>
          <Marcador
            marcadorId={marcador.id}
            estiloMarcadorGeral={estiloMarcadorGeral}
            estiloMarcadorEspecifico={marcador.estiloMarcador}
            onEndChangePosition={onEndChangePosition}
            imageSize={imageWidth}
            index={showIndex ? index : null}
          />
        </Show>
      ))}
    </Style.Imagem>
  )
}

const Marcador = ({
  estiloMarcadorGeral,
  estiloMarcadorEspecifico,
  onEndChangePosition,
  index,
  imageSize,
  marcadorId,
}) => {
  const estiloMarcador = {
    top: 50,
    left: 50,
    ...estiloMarcadorGeral,
    ...estiloMarcadorEspecifico,
  }

  const { circle, borderWidth, borderColor, backgroundColor, ...estiloExterno } = estiloMarcador

  const ref = useRef(null)
  const left = useRef<number>(0)
  const top = useRef<number>(0)
  const [isGrabbing, setIsGrabbing] = useState(false)
  function handleMouseMove(e) {
    if (!isGrabbing) return
    const rects = e.currentTarget.parentNode.getBoundingClientRect()
    left.current = Math.min(100, Math.max(0, ((e.clientX - rects.x) / rects.width) * 100))
    top.current = Math.min(100, Math.max(0, ((e.clientY - rects.y) / rects.height) * 100))
    ref.current.style.left = left.current + '%'
    ref.current.style.top = top.current + '%'
  }
  function handleMouseDown(e) {
    if (onEndChangePosition) setIsGrabbing(true)
  }
  function handleMouseUp(e) {
    if (!onEndChangePosition) return
    onEndChangePosition(left.current, top.current, marcadorId)
    ref.current.style.left = ''
    ref.current.style.top = ''
    setIsGrabbing(false)
  }
  return (
    <Style.Marcador
      onMouseDown={handleMouseDown}
      onMouseUp={handleMouseUp}
      onMouseMove={handleMouseMove}
      {...estiloMarcador}
      imageSize={imageSize}
      ref={ref}
    >
      <Style.MarcadorInterno
        {...{ circle, borderWidth, borderColor, backgroundColor, imageSize }}
        grabable={!!onEndChangePosition}
      />
      <Show condition={index != null}>
        <Style.MarcadorNumero borderColor={borderColor}>{index + 1}</Style.MarcadorNumero>
      </Show>
    </Style.Marcador>
  )
}

function ConfiguracaoEstiloMarcadorGeral({ questaoId }) {
  const dispatch = useDispatch()
  const actions = bindActionCreators(questaoEditorActions, dispatch)
  const configuracaoAdicional = useSelector((state) =>
    selectConfiguracaoAdicional({ state, questaoId })
  )

  function handleChangeConfig(newEstiloMarcador: IEstiloMarcador) {
    const newConfiguracaoAdicional: IConfiguracaoAdicional = {
      ...configuracaoAdicional,
      estiloMarcador: newEstiloMarcador,
    }
    actions.setConfiguracaoAdicional({
      questaoId,
      configuracaoAdicional: JSON.stringify(newConfiguracaoAdicional),
    })
  }

  return (
    <div>
      <ConfiguracaoEstiloMarcador
        estiloMarcador={configuracaoAdicional.estiloMarcador}
        onChangeEstiloMarcador={handleChangeConfig}
      />
    </div>
  )
}

function ConfiguracaoEstiloMarcador({
  estiloMarcador,
  onChangeEstiloMarcador,
  disabled,
}: {
  estiloMarcador: IEstiloMarcador
  disabled?: boolean
  onChangeEstiloMarcador: (newEstiloMarcador: IEstiloMarcador) => void
}) {
  function handleChange(e) {
    const newEstiloMarcador = { ...estiloMarcador, [e.currentTarget.name]: e.currentTarget.value }
    if (e.currentTarget.value === '') delete newEstiloMarcador[e.currentTarget.name]
    onChangeEstiloMarcador(newEstiloMarcador)
  }
  if (!estiloMarcador) estiloMarcador = {}
  return (
    <div>
      <div>
        Cor do fundo
        <input
          disabled={disabled}
          value={estiloMarcador.backgroundColor || ''}
          name="backgroundColor"
          onChange={handleChange}
        />
      </div>
      <div>
        Cor da borda
        <input
          disabled={disabled}
          value={estiloMarcador.borderColor || ''}
          name="borderColor"
          onChange={handleChange}
        />
      </div>
      <div>
        Largura da borda
        <input
          disabled={disabled}
          value={estiloMarcador.borderWidth || ''}
          name="borderWidth"
          onChange={handleChange}
        />
      </div>
      <div>
        Tamanho
        <input
          disabled={disabled}
          value={estiloMarcador.width || ''}
          name="width"
          onChange={handleChange}
        />
      </div>
      <div>
        Circulo
        <input
          disabled={disabled}
          type="checkbox"
          checked={estiloMarcador.circle}
          name="circle"
          onChange={handleChange}
        />
      </div>
    </div>
  )
}

interface AlternativaProps {
  questaoId: string
  questaoAlternativa: QuestaoAlternativaModel
  index?: number
  estiloMarcadorGeral?: any
}

const Alternativa = ({ questaoId, questaoAlternativa, index }: AlternativaProps) => {
  const dispatch = useDispatch()
  const configuracaoAdicional = useSelector((state) =>
    selectConfiguracaoAdicional({ state, questaoId })
  )
  const actions = bindActionCreators(questaoEditorActions, dispatch)
  const respostaEsperada = questaoAlternativa.respostaEsperada
    ? JSON.parse(questaoAlternativa.respostaEsperada)
    : null
  const handleUpdateConteudoAlternativa = useCallback(
    (conteudoAlternativa) => {
      actions.changeConteudoAlternativa({
        questaoId,
        alternativaId: questaoAlternativa.id,
        conteudoAlternativa,
      } as any)
    },
    [questaoAlternativa]
  )
  function handleDeleteAlternativa() {
    actions.deleteAlternativaById({ questaoId, alternativaId: questaoAlternativa.id })
  }
  function handleClickImagem(e) {
    const rects = e.currentTarget.parentNode.getBoundingClientRect()
    const left = Math.min(100, Math.max(0, ((e.clientX - rects.x) / rects.width) * 100))
    const top = Math.min(100, Math.max(0, ((e.clientY - rects.y) / rects.height) * 100))
    const newRespostaEsperada = JSON.stringify({ ...respostaEsperada, left, top })
    actions.changeRespostaEsperada({
      questaoId,
      alternativaId: questaoAlternativa.id,
      respostaEsperada: newRespostaEsperada,
    })
  }
  function handleChangeEstiloMarcador(newEstiloMarcador: IEstiloMarcador) {
    const { left, top } = respostaEsperada
    const newRespostaEsperada = JSON.stringify({ left, top, ...newEstiloMarcador })
    const alternativa = { ...questaoAlternativa, respostaEsperada: newRespostaEsperada }
    actions.changeAlternativaById({ questaoId, alternativa })
  }
  function handleClickRemoveMarcador() {
    actions.changeRespostaEsperada({
      questaoId,
      alternativaId: questaoAlternativa.id,
      respostaEsperada: null,
    })
  }

  const estiloMarcador = respostaEsperada
  const marcadores = [{ id: questaoAlternativa.id, estiloMarcador }]

  return (
    <Style.ContainerConceito>
      <div className="indice">{index + 1}</div>
      <div>
        <ItemAlternativa
          item={questaoAlternativa.conteudoAlternativa}
          updateItem={handleUpdateConteudoAlternativa}
        />
      </div>
      <div>
        <ImagemQuestao
          marcadores={marcadores}
          imagemQuestao={configuracaoAdicional.imagemQuestao}
          estiloMarcadorGeral={configuracaoAdicional.estiloMarcador}
          onEndChangePosition={null}
          onClick={handleClickImagem}
          showIndex={false}
        />
        <Button onClick={handleClickRemoveMarcador}>Remover Marcador</Button>
      </div>
      <div>
        <ConfiguracaoEstiloMarcador
          estiloMarcador={estiloMarcador}
          onChangeEstiloMarcador={handleChangeEstiloMarcador}
          disabled={!estiloMarcador}
        />
      </div>
      <div className="delete">
        <Button icon="trash" onClick={handleDeleteAlternativa} />
      </div>
    </Style.ContainerConceito>
  )
}

const Style = {
  Imagem: styled('div')`
    position: relative;
    img {
      width: 100%;
    }
  `,
  Img: styled('img')`
    width: 100%;
  `,
  Marcador: styled('div')<IEstiloMarcador>(
    (props) => `
    display:block;
    position: absolute;
    top: ${props.top}%;
    left: ${props.left}%;
    width: ${props.width || 3}%;
  `
  ),
  MarcadorInterno: styled('div')<IEstiloMarcador>(
    (props) => `
    transform: translate3d(-50%, -50%, 0);
    ${props.grabable ? 'cursor: grab;' : ''}
    border-radius: ${props.circle ? '50%' : 'unset'};
    border: ${(props.borderWidth * props.imageSize) / 1000}px solid ${props.borderColor};
    background-color: ${props.backgroundColor || 'transparent'};
    ${
      props.grabable
        ? `&:active {
          cursor: grabbing;
        }`
        : ''
    }
    &:before {
      content: "";
      display: block;
      padding-top: 100%;
    }
  `
  ),
  MarcadorNumero: styled('div')<IEstiloMarcador>(
    (props) => `
    position: relative;
    color: ${props.borderColor || 'white'};
    width: 50%;
    text-align: right;
    text-shadow: 1px 1px 3px rgba(0,0,0,.5);
    margin-top: -75%;
  `
  ),
  ContainerConceito: styled('div')`
    display: flex;
    padding: 12px;
    border: 1px solid #ccc;
    border-radius: 4px;
    margin-bottom: 12px;
    & > div {
      flex: 1;
      &:not(:first-child) {
        margin-left: 12px;
      }
      &.delete {
        flex: none;
        align-self: flex-end;
      }
      &.indice {
        width: 32px;
        height: 32px;
        border-radius: 50%;
        background-color: #888;
        flex: none !important;
        display: flex;
        justify-content: center;
        align-items: center;
        color: white;
        font-weight: bold;
      }
    }
    &:not(:first-child) {
      border-top: 1px solid #ccc;
    }
  `,
}
