import { Button, Modal } from 'semantic-ui-react'
import { useParams } from 'react-router'
import React, { useEffect, useState } from 'react'
import styled from '@emotion/styled'

import { ConteudoContainer } from './ConteudoContainer'
import { CursoModel } from '../../models/CursoModel'
import { DisplayFlex, Padding, Table, styles } from '../../components/Styles'
import { Hide, Show } from '../../components/Basic'
import { TIPOS_CONTEUDO } from '../../components/Constants'
import { EditorConteudoStore } from './EditorConteudoStore'
import axios from '../../axios'

const ModalImportarConteudo = () => {
  const { moduloId: moduloIdCarregadoNaPagina } = useParams<{ moduloId }>()
  const dispatch = EditorConteudoStore.useDispatch()
  const state = EditorConteudoStore.useState()
  const [cursos, setCursos] = useState([])
  const [modulos, setModulos] = useState([])
  const [paginas, setPaginas] = useState([])

  const [cursoAtivo, setCursoAtivo] = useState(null)
  const [moduloAtivo, setModuloAtivo] = useState(null)
  const [paginasAtivas, setPaginasAtivas] = useState([])

  useEffect(() => {
    async function getCursos() {
      try {
        const cursos = (await axios.Cursos.get()) as any
        setCursos(cursos.rows)
      } catch (e) {
        console.log(e)
      }
    }

    getCursos()
  }, [])

  useEffect(() => {
    async function getModulos() {
      try {
        const modulos = (await axios.Modulos.get(null, cursoAtivo)) as any
        setModulos(modulos.rows)
      } catch (e) {
        console.log(e)
      }
    }

    if (cursoAtivo) {
      getModulos()
    }
  }, [cursoAtivo])

  useEffect(() => {
    async function getPaginas() {
      try {
        const paginas = await axios.Paginas.getByModulo(moduloAtivo)
        setPaginas(paginas.filter((pagina: any) => pagina.status === 'PUBLICADO'))
      } catch (e) {
        console.log(e)
      }
    }

    if (moduloAtivo) {
      getPaginas()
    }
  }, [moduloAtivo])

  const handleClose = () => {
    dispatch(EditorConteudoStore.thunks.setImportarPagina(false))
  }

  const escolherCurso = (curso) => {
    setCursoAtivo(curso.id)
  }

  const escolherModulo = (modulo) => {
    setModuloAtivo(modulo.id)
  }

  const togglePagina = (pagina) => {
    const _paginas = paginas.filter((p) => p.id !== pagina.id)
    pagina.active = !pagina.active
    _paginas.push(pagina)
    setPaginas(_paginas.sort((x, y) => (x.numero > y.numero ? 1 : -1)))
  }

  const handleImportar = () => {
    const _paginas = paginas.filter((p) => p.active).map((p) => p.id)
    dispatch(EditorConteudoStore.thunks.importarPaginas(moduloIdCarregadoNaPagina, _paginas))
    handleClose()
  }

  return (
    <Modal closeIcon onClose={handleClose} open={state.importarPagina}>
      <Modal.Header>Importar Página</Modal.Header>
      <Modal.Content>
        <div>
          <h4> Cursos </h4>
          <Lista itens={cursos} onClick={escolherCurso} />
        </div>
        <div>
          <h4> Modulos </h4>
          <Lista itens={modulos} onClick={escolherModulo} />
        </div>
        <div>
          <h4> Paginas </h4>
          <Lista itens={paginas.map((p) => ({ ...p, nome: p.titulo }))} onClick={togglePagina} />
          <Button onClick={handleImportar}>Importar</Button>
        </div>
      </Modal.Content>
    </Modal>
  )
}

const Lista = ({
  itens,
  onClick,
  multiple,
}: {
  itens: any[]
  onClick: (val: any) => void
  multiple?: boolean
}) => {
  if (!itens) return null
  return (
    <Table>
      <table>
        <thead>
          <tr>
            <th>Nome</th>
          </tr>
        </thead>
        <tbody>
          {itens.map((item: any) => (
            <tr
              key={item.id}
              style={{ backgroundColor: item.active ? '#ddd' : 'white' }}
              onClick={() => onClick(item)}
            >
              <td>{item.nome}</td>
            </tr>
          ))}
        </tbody>
      </table>
    </Table>
  )
}

const NovoConteudoTexto = styled('div')`
  width: 100%;
  border: 1px solid #ccc;
  height: 42px;
`

export { ModalImportarConteudo }
