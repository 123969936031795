import { Button, Icon, Modal } from 'semantic-ui-react'
import { useParams, useHistory } from 'react-router'
import React, { useEffect, useLayoutEffect, useMemo } from 'react'
import styled from '@emotion/styled'

import {
  EditorConteudoStore,
  historicoPaginaAtiva,
  selectUltimoSalvamento,
  selectTituloPagina,
  selectPaginas,
} from './EditorConteudoStore'
import { Pagina } from './Pagina'
import { Show } from '../../components/Basic'
import { Toolbox } from './toolbox'
import { colors, text } from '../../theme'
import { ModalImportarConteudo } from './ImportarConteudo'
import { DisplayFlex } from '../../components/Styles'
import { animated, useSpring } from 'react-spring'
import { format } from 'date-fns'
import { ConteudoPaginaModel } from '../../models/ConteudoPaginaModel'
import { QuestaoEditor, TIPO_BOTAO } from '../../components/QuestaoEditor/QuestaoEditor'
import { QuestaoModel } from '../../models/QuestaoModel'
import { questoesActions } from '../Questoes/QuestoesStore'
import { bindActionCreators } from 'redux'
import { useDispatch } from 'react-redux'

export const EditorConteudo = () => {
  return (
    <EditorConteudoStore.Provider>
      <Layout>
        <Topbar className="topbar" />
        <SeletorPaginas className="seletor-paginas" />
        <div className="conteudo">
          <div className="conteudo-inner">
            <Pagina />
          </div>
        </div>
        <Toolbox className="componentes" />
        <Publicar className="publicar" />
        <Modais>
          <ModalImportarConteudo />
          <ModalEditarQuestao />
        </Modais>
        <Init />
      </Layout>
    </EditorConteudoStore.Provider>
  )
}

const Modais = ({ children }) => <>{children}</>

const Init = () => {
  const { paginaAtiva } = EditorConteudoStore.useState()
  const dispatch = EditorConteudoStore.useDispatch()
  const { moduloId } = useParams<any>()
  useEffect(() => {
    dispatch(EditorConteudoStore.thunks.getPaginas(moduloId))
  }, [])
  useEffect(() => {
    if (paginaAtiva) historicoPaginaAtiva.set(paginaAtiva.id)
  }, [paginaAtiva])

  return null
}

const ModalEditarQuestao = () => {
  const { moduloId } = useParams<any>()
  const editorConteudoDispatch = EditorConteudoStore.useDispatch()
  const { questaoEdicao, paginaAtiva } = EditorConteudoStore.useState()

  function handleClose() {
    editorConteudoDispatch(EditorConteudoStore.actions.closeQuestaoEdicao())
  }

  function handleQuestaoSave(questao: QuestaoModel) {
    if (questaoEdicao.questaoId) {
      editorConteudoDispatch(
        EditorConteudoStore.thunks.updateConteudo(questaoEdicao.conteudoId, questao.id.toString())
      )
    } else {
      editorConteudoDispatch(
        EditorConteudoStore.thunks.addConteudo(
          moduloId,
          paginaAtiva.id,
          'QUESTAO',
          questao.id.toString()
        )
      )
    }
  }

  if (!questaoEdicao) return null
  return (
    <Modal closeIcon onClose={handleClose} open={questaoEdicao != null}>
      <Modal.Header>{questaoEdicao.questaoId ? 'Editando questão' : 'Nova questão'}</Modal.Header>
      <Modal.Content>
        <QuestaoEditor
          questaoId={questaoEdicao.questaoId}
          buttons={[TIPO_BOTAO.CANCELAR, TIPO_BOTAO.SALVAR]}
          moduloId={+moduloId}
          local="CONTEUDO"
          tipoQuestao={questaoEdicao.tipoQuestao}
          onQuestaoSave={handleQuestaoSave}
          onCancel={handleClose}
        />
      </Modal.Content>
    </Modal>
  )
}

const Publicar = ({ ...props }: React.HTMLAttributes<HTMLDivElement>) => {
  const { moduloId } = useParams<any>()
  const dispatch = EditorConteudoStore.useDispatch()
  const { paginas } = EditorConteudoStore.useState()
  const onClickPublicar = () => {
    dispatch(EditorConteudoStore.thunks.publicar(moduloId))
  }

  const hasRascunho = !!paginas.find(
    (p) => p.status === 'RASCUNHO' || p.status === 'NOVO' || p.status === 'RASCUNHO_ARQUIVO'
  )

  return (
    <PublicarButtonContainer {...props} onClick={onClickPublicar} disabled={!hasRascunho}>
      <BotaoPublicar>Publicar</BotaoPublicar>
    </PublicarButtonContainer>
  )
}

const PublicarButtonContainer = styled('div')<{ disabled }>`
  background-color: ${(props: any) => (props.disabled ? '#C4C4C4' : colors.secondary)};
  color: white;
  cursor: ${(props: any) => (props.disabled ? 'default' : 'pointer')};
`

const BotaoPublicar = styled('div')`
  height: 60px;
  font-weight: 500;
  display: flex;
  align-items: center;
  justify-content: center;
`

const SeletorPaginas = ({ ...props }: React.HTMLAttributes<HTMLDivElement>) => {
  const state = EditorConteudoStore.useState()
  const paginas = selectPaginas(state)

  const TituloSeletorPaginas = styled('div')`
    font-size: 1.5em;
    font-weight: 700;
    margin-bottom: 10px;
    margin-left: 5px;
    i {
      color: var(--primary-color);
    }
  `

  if (!paginas) return null

  return (
    <Styles.Container {...props}>
      <TituloSeletorPaginas>
        <Icon name="file outline"></Icon>
        Páginas
      </TituloSeletorPaginas>
      {paginas.map((p) => (
        <PaginaItem key={p.id} pagina={p} />
      ))}
    </Styles.Container>
  )
}

const Styles = {
  Container: styled('div')`
    padding: 18px;
  `,
}

const DescricaoContainer = styled('div')<{ active: boolean }>(
  (props) => `
  cursor: pointer;
  background-color: ${props.active ? 'var(--primary-color)' : '#F2F2F2'};
  color: ${props.active ? 'var(--primary-color-contrast)' : 'black'};
  padding: 13px 12px;
  font-size: 14px;
  margin-bottom: 4px;
  border-radius: 4px;
  transition: .25s background-color;
  &:hover {
    background-color: ${props.active ? 'var(--primary-color-shade)' : '#dadada'};
  }
`
)

const PaginaItem = ({ pagina }: { pagina: ConteudoPaginaModel }) => {
  const dispatch = EditorConteudoStore.useDispatch()
  const state = EditorConteudoStore.useState()
  const { paginaAtiva } = state
  const stateWithPaginaId = useMemo(() => ({ ...state, paginaId: pagina.id }), [state, pagina.id])
  const titulo = selectTituloPagina(stateWithPaginaId)
  const Container = styled('div')`
    margin-bottom: 18px;
  `

  const Titulo = styled('span')`
    font-weight: bold;
    margin-bottom: 5px;
  `

  const Observacao = styled('span')`
    text-align: right;
    font-size: 0.9em;
    i {
      color: var(--primary-color);
    }
  `

  const Descricao = styled('span')``

  const clickPagina = () => {
    dispatch(EditorConteudoStore.thunks.getPaginaConteudo(pagina.id))
  }

  const isPaginaAtiva = paginaAtiva?.identificadorUnico === pagina?.identificadorUnico

  return (
    <Container>
      <DescricaoContainer onClick={clickPagina} active={isPaginaAtiva}>
        <Descricao>{titulo || <em>Informe o título</em>}</Descricao>
      </DescricaoContainer>
      <Observacao>
        {(pagina?.status === 'RASCUNHO' || pagina?.status === 'NOVO') && (
          <div>
            <Icon name="flag outline"></Icon> Rascunho
          </div>
        )}
        {pagina?.status === 'RASCUNHO_ARQUIVO' && 'Removido'}
      </Observacao>
    </Container>
  )
}

const Style = {
  PaginaItem: styled('div')``,
}

const Topbar = ({ ...props }: React.HTMLAttributes<HTMLDivElement>) => {
  const history = useHistory()
  const { paginaAtiva } = EditorConteudoStore.useState()
  const dispatch = EditorConteudoStore.useDispatch()
  const novaPaginaRascunho = () =>
    dispatch(
      EditorConteudoStore.thunks.novaPaginaRascunho(
        paginaAtiva.moduloId,
        paginaAtiva.identificadorUnico
      )
    )
  const excluirPagina = () => dispatch(EditorConteudoStore.thunks.deletePagina(paginaAtiva.id))
  const restaurarPagina = () => dispatch(EditorConteudoStore.thunks.restaurarPagina(paginaAtiva.id))

  const isRascunho =
    paginaAtiva && (paginaAtiva.status === 'RASCUNHO' || paginaAtiva.status === 'NOVO')
  const isRascunhoArquivo = paginaAtiva && paginaAtiva.status === 'RASCUNHO_ARQUIVO'
  console.log({ isRascunhoArquivo })

  const onClickVoltar = () => {
    history.goBack()
  }

  return (
    <DisplayFlex {...props} center={true} spacing="between" style={{ padding: '0 16px' }}>
      <div>
        <BotaoVoltar onClick={onClickVoltar}>
          <Icon name="angle left" className="priamryColor"></Icon>
          <span style={{ fontWeight: 'bold' }}>Voltar</span>
        </BotaoVoltar>
      </div>
      <InfoSalvoAutomaticamente />
      <div>
        <Show condition={!isRascunho}>
          <Button
            style={{
              padding: '9.5px 45px',
              fontWeight: '500',
              backgroundColor: colors.secondary,
              color: text.color,
            }}
            size="tiny"
            circular
            onClick={novaPaginaRascunho}
          >
            Editar
          </Button>
        </Show>
        <Show condition={isRascunho}>
          <Button
            style={{
              padding: '9.5px 35px',
              fontWeight: '500',
              backgroundColor: colors.secondary,
              color: text.color,
            }}
            size="tiny"
            circular
          >
            Salvar Rascunho
          </Button>
          <Button
            style={{ padding: '9.5px 35px', backgroundColor: colors.secondary, color: text.color }}
            circular
            size="tiny"
            basic
            onClick={excluirPagina}
          >
            Excluir Página
          </Button>
        </Show>
        <Show condition={isRascunhoArquivo}>
          <Button
            style={{
              padding: '9.5px 35px',
              fontWeight: '500',
              backgroundColor: colors.secondary,
              color: text.color,
            }}
            secondary
            size="tiny"
            circular
            onClick={restaurarPagina}
          >
            Restaurar
          </Button>
        </Show>
      </div>
    </DisplayFlex>
  )
}

const InfoSalvoAutomaticamente = () => {
  const state = EditorConteudoStore.useState()
  const ultimoSalvamento = selectUltimoSalvamento(state)
  if (!state.paginaAtiva || state.paginaAtiva.status === 'PUBLICADO') return null
  if (!ultimoSalvamento) return null
  return (
    <InfoSalvoAutomaticamenteAnimated key={format(ultimoSalvamento, 'yyyymmddhhmmss')}>
      <IconPrimary name="clipboard check" /> Salvo automaticamente às{' '}
      {format(ultimoSalvamento, 'HH:mm')}
    </InfoSalvoAutomaticamenteAnimated>
  )
}

const IconPrimary = styled(Icon)`
  & {
    color: var(--primary-color);
  }
`

const InfoSalvoAutomaticamenteAnimated = ({ children }) => {
  const springFade = useSpring({ opacity: 0, from: { opacity: 1 }, config: { duration: 2500 } })
  return (
    <animated.div
      style={{
        opacity: springFade.opacity.interpolate({
          range: [0, 0.2, 0.6, 1],
          output: [0, 1, 1, 0],
        }),
      }}
    >
      {children}
    </animated.div>
  )
}

const Layout = styled('div')`
  display: grid;
  grid-template-columns: 315px 1fr 300px;
  @media (max-width: 1280px) {
    grid-template-columns: 275px 1fr 250px;
  }
  grid-template-rows: 50px 1fr 60px;
  grid-template-areas:
    'topbar topbar topbar'
    'seletor-paginas conteudo componentes'
    'seletor-paginas conteudo publicar';
  .topbar {
    grid-area: topbar;
  }
  .seletor-paginas {
    grid-area: seletor-paginas;
  }
  .conteudo {
    grid-area: conteudo;
    margin: 0 auto;
    width: 100%;
    padding: 18px 0;
    & > div {
      max-width: 1024px;
      margin: 0 auto;
    }
  }
  .componentes {
    grid-area: componentes;
  }
  .publicar {
    grid-area: publicar;
  }
  .topbar,
  .seletor-paginas,
  .conteudo,
  .componentes,
  .publicar {
    overflow: auto;
  }
  .seletor-paginas,
  .conteudo,
  .componentes {
    border: 1px solid #f2f2f2;
  }
`

const BotaoVoltar = styled('div')`
  cursor: pointer;
  width: max-content;
  border-radius: 10em;
  padding: 9.5px 22px;
  transition: 0.25s background-color;
  &:hover {
    background-color: #f2f2f2;
  }
`
