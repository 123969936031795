import {
  Button,
  Container,
  Icon,
  Input,
  Segment as SegmentComp,
  SemanticCOLORS,
  Table as TableComp,
  Pagination,
  Modal,
  Tab,
} from 'semantic-ui-react'
import { Formik } from 'formik'
import { useDispatch, useSelector } from 'react-redux'
import React, { useEffect, useState } from 'react'
import { toast } from 'react-toastify'

import { bindActionCreators } from 'redux'

import { ModuloModel } from '../../models/ModuloModel'
import { IStateRedux } from '../../ReduxStore'
import { modulosActions } from './ModulosStore'
import * as _ from 'lodash'
import { NavEstrutural } from '../../components/NavEstrutural'
import { useHistory, useParams } from 'react-router'
import { QuestaoModel } from '../../models/QuestaoModel'

import useAxios from 'axios-hooks'
import { Show } from '../../components/Basic'
import { QuestaoEditor, TIPO_BOTAO } from '../../components/QuestaoEditor/QuestaoEditor'
import Axios from 'axios'
import { createSelector } from 'reselect'
import { questoesActions } from '../Questoes/QuestoesStore'
import { createSimpleStore } from '../../components/SimpleReducer'
const Table = TableComp as any
const Segment = SegmentComp as any

export const Store = createSimpleStore(
  {
    loadingQuestoes: false,
    questoes: [] as QuestaoModel[],
    questaoRevisao: null as QuestaoModel | null,
  },
  {
    getQuestoesStarted(state) {
      state.loadingQuestoes = true
    },
    getQuestoesSuccess(state, questoes: QuestaoModel[]) {
      state.questoes = questoes
      state.loadingQuestoes = false
    },
    getQuestoesError(state) {
      state.loadingQuestoes = false
    },
    openRevisarQuestao(state, questao: QuestaoModel) {
      state.questaoRevisao = questao
    },
    updateQuestao(state, questao: QuestaoModel) {
      state.questaoRevisao = questao
    },
    closeRevisarQuestao(state) {
      state.questaoRevisao = null
    },
    novaQuestaoAvaliacao(state, moduloId) {
      state.questaoRevisao = new QuestaoModel('AVALIACAO', moduloId)
    },
  },
  {
    thunks: {
      getQuestoes(moduloId) {
        return async (dispatch) => {
          _getQuestoes(moduloId, dispatch as any)
        }
      },
      closeRevisarQuestao(moduloId) {
        return async (dispatch) => {
          _getQuestoes(moduloId, dispatch as any)
          dispatch(Store.actions.closeRevisarQuestao())
        }
      },
    },
  }
)

type TDispatch = ReturnType<typeof Store.useDispatch>
async function _getQuestoes(moduloId, dispatch: TDispatch) {
  dispatch(Store.actions.getQuestoesStarted())
  try {
    const response = await Axios.get(`/questoes/modulo/${moduloId}`).then((x) => x?.data)
    dispatch(Store.actions.getQuestoesSuccess(response))
  } catch (error) {
    toast(error.response?.data?.message ?? 'Ocorreu um erro ao buscar as questões')
    dispatch(Store.actions.getQuestoesError())
  }
}

type TState = ReturnType<typeof Store.useState>
const selectQuestoesConteudo = createSelector(
  (s: TState) => s.questoes,
  (questoes) => questoes.filter((q) => q.local === 'CONTEUDO')
)
const selectQuestoesAvaliacao = createSelector(
  (s: TState) => s.questoes,
  (questoes) => questoes.filter((q) => q.local === 'AVALIACAO')
)

export const ModulosEditar = () => {
  const dispatch = useDispatch()
  const history = useHistory()
  const { cursoId } = useParams<any>()

  const actions = bindActionCreators(modulosActions, dispatch)

  useEffect(() => {
    actions.modulosFetch({ cursoId })
  }, [])

  const backToModulos = () => history.goBack()

  return (
    <Store.Provider>
      <Container>
        <NavEstrutural />
        <BotaoVoltar handler={backToModulos} />
        <DadosModulo actions={actions} submitCallback={backToModulos} />
        <Aulas />
        <Questoes />
      </Container>
    </Store.Provider>
  )
}

interface BlocoConteudoProps {
  color: SemanticCOLORS
  children
}
const BlocoConteudo: React.FC<BlocoConteudoProps> = ({ color, children }) => (
  <Segment color={color} style={{ marginTop: 25 }}>
    {children || null}
  </Segment>
)

const Aulas = () => {
  const history = useHistory()
  const { moduloId, cursoId } = useParams<any>()
  const modulo = useSelector((s: IStateRedux) => {
    return (
      s.modulos.modulos.find((m) => +m.id === +moduloId) ?? {
        id: null,
        nome: '',
        cursoId,
      }
    )
  })
  return (
    <BlocoConteudo color="olive">
      <h4>Aulas</h4>
      <Button onClick={() => history.push(`/conteudo/${modulo.id}`)} conteudo primary fluid>
        Visualizar Todas as Aulas
      </Button>
    </BlocoConteudo>
  )
}

interface DadosModuloProps {
  submitCallback: () => any
  actions: any
}
const DadosModulo: React.FC<DadosModuloProps> = ({ submitCallback, actions }) => {
  const { moduloId, cursoId } = useParams<any>()
  const modulo = useSelector((s: IStateRedux) => {
    return (
      s.modulos.modulos.find((m) => +m.id === +moduloId) ?? {
        id: null,
        nome: '',
        cursoId,
        duracao: '',
      }
    )
  })

  const [duracaoHoras, setDuracaoHoras] = useState('')
  return (
    <BlocoConteudo color="orange">
      <h2> Dados do modulo </h2>
      <Formik
        enableReinitialize
        initialValues={{
          id: modulo.id,
          nome: modulo.nome,
          duracao: modulo.duracao,
        }}
        onSubmit={async (values, { setSubmitting }) => {
          try {
            await actions.createOrUpdateModulos({ ...modulo, ...values })
            setSubmitting(false)
            submitCallback()
          } catch (e) {
            toast.warn(e.message)
          }
        }}
      >
        {({ values, handleChange, handleSubmit, isSubmitting }) => (
          <form onSubmit={handleSubmit}>
            <div style={{ display: 'block' }}>
              <label>Id</label>
              <br />
              <Input disabled name="id" onChange={handleChange} value={values.id} />
            </div>
            <br />
            <div style={{ display: 'block' }}>
              <label>Nome</label>
              <br />
              <Input fluid name="nome" onChange={handleChange} value={values.nome} />
            </div>
            <br />
            <div style={{ display: 'block' }}>
              <input value={duracaoHoras} onChange={(e) => setDuracaoHoras(e.target.value)} />
              Duração em segundos: {+duracaoHoras * 60 * 60} (preencher no campo abaixo)
              <br />
              <br />
              <label>Duração (em segundos)</label>
              <Input fluid name="duracao" onChange={handleChange} value={values.duracao} />
            </div>
            <br />
            <div style={{ display: 'table', width: '100%' }}>
              <div style={{ display: 'inline-block', float: 'right' }}>
                <Button primary type="submit" disabled={values.nome === '' || isSubmitting}>
                  Salvar
                </Button>
              </div>
            </div>
          </form>
        )}
      </Formik>
    </BlocoConteudo>
  )
}

const BotaoVoltar = ({ handler }) => (
  <Button style={{ marginTop: 25 }} onClick={handler} circular>
    <Icon name="arrow left" />
    Voltar
  </Button>
)

const Questoes = () => {
  const { moduloId } = useParams<any>()
  const dispatch = Store.useDispatch()
  const { questaoRevisao } = Store.useState()

  useEffect(() => {
    dispatch(Store.thunks.getQuestoes(moduloId))
  }, [questaoRevisao])

  return (
    <>
      <ModalEditarQuestao />
      <BlocoConteudo color="blue">
        <h2> Questões do módulo </h2>
        <Tab
          panes={[
            {
              menuItem: 'Conteúdo',
              render: () => (
                <Tab.Pane>
                  <QuestoesConteudo />
                </Tab.Pane>
              ),
            },
            {
              menuItem: 'Avaliação',
              render: () => (
                <Tab.Pane>
                  <QuestoesAvaliacao />
                </Tab.Pane>
              ),
            },
          ]}
        />
      </BlocoConteudo>
    </>
  )
}

const QuestoesConteudo = () => {
  const state = Store.useState()
  const questoes = selectQuestoesConteudo(state)
  return <ListaQuestoes questoes={questoes} />
}

const QuestoesAvaliacao = () => {
  const { moduloId } = useParams<any>()
  const state = Store.useState()
  const questoes = selectQuestoesAvaliacao(state)
  const dispatch = Store.useDispatch()
  function handleClick() {
    dispatch(Store.actions.novaQuestaoAvaliacao(moduloId))
  }
  return (
    <>
      <Button onClick={handleClick}>Nova Questão</Button>

      <ListaQuestoes questoes={questoes} />
    </>
  )
}

const ListaQuestoes = ({ questoes }: { questoes: QuestaoModel[] }) => {
  return (
    <Table>
      <table style={{ width: '100%' }}>
        <thead>
          <tr>
            <th>Id</th>
            <th>Texto</th>
            <th>Revisada</th>
            <th></th>
          </tr>
        </thead>
        <tbody>
          {questoes.map((questao: QuestaoModel) => (
            <QuestaoRow key={questao.id} questao={questao} />
          ))}
        </tbody>
      </table>
    </Table>
  )
}

const QuestaoRow = ({ questao = {} as QuestaoModel }) => {
  const dispatch = Store.useDispatch()
  return (
    <tr>
      <td>{questao.id}</td>
      <td>{questao.textoQuestao}</td>
      <td>{questao.revisada ? <Icon name="checkmark" /> : null}</td>
      <td>
        <Button onClick={() => dispatch(Store.actions.openRevisarQuestao(questao))}>Revisar</Button>
      </td>
    </tr>
  )
}

const ModalEditarQuestao = () => {
  const modulosDispatch = Store.useDispatch()
  const dispatchGlobal = useDispatch()
  const { questaoRevisao } = Store.useState()
  const { moduloId } = useParams<any>()
  const actions = bindActionCreators(questoesActions, dispatchGlobal)

  function handleQuestaoReview() {
    modulosDispatch(Store.actions.closeRevisarQuestao())
  }

  function handleClose() {
    modulosDispatch(Store.actions.closeRevisarQuestao())
  }

  function handleQuestaoSave() {
    modulosDispatch(Store.actions.closeRevisarQuestao())
  }

  if (!questaoRevisao) return null
  return (
    <Modal closeIcon onClose={handleClose} open={questaoRevisao != null}>
      <Modal.Header>{questaoRevisao?.id ? 'Editando questão' : 'Nova questão'}</Modal.Header>
      <Modal.Content>
        <QuestaoEditor
          questaoId={'' + questaoRevisao?.id}
          moduloId={+moduloId}
          local={questaoRevisao?.local}
          tipoQuestao={questaoRevisao?.tipoQuestao}
          onQuestaoSave={handleQuestaoSave}
          onQuestaoReview={handleQuestaoReview}
          onCancel={handleClose}
          buttons={[TIPO_BOTAO.SALVAR, TIPO_BOTAO.REVISAR, TIPO_BOTAO.CANCELAR]}
        />
      </Modal.Content>
    </Modal>
  )
}
