import React, { useState, useEffect, useContext } from 'react'
import { PerfilView } from './PerfilView'
import { GlobalStoreValues, GlobalStoreCtx } from '../../GlobalStore'
import { UsuarioModel } from '../../models/UsuarioModel'
import axios from '../../axios'

const usePerfilStore = () => {
  const {
    setErrorMessage,
    usuario: usuarioLogado,
    setIsLoading,
    firstPage,
    setToken,
    setSuccessMessage,
  } = useContext<GlobalStoreValues>(GlobalStoreCtx)

  const [usuario, _setUsuario] = useState<UsuarioModel>(new UsuarioModel())
  const [redirectTo, _setRedirectTo] = useState('')

  useEffect(() => {
    _setUsuario(usuarioLogado)
  }, [])

  const cancelar = () => {
    _setRedirectTo(firstPage)
  }

  const updatePerfil = async (values: UsuarioModel) => {
    try {
      await axios.Usuarios.createOrUpdate(values)
      const jwt = await axios.Usuarios.revalidaToken(values.id)
      setToken(jwt.token)
      setSuccessMessage('Usuário alterado com sucesso')
      _setRedirectTo(firstPage)
    } catch (error) {
      setErrorMessage(error.message)
    }
  }

  const setLoading = () => {
    setIsLoading(true)
  }

  return {
    usuario,
    redirectTo,
    cancelar,
    updatePerfil,
    setLoading,
  }
}

export type PerfilStoreValues = ReturnType<typeof usePerfilStore>
export const PerfilStoreCtx = React.createContext(null)
export const Perfil = () => {
  const Values = usePerfilStore()
  return (
    <PerfilStoreCtx.Provider value={Values}>
      <PerfilView />
    </PerfilStoreCtx.Provider>
  )
}
