import { Route, Switch } from 'react-router-dom'
import React from 'react'

import { UsuariosCursosEditar } from '../UsuariosCurso/UsuariosCursosEditar'

export const Usuarios = () => {
  return (
    <Switch>
      <Route path="/usuarios/editar" component={UsuariosCursosEditar} />
    </Switch>
  )
}
