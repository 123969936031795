import { Route, Switch, useHistory } from 'react-router-dom'
import React, { useCallback, useEffect, useRef, useState } from 'react'
import styled from '@emotion/styled/macro'
import { TextFieldSearch } from '../../components/TextField'
import filtroIcon from '../../assets/filtro-icon.svg'
import { ClickAway } from '../../components/ClickAway'
import { ListaCheckBoxContainer } from '../../components/ListaCheckBox'
import { createSimpleStore } from '../../components/SimpleReducer'
import userIcon from '../../assets/user-icon.svg'
import { Paginacao } from '../../components/Paginacao'
import animatedLoading from '../../assets/loading.gif'
import aguardandoPagamentoIcon from '../../assets/aguardando-pagamento-icon.svg'
import matriculadoIcon from '../../assets/matriculado-icon.svg'
import finalizadoIcon from '../../assets/finalizado-icon.svg'
import semCertificadoIcon from '../../assets/sem-certificado-icon.svg'
import Axios from 'axios'
import { MatriculaModel } from '../../models/MatriculaModel'
import { formatDistance } from 'date-fns'
import { ptBR } from 'date-fns/locale'
import useAxios from 'axios-hooks'

type OptionFilterType = 'matricula' | 'estado' | 'curso' | null
const AlunosStore = createSimpleStore(
  {
    optionFilterSelected: null as OptionFilterType,
    matriculas: [] as MatriculaModel[],
    total: 0 as number,
    aguardandoPagamento: 0 as number,
    matriculados: 0 as number,
    finalizados: 0 as number,
    semCertificado: 0 as number,
    limit: 15 as number,
    page: 1 as number,
    offset: 0 as number,
    loading: false as boolean,
    filters: {
      filtroTexto: '' as string,
      matricula: {
        todasMatriculas: false,
        aguardandoPagamento: false,
        matriculado: false,
        semCertificado: false,
        finalizado: false,
      },
      estado: {
        todosEstados: false,
      },
      cursos: {
        todosCursos: false,
        preventivoDeReciclagem: false,
        reciclagemParaCondutoresInfratores: false,
        ['esp.Para.Condutores.Transp.Coletivo.Passageiros']: false,
        ['esp.Para.Condutores.Transp.Prod.Perigosos']: false,
        ['esp.Para.Condutores.Transp.Cargas.Indivisíveis']: false,
        ['esp.Para.Condutores.Transp.Emergencia']: false,
        ['esp.Para.Condutores.Transp.Escolar']: false,
        ['atualizacao.Para.Condutores.Transp.Coletivo.Passageiros']: false,
        ['atualizacao.Para.Condutores.Transp.Prod.Perigosos']: false,
        ['atualizacao.Para.Condutores.Transp.Cargas.Indivisíveis']: false,
        ['atualizacao.Para.Condutores.Transp.Emergencia']: false,
        ['atualizacao.Para.Condutores.Transp.Escolar']: false,

        ['Mototaxista']: false,
        ['Motofretista']: false,
        ['Atualização.para.Mototaxista']: false,
        ['Atualização.para.Motofretista']: false,

        ['Direção.Defensiva']: false,
      },
    },
  },
  {
    setEstados: (state, payload) => {
      state.filters.estado = {
        ...state.filters.estado,
        ...payload,
      }
    },
    setFilterText: (state, filtroTexto) => ({
      ...state,
      filters: { ...state.filters, filtroTexto },
    }),
    setFilters: (state, payload) => {
      const { key } = payload
      const newPayload = { ...payload }
      delete newPayload.key
      return {
        ...state,
        filters: {
          ...state.filters,
          [key]: {
            ...state.filters[key],
            ...newPayload,
          },
        },
      }
    },
    setOptionFilterSelected: (state, { optionFilterSelected }) => {
      return { ...state, optionFilterSelected }
    },
    setQueryParams: (state, { page, limit, offset }) => ({ ...state, page, limit, offset }),
    fetchAlunosData: (state, loading) => ({ ...state, loading }),
    fetchAlunosSuccess: (
      state,
      { matriculas, total, aguardandoPagamento, matriculados, finalizados, semCertificado }
    ) => {
      return {
        ...state,
        matriculas,
        total,
        aguardandoPagamento,
        matriculados,
        finalizados,
        semCertificado,
        loading: false,
      }
    },
  },
  {
    thunks: {
      getAlunosData({
        page,
        offset,
        limit,
        order = 'DESC',
        orderBy = 'createdAt',
        filtroTexto = '',
        filter,
      }: {
        page?: number
        offset?: number
        limit?: number
        order?: string
        orderBy?: string
        filtroTexto?: string | number
        filter?: { key: string; value: boolean; name: string }
      }) {
        return async (dispatch, getState) => {
          const {
            page: sPage,
            offset: sOffset,
            limit: sLimit,
            filters,
            filters: { filtroTexto: sFiltroTexto },
          } = getState()

          let newFilters = { ...filters }
          if (filter)
            newFilters[filter.key] = { ...filters[filter.key], [filter.name]: filter.value }
          if (filter && !filter.value) {
            page = 1
            offset = 0
          }

          const selectedFiltersMatricula = Object.keys(newFilters.matricula).filter(
            (key) => newFilters.matricula[key]
          )

          const selectedFiltersEstado = Object.keys(newFilters.estado)
            .filter((key) => newFilters.estado[key])
            .map((f) => f.replace(/([A-Z])/g, ' $1').replace(/^./, (str) => str.toUpperCase()))

          const selectedFiltersCursos = Object.keys(newFilters.cursos).filter(
            (key) => newFilters.cursos[key]
          )

          try {
            dispatch(AlunosStore.actions.fetchAlunosData(true))
            dispatch(AlunosStore.actions.setFilterText(filtroTexto ?? sFiltroTexto))
            dispatch(
              AlunosStore.actions.setQueryParams({
                page: page ?? sPage,
                limit: limit ?? sLimit,
                offset: offset ?? sOffset,
              })
            )

            const cpf = filtroTexto.toString().replace(/\D/g, '')
            if (cpf.length === 11) filtroTexto = cpf

            const { data } = await Axios.post('matriculas/get-dados-alunos', {
              query: { offset: offset ?? sOffset, limit: limit ?? sLimit, order, orderBy },
              filters: {
                filtroTexto: filtroTexto ?? sFiltroTexto,
                matricula: selectedFiltersMatricula,
                estado: selectedFiltersEstado,
                cursos: selectedFiltersCursos,
              },
            })

            dispatch(AlunosStore.actions.fetchAlunosSuccess(data))
          } catch (error) {
            dispatch(AlunosStore.actions.fetchAlunosData(false))
          }
        }
      },
      getEstados() {
        return async (dispatch, getState) => {
          try {
            const { data } = await Axios.get('/matriculas/get-estados')

            const estados = {}
            data.forEach((estado: { nome: string; sigla: string }) => {
              const nome = estado.nome.split(' ')
              estados[`${nome[0].toLowerCase()}${nome[1] || ''}`] = false
            })

            dispatch(AlunosStore.actions.setEstados(estados))
          } catch (error) {
            dispatch(AlunosStore.actions.fetchAlunosData(false))
          }
        }
      },
    },
  }
)

export const Alunos = () => {
  const { Layout, Container, Title } = Alunos

  const init = useCallback((dispatch) => {
    dispatch(AlunosStore.thunks.getAlunosData({ offset: 0, limit: 15 }))
    dispatch(AlunosStore.thunks.getEstados())
  }, [])

  return (
    <AlunosStore.Provider init={init}>
      <Layout>
        <Container>
          <Title>Alunos</Title>
          <ContainerCards />
          <ContainerPesquisaFiltro />
          <TagsContainer />
          <TabelaContainer />
        </Container>
      </Layout>
    </AlunosStore.Provider>
  )
}

Alunos.Layout = styled.div`
  padding: 20px 40px;
  background-color: whitesmoke;
  @media (max-width: 768px) {
    padding: 15px;
  }
`
Alunos.Container = styled.div`
  max-width: 1200px;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  gap: 20px;
  padding: 10px;
`
Alunos.Title = styled.span`
  font-size: 22px;
  color: #494d50;
  font-weight: 600;
`
const ContainerCards = () => {
  const { Layout, Card } = ContainerCards
  const { total, aguardandoPagamento, matriculados, finalizados, semCertificado } =
    AlunosStore.useState()

  return (
    <Layout>
      <Card>
        <span className="title-data">{total}</span>
        <span className="title-label">Todos</span>
      </Card>
      <Card>
        <span className="title-data">{aguardandoPagamento}</span>
        <span className="title-label">Aguardando Pagamento</span>
      </Card>
      <Card>
        <span className="title-data">{matriculados}</span>
        <span className="title-label">Matriculados</span>
      </Card>
      <Card>
        <span className="title-data">{semCertificado}</span>
        <span className="title-label">Sem Certificado</span>
      </Card>
      <Card>
        <span className="title-data">{finalizados}</span>
        <span className="title-label">Finalizados</span>
      </Card>
    </Layout>
  )
}

ContainerCards.Layout = styled.div`
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  gap: 5px;
  @media (max-width: 768px) {
    justify-content: center;
  }
`
ContainerCards.Card = styled.div`
  padding: 24px;
  min-width: 210px;
  height: 94px;
  display: flex;
  flex-direction: column;
  border: 2px solid #d4d4d4;
  background-color: #ffffff;
  border-radius: 8px;
  gap: 1px;
  span {
    text-align: left;
    color: #494d50;
  }
  .title-data {
    font-size: 22px;
    font-weight: 600;
  }
  .title-label {
    font-size: 14px;
  }
`

const ContainerPesquisaFiltro = () => {
  const { Layout } = ContainerPesquisaFiltro
  const dispatch = AlunosStore.useDispatch()
  const {
    filters: { filtroTexto },
  } = AlunosStore.useState()
  return (
    <Layout>
      <TextFieldSearch
        value={filtroTexto}
        placeholder="Pesquisar por CPF, Nome, Curso e ID"
        onChange={(e: any) =>
          dispatch(
            AlunosStore.thunks.getAlunosData({ filtroTexto: e.target.value, page: 1, offset: 0 })
          )
        }
      />
      <Filtro />
    </Layout>
  )
}
ContainerPesquisaFiltro.Layout = styled.div`
  display: flex;
  align-items: center;
  gap: 20px;
`

const Filtro = () => {
  const { Layout, ClickAwayContainer } = Filtro
  const [activeFilter, setActiveFilter] = useState(false)
  const dispatch = AlunosStore.useDispatch()

  return (
    <ClickAwayContainer
      setClickAway={(e) => {
        setActiveFilter(e)
        // dispatch(AlunosStore.actions.setOptionFilterSelected({ optionFilterSelected: null }))
      }}
    >
      <Layout active={activeFilter} onClick={() => setActiveFilter(!activeFilter)}>
        <span>Filtros</span>
        <img src={filtroIcon} />
      </Layout>
      <ContainerSelectFilter active={activeFilter} />
    </ClickAwayContainer>
  )
}

Filtro.ClickAwayContainer = styled(ClickAway)`
  position: relative;
`

Filtro.Layout = styled.div<{ active: boolean }>(
  ({ active }) => `
  padding: 4px 16px;
  border: 1px solid var(--primary-color);
  border-radius: 7px;
  display: flex;
  gap: 3px;
  background: ${active ? 'var(--primary-color)' : 'transparent'};
  &:hover {
    filter: brightness(0.8);
  }
  cursor: pointer;
  span {
    font-size: 16px;
    color: ${active ? '#FFFFFF;' : 'var(--primary-color);'}
    font-weight: 500;
    line-height: 1.5;
  }
  img {
    filter: ${active ? 'brightness(0) invert(1)' : 'none'};
  }
`
)

const ContainerSelectFilter = ({ active }: { active: boolean }) => {
  const { Layout } = ContainerSelectFilter
  if (!active) return null

  return (
    <Layout>
      <OptionsFilter />
    </Layout>
  )
}

ContainerSelectFilter.Layout = styled.div`
  padding: 10px;
  position: absolute;
  right: -4px;
  top: 40px;
  border: 1px solid #d4d4d4;
  border-radius: 7px;
  box-shadow: 0px 3px 5px rgba(0, 0, 0, 0.2);
  background: #ffffff;
  z-index: 1;
`

const OptionsFilter = () => {
  const { Layout, Label, Grid } = OptionsFilter
  const { optionFilterSelected } = AlunosStore.useState()
  const {
    filters: { matricula, estado, cursos },
  } = AlunosStore.useState()
  const dispatch = AlunosStore.useDispatch()
  const haveFiltersMatricula = Object.values(matricula).filter(Boolean).length > 0
  const haveFiltersEstado = Object.values(estado).filter(Boolean).length > 0
  const haveFiltersCursos = Object.values(cursos).filter(Boolean).length > 0

  const handleSetOptionFilterSelected = (option: OptionFilterType) => {
    if (optionFilterSelected === option) {
      dispatch(AlunosStore.actions.setOptionFilterSelected({ optionFilterSelected: null }))
      return
    }
    dispatch(AlunosStore.actions.setOptionFilterSelected({ optionFilterSelected: option }))
  }

  return (
    <Layout>
      <Grid>
        <Label>Matrícula</Label>
        <SelectFilter
          onClick={() => handleSetOptionFilterSelected('matricula')}
          placeholder="Selecione um ou mais status"
          active={optionFilterSelected === 'matricula'}
          haveFilters={haveFiltersMatricula}
        />
        <ContainerSelectFilterContent name="matricula" />
      </Grid>
      <Grid>
        <Label>Estado</Label>
        <SelectFilter
          onClick={() => handleSetOptionFilterSelected('estado')}
          placeholder="Selecione um ou mais estados"
          active={optionFilterSelected === 'estado'}
          haveFilters={haveFiltersEstado}
        />
        <ContainerSelectFilterContent name="estado" />
      </Grid>
      <Grid>
        <Label>Curso</Label>
        <SelectFilter
          onClick={() => handleSetOptionFilterSelected('curso')}
          placeholder="Selecione um ou mais cursos"
          active={optionFilterSelected === 'curso'}
          haveFilters={haveFiltersCursos}
        />
        <ContainerSelectFilterContent name="curso" />
      </Grid>
    </Layout>
  )
}

OptionsFilter.Layout = styled.div`
  width: 300px;
  padding: 10px 14px;
`
OptionsFilter.Label = styled.div`
  font-size: 16px;
  font-weight: 450;
  color: #585858;
`
OptionsFilter.Grid = styled.div`
  display: grid;
  &:not(:first-child) {
    margin-top: 10px;
  }
`
interface ISelectFilter
  extends React.DetailedHTMLProps<React.HTMLAttributes<HTMLDivElement>, HTMLDivElement> {
  placeholder: string
  active: boolean
  haveFilters: boolean
}
const SelectFilter = ({ placeholder, active, haveFilters, ...props }: ISelectFilter) => {
  const { Layout, PlaceHolder } = SelectFilter

  return (
    <Layout active={active} haveFilters={haveFilters} {...props}>
      <PlaceHolder>{placeholder}</PlaceHolder>
      <div className="dropdown-cursor" />
    </Layout>
  )
}

SelectFilter.Layout = styled.div<{ active: boolean; haveFilters: boolean }>(
  ({ active, haveFilters }) => `
  ${active ? 'border: 1px solid var(--primary-color);' : 'border: 1px solid #D4D4D4;'}
  box-shadow: ${haveFilters ? '1px 2px 2px var(--primary-color);' : 'none'};
  padding: 10px 30px 10px 12px;
  position: relative;
  border-radius: 7px;
  cursor: pointer;
  .dropdown-cursor {
    width: 10px;
    height: 10px;
    border-left: 2px solid;
    border-bottom: 2px solid;
    rotate: ${active ? '135deg' : '-45deg'};
    -webkit-transform: rotate(${active ? '135deg' : '-45deg'});
    -moz-transform: rotate(${active ? '0deg' : '0deg'});
    position: absolute;
    right: 16px;
    top: 20px;
  }
`
)

SelectFilter.PlaceHolder = styled.span`
  font-size: 16px;
  line-height: 1.6;
  color: #666666;
`

const ContainerSelectFilterContent = ({ name }: { name }) => {
  const { Layout } = ContainerSelectFilterContent
  const { optionFilterSelected } = AlunosStore.useState()
  if (!optionFilterSelected) return null

  return (
    <Layout>
      <StatusContent active={optionFilterSelected === 'matricula' && name === 'matricula'} />
      <EstadoContent active={optionFilterSelected === 'estado' && name === 'estado'} />
      <CursosContent active={optionFilterSelected === 'curso' && name === 'curso'} />
    </Layout>
  )
}

ContainerSelectFilterContent.Layout = styled.div`
  position: relative;
  z-index: 1;
`

const StatusContent = ({ active }: { active: boolean }) => {
  const { Layout } = StatusContent
  const {
    filters: { matricula, filtroTexto },
  } = AlunosStore.useState()
  const dispatch = AlunosStore.useDispatch()
  if (!active) return null

  return (
    <Layout>
      <ClickAway
        setClickAway={() =>
          dispatch(AlunosStore.actions.setOptionFilterSelected({ optionFilterSelected: null }))
        }
      >
        <ListaCheckBoxContainer
          names={matricula}
          handleCheck={(check, name) => {
            dispatch(AlunosStore.actions.setFilters({ [name]: check, key: 'matricula' }))
            dispatch(
              AlunosStore.thunks.getAlunosData({
                filter: { name, value: check, key: 'matricula' },
                filtroTexto,
              })
            )
          }}
        />
      </ClickAway>
    </Layout>
  )
}

StatusContent.Layout = styled.div`
  position: absolute;
  top: 10px;
`

const EstadoContent = ({ active }: { active: boolean }) => {
  const { Layout } = StatusContent
  const {
    filters: { estado, filtroTexto },
  } = AlunosStore.useState()
  const dispatch = AlunosStore.useDispatch()
  if (!active) return null
  return (
    <Layout>
      <ClickAway
        setClickAway={() =>
          dispatch(AlunosStore.actions.setOptionFilterSelected({ optionFilterSelected: null }))
        }
      >
        <ListaCheckBoxContainer
          names={estado}
          handleCheck={(check, name) => {
            dispatch(AlunosStore.actions.setFilters({ [name]: check, key: 'estado' }))
            dispatch(
              AlunosStore.thunks.getAlunosData({
                filter: { name, value: check, key: 'estado' },
                filtroTexto,
              })
            )
          }}
        />
      </ClickAway>
    </Layout>
  )
}

const CursosContent = ({ active }: { active: boolean }) => {
  const { Layout } = StatusContent
  const {
    filters: { cursos, filtroTexto },
  } = AlunosStore.useState()
  const dispatch = AlunosStore.useDispatch()
  if (!active) return null
  return (
    <Layout>
      <ClickAway
        setClickAway={() =>
          dispatch(AlunosStore.actions.setOptionFilterSelected({ optionFilterSelected: null }))
        }
      >
        <ListaCheckBoxContainer
          names={cursos}
          handleCheck={(check, name) => {
            dispatch(AlunosStore.actions.setFilters({ [name]: check, key: 'cursos' }))
            dispatch(
              AlunosStore.thunks.getAlunosData({
                filter: { name, value: check, key: 'cursos' },
                filtroTexto,
              })
            )
          }}
        />
      </ClickAway>
    </Layout>
  )
}

const TagsContainer = () => {
  const { Layout, Tag, Filtro } = TagsContainer
  const {
    filters: { matricula, cursos, estado },
  } = AlunosStore.useState()
  const allFilters = [matricula, cursos, estado]
  const dispatch = AlunosStore.useDispatch()

  const sanitize = (text) =>
    text.replace(/([A-Z])/g, ' $1').replace(/^./, (str) => str.toUpperCase())
  return (
    <Layout>
      {allFilters.map((filter, index) => {
        const key = Object.keys({ matricula, cursos, estado })[index]
        return Object.keys(filter).map((name, index) => {
          return (
            filter[name] && (
              <Tag key={index}>
                <Filtro>{sanitize(name)}</Filtro>
                <span
                  onClick={() => {
                    dispatch(AlunosStore.actions.setFilters({ [name]: false, key }))
                    dispatch(
                      AlunosStore.thunks.getAlunosData({ filter: { name, value: false, key } })
                    )
                  }}
                >
                  X
                </span>
              </Tag>
            )
          )
        })
      })}
    </Layout>
  )
}

TagsContainer.Layout = styled.div`
  display: flex;
  padding: 10px 20px;
  gap: 6px;
  flex-wrap: wrap;
`
TagsContainer.Tag = styled.div`
  background: #ffecd6;
  border-radius: 3px;
  display: flex;
  padding: 4px 5px;
  justify-content: center;
  align-items: center;
  gap: 4px;
  & > span:last-child {
    font-size: 12px;
    cursor: pointer;
    color: #ff9722;
  }
`
TagsContainer.Filtro = styled.span`
  font-size: 12px;
  color: #ff9722;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  direction: ltr;
  max-width: 160px;
`

const TabelaContainer = () => {
  const { Layout } = TabelaContainer
  return (
    <Layout>
      <LoadingContent />
      <Tabela />
      <PaginacaoContainer />
    </Layout>
  )
}
TabelaContainer.Layout = styled.div`
  padding: 0px 8px;
  background: #ffffff;
  border-radius: 10px;
  border: 2px solid #e3e3ec;
  height: calc(100vh - 300px);
  overflow-y: auto;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
`

const Tabela = () => {
  const { Table, Thead, Th, TBody, Tr, Td, Grid, Flex, getDateDistance } = Tabela
  const { matriculas, loading } = AlunosStore.useState()

  if (loading) return null

  return (
    <Table cellPadding={0} cellSpacing={0}>
      <Thead>
        <Tr>
          <Th>Identificação</Th>
          {/* <Th>Origem</Th> */}
          <Th>Curso</Th>
          <Th>Dias</Th>
          <Th>Uf</Th>
          <Th></Th>
        </Tr>
      </Thead>
      <TBody>
        {matriculas?.map((matricula, key) => (
          <Tr key={key}>
            <Td>
              <Grid>
                <Flex>
                  <span>Id Matricula: {matricula?.id} | </span>
                  <span>Id Aluno: {matricula?.aluno?.id} | </span>
                  <span>
                    Nome Aluno: <strong>{matricula?.aluno?.nome}</strong> |
                  </span>
                  <span>{matricula?.aluno?.cpf}</span>
                  {matricula?.turmaId && (
                    <span>
                      Turma: <strong>{matricula?.turmaId}</strong>
                    </span>
                  )}
                  {matricula?.parceiroId && (
                    <span>
                      Parceiro: <strong>{matricula?.parceiro?.nome}</strong>
                    </span>
                  )}
                  {matricula.captadorId && (
                    <span>
                      Captador: <strong>{matricula?.captador?.nome}</strong>
                    </span>
                  )}
                </Flex>
              </Grid>
            </Td>

            <Td>{matricula?.curso?.nomeReduzido}</Td>
            <Td>{getDateDistance(matricula)}</Td>
            <Td>{matricula?.uf}</Td>
            <Td width={80}>
              <OptionsContainer matricula={matricula} />
            </Td>
          </Tr>
        ))}
      </TBody>
    </Table>
  )
}

Tabela.getDateDistance = (matricula: MatriculaModel) => {
  if (matricula?.dataFimCurso)
    return formatDistance(new Date(matricula?.dataFimCurso), new Date(), { locale: ptBR })
  if (!matricula?.dataInicioCurso) return '-'
  return formatDistance(new Date(), new Date(matricula?.dataInicioCurso), { locale: ptBR })
}

Tabela.Table = styled.table`
  width: 100%;
  padding: 0px 10px;
`
Tabela.Thead = styled.thead`
  tr {
    position: sticky;
    top: 0;
    background: #ffffff;
    box-shadow: 0 1px 0 0 #dddddd, 0 -5px 0 0 #ffffff;
  }
`
Tabela.Th = styled.th`
  font-size: 16px;
  font-weight: normal;
  text-align: left;
  color: #585858;
  border-bottom: 1px solid #e7e3ec;
  padding: 10px 0px;
`
Tabela.TBody = styled.tbody`
  tr {
    transition: all 0.26s ease-in-out;
    &:hover {
      background: #f5f5f5;
    }
  }
`
Tabela.Tr = styled.tr``
Tabela.Td = styled.td`
  border-bottom: 1px solid #e7e3ec;
  padding: 5px 0px;
`
Tabela.Grid = styled.div`
  display: grid;
  gap: 2px;
`
Tabela.Flex = styled.div`
  display: flex;
  gap: 5px;
  span {
    color: #494d50;
    font-size: 15px;
    @media (max-width: 768px) {
      &:first-child {
        display: none;
      }
      font-size: 12px;
    }
  }
`

const LoadingContent = () => {
  const { Layout } = LoadingContent
  const { loading } = AlunosStore.useState()

  if (!loading) return null

  return (
    <Layout>
      <span>Carregando...</span>
      <img width={220} src={animatedLoading} />
    </Layout>
  )
}
LoadingContent.Layout = styled.div`
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  flex: 1;
  gap: 10px;
  span {
    font-size: 16px;
    font-weight: 650;
    text-align: center;
  }
`

const OptionsContainer = ({ matricula }: { matricula: MatriculaModel }) => {
  const { Layout } = OptionsContainer
  const history = useHistory()
  function handleClickUsuario() {
    history.push(
      `/cursos/${matricula?.curso?.id}/usuarios/${matricula?.aluno?.id}/acompanhamento?matriculaId=${matricula.id}`
    )
  }

  return (
    <Layout>
      <DadosUsuario onClick={handleClickUsuario} />
      <StatusUsuario matricula={matricula} />
    </Layout>
  )
}

OptionsContainer.Layout = styled.div`
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  gap: 5px;
`
const DadosUsuario = ({ ...props }) => {
  const { Layout } = DadosUsuario
  return (
    <Layout {...props}>
      <img src={userIcon} />
    </Layout>
  )
}

const StatusUsuario = ({ matricula }: { matricula: MatriculaModel }) => {
  const { Layout, Icon, TooltipText } = StatusUsuario

  return (
    <Layout>
      <Icon src={matriculadoIcon} active={matricula?.statusDetran === 'MATRICULADO'} />
      <Icon
        src={aguardandoPagamentoIcon}
        active={matricula?.statusDetran === 'AGUARDANDO_PAGAMENTO'}
      />
      <Icon src={finalizadoIcon} active={matricula?.statusDetran === 'FINALIZADO'} />
      <TooltipText>{String(matricula?.statusDetran).replace('_', ' ')}</TooltipText>
    </Layout>
  )
}
StatusUsuario.TooltipText = styled.span`
  visibility: hidden;
  width: 120px;
  background-color: black;
  color: #fff;
  text-align: center;
  border-radius: 6px;
  padding: 5px 0;
  position: absolute;
  z-index: 9999;
  top: -5px;
  right: 105%;
`
StatusUsuario.Layout = styled.div`
  position: relative;
  display: inline-block;
  &:hover ${StatusUsuario.TooltipText} {
    visibility: visible;
  }
`
StatusUsuario.Icon = styled.img<{ active: boolean }>(
  ({ active }) => `
  display: ${active ? 'block' : 'none'};
  width: 15px;
`
)

DadosUsuario.Layout = styled.div`
  padding: 3px 7px;
  border-radius: 7px;
  border: 2px solid #494d50;
  cursor: pointer;
  &:hover {
    filter: brightness(0.6);
  }
  img {
    width: 13px;
    height: 13px;
  }
`

const PaginacaoContainer = () => {
  const { Layout, Paginacao } = PaginacaoContainer
  const {
    total,
    limit,
    page,
    filters: { filtroTexto },
  } = AlunosStore.useState()
  const dispatch = AlunosStore.useDispatch()
  const handlePageActive = ({
    pageSelected,
    newLimit,
  }: {
    pageSelected: number
    newLimit?: number
  }) => {
    const offset = (pageSelected - 1) * limit
    dispatch(
      AlunosStore.thunks.getAlunosData({
        page: pageSelected,
        offset,
        limit: newLimit ?? limit,
        filtroTexto,
      })
    )
  }

  return (
    <Layout>
      <div style={{ flex: 1 }} />
      <Paginacao total={total} limit={limit} page={page} handlePageActive={handlePageActive} />
      <QuantidadeDePaginas />
    </Layout>
  )
}

PaginacaoContainer.Paginacao = styled(Paginacao)`
  flex: 1;
`

PaginacaoContainer.Layout = styled.div`
  position: sticky;
  bottom: 0;
  background: #ffffff;
  padding: px 5px;
  border-top: 2px solid #dddddd;
  width: 100%;
  display: flex;
  @media (max-width: 768px) {
    flex-direction: column;
  }
`

const QuantidadeDePaginas = () => {
  const { Layout } = QuantidadeDePaginas
  const { total, page, limit } = AlunosStore.useState()
  const quantidadePages = Math.ceil(total / limit)

  return (
    <Layout>
      <span>
        Mostrando <strong>{limit > total ? total : limit}</strong> de <strong>{total}</strong> itens
      </span>
    </Layout>
  )
}

QuantidadeDePaginas.Layout = styled.div`
  flex: 1;
  display: flex;
  gap: 5px;
  align-items: center;
  justify-content: flex-end;
  @media (max-width: 768px) {
    justify-content: center;
  }
  span {
    color: #494d50;
    font-size: 14px;
  }
`
