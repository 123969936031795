import React, { useState, useEffect, useContext, useMemo } from 'react'
import { HistoricoView } from './HistoricoView'
import { GlobalStoreValues, GlobalStoreCtx } from '../../GlobalStore'
import axios, { ICounter } from '../../axios'
import { ProgressoModel } from '../../models/ProgressoModel'
import { format } from 'date-fns'

const useHistoricoStore = (usuarioId: number) => {
  const { setErrorMessage } = useContext<GlobalStoreValues>(GlobalStoreCtx)

  const [historico, _setHistorico] = useState<ICounter<ProgressoModel>>({ count: 0, rows: [] })
  const [totalItensPagina, _setTotalItensPagina] = useState<Array<{ id: string; total: number }>>(
    []
  )

  useEffect(() => {
    _getHistorico()
  }, [])

  async function _getHistorico() {
    try {
      const progresso = await axios.Progresso.getByAluno(usuarioId)
      _setHistorico(progresso)
      const paginas = Array.from(new Set(progresso.rows.map((x) => x.conteudoPaginaId)))
      const $totalItensPagina = await axios.Paginas.getTotalItensPaginas(paginas)
      _setTotalItensPagina($totalItensPagina)
    } catch (error) {
      setErrorMessage(error.message)
    }
  }

  const dias = useMemo(() => {
    return Array.from(new Set(historico.rows.map((x) => format(x.createdAt, 'DD/MM/YYYY'))))
  }, [historico])

  return { historico, dias, totalItensPagina }
}

export type HistoricoStoreValues = ReturnType<typeof useHistoricoStore>
export const HistoricoStoreCtx = React.createContext(null)
export const Historico = ({ match }: any) => {
  const Values = useHistoricoStore(match.params.usuarioId)
  return (
    <HistoricoStoreCtx.Provider value={Values}>
      <HistoricoView />
    </HistoricoStoreCtx.Provider>
  )
}
