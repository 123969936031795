import { Button, Icon } from 'semantic-ui-react'
import { Table } from '../../components/Styles'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory } from 'react-router'

import { toast } from 'react-toastify'
import React, { useEffect } from 'react'

import { bindActionCreators } from 'redux'

import { IStateRedux } from '../../ReduxStore'
import { ModuloModel } from '../../models/ModuloModel'
import { Pagination } from '../../components/Pagination'
import { modulosActions } from './ModulosStore'

interface ModulosViewProps {
  cursoId: number
}

export const ModulosView: React.FC<ModulosViewProps> = (props) => {
  const { cursoId } = props
  const history = useHistory()
  const dispatch = useDispatch()
  const { modulos, query, modulosCount, request } = useSelector((s: IStateRedux) => s.modulos)
  const actions = bindActionCreators(modulosActions, dispatch)
  useEffect(() => {
    actions.modulosFetch({ cursoId: +cursoId })
  }, [])

  if (request.message) {
    toast.warn(request.message)
    actions.requestClean()
  }

  const goToConteudo = (m) => history.push(`/cursos/${cursoId}/modulos/${m.id}/conteudo`)

  return (
    <div>
      <Table>
        <table>
          <thead>
            <tr>
              <th>Id</th>
              <th>Nome</th>
              <th></th>
            </tr>
          </thead>
          <tbody>
            {modulos.length === 0 ? (
              <tr>
                <td>
                  <span>Não há nenhum módulo cadastrado ainda...</span>
                </td>
              </tr>
            ) : null}
            {modulos.map((modulo: ModuloModel) => (
              <tr
                key={modulo.id}
                onClick={() => history.push(`/cursos/${cursoId}/modulos/${modulo.id}/editar`)}
              >
                <td>{modulo.id}</td>
                <td>{modulo.nome}</td>
                <td>
                  <Button
                    secondary
                    onClick={(e) => {
                      e.stopPropagation()
                      actions.deleteModulo(+modulo.id)
                    }}
                    icon
                  >
                    <Icon name="delete" />
                  </Button>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
        <Pagination
          onChangePage={(q) => actions.updatePagination(cursoId, q)}
          limit={query.limit}
          offset={query.offset}
          count={modulosCount}
        />
      </Table>
      <Button
        primary
        fluid
        onClick={() => history.push(`/cursos/${cursoId}/modulos/novo`, { cursoId })}
      >
        Criar novo módulo
      </Button>
    </div>
  )
}
