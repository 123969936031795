import React, { useState, useMemo } from 'react'
import { Util, Show, ContainersArquivos } from '../../Util'
import { CustomEditor } from '../../CustomEditor/CustomEditor'
import styled from '@emotion/styled'
import { DisplayFlex } from '../../Styles'
import { Button } from 'semantic-ui-react'
import { filesUrl } from '../../..'
import { useDecorateBlobWithSas } from '../../UseDecorateBlobWithSas'
interface IProps {
  item: any
  updateItem: (item: any) => void
}
export const ItemAlternativa = ({ item, updateItem }: IProps) => {
  if (!item) item = { tipo: 'TEXTO' }
  else item = JSON.parse(item)
  function changeTipo(tipo: string) {
    updateItem(JSON.stringify({ ...item, tipo }))
  }

  function handleChangeTexto(e) {
    if (!e.target.value.trim()) {
      return updateItem(null)
    }

    updateItem(JSON.stringify({ ...item, tipo: 'TEXTO', item: e.target.value }))
  }

  async function handleClickImagem() {
    try {
      const uploadedImage = await Util.requestUploadImagem({
        containerName: ContainersArquivos.QUESTAO,
      })
      updateItem(JSON.stringify({ ...item, tipo: 'IMAGEM', item: uploadedImage }))
    } catch (error) {}
  }
  const blobUrl = useDecorateBlobWithSas({ blobPath: item.tipo === 'IMAGEM' ? item.item : '' })

  return (
    <div>
      <Show condition={item.tipo === 'TEXTO'}>
        <textarea value={item.item || ''} onChange={handleChangeTexto} style={{ width: '100%' }} />
      </Show>
      <Show condition={item.tipo === 'IMAGEM'}>
        <Style.Img src={blobUrl} />
      </Show>
      <DisplayFlex marginBetween={12}>
        <Button
          color={item.tipo === 'TEXTO' ? 'blue' : 'grey'}
          onClick={(_) => changeTipo('TEXTO')}
        >
          Texto
        </Button>
        <Button color={item.tipo === 'IMAGEM' ? 'blue' : 'grey'} onClick={handleClickImagem}>
          Imagem
        </Button>
      </DisplayFlex>
    </div>
  )
}

const Style = {
  Img: styled('img')`
    max-width: 100%;
  `,
}
