import { Button, Icon, Accordion } from 'semantic-ui-react'
import React, { useState } from 'react'
import styled from '@emotion/styled'

import { Show } from '../../../components/Basic'
import AnimateHeight from 'react-animate-height'
import { useSpring, animated } from 'react-spring'
import { DisplayFlex } from '../../../components/Styles'

const Component = () => {
  const CustomStyle = styled('div')`
    background-color: pinkzão;
  `
  return <CustomStyle>Componentezão</CustomStyle>
}

export { Component }

const ToolboxItem = (props: { icon; label; onClick?; children? }) => {
  const { icon, label, onClick, children } = props
  const [contentOpen, setContentOpen] = useState(false)
  const springRotate = useSpring({ transform: contentOpen ? 'rotate(180deg)' : 'rotate(0deg)' })

  if (!children) {
    return (
      <Container>
        <Header onClick={onClick}>
          <IconSingleContainer className="header-icon-container">
            <Icon size="big" name={icon} />
            <Titulo> {label} </Titulo>
          </IconSingleContainer>
        </Header>
      </Container>
    )
  }

  return (
    <Container>
      <Header onClick={() => setContentOpen(!contentOpen)}>
        <IconContainer className="header-icon-container">
          <Icon size="big" name={icon} />
        </IconContainer>
        <Titulo style={{ flex: 1 }}> {label} </Titulo>
        <IconExpand>
          <animated.div style={springRotate}>
            <Icon name="angle up" />
          </animated.div>
        </IconExpand>
      </Header>
      <AnimateHeight height={contentOpen ? 'auto' : 0}>{children}</AnimateHeight>
    </Container>
  )
}

const Container = styled('div')`
  border-bottom: 1px solid #f2f2f2;
`
const IconContainer = styled('div')`
  background-color: #f2f2f2;
  border-radius: 4px;
  width: 50px;
  height: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: 0.25s background-color;
  i.icon {
    color: var(--primary-color);
    margin: 0;
    font-size: 1.4em;
  }
`
const IconSingleContainer = styled(IconContainer)`
  width: 100%;
  justify-content: flex-start;
  i.icon {
    margin: 0 18px 0 13px;
  }
`
const Header = styled('div')`
  display: flex;
  width: 100%;
  padding: 22px 24px;
  cursor: pointer;
  transition: 0.25s background-color;
  &:hover {
    background-color: #f2f2f2;
    .header-icon-container {
      background-color: #dadada;
    }
  }
`

const Titulo = styled('div')`
  align-self: center;
  margin-left: 5px;
`

const IconExpand = styled('div')`
  align-self: center;
  justify-self: center;
  i {
    margin: 0;
  }
`

const Row = styled('div')`
  padding: 15px;
`
const Content = (props: { condition: boolean; children? }) => {
  return (
    <div>
      <Show condition={props.condition}>{props.children}</Show>
    </div>
  )
}

ToolboxItem.SubItem = (props: { label: string; onClick: any }) => {
  const handler = (e) => {
    e.preventDefault()
    props.onClick()
  }

  return (
    <SubItemContainer onClick={handler}>
      <SubItemIcon> + </SubItemIcon>
      <div className="label"> {props.label} </div>
    </SubItemContainer>
  )
}

const SubItemIcon = styled('span')`
  margin-left: 25px;
  color: var(--primary-color);
  font-weight: 700;
  font-size: 1.5em;
`

const SubItemSpan = styled('span')`
  margin-left: 25px;
  color: var(--primary-color);
  font-weight: 700;
  font-size: 1.5em;
`

const SubItemLabel = styled('span')``

const SubItemContainer = styled('div')`
  display: flex;
  align-items: center;
  padding: 18px 0;
  cursor: pointer;
  .label {
    margin-left: 8px;
  }
  :hover .label {
    text-decoration: underline;
  }
`

export { ToolboxItem }
