import { ConteudoItemModel } from './ConteudoItemModel'
import { QuestaoAlternativaModel } from './QuestaoAlternativaModel'
import { RespostaModel } from './RespostaModel'
import { ModuloModel } from './ModuloModel'

export class QuestaoModel {
  constructor(local?: 'AVALIACAO' | 'CONTEUDO', moduloId?: number) {
    this.questoesAlternativas = []
    this.local = local || undefined
    this.moduloId = moduloId || undefined
    if (this.local === 'AVALIACAO') this.tipoQuestao = TIPO_QUESTAO.MULTIPLA_ESCOLHA
  }

  id?: number
  tipoQuestao?: TIPO_QUESTAO
  textoQuestao?: string
  ordenacao?: number
  local?: 'AVALIACAO' | 'CONTEUDO'
  configuracaoAdicional?: string
  revisada?: boolean
  moduloId?: number

  questoesAlternativas?: QuestaoAlternativaModel[]
  respostas?: RespostaModel[]
  conteudos?: ConteudoItemModel[]
  modulo?: ModuloModel
}

export enum TIPO_QUESTAO {
  MULTIPLA_ESCOLHA = 'MULTIPLA_ESCOLHA',
  RELACIONAR_ITENS = 'RELACIONAR_ITENS',
  RELACIONAR_ITEM_A_IMAGEM = 'RELACIONAR_ITEM_A_IMAGEM',
  MULTIPLO_VERDADEIRO_FALSO = 'MULTIPLO_VERDADEIRO_FALSO',
}
