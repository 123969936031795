import styled from '@emotion/styled'

export const styles = {
  color: {
    bg: '#482D6C',
    bgDark: 'rgba(0,0,0,.5)',
    bgLight: 'rgba(0,0,0,.3)',
    bgHover: 'rgba(0,0,0,.2)',
    blue: '#1c7dfa',
    green: 'green',
    red: '#f35958',
    white: 'rgba(255,255,255,.85)',
    orange: 'orange',
  },
  textShadow: '1px 1px 0 #111',
  navHeight: '77px',
  bg: '#a79d80',
}

export const DisplayFlex = styled('div')(
  (props: {
    center?: boolean
    verticalCenter?: boolean
    horizontalCenter?: boolean
    marginBetween?: number
    wrap?: 'wrap' | 'unset'
    forceFlexChildren?: boolean
    spacing?: 'between' | 'around'
  }) => {
    let {
      center,
      verticalCenter,
      horizontalCenter,
      marginBetween,
      wrap,
      forceFlexChildren,
      spacing,
    } = props
    if (center) verticalCenter = horizontalCenter = true

    const retorno = {
      justifyContent: verticalCenter ? 'center' : null,
      alignItems: horizontalCenter ? 'center' : null,
      display: 'flex',
      flexWrap: wrap || 'unset',
    }

    if (forceFlexChildren) {
      retorno['&>*'] = { flex: 1 }
    }

    if (spacing === 'between') retorno.justifyContent = 'space-between'
    if (spacing === 'around') retorno.justifyContent = 'space-around'

    if (marginBetween) {
      return {
        ...retorno,
        '&>*:not(:first-child)': {
          marginLeft: marginBetween ? `${marginBetween}px` : 0,
        },
      }
    }

    return retorno
  }
)

export const Flex = styled('div')((props: { size?: number; self?: string }) => {
  const partial: any = {}
  if (props.self === 'center') {
    partial.textAlign = 'center'
    partial.alignSelf = 'center'
  }

  return {
    ...partial,
    flex: props.size ? props.size : 1,
  }
})

export const Padding = styled('div')(
  (props: { vertical?: boolean; horizontal?: boolean; size?: number }) => {
    const size = props.size || 12
    if (props.vertical) return { padding: `${size}px 0` }
    if (props.horizontal) return { padding: `0 ${size}px` }
    return { padding: `${size}px` }
  }
)

export const Fieldset = styled('div')`
  box-shadow: 0px 1px 3px 0px rgba(0, 0, 0, 0.2), 0px 1px 1px 0px rgba(0, 0, 0, 0.14),
    0px 2px 1px -1px rgba(0, 0, 0, 0.12);
  margin: 12px;
  padding: 12px;
  border: none;
  border-radius: 4px;
  background: #fff;
  & > h3 {
    font-weight: bold;
    font-size: 1.2em;
    color: rgba(0, 0, 0, 0.75);
  }
`

export const Container = styled('div')((props: { flex?: boolean; flexDirection?: string }) => {
  const initial: any = {
    width: '100vw',
    height: '100vh',
    overflow: 'auto',
    background: styles.bg,
    backgroundSize: 'cover',
  }

  if (props.flex) {
    initial.display = 'flex'
    const flexDirection = props.flexDirection ? props.flexDirection : 'column'
    initial.flexDirection = flexDirection
  }
  return initial
})

export const Breadcrumb = styled('div')`
  > div {
    display: inline-block;
    white-space: no-wrap;
    &.text {
      padding: 12px;
      color: ${styles.color.white};
      text-shadow: 1px 1px 0px #444;
      cursor: pointer;
      &:hover {
        color: ${styles.color.bgHover};
      }
      &.active {
        color: ${styles.color.orange};
      }
    }
  }
`

export const Table = styled('div')`
  box-shadow: 0px 1px 5px 0px rgba(0, 0, 0, 0.2), 0px 2px 2px 0px rgba(0, 0, 0, 0.14),
    0px 3px 1px -2px rgba(0, 0, 0, 0.12);
  border-radius: 4px;
  background-color: #fff;
  table {
    width: 100%;
    border-spacing: 1px;
    border-collapse: collapse;
    thead tr {
      height: 38px;
      outline: none;
      vertical-align: middle;
      th {
        color: rgba(0, 0, 0, 0.54);
        font-size: 0.8125rem;
        font-weight: 500;
        padding: 4px 56px 4px 24px;
        text-align: left;
        border-bottom: 1px solid rgba(224, 224, 224, 1);
      }
    }

    tbody tr {
      height: 48px;
      outline: none;
      vertical-align: middle;
      td {
        color: rgba(0, 0, 0, 0.87);
        font-weight: 400;
        padding: 4px 56px 4px 24px;
        border-bottom: 1px solid rgba(224, 224, 224, 1);
        display: table-cell;
        vertical-align: inherit;
        border-spacing: 0;
        border-collapse: collapse;
      }
      &:hover td {
        background: rgba(0, 0, 0, 0.084);
        cursor: pointer;
      }
    }
  }
`
