import { createSlice, PayloadAction } from '@reduxjs/toolkit'

import { ModuloModel } from '../../models/ModuloModel'
import axios, { IQueryParams, ICounter } from '../../axios'

export interface IRequest {
  errorCode: number
  message: string
  fetching: boolean
}
export interface IState {
  modulos?: ModuloModel[]
  modulosCount: number
  request: IRequest
  query?: IQueryParams
}

export const initialState: IState = {
  modulos: [],
  modulosCount: 0,
  request: {
    fetching: false,
    errorCode: null,
    message: '',
  },
  query: { limit: 10, offset: 0 },
}

const reducers = {
  requestAsync(state: IState) {
    state.request = {
      ...initialState.request,
      fetching: true,
    }
  },
  requestClean(state: IState) {
    state.request = initialState.request
  },
  requestError(state: IState, { payload }: PayloadAction<Partial<IRequest>>) {
    state.request = {
      errorCode: payload.errorCode,
      message: payload.message,
      fetching: false,
    }
  },
  modulosGetSuccess(state: IState, { payload }: PayloadAction<ICounter<ModuloModel>>) {
    state.modulos = payload.rows
    state.modulosCount = payload.count
    state.request = initialState.request
  },
  modulosCreateOrUpdateSuccess(state: IState, { payload }: PayloadAction<ModuloModel>) {
    state.modulos = state.modulos.filter((c) => payload.id !== c.id)
    state.modulos.push(payload)
  },
  modulosDeleteSuccess(state: IState, { payload }: PayloadAction<{ id: number }>) {
    state.modulos = state.modulos.filter((c) => +c.id !== payload.id)
  },
  setQueryParams(state: IState, { payload }: PayloadAction<IQueryParams>) {
    state.query = { ...state.query, ...payload }
  },
}

const { reducer: modulosReducer, actions } = createSlice({
  name: 'modulos',
  initialState: initialState,
  reducers,
})

const modulosActions = {
  ...actions,
  updatePagination(cursoId: number, query: IQueryParams) {
    return (dispatch) => {
      dispatch(actions.setQueryParams(query))
      this.modulosFetch({ cursoId })
    }
  },
  modulosFetch({ cursoId }: { cursoId: number }) {
    return async (dispatch, getState) => {
      dispatch(actions.requestAsync())
      try {
        const modulos = await axios.Modulos.get(getState().modulos.query, cursoId)
        dispatch(actions.modulosGetSuccess(modulos))
      } catch (error) {
        dispatch(actions.requestError({ errorCode: 400, message: 'Falha a consultar modulos' }))
      }
    }
  },
  deleteModulo(id: number) {
    return async (dispatch) => {
      dispatch(actions.requestAsync())
      try {
        await axios.Modulos.delete(id)
        dispatch(actions.modulosDeleteSuccess({ id }))
      } catch (error) {
        dispatch(actions.requestError({ errorCode: 400, message: 'Falha ao remover modulo' }))
      }
    }
  },
  createOrUpdateModulos(values: Partial<ModuloModel>) {
    return async (dispatch) => {
      try {
        if (!values.moduloPrecedenteId) values.moduloPrecedenteId = null
        const modulo = await axios.Modulos.post(values)
        dispatch(actions.modulosCreateOrUpdateSuccess({ ...modulo }))
      } catch (e) {
        dispatch(actions.requestError({ errorCode: 400, message: 'Falha ao salvar o modulo' }))
      }
    }
  },
}

export { modulosReducer, modulosActions }
