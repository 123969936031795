import { useEffect, useRef } from 'react'

export const ClickAway = ({ children, setClickAway, ...props }) => {
  const elementRef = useRef(null)

  const clickAway = () => {
    useEffect(() => {
      function handlerClick(e) {
        if (!elementRef?.current?.contains?.(e?.target)) return setClickAway(false)
      }
      window.addEventListener('click', handlerClick)
      return () => window.removeEventListener('click', handlerClick)
    }, [elementRef])
  }

  clickAway()
  return (
    <div {...props} ref={elementRef}>
      {children}
    </div>
  )
}
