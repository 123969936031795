import { ModuloModel } from './ModuloModel'

export class CursoModel {
  id: number
  nome: string
  nomeReduzido: string
  diasParaEmissaoCertificado
  modulos: ModuloModel[]
}

export enum CURSOS {
  RECICLAGEM = 1,
  PREVENTIVO_RECICLAGEM = 18,
  COLETIVO_DE_PASSAGEIROS = 3,
  ESCOLAR = 4,
  PRODUTOS_PERIGOSOS = 5,
  EMERGENCIA = 6,
  CARGA_INDIVISIVEL = 7,
  ATUALIZACAO_COLETIVO_DE_PASSAGEIROS = 8,
  ATUALIZACAO_ESCOLAR = 9,
  ATUALIZACAO_PRODUTOS_PERIGOSOS = 10,
  ATUALIZACAO_EMERGENCIA = 11,
  ATUALIZACAO_CARGA_INDIVISIVEL = 12,
  MOTOTAXISTA = 13,
  MOTOFRETISTA = 14,
  ATUALIZACAO_MOTOTAXISTA = 16,
  ATUALIZACAO_MOTOFRETISTA = 17,
  DIRECAO_DEFENSIVA = 25,
  ATUALIZACAO_RENOVACAO_CNH = 23,
}
