import { PermissaoModel } from '../../models/PermissaoModel'

export class Usuario {
  id: number
  nome: string
  cpf: string
  avatar: string
  email: string
  perfil: string
  cor: string
  permissoes: PermissaoModel[]
}

export const TIPO_USUARIO = {
  ALUNO: 'Aluno',
  TUTOR: 'Tutor',
}

export const TIPO_USUARIO_PLURAL = {
  ALUNO: 'Alunos',
  TUTOR: 'Tutores',
}
