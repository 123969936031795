import { Button, Icon, Popup } from 'semantic-ui-react'
import React, { useContext } from 'react'
import styled from '@emotion/styled'

import { ConteudoEdicao } from './ConteudoEdicao'
import { ConteudoItemModel } from '../../models/ConteudoItemModel'
import { TIPOS_CONTEUDO } from '../../components/Constants'
import { EditorConteudoStore } from './EditorConteudoStore'
import { ConteudoVisualizacao } from './ConteudoVisualizacao'
import { useDrag, DragSourceMonitor } from 'react-dnd'

interface IProps {
  conteudo: ConteudoItemModel
  conteudoItemPaiId?: string
}

export const ConteudoContainer = ({ conteudo, conteudoItemPaiId }: IProps) => {
  const dispatch = EditorConteudoStore.useDispatch()
  const { paginaAtiva } = EditorConteudoStore.useState()

  // if (!conteudoItemPaiId) return (
  //   <Container className="container-conteudo">
  //     <div className="buttons-container">
  //       <Popup trigger={(
  //         <Button circular icon onClick={() => dispatch(EditorConteudoStore.thunks.deleteConteudo(conteudo.id))}><Icon name="delete" /></Button>
  //       )} content="Excluir" />
  //     </div>

  //     <Conteudo conteudo={conteudo} />
  //   </Container>
  // )

  const [{ opacity }, drag, preview] = useDrag({
    item: { conteudo, type: 'CONTEUDO' },
    end: ({ conteudo }, monitor: DragSourceMonitor) => {
      const dropResult = monitor.getDropResult()
      if (conteudo && dropResult) {
        console.log({ conteudo, dropResult })
        dispatch(
          EditorConteudoStore.thunks.moveConteudo({ from: conteudo.id, to: dropResult.conteudo.id })
        )
      }
    },
    collect: (monitor) => ({
      opacity: monitor.isDragging() ? 0.4 : 1,
    }),
  })

  if (paginaAtiva.status === 'PUBLICADO') {
    return <ConteudoVisualizacao conteudo={conteudo} />
  }

  return (
    <Styles.Container primeiroConteudo={conteudo.ordenacao === 1} ref={preview} style={{ opacity }}>
      <div ref={drag}>
        <GrabElement conteudo={conteudo} />
      </div>
      <div className="conteudo-container">
        <ConteudoEdicao conteudo={conteudo} />
      </div>
      <Excluir conteudo={conteudo} />
    </Styles.Container>
  )
}

const GrabElement = ({ conteudo }: { conteudo: ConteudoItemModel }) => {
  if (conteudo.ordenacao === 1) return null
  return (
    <Styles.GrabHandler>
      <GrabHandlerIcon />
    </Styles.GrabHandler>
  )
}

const Excluir = ({ conteudo }: { conteudo: ConteudoItemModel }) => {
  const dispatch = EditorConteudoStore.useDispatch()
  const { deleteConteudo } = EditorConteudoStore.thunks
  if (conteudo.ordenacao === 1) return null
  return (
    <Styles.Excluir onClick={() => dispatch(deleteConteudo(conteudo.id))}>
      <Icon name="trash" />
    </Styles.Excluir>
  )
}

const Styles = {
  Container: styled('div')<{ primeiroConteudo }>(
    (props) => `
    display: flex;
    align-items: center;
    .conteudo-container {
      flex: 1;
      ${props.primeiroConteudo ? 'margin: 0 44px;' : ''}
    }
  `
  ),
  Excluir: styled('div')`
    width: 36px;
    height: 36px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 4px;
    transition: 0.25s background;
    margin: 0 4px;
    &:hover {
      background: #f2f2f2;
      cursor: pointer;
    }
    i.icon {
      color: #c4c4c4;
      margin: 0;
    }
  `,
  GrabHandler: styled('div')`
    margin: 0 4px;
    width: 36px;
    height: 36px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 4px;
    cursor: grab;
    &:active {
      cursor: move;
    }
  `,
}

const GrabHandlerIcon = () => {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <circle cx="7" cy="12" r="2" fill="#C4C4C4" />
      <circle cx="7" cy="19" r="2" fill="#C4C4C4" />
      <circle cx="7" cy="5" r="2" fill="#C4C4C4" />
      <circle cx="16" cy="12" r="2" fill="#C4C4C4" />
      <circle cx="16" cy="19" r="2" fill="#C4C4C4" />
      <circle cx="16" cy="5" r="2" fill="#C4C4C4" />
    </svg>
  )
}
