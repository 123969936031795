import { Route, Switch } from 'react-router-dom'
import React from 'react'
import { IsencaoListar } from './IsencaoListar'

export const Isencao = () => {
  return (
    <Switch>
      <Route path="/isencoes" component={IsencaoListar} />
    </Switch>
  )
}
