import { createSlice } from '@reduxjs/toolkit'
import axios, { IQueryParams } from '../../axios'
import { Forum } from './Forum'
import { ForumMensagemModel } from '../../models/ForumMensagemModel'

export interface IRequest {
  errorCode: number
  message: string
  fetching: boolean
}

export interface IState {
  forumActive: Forum
  forumMensagemEditing: Partial<ForumMensagemModel>
  selectedForumMensagem: Partial<ForumMensagemModel>
  foruns: Forum[]
  forunsCount: 0
  mensagensPendentes: Forum[]
  mensagensPendentesCount: 0
  request: IRequest
  queryForuns: IQueryParams
  queryMensagensPendentes: IQueryParams
}

export const initialState: IState = {
  forumActive: null,
  forumMensagemEditing: {},
  selectedForumMensagem: null,
  foruns: [],
  forunsCount: 0,
  mensagensPendentes: [],
  mensagensPendentesCount: 0,
  request: {
    errorCode: null,
    message: '',
    fetching: false,
  },
  queryForuns: { limit: 10, offset: 0 },
  queryMensagensPendentes: { limit: 10, offset: 0 },
}

const reducers = {
  requestAsync(state: IState) {
    state.request = {
      ...initialState.request,
      fetching: true,
    }
  },
  requestError(state: IState, { payload }: any) {
    state.request = {
      errorCode: payload.errorCode,
      message: payload.message,
      fetching: false,
    }
  },
  setEditaForumMensagem(state: IState, { payload }: any) {
    if (!payload.forumMensagem) {
      state.forumMensagemEditing = {}
      return state
    }
    state.forumMensagemEditing = payload.forumMensagem
  },
  setMensagemResposta(state: IState, { payload }: any) {
    state.forumMensagemEditing = { ...state.forumMensagemEditing, texto: payload.texto }
  },
  setSelectedForumMensagem(state: IState, { payload }: any) {
    state.selectedForumMensagem = payload.selectedForumMensagem
  },
  forumActiveSet(state: IState, { payload }: any) {
    state.forumActive = payload.forum
  },
  forumActiveClean(state: IState) {
    state.forumActive = null
  },
  forunsGetSuccess(state: IState, { payload }: any) {
    state.foruns = payload.rows
    state.forunsCount = payload.count
    state.request = initialState.request
  },
  mensagensPendenteGetSuccess(state: IState, { payload }: any) {
    state.mensagensPendentes = payload.rows
    state.mensagensPendentesCount = payload.count
    state.request = initialState.request
  },
  setQueryParamsForuns(state: IState, { payload }: any) {
    state.queryForuns = { ...state.queryForuns, ...payload }
  },
  setQueryParamsMensagensPendentes(state: IState, { payload }: any) {
    state.queryMensagensPendentes = { ...state.queryMensagensPendentes, ...payload }
  },
}

const { reducer: forunsReducer, actions } = createSlice({
  name: 'foruns',
  initialState: initialState,
  reducers,
})

const forunsActions = {
  ...actions,
  paginateForuns(query: IQueryParams) {
    return (dispatch) => {
      dispatch(actions.setQueryParamsForuns(query as any))
      this.getForuns()
    }
  },
  paginateMensagensPendentes(query: IQueryParams) {
    return (dispatch) => {
      dispatch(actions.setQueryParamsMensagensPendentes(query as any))
      this.getMensagensPendentes()
    }
  },
  enviarMensagemResposta() {
    return async (dispatch, getState) => {
      try {
        const forumMensagemEditing = getState().foruns.forumMensagemEditing
        await axios.ForunsMensagens.createOrUpdate({
          ...forumMensagemEditing,
          forumId: getState().foruns.forumActive.id,
        })
        dispatch(forunsActions.setForum(getState().foruns.forumActive.id))
        dispatch(
          forunsActions.setEditaForumMensagem({ forumMensagem: new ForumMensagemModel() } as any)
        )
      } catch (error) {
        /* setErrorMessage(error.message) */
      }
    }
  },
  aprovarMensagem(forumMensagem: ForumMensagemModel) {
    return async (dispatch) => {
      try {
        await axios.Foruns.aprovarMensagem(forumMensagem.id)
        dispatch(forunsActions.getMensagensPendentes())
        dispatch(forunsActions.setForum(forumMensagem.forumId))
      } catch (error) {
        console.log({ error })
      }
    }
  },
  rejeitarMensagem(forumMensagem: ForumMensagemModel) {
    return async (dispatch) => {
      try {
        await axios.Foruns.rejeitar(forumMensagem.id)
        dispatch(forunsActions.getMensagensPendentes())
        dispatch(forunsActions.setForum(forumMensagem.forumId))
      } catch (error) {
        /* setErrorMessage(error.message) */
      }
    }
  },
  aprovarForum() {
    return async (dispatch, getState) => {
      try {
        await axios.Foruns.aprovarForum(getState().foruns.forumActive.id)
        dispatch(forunsActions.getForuns())
        dispatch(forunsActions.setForum(null))
      } catch (error) {
        console.log({ error })
      }
    }
  },
  setForum(forumId: number) {
    return async (dispatch) => {
      if (!forumId) {
        dispatch(actions.forumActiveClean())
        dispatch(actions.setSelectedForumMensagem({ selectedForumMensagem: null } as any))
      }
      try {
        const [forum, forunsMensagens] = await Promise.all([
          axios.Foruns.getOne(forumId),
          axios.Foruns.getForunsMensagensByForum(forumId),
        ])
        dispatch(actions.forumActiveSet({ forum: { ...forum, forunsMensagens } } as any))
      } catch (e) {
        dispatch(
          actions.requestError({ errorCode: 400, errorMessage: 'falha ao consultar foruns' } as any)
        )
      }
    }
  },
  setForumMensagem(forumMensagem: ForumMensagemModel) {
    return async (dispatch) => {
      dispatch(
        forunsActions.setSelectedForumMensagem({ selectedForumMensagem: forumMensagem } as any)
      )
      await dispatch(forunsActions.setForum(forumMensagem.forumId))
      return true
    }
  },
  getForuns() {
    return async (dispatch, getState) => {
      dispatch(actions.requestAsync())
      try {
        const foruns = await axios.Foruns.getAll(getState().foruns.queryForuns)
        dispatch(actions.forunsGetSuccess({ ...foruns } as any))
      } catch (error) {
        dispatch(
          actions.requestError({ errorCode: 400, message: 'Falha a consultar foruns' } as any)
        )
      }
    }
  },
  getMensagensPendentes() {
    return async (dispatch, getState) => {
      dispatch(actions.requestAsync())
      try {
        const foruns = await axios.Foruns.getForunsMensagensPendentes(
          getState().foruns.queryMensagensPendentes
        )
        dispatch(actions.mensagensPendenteGetSuccess({ ...foruns } as any))
      } catch (error) {
        dispatch(
          actions.requestError({ errorCode: 400, message: 'Falha a consultar mensagens' } as any)
        )
      }
    }
  },
}

export { forunsReducer, forunsActions }
