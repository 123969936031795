import { Icon, Popup } from 'semantic-ui-react'
import { Redirect, Switch, useHistory } from 'react-router'
import { Route, NavLink } from 'react-router-dom'
import { bindActionCreators, Store } from 'redux'
import { colors } from '../theme'
import { useDispatch, useSelector } from 'react-redux'
import React, { useState, useContext, useEffect } from 'react'
import styled from '@emotion/styled'

import { AlteraSenha } from '../pages/AlteraSenha/AlteraSenha'
import { Avatar } from './Avatar'
import { Chat } from '../pages/Chat/Chat'
import { ChatContactList } from '../pages/ChatContactList/ChatContactList'
import { ChatMensagem } from '../pages/ChatMensagem/ChatMensagem'
import { Cursos } from '../pages/Cursos/Cursos'
import { EditorConteudo } from '../pages/EditorConteudo/EditorConteudo'
import { Flex, styles, DisplayFlex } from './Styles'
import { Foruns } from '../pages/Foruns/Foruns'
import { Historico } from '../pages/Historico/Historico'
import { IStateRedux } from '../ReduxStore'
import { Modulos } from '../pages/Modulos/Modulos'
import { ModulosEditar } from '../pages/Modulos/ModulosEditar'
import { Perfil } from '../pages/Perfil/Perfil'
import { Progresso } from '../pages/Progresso/Progresso'
import { Questoes } from '../pages/Questoes/Questoes'
import { Usuarios } from '../pages/Usuarios'
import { Alunos, AlunosEditar, AlunosMatricular } from '../pages/Alunos'
import { UsuariosCursos } from '../pages/UsuariosCurso/UsuariosCursos'
import { Cupons } from '../pages/Cupons/Cupons'
import { authActions, authReducer, getUsuarioLogado } from '../pages/Auth/AuthReducer'
import { filesUrl } from '..'
import useAxios from 'axios-hooks'
import { Certificados } from '../pages/Certificados/Certificados'
import { copyToClipboard } from '../util/copyToClipboard'
import { createSimpleStore } from './SimpleReducer'
import { Isencao } from '../pages/Isencao/Isencao'

type TMenuOptions = 'Alunos' | 'Cursos' | 'isencoes'
const StoreHome = createSimpleStore(
  {
    menuOptionSelected: 'Alunos' as TMenuOptions,
  },
  {
    changeMenuOptionSelected: (state, option: TMenuOptions) => ({
      ...state,
      menuOptionSelected: option,
    }),
  },
  {
    thunks: {},
  }
)

export type TStore = ReturnType<typeof StoreHome.useState>

const routes = [
  { path: '/altera-senha', component: AlteraSenha },
  { path: '/perfil', component: Perfil },
  { path: '/cursos/:cursoId/modulos/novo', component: ModulosEditar },
  { path: '/cursos/:cursoId/modulos/:moduloId/editar', component: ModulosEditar },
  { path: '/cursos/:cursoId/usuarios/:usuarioId/acompanhamento', component: Progresso },
  { path: '/cursos/:cursoId/alunos/editar/:usuarioId?', component: AlunosEditar },
  { path: '/cursos/:cursoId/alunos/', component: AlunosMatricular },
  { path: '/cursos', component: Cursos },
  { path: '/certificados', component: Certificados },
  { path: '/conteudo/:moduloId', component: EditorConteudo },
  // { path: '/modulos', component: Modulos },
  { path: '/foruns', component: Foruns },
  { path: '/usuarios/editar', component: Usuarios },
  { path: '/usuarios-cursos', component: UsuariosCursos },
  { path: '/historico/:usuarioId', component: Historico },
  { path: '/chat/alunos', component: ChatContactList },
  { path: '/chat/:chatId', component: ChatMensagem },
  { path: '/chat', component: Chat },
  { path: '/cupons', component: Cupons },
  { path: '/alunos', component: Alunos },
  { path: '/isencoes', component: Isencao },
]
export const Home = () => {
  const { token } = useSelector((s: IStateRedux) => s.auth)
  if (!token) return <Redirect to="/login" />

  return (
    <StoreHome.Provider>
      <Container>
        <Nav>
          <img src={require('../assets/New_Driver_Negativo.png')} />
          <ContainerOptionsNav />
          <Flex />
          <SenhaMaster />
          {/* <i className="bell outline icon" style={{ fontSize: '25px' }}></i> */}
          <UsuarioLogado />
        </Nav>
        <ContainerMenuContent>
          {/* <Menu /> */}
          <Content>
            <Switch>
              {routes.map((route, i) => (
                <Route key={i} path={route.path} component={route.component} />
              ))}
            </Switch>
          </Content>
        </ContainerMenuContent>
        {/* <DevTools /> */}
      </Container>
    </StoreHome.Provider>
  )
}

const ContainerOptionsNav = () => {
  const { Layout, Option } = ContainerOptionsNav
  const { menuOptionSelected } = StoreHome.useState()
  const dispatch = StoreHome.useDispatch()
  const history = useHistory()
  const handleSelectOptionMenu = (option) => {
    history.push(`/${option.toLowerCase()}`)
    dispatch(StoreHome.actions.changeMenuOptionSelected(option))
  }

  return (
    <Layout>
      <Option
        onClick={() => handleSelectOptionMenu('Alunos')}
        active={menuOptionSelected === 'Alunos'}
      >
        Alunos
      </Option>
      <Option
        onClick={() => handleSelectOptionMenu('Cursos')}
        active={menuOptionSelected === 'Cursos'}
      >
        Cursos
      </Option>
      <Option
        onClick={() => handleSelectOptionMenu('isencoes')}
        active={menuOptionSelected === 'isencoes'}
      >
        Isenções
      </Option>
    </Layout>
  )
}

ContainerOptionsNav.Layout = styled.div`
  display: flex;
`
ContainerOptionsNav.Option = styled.div<{ active: boolean }>(
  ({ active }) => `
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0 10px;
  color: ${active ? '#373737;' : '#FFFFFF;'}
  cursor: pointer;
  font-weight: 700;
  font-size: 17px;
`
)

const SenhaMaster = () => {
  const { Layout } = SenhaMaster
  const usuario = useSelector(getUsuarioLogado)
  const [{ data }, req] = useAxios('/usuarios/senha-master')

  useEffect(() => {
    const interval = setInterval(() => req(), 60 * 1000)
    return () => clearInterval(interval)
  })

  function copy() {
    copyToClipboard(data)
  }

  if ([1000, 1001, 1002, 1003, 1004, 1005, 1006].includes(usuario?.id)) return null
  return (
    <Layout>
      <span>
        Senha Master: <strong>{data}</strong>
      </span>
      <Icon name="redo" onClick={req} />
      <Icon name="copy outline" onClick={copy} />
    </Layout>
  )
}
SenhaMaster.Layout = styled.div`
  align-self: center;
  display: flex;
  gap: 8px;
  i {
    cursor: pointer;
  }
`

const Menu = () => {
  return (
    <MenuStyled>
      <MenuItem to="/inicio">Início</MenuItem>
      <MenuItem to="/cursos">Cursos</MenuItem>
      <MenuItem to="/foruns">Fóruns</MenuItem>
      <MenuItem to="/usuarios/editar">Usuários</MenuItem>
      <MenuItem to="/chat">Chat</MenuItem>
      <MenuItemPlaceholder />
    </MenuStyled>
  )
}

const UsuarioLogado = () => {
  const dispatch = useDispatch()
  const actions = bindActionCreators(authActions, dispatch)

  const usuario = useSelector(getUsuarioLogado)
  const goToPerfil = () => null
  const changeSenha = () => null

  return (
    <PopupElement>
      <Popup
        trigger={
          <DisplayFlex marginBetween={6} center={true}>
            <div style={{ border: '2px solid white', borderRadius: '50%' }}>
              <Avatar usuario={usuario} circular={true} tamanho={39} />
            </div>
            {/* <div> {usuario.nome} </div> */}
          </DisplayFlex>
        }
        flowing
        hoverable
        inverted
      >
        <PopupItemElement onClick={goToPerfil}>Perfil</PopupItemElement>
        <PopupItemElement onClick={changeSenha}>Alterar Senha</PopupItemElement>
        <PopupItemElement onClick={actions.logout as any}>Sair</PopupItemElement>
      </Popup>
    </PopupElement>
  )
}

const MenuItem = React.memo(({ children, to }: any) => {
  return (
    <MenuItemStyled activeClassName="active" to={to}>
      {children}
    </MenuItemStyled>
  )
})

const Container = styled('div')`
  width: 100vw;
  height: 100vh;
  overflow: hidden;
  background-image: ${styles.bg};
  background-size: cover;
`

const Nav = styled('div')`
  height: 76px;
  background-color: var(--primary-color);
  width: 100%;
  margin-bottom: 1px;
  color: white;
  display: flex;
  img {
    height: 45px;
    width: 123px;
    margin: 13px 42px;
  }
`

const ContainerMenuContent = styled('div')`
  display: flex;
  overflow: auto;
  height: calc(100vh - 76px);
`

const MenuStyled = styled('div')`
  height: 100%;
  display: flex;
  flex-direction: column;
`
const MenuItemStyled = styled(NavLink)`
  color: #fff;
  &:hover {
    background-color: ${styles.color.bgHover};
    color: #fff;
  }
  &.active {
    border-right: 6px solid ${styles.color.blue};
  }
  font-size: 16px;
  background-color: ${styles.color.bg};
  line-height: 63px;
  margin-bottom: 1px;
  transition: background 0.3s;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  padding-left: 16px;
  cursor: pointer;
`

const MenuItemPlaceholder = styled('div')`
  flex: 1;
  background-color: ${styles.color.bg};
`

const Content = styled('div')`
  flex: 1;
  display: flex;
  & > div {
    flex: 1;
  }
`

const PopupItemElement = styled('div')`
  padding: 4px 12px;
  cursor: pointer;
  text-align: right;
  &:after {
    display: block;
    content: '';
    border-bottom: solid 1px ${styles.color.orange};
    transform: scaleX(0);
    transform-origin: right;
    transition: transform 250ms ease-in-out;
  }
  &:hover:after {
    transform: scaleX(1);
  }
`
const PopupElement = styled('div')`
  display: flex;
  align-items: center;
  padding: 0 12px;
  cursor: pointer;
`

const AvatarStyled = styled('img')`
  border-radius: 50%;
  width: 32px;
`
