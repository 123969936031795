import React from 'react'
import { Switch, Route, Redirect, BrowserRouter } from 'react-router-dom'
import { Home } from './Home'
import { Login } from '../pages/Auth'
import { NovaSenha } from '../pages/NovaSenha/NovaSenha'
import { MatricularAluno } from '../pages/UsuariosCurso'

import { EsqueceuSenha } from '../pages/EsqueceuSenha/EsqueceuSenha'
import { toast, ToastContainer } from 'react-toastify'
import axios from 'axios'
import { useSelector, useDispatch } from 'react-redux'
import { IStateRedux } from '../ReduxStore'
import '../App.css'
import { authActions, authReducer } from '../pages/Auth/AuthReducer'

axios.defaults.baseURL = process.env.REACT_APP_API_URL || process.env.react_app_api_url // 'http://localhost:3500/api'
axios.defaults.headers.common.platform = 'PAINEL'
axios.defaults.headers.Authorization = `Bearer ${localStorage.getItem('token')}`

const ConfigureAxios = () => {
  const token = useSelector((s: IStateRedux) => s.auth.token)

  React.useEffect(() => {
    if (token) {
      localStorage.setItem('token', '' + token)
      axios.defaults.headers.Authorization = `Bearer ${token}`
    } else {
      localStorage.removeItem('token')
      axios.defaults.headers.Authorization = null
    }
  }, [token])
  return null
}

export const App = () => {
  return (
    <>
      <BrowserRouter basename="/">
        <Switch>
          <Route path="/login" component={Login} />
          <Route path="/nova-senha/:token(\w+)" component={NovaSenha} />
          <Route path="/esqueceu-senha" component={EsqueceuSenha} />
          <Route exact path="/pe/matricular" component={MatricularAluno} />
          <Route exact path="/" render={() => <Redirect to="/login" />} />
          <Route path="/" component={Home} />
        </Switch>
      </BrowserRouter>
      <ToastContainer style={{ marginTop: '40px' }} />
      <ConfigureAxios />
    </>
  )
}
