import { Field, getIn, FieldProps } from 'formik'
import { Input } from 'semantic-ui-react'
import React from 'react'

interface IProps {
  name: string
  placeholder?: string
  icon?: string
  type?: string
  className?: string
  label?: string
  disabled?: boolean
  upperCase?: boolean
  onChange?: (value: React.FormEvent<HTMLInputElement>) => void
}

/**
 * @deprecated use TextField instead
 */
export const SimpleInput: React.FC<IProps> = (props) => {
  let { name, className } = props

  if (!className) className = ''
  return (
    <Field
      name={name}
      render={({ field, form }: FieldProps<any>) => {
        const error = getIn(form.errors, name)
        const touch = getIn(form.touched, name)
        const showError: boolean = error && touch
        if (touch) className += ' input-touched'
        if (error) className += ' input-error'

        return <SimpleInputContainer {...props} {...{ className, field, showError, error, form }} />
      }}
    />
  )
}

interface IPropsContainer {
  name: string
  placeholder?: string
  icon?: string
  type?: string
  label?: string
  className?: string
  field: any
  showError?: boolean
  error?: string
  disabled?: boolean
  form?: any
  upperCase?: any
  classes?: any
  onChange?: (value: React.FormEvent<HTMLInputElement>) => void
}

const callAll =
  (...fns: any[]) =>
  (...args: any[]) =>
    fns.forEach((fn) => fn && fn(...args))
let timeoutValidation: any

/**
 * @deprecated use TextField instead
 */
export const SimpleInputContainer = (props: IPropsContainer) => {
  let { name, placeholder, type, label, field, showError, error, onChange, disabled, classes } =
    props
  if (!type) type = 'text'
  if (!label) label = placeholder
  const throttleValidation = () => {
    if (timeoutValidation) clearTimeout(timeoutValidation)
    timeoutValidation = setTimeout(() => {
      if (props.form) props.form.validateForm()
    }, 500)
  }

  return (
    <div>
      <Input fluid type={type} {...field} disabled={disabled} error={error} />
    </div>
  )
}
