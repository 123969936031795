import React, { useState, useContext, useEffect } from 'react'
import { NovaSenhaView } from './NovaSenhaView'
import { GlobalStoreValues, GlobalStoreCtx } from '../../GlobalStore'
import axios from '../../axios'
import { UsuarioModel } from '../../models/UsuarioModel'

const useNovaSenhaStore = (token: string) => {
  const { setErrorMessage, setSuccessMessage } = useContext<GlobalStoreValues>(GlobalStoreCtx)

  const [usuario, _setUsuario] = useState<UsuarioModel>(null)
  const [redirectTo, _setRedirectTo] = useState('')
  const [tokenInvalido, _setTokenInvalido] = useState(false)

  useEffect(() => {
    getUsuarioByToken()
  }, [])

  const getUsuarioByToken = async () => {
    try {
      const $usuario = await axios.Usuarios.getByToken(token)
      if ($usuario) _setUsuario($usuario)
      else _setTokenInvalido(true)
    } catch (error) {
      setErrorMessage(error.message)
    }
  }

  const updateSenha = async (usuarioId: number, senha: string) => {
    try {
      await axios.Usuarios.updateSenha(usuarioId, senha, { token })
      _setRedirectTo('/login')
      setSuccessMessage('Senha alterada com sucesso', 6000)
    } catch (error) {
      setErrorMessage(error.message)
    }
  }

  const setRedirectTo = (location: string) => _setRedirectTo(location)

  return { updateSenha, usuario, tokenInvalido, setRedirectTo, redirectTo }
}

export type NovaSenhaStoreValues = ReturnType<typeof useNovaSenhaStore>
export const NovaSenhaStoreCtx = React.createContext(null)
export const NovaSenha = ({ match }: any) => {
  const Values = useNovaSenhaStore(match.params.token)
  return (
    <NovaSenhaStoreCtx.Provider value={Values}>
      <NovaSenhaView />
    </NovaSenhaStoreCtx.Provider>
  )
}
