import React, { useEffect, useState } from 'react'
import styled from '@emotion/styled'
import useAxios from 'axios-hooks'
import { Container, Button, Table as TableComp, Segment as SegmentComp } from 'semantic-ui-react'
import { NavEstrutural } from '../../components/NavEstrutural'
const Table = TableComp as any
const Segment = SegmentComp as any

export const CuponsView = (props: any) => {
  const { Layout } = CuponsView

  const [{ data: cupons }] = useAxios(`/cupons/${1}`)

  if (!cupons) return null
  return (
    <Layout>
      <NavEstrutural />
      <Segment color="purple" style={{ marginTop: 25 }}>
        <h4> Cupons </h4>
        <Table>
          <thead>
            <tr>
              <th>Parceiro</th>
              <th>Código</th>
              <th>Valor de desconto</th>
            </tr>
          </thead>
          <tbody>
            {cupons.length === 0 ? (
              <tr>
                <td>
                  <span>Não há nenhum curso cadastrado ainda...</span>
                </td>
              </tr>
            ) : null}
            {cupons.map((cupom) => (
              <tr key={cupom.id}>
                <td>{cupom.parceiro.nome}</td>
                <td>{cupom.codigo}</td>
                <td>R${cupom.valorDesconto.toFixed(2).replace('.', ',')}</td>
              </tr>
            ))}
          </tbody>
        </Table>
        <Button primary fluid onClick={() => props.history.push('/cupons/novo')}>
          Criar novo cupom
        </Button>
      </Segment>
    </Layout>
  )
}
CuponsView.Layout = styled(Container)`
  text-align: center;
`
