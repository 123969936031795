import { Button } from 'semantic-ui-react'
import React from 'react'
import styled from '@emotion/styled'

import { ToolboxItem } from './ToolboxItem'
import { colors } from '../../../theme'
import { EditorConteudoStore } from '../EditorConteudoStore'
import { useParams } from 'react-router'
import { filesUrl } from '../../..'
import { toast } from 'react-toastify'
import { TIPO_QUESTAO } from '../../../models/QuestaoModel'
import { ContainersArquivos, Util } from '../../../components/Util'

const Toolbox = ({ ...props }: React.HTMLAttributes<HTMLDivElement>) => {
  const dispatch = EditorConteudoStore.useDispatch()
  const { moduloId } = useParams<any>()
  const state = EditorConteudoStore.useState()
  const { paginaAtiva, paginas } = state
  const { novaPagina, addConteudo, setImportarPagina } = EditorConteudoStore.thunks

  function handleClickPagina() {
    dispatch(novaPagina(moduloId))
  }

  async function handleClickImagem() {
    if (paginaAtiva.status === 'PUBLICADO') {
      return toast('Antes de editar uma página, clique no botão EDITAR')
    }
    const imagem: any = await Util.requestUploadImagem({
      containerName: ContainersArquivos.CONTEUDO,
    })
    dispatch(addConteudo(moduloId, paginaAtiva.id, 'IMAGEM', imagem))
  }

  function handleClickQuestao(tipoQuestao: TIPO_QUESTAO) {
    if (paginaAtiva.status === 'PUBLICADO') {
      return toast('Antes de editar uma página, clique no botão EDITAR')
    }
    dispatch(
      EditorConteudoStore.actions.openQuestaoEdicao({
        tipoQuestao,
        conteudoId: null,
        questaoId: null,
      })
    )
  }
  return (
    <Container {...props}>
      <ToolboxContainer>
        <ToolboxItem
          icon="call"
          label="Criar Live"
          onClick={() => dispatch(addConteudo(moduloId, paginaAtiva.id, 'LIVE'))}
        />
        <ToolboxItem icon="plus square outline" label="Página">
          <ToolboxItem.SubItem label="Adicionar página" onClick={handleClickPagina} />
          <ToolboxItem.SubItem
            label="Importar página"
            onClick={() => dispatch(setImportarPagina(true))}
          />
        </ToolboxItem>
        <ToolboxItem icon="font" label="Texto">
          <ToolboxItem.SubItem
            label="Título"
            onClick={() => dispatch(addConteudo(moduloId, paginaAtiva.id, 'TITULO'))}
          />
          <ToolboxItem.SubItem
            label="Subtítulo"
            onClick={() => dispatch(addConteudo(moduloId, paginaAtiva.id, 'SUBTITULO'))}
          />
          <ToolboxItem.SubItem
            label="Parágrafo"
            onClick={() => dispatch(addConteudo(moduloId, paginaAtiva.id, 'TEXTO'))}
          />
          <ToolboxItem.SubItem
            label="Lista"
            onClick={() =>
              dispatch(addConteudo(moduloId, paginaAtiva.id, 'TEXTO', '<ul><li></li></ul>'))
            }
          />
        </ToolboxItem>
        <ToolboxItem icon="video" label="Vídeo">
          <ToolboxItem.SubItem
            label="Novo vídeo"
            onClick={() => dispatch(addConteudo(moduloId, paginaAtiva.id, 'VIDEO'))}
          />
          {/* <ToolboxItem.SubItem label='Importar da biblioteca' onClick={() => console.log('lib')}/> */}
        </ToolboxItem>
        <ToolboxItem icon="photo" label="Imagem">
          <ToolboxItem.SubItem label="Nova imagem" onClick={handleClickImagem} />
        </ToolboxItem>
        <ToolboxItem icon="check square outline" label="Questões">
          <ToolboxItem.SubItem
            label="Múltipla escolha"
            onClick={() => handleClickQuestao(TIPO_QUESTAO.MULTIPLA_ESCOLHA)}
          />
          <ToolboxItem.SubItem
            label="Relacionar uma reposta a outra"
            onClick={() => handleClickQuestao(TIPO_QUESTAO.RELACIONAR_ITENS)}
          />
          <ToolboxItem.SubItem
            label="Verdadeiro ou Falso"
            onClick={() => handleClickQuestao(TIPO_QUESTAO.MULTIPLO_VERDADEIRO_FALSO)}
          />
          {/* <ToolboxItem.SubItem label='Importar questão' onClick={() => handleClickQuestao('')} /> */}
        </ToolboxItem>
      </ToolboxContainer>
    </Container>
  )
}

const Container = styled('div')``

const ToolboxContainer = styled('div')``

export { Toolbox }
