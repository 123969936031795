import { UsuarioModel } from './UsuarioModel'
import { ForumModel } from './ForumModel'

export class ForumMensagemModel {
  constructor() {
    this.texto = ''
  }

  id: number
  texto: string
  textoPendente: string

  usuarioId: number
  usuario: UsuarioModel
  forumId: number
  forum: ForumModel
  forumMensagemId: number
  forunsMensagens: ForumMensagemModel[]
  forumMensagemPai: ForumMensagemModel
  // forunsMensagensReacoes: ForumMensagemReacao[]

  createdAt: Date
  updatedAt: Date
}
