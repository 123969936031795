import React, { useContext } from 'react'
import { HistoricoStoreValues, HistoricoStoreCtx } from './Historico'
import { format } from 'date-fns'
import { ProgressoModel } from '../../models/ProgressoModel'

export const HistoricoView = () => {
  const { dias } = useContext<HistoricoStoreValues>(HistoricoStoreCtx)

  return (
    <div>
      {dias.map((dia) => (
        <div key={dia}>
          <div>{dia}</div>
          <ProgressosDiarios dia={dia} />
        </div>
      ))}
    </div>
  )
}

const ProgressosDiarios = React.memo(({ dia }: { dia: string }) => {
  const { historico } = useContext<HistoricoStoreValues>(HistoricoStoreCtx)

  const progressos = historico.rows.filter((x) => format(x.createdAt, 'DD/MM/YYYY') === dia)

  return (
    <div>
      {progressos.map((progresso) => (
        <ProgressoDiarioItem key={progresso.id} progresso={progresso} />
      ))}
    </div>
  )
})

const ProgressoDiarioItem = ({ progresso }: { progresso: ProgressoModel }) => {
  const { totalItensPagina } = useContext<HistoricoStoreValues>(HistoricoStoreCtx)

  let totalPagina: any = totalItensPagina.find((x) => x.id === progresso.conteudoPaginaId)
  if (totalPagina) totalPagina = totalPagina.total

  return (
    <div>
      Módulo: {progresso.conteudoPagina.modulo.nome} -&nbsp; Página n#{' '}
      {progresso.conteudoPagina.numero} -&nbsp;
      {progresso.conteudoItem.ordenacao}/{totalPagina} -&nbsp;
      {progresso.porcentagem ? `${progresso.porcentagem}% - ` : null}
      {format(progresso.createdAt, 'HH:mm')}
    </div>
  )
}
