import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { IStateRedux } from '../../../ReduxStore'
import { bindActionCreators } from 'redux'
import { questaoEditorActions } from '../QuestaoEditorStore'
import { Button, Icon } from 'semantic-ui-react'
import { QuestaoAlternativaModel } from '../../../models/QuestaoAlternativaModel'
import styled from '@emotion/styled'
import { ItemAlternativa } from './ItemAlternativa'

export const RelacionarItens = ({ questaoId }) => {
  const dispatch = useDispatch()
  const questao = useSelector((s: IStateRedux) =>
    s.questaoEditor[questaoId] ? s.questaoEditor[questaoId].questao : { questoesAlternativas: [] }
  )
  const actions = bindActionCreators(questaoEditorActions, dispatch)

  return (
    <div>
      <div>Conceitos:</div>
      <div>
        {questao.questoesAlternativas.map((alternativa) => (
          <Alternativa
            key={alternativa.id}
            questaoAlternativa={alternativa}
            questaoId={questaoId}
          />
        ))}
      </div>
      <div style={{ textAlign: 'right' }}>
        <Style.AdicionarAlternativa onClick={() => actions.addAlternativa({ questaoId })}>
          <Icon name="plus circle" /> Adicionar novo conceito
        </Style.AdicionarAlternativa>
      </div>
    </div>
  )
}

const Alternativa = ({
  questaoId,
  questaoAlternativa,
}: {
  questaoId: string
  questaoAlternativa: QuestaoAlternativaModel
}) => {
  const dispatch = useDispatch()
  const actions = bindActionCreators(questaoEditorActions, dispatch)
  function handleUpdateConteudoAlternativa(conteudoAlternativa) {
    actions.changeConteudoAlternativa({
      questaoId,
      conteudoAlternativa,
      alternativaId: questaoAlternativa.id,
    } as any)
  }
  function handleUpdateRespostaEsperada(respostaEsperada) {
    actions.changeRespostaEsperada({
      questaoId,
      respostaEsperada,
      alternativaId: questaoAlternativa.id,
    })
  }
  function handleDeleteAlternativa() {
    actions.deleteAlternativaById({ questaoId, alternativaId: questaoAlternativa.id })
  }
  return (
    <Style.ContainerConceito>
      <div>
        <ItemAlternativa
          item={questaoAlternativa.conteudoAlternativa}
          updateItem={handleUpdateConteudoAlternativa}
        />
      </div>
      <div>
        <ItemAlternativa
          item={questaoAlternativa.respostaEsperada}
          updateItem={handleUpdateRespostaEsperada}
        />
      </div>
      <div className="delete">
        <Button icon="trash" onClick={handleDeleteAlternativa} />
      </div>
    </Style.ContainerConceito>
  )
}

const Style = {
  ContainerConceito: styled('div')`
    display: flex;
    padding: 12px;
    border: 1px solid #ccc;
    border-radius: 4px;
    margin-bottom: 12px;
    & > div {
      flex: 1;
      &:not(:first-child) {
        margin-left: 12px;
      }
      &.delete {
        flex: none;
        align-self: flex-end;
      }
    }
    &:not(:first-child) {
      border-top: 1px solid #ccc;
    }
  `,
  Img: styled('img')`
    max-width: 100%;
  `,
  AdicionarAlternativa: styled('div')`
    padding: 12px;
    cursor: pointer;
    border-radius: 4px;
    display: inline-block;
    &:hover {
      background: #f2f2f2;
    }
  `,
}
