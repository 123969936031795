import { Button, Icon } from 'semantic-ui-react'
import { Table } from '../../components/Styles'
import React from 'react'

import { Pagination } from '../../components/Pagination'
import { useHistory } from 'react-router'
import axios, { ICounter } from '../../axios'
import { DownloadModel } from '../../models/DownloadModel'
import { Show } from '../../components/Basic'
import { createSimpleStore } from '../../components/SimpleReducer'

interface DownloadsCursosViewProps {
  cursoId: number
}

const Store = createSimpleStore(
  {
    cursoId: 0,
    downloads: [] as DownloadModel[],
    downloadsCount: 0,
    fetching: false,
    query: { limit: 10, offset: 0 },
    deletingDownloadId: null as number | null,
  },
  {
    init(state, payload: { cursoId }) {
      state.cursoId = payload.cursoId
    },
    downloadCreateOrUpdateSuccess(state, download: DownloadModel) {
      const edicao = state.downloads.some((d) => d.id === download.id)
      if (!edicao) {
        state.downloadsCount = state.downloadsCount + 1
        state.downloads.push(download)
      } else {
        state.downloads = state.downloads.map((d) => (download.id === d.id ? download : d))
      }
    },
    downloadsDeleteSuccess(state, downloadId: number) {
      state.downloads = state.downloads.filter((c) => +c.id !== downloadId)
      state.downloadsCount = state.downloadsCount - 1
    },
    setQueryParams(state, query: { limit?: number; offset?: number }) {
      state.query = { ...state.query, ...query }
    },
    getDownloadsByCursoStarted(state) {
      state.fetching = true
    },
    getDownloadsByCursoSuccess(state, responseDownloads: ICounter<DownloadModel>) {
      state.downloads = responseDownloads.rows
      state.downloadsCount = responseDownloads.count
      state.fetching = false
    },
    getDownloadsByCursoError(state) {
      state.fetching = false
    },
    deleteDownloadStarted(state, downloadId: number) {
      state.deletingDownloadId = downloadId
    },
    deleteDownloadSuccess(state, downloadId: number) {
      state.downloads = state.downloads.filter((u) => u.id !== downloadId)
      state.downloadsCount = state.downloadsCount - 1
      state.deletingDownloadId = null
    },
    deleteDownloadError(state) {
      state.deletingDownloadId = null
    },
  },
  {
    thunks: {
      getDownloadsByCurso({ cursoId, papel }) {
        return async (dispatch, getState) => {
          try {
            dispatch(Store.actions.getDownloadsByCursoStarted())
            const downloads = await axios.DownloadsCursos.get({
              query: getState().query,
              cursoId,
            })
            dispatch(Store.actions.getDownloadsByCursoSuccess(downloads))
          } catch (error) {}
        }
      },
      deleteDownload({ downloadId, cursoId }) {
        return async (dispatch) => {
          dispatch(Store.actions.deleteDownloadStarted(downloadId))
          try {
            await axios.DownloadsCursos.delete(downloadId, cursoId)
            dispatch(Store.actions.downloadsDeleteSuccess(downloadId))
          } catch (error) {
            dispatch(Store.actions.deleteDownloadError())
          }
        }
      },
    },
  }
)

export const DownloadsCursosView = ({ cursoId }: DownloadsCursosViewProps) => {
  const init = React.useCallback(
    (dispatch: ReturnType<typeof Store.useDispatch>) => {
      dispatch(Store.actions.init({ cursoId }))
      dispatch(Store.thunks.getDownloadsByCurso({ cursoId }))
    },
    [cursoId]
  )
  return (
    <Store.Provider init={init}>
      <Store.GetState>
        {({ downloadsCount }) => (
          <>
            <Show condition={downloadsCount > 0}>
              <Tabela>
                {(download) => <TuplaDownload key={download.id} download={download} />}
              </Tabela>
            </Show>
            <Show condition={downloadsCount === 0}>Nenhum download para o curso ainda</Show>
          </>
        )}
      </Store.GetState>
      <Paginacao />
      <NovoDownload />
    </Store.Provider>
  )
}

const Tabela = ({ children }: { children: (download: DownloadModel) => JSX.Element }) => {
  const { downloads } = Store.useState()
  return (
    <Table>
      <table>
        <thead>
          <tr>
            <th>Id</th>
            <th>Nome</th>
            <th>Link</th>
            <th></th>
          </tr>
        </thead>
        <tbody>{downloads.map((download) => children(download))}</tbody>
      </table>
    </Table>
  )
}

const TuplaDownload = ({ download }: { download: DownloadModel }) => {
  const { cursoId } = Store.useState()
  const history = useHistory()
  function handleClickDownload(download) {}

  return (
    <tr key={download.id} onClick={() => handleClickDownload(download)}>
      <td>{download.id}</td>
      <td>{download.nome}</td>
      <td>{download.link}</td>
      <td>
        <DeleteButton downloadId={+download.id} />
      </td>
    </tr>
  )
}

const Paginacao = () => {
  const { query, downloadsCount } = Store.useState()
  const dispatch = Store.useDispatch()
  return (
    <Pagination
      onChangePage={(e) => dispatch(Store.actions.setQueryParams(e))}
      limit={query.limit}
      offset={query.offset}
      count={downloadsCount}
    />
  )
}

const DeleteButton = (downloadId) => {
  const { cursoId } = Store.useState()
  const dispatch = Store.useDispatch()
  return (
    <Button
      secondary
      onClick={(e) => {
        e.stopPropagation()
        dispatch(Store.thunks.deleteDownload({ downloadId, cursoId }))
      }}
      icon
    >
      <Icon name="delete" />
    </Button>
  )
}

const NovoDownload = () => {
  const history = useHistory()
  const { cursoId } = Store.useState()
  return (
    <Button primary fluid>
      Enviar novo arquivo
    </Button>
  )
}
