import { FormikProvider, Formik, Form, useFormik, ErrorMessage } from 'formik'
import { Input } from 'semantic-ui-react'
import { Link, Redirect } from 'react-router-dom'
import { bindActionCreators } from 'redux'
import { useSelector, useDispatch } from 'react-redux'
import React, { useState } from 'react'
import NewDriverLogo from '../../assets/New_Driver.png'

import styled from '@emotion/styled'

import { ButtonLoader } from '../../components/ButtonLoader'
import { styles } from '../../components/Styles'
import { IStateRedux } from '../../ReduxStore'
import { Yup } from '../../components/Yup'
import { authActions } from './AuthReducer'
import { TextField } from '../../components/TextField'

export interface IValues {
  cpf: string
  pwd: string
}

export const LoginView = () => {
  const dispatch = useDispatch()
  const actions = bindActionCreators(authActions, dispatch)
  const { token, request } = useSelector((s: IStateRedux) => s.auth)

  const formik = useFormik({
    initialValues: { cpf: '', pwd: '' },
    onSubmit: (values, meta) => {
      const cpf = values.cpf
      const pwd = values.pwd
      actions.login({ cpf, pwd })
    },
    validationSchema: Yup.object().shape({
      cpf: (Yup as any).string().required().cpf(),
      pwd: Yup.string().required(),
    }),
    validateOnChange: true,
    validateOnBlur: true,
  })

  if (token) {
    localStorage.setItem('token', token)
    return <Redirect to="/alunos" push />
  }

  return (
    <Container>
      <Content>
        <img src={NewDriverLogo} style={{ width: 150, marginBottom: 24 }} />
        <FormikProvider value={formik}>
          <Form>
            <TextField mask="cpf" label="Login" name="cpf" placeholder="CPF" />
            <br />
            <TextField
              onKeyPress={(e) => {
                if (e.key === 'Enter') formik.handleSubmit()
              }}
              name="pwd"
              type="password"
              label="Senha"
              placeholder="Informa a senha"
            />
            <br />

            <ButtonLoader
              fullWidth
              variant="contained"
              type="submit"
              disabled={request.fetching}
              loading={request.fetching}
            >
              Enviar
            </ButtonLoader>
          </Form>
        </FormikProvider>

        <ContainerEsqueceuSenha>
          <Link to="/esqueceu-senha">Esqueceu sua senha?</Link>
        </ContainerEsqueceuSenha>
      </Content>
    </Container>
  )
}

const Container = styled('div')`
  display: flex;
  flex-direction: column;
  align-content: center;
  height: 100vh;
  background: #f3f3f3;
`

const Content = styled('div')`
  width: 450px;
  margin: auto;
`

const ContainerEsqueceuSenha = styled('div')`
  text-align: center;
  padding: 12px;
  text-shadow: 1px 1px 0 #333;
  a {
    color: ${styles.color.white};
  }
`
