import React, { useContext, useState } from 'react'
import { ChatMensagemStoreValues, ChatMensagemStoreCtx } from './ChatMensagem'
import { GlobalStoreValues, GlobalStoreCtx } from '../../GlobalStore'
import { Flex, DisplayFlex } from '../../components/Styles'
import { format } from 'date-fns'
import styled from '@emotion/styled'
import { Button } from 'semantic-ui-react'

export const ChatMensagemView = () => {
  const { chatMensagens, sendMensagem } = useContext<ChatMensagemStoreValues>(ChatMensagemStoreCtx)
  const {
    usuario: { id: usuarioId },
  } = useContext<GlobalStoreValues>(GlobalStoreCtx)

  const [mensagem, _setMensagem] = useState('')

  async function sendCurrentMensagem() {
    await sendMensagem(mensagem)
    _setMensagem('')
  }

  return (
    <div>
      <ContainerGeral>
        <div className="titulo">CHATS</div>
        <div className="conversas">Aqui vão os alunos</div>
        <div className="topo">Aluno.nome</div>
        <ChatBox id="container-chat-scrollable">
          {chatMensagens.map((chatMensagem) => {
            return (
              <div key={chatMensagem.id}>
                <BlocoMensagem mensagemPropria={chatMensagem.usuarioId === usuarioId}>
                  <div>
                    {chatMensagem.mensagem}
                    <br />
                    <small>{format(chatMensagem.createdAt, 'HH:mm')}</small>
                  </div>
                </BlocoMensagem>
              </div>
            )
          })}
        </ChatBox>
        <MessageBox marginBetween={12}>
          <Flex>
            <MessageField value={mensagem} onChange={(e) => _setMensagem(e.target.value)} />
          </Flex>
          <Button variant="contained" onClick={sendCurrentMensagem}>
            Enviar
          </Button>
        </MessageBox>
      </ContainerGeral>
    </div>
  )
}

// const UserInfo = React.memo(({ aluno }: { aluno: UsuarioModel }) => {
//   return (
//     <UserInfoContainer>
//       {/* <img src={'http://localhost:3500/files/' + chat.aluno.avatar} alt="" width="50" height="50" /> */}
//       {/* <Avatar usuario={chat.aluno} /> */} {aluno.nome}
//     </UserInfoContainer>
//   )

// })

const BlocoMensagem = styled('div')((props: { mensagemPropria: boolean }) => {
  return {
    textAlign: props.mensagemPropria ? 'right' : 'left',
    '&>div': {
      maxWidth: '70%',
      display: 'inline-block',
      borderRadius: '3px',
      backgroundColor: props.mensagemPropria ? 'white' : '#FFE0B2',
      margin: '6px',
      padding: '6px 12px 3px 12px',
      lineHeight: '1.3em',
    },
  }
})

const ContainerGeral = styled('div')`
  width: 100%;
  height: 100%;
  display: grid;
  grid-template-areas:
    'titulo topo'
    'conversas chat-box'
    'conversas message-box';

  grid-template-columns: 1fr 3fr;
  grid-template-rows: 69px 1fr auto;
  .titulo {
    grid-area: titulo;
  }
  .conversas {
    grid-area: conversas;
  }
  .topo {
    grid-area: topo;
  }
`

// const ChatBoxWrapper = styled('div')`
//   width: 70%;
//   height: 600px;
//   display: inline-block;
// `

// const TopoChat = styled('div')`
//   height: 12%;
//   background: white;
//   border: 1px solid red;
// `

// const UserInfoContainer = styled('div')`
//   background-color: orange;
//   height:60px;
//   width:100%;
//   z-index: 0
//   flex: 1;
// `

// const LeftMenu = styled('div')`
//   width: 28%;
//   height: 600px;
//   background: #FFFFFF;
//   border-radius: 0px 0px 0px 10px;
//   border: 1px solid black;
//   display: inline-block;
// `

const ChatBox = styled('div')`
  grid-area: chat-box;
  background-image: url('https://images3.alphacoders.com/901/thumb-1920-901648.jpg');
  height: auto;
  overflow: auto;
  flex: 1;
`
const MessageField = styled('input')`
  border: 1px solid black;
  height: 50px;
  padding: 14px;
  font-size: 20px;
  width: 100%;
`
const MessageBox = styled(DisplayFlex)`
  grid-area: message-box;
  padding: 12px;
`
