import React, { useContext } from 'react'
import { ChatContactListStoreValues, ChatContactListStoreCtx } from './ChatContactList'
import styled from '@emotion/styled'
import { Redirect } from 'react-router'

export const ChatContactListView = () => {
  const { contatos, redirectTo, loadOrCreateChat } =
    useContext<ChatContactListStoreValues>(ChatContactListStoreCtx)

  if (redirectTo) return <Redirect to={redirectTo} />

  return (
    <div>
      {contatos.rows.map((alunoInfo) => {
        return (
          <ContatoContainer
            key={alunoInfo.id}
            onClick={async () => await loadOrCreateChat(alunoInfo.id)}
          >
            <div>{/* <Avatar usuario={alunoInfo} /> */}</div>
            <div>
              <strong>Aluno</strong>: {alunoInfo.nome} <br />
              <strong>Perfil</strong>: {alunoInfo.perfil}
            </div>
          </ContatoContainer>
        )
      })}
    </div>
  )
}

const ContatoContainer = styled('div')`
  padding: 10px;
  margin-bottom: 5px;
  border: 1px solid black;
  border-radius: 5px;
  box-shadow: 1px 1px 1px 1px black;
  cursor: pointer;
  display: flex;
`
