import * as YupOriginal from 'yup'
import { isValid as isValidCpf } from '@fnando/cpf'

YupOriginal.addMethod(YupOriginal.string, 'cpf', function (this: any, message: string) {
  return this.test('cpf', message || 'número de CPF inválido', cpfValidate)
})

YupOriginal.addMethod(YupOriginal.string, 'minuto', function (this: any, message: string) {
  return this.test('minuto', message || 'minutos inválidos', minutoValidate)
})

function cpfValidate(value: string) {
  if (!value) return true
  return isValidCpf(value)
}

function minutoValidate(value: string) {
  if (!value) return true
  const [hora, minuto] = value.split(':')
  if (isNaN(+hora) || isNaN(+minuto)) return false
  if (+hora > 23 || +minuto > 59) return false
  return true
}

YupOriginal.setLocale({
  mixed: {
    required: 'Campo obrigatório',
  },
  string: {
    email: 'E-mail inválido',
  },
})

export const Yup = YupOriginal
