import { CupomModel } from './CupomModel'
import { CursoModel } from './CursoModel'
import { UsuarioModel } from './UsuarioModel'

export class MatriculaModel {
  id: number
  createdAt: string
  matricula: string
  dataPagamento: Date
  referenciaPagamento: string
  cobranca: any
  uf: string
  statusDetran: STATUS_MATRICULA
  cpfInstrutor: string
  dataInicioCurso: Date
  dataFimCurso: Date
  dataInicioModuloTeorico: Date
  dataFimModuloTeorico: Date
  dataInicioModuloPratico: string
  dataFimModuloPratico: string
  dataValidadeCurso: Date
  identificacaoCertificado: string
  identificacaoCertificadoRetornado: string
  identificacaoCertificadoRetornadoTeorico: string
  nota: string
  dataValidacaoFacialInicial: Date
  dataValidacaoFacialFinal: Date
  alunoId: number
  aluno: UsuarioModel
  renach: string

  cursoId: number
  curso: CursoModel

  cupomId: number
  cupom: CupomModel

  turmaId: number

  permiteEmissaoCertificado?: boolean
  dataEmissaoCertificado?: Date

  parceiroCursoPraticoId?: number
  parceiroId?: number
  parceiro?: any

  captadorId?: number
  captador?: any
}

class Pagador {
  cpfCnpj: string
  nome: string
}

class BeneficiarioFinal {
  cpfCnpj: string
  nome: string
}

class Split {
  cpfCnpj: string
  nome: string
  valor: number
}

enum MEIO_PAGAMENTO {
  CARTAO_CREDITO = 'CARTAO_CREDITO',
  PIX = 'PIX',
  BOLETO = 'BOLETO',
}

export enum STATUS_MATRICULA {
  AGUARDANDO_PAGAMENTO = 'AGUARDANDO_PAGAMENTO',
  MATRICULADO = 'MATRICULADO',
  FINALIZADO = 'FINALIZADO',
  AGUARDANDO_ENVIO = 'AGUARDANDO_ENVIO',
  AGUARDANDO_MATRICULA_MODULO_PRATICO = 'AGUARDANDO_MATRICULA_MODULO_PRATICO',
  AGUARDANDO_CONCLUSAO_MODULO_PRATICO = 'AGUARDANDO_CONCLUSAO_MODULO_PRATICO',
  AGUARDANDO_MATRICULA = 'AGUARDANDO_MATRICULA',
}
