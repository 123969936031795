import { createSlice, PayloadAction } from '@reduxjs/toolkit'

import axios, { IQueryParams, ICounter } from '../../axios'
import { CursoModel } from '../../models/CursoModel'
import { toast } from 'react-toastify'

export const initialState = {
  cursos: [] as CursoModel[],
  loading: false,
  query: { limit: 10, offset: 0 },
  cursosCount: 0,
}

export type IState = typeof initialState

const { reducer: cursosReducer, actions } = createSlice({
  name: 'cursos',
  initialState: initialState,
  reducers: {
    requestAsync(state) {
      state.loading = true
    },
    requestError(state) {
      state.loading = false
    },
    cursosGetSuccess(state, { payload }: PayloadAction<ICounter<CursoModel>>) {
      state.cursos = payload.rows
      state.cursosCount = payload.count
      state.loading = false
    },
    cursosCreateOrUpdateSuccess(state, { payload }: PayloadAction<CursoModel>) {
      if (state.cursos.some((c) => c.id === payload.id)) {
        state.cursos = state.cursos.map((c) => (c.id === payload.id ? payload : c))
      } else {
        state.cursos.push(payload)
      }
    },
    cursosDeleteSuccess(state, { payload }: PayloadAction<{ id: number }>) {
      state.cursos = state.cursos.filter((c) => +c.id !== payload.id)
    },
    setQueryParams(state, { payload }: PayloadAction<IQueryParams>) {
      state.query = { ...state.query, ...payload }
    },
  },
})

const cursosActions = {
  ...actions,
  updatePagination(cursoId: number, query: IQueryParams) {
    return (dispatch) => {
      dispatch(actions.setQueryParams(query))
      this.modulosFetch({ cursoId })
    }
  },
  cursosFetch() {
    return async (dispatch, getState) => {
      dispatch(actions.requestAsync())
      try {
        const cursos = await axios.Cursos.get(getState().modulos.query)
        dispatch(actions.cursosGetSuccess(cursos))
      } catch (error) {
        dispatch(actions.requestError())
        toast(error.response?.data?.message ?? 'Ocorreu um erro ao buscar os cursos')
      }
    }
  },
  deleteCursos(id: number) {
    return async (dispatch) => {
      dispatch(actions.requestAsync())
      try {
        await axios.Cursos.delete(id)
        dispatch(actions.cursosDeleteSuccess({ id }))
      } catch (error) {
        dispatch(actions.requestError())
        toast(error.response?.data?.message ?? 'Ocorreu um erro ao deletar o curso')
      }
    }
  },
  createOrUpdateCursos(values: Partial<CursoModel>) {
    return async (dispatch) => {
      try {
        const curso = await axios.Cursos.post(values)
        dispatch(actions.cursosCreateOrUpdateSuccess({ ...curso }))
      } catch (error) {
        dispatch(actions.requestError())
        toast(error.response?.data?.message ?? 'Ocorreu um erro ao deletar o curso')
      }
    }
  },
}

export { cursosReducer, cursosActions }
