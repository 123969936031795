import React, { useState, useContext } from 'react'
import { AlteraSenhaView, IValues } from './AlteraSenhaView'
import { GlobalStoreValues, GlobalStoreCtx } from '../../GlobalStore'
import axios from '../../axios'

const useAlteraSenhaStore = () => {
  const {
    setErrorMessage,
    setSuccessMessage,
    firstPage,
    usuario: { id: usuarioLogadoId },
  } = useContext<GlobalStoreValues>(GlobalStoreCtx)

  const [redirectTo, _setRedirectTo] = useState('')

  const updateSenha = async (values: IValues) => {
    try {
      await axios.Usuarios.updateSenha(usuarioLogadoId, values.novaSenha, {
        senhaAtual: values.novaSenha,
      })
      setSuccessMessage('Senha alterada com sucesso')
    } catch (error) {
      setErrorMessage(error.message)
    }
  }

  const cancelar = () => {
    _setRedirectTo(firstPage)
  }

  return {
    redirectTo,
    updateSenha,
    cancelar,
  }
}

export type AlteraSenhaStoreValues = ReturnType<typeof useAlteraSenhaStore>
export const AlteraSenhaStoreCtx = React.createContext(null)
export const AlteraSenha = () => {
  const Values = useAlteraSenhaStore()
  return (
    <AlteraSenhaStoreCtx.Provider value={Values}>
      <AlteraSenhaView />
    </AlteraSenhaStoreCtx.Provider>
  )
}
