import React from 'react'
import { Route, Switch } from 'react-router-dom'
import { CertificadosPendentesEnvio } from './CertificadosPendentesEnvio'

export const Certificados = () => {
  return (
    <Switch>
      <Route path="/certificados/pendentes" component={CertificadosPendentesEnvio} />
    </Switch>
  )
}
