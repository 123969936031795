import React, { useState, useEffect, useRef } from 'react'
import { QuestaoModel, TIPO_QUESTAO } from '../../models/QuestaoModel'
import { DisplayFlex } from '../../components/Styles'
import { questaoEditorActions } from './QuestaoEditorStore'
import { MultiplaEscolha } from './variacoes/MultiplaEscolha'
import { RelacionarItens } from './variacoes/RelacionarItens'
import { RelacionarItemImagem } from './variacoes/RelacionarItemImagem'
import { Button, Modal } from 'semantic-ui-react'
import { useDispatch, useSelector } from 'react-redux'
import { useParams } from 'react-router'
import { bindActionCreators } from 'redux'
import { IStateRedux } from '../../ReduxStore'
import { CustomEditor } from '../CustomEditor/CustomEditor'
import { MultiploVerdadeiroFalso } from './variacoes/MultiploVerdadeiroFalso'
import { Util } from '../Util'
import { Store } from '../../pages/Modulos/ModulosEditar'
import { createSelector } from 'reselect'
import styled from '@emotion/styled'

export enum TIPO_BOTAO {
  REVISAR = 'REVISAR',
  SALVAR = 'SALVAR',
  CANCELAR = 'CANCELAR',
  EXCLUIR = 'EXCLUIR',
}

interface IProps {
  questaoId: string
  buttons?: TIPO_BOTAO[]
  local: 'AVALIACAO' | 'CONTEUDO'
  moduloId: number
  clearOnCancel?: boolean
  tipoQuestao?: TIPO_QUESTAO
  onQuestaoSave?: (questao: QuestaoModel) => void
  onQuestaoReview?: (questao: QuestaoModel) => void
  onQuestaoDelete?: (questaoId: number | string) => void
  onCancel?: () => void
}

export const QuestaoEditor = (props: IProps) => {
  const { questaoId: propQuestaoId, moduloId, local, tipoQuestao } = props
  const refQuestaoId: any = useRef('' + (propQuestaoId || Math.random() * -1))
  const questaoId = refQuestaoId.current
  const dispatch = useDispatch()
  const state = useSelector((s: IStateRedux) => s.questaoEditor[questaoId] || { questao: {} })
  const actions = bindActionCreators(questaoEditorActions, dispatch)

  useEffect(() => {
    actions.pushQuestaoItem({ questaoId })
    if (!refQuestaoId.current) refQuestaoId.current = '' + (propQuestaoId || Math.random() * -1)
    actions.setTipoQuestao({ tipoQuestao, questaoId })
    return () => {
      refQuestaoId.current = null
    }
  }, [])
  useEffect(() => {
    actions.fetchQuestao(questaoId)
    refQuestaoId.current = questaoId
  }, [questaoId])
  useEffect(() => {
    actions.setLocal({ local, questaoId })
  }, [local])
  useEffect(() => {
    actions.setModuloId({ moduloId, questaoId })
  }, [moduloId])

  function selecionaTipoQuestao(_tipoQuestao: TIPO_QUESTAO) {
    actions.setTipoQuestao({ tipoQuestao: _tipoQuestao, questaoId })
  }

  function updateEnunciado(e) {
    actions.setEnunciado({ questaoId: questaoId, textoQuestao: e.currentTarget.innerText })
  }

  if (!state.questao) return null
  return (
    <div className={state.hasChange ? 'questao-has-change' : 'questao-no-change'}>
      <Show condition={state.questao.tipoQuestao}>
        <div>Enunciado:</div>
        <div>
          <CustomEditor
            text={state.questao.textoQuestao}
            onBlur={updateEnunciado}
            style={{ minHeight: '6em' }}
            toolbarType="FIXED"
            placeholder="Digite o enunciado da questão"
          />
        </div>
        <div>
          {tipoQuestao === TIPO_QUESTAO.MULTIPLA_ESCOLHA && (
            <MultiplaEscolha questaoId={questaoId} />
          )}
          {tipoQuestao === TIPO_QUESTAO.RELACIONAR_ITENS && (
            <RelacionarItens questaoId={questaoId} />
          )}
          {tipoQuestao === TIPO_QUESTAO.RELACIONAR_ITEM_A_IMAGEM && (
            <RelacionarItemImagem questaoId={questaoId} />
          )}
          {tipoQuestao === TIPO_QUESTAO.MULTIPLO_VERDADEIRO_FALSO && (
            <MultiploVerdadeiroFalso questaoId={questaoId} />
          )}
        </div>
      </Show>
      <Show condition={!tipoQuestao}>
        <NenhumTipoSelecionado selecionaTipoQuestao={selecionaTipoQuestao} />
      </Show>
      <Botoes {...props} questaoId={questaoId} />
    </div>
  )
}

const Botoes = (props: IProps) => {
  const { buttons, onQuestaoReview, onQuestaoSave, onCancel, questaoId } = props
  const { Layout } = Botoes
  const showCancelar = buttons.includes(TIPO_BOTAO.CANCELAR)
  const showSalvar = buttons.includes(TIPO_BOTAO.SALVAR)
  const showRevisar = buttons.includes(TIPO_BOTAO.REVISAR)
  const showExcluir = buttons.includes(TIPO_BOTAO.EXCLUIR)
  const state = useSelector((s: IStateRedux) => s.questaoEditor[questaoId] || { questao: {} })
  const dispatch = useDispatch()
  const actions = bindActionCreators(questaoEditorActions, dispatch)

  function handleClickSalvar() {
    actions.createOrUpdateQuestao({ ...state.questao, revisada: false }, questaoId, onQuestaoSave)
  }

  function handleRevisar() {
    const questao = { ...state.questao, revisada: true }
    actions.createOrUpdateQuestao(questao, questaoId, onQuestaoReview)
  }

  function handleCancelar() {
    actions.resetQuestao({ questaoId })
    onCancel()
  }

  function handleDelete() {
    actions.deleteQuestao(questaoId, onCancel)
  }

  return (
    <Layout>
      <Show condition={showRevisar}>
        <Button
          color={state.hasChange ? 'blue' : 'grey'}
          type="button"
          onClick={handleRevisar}
          loading={state.isLoading}
          disabled={state.isLoading || state?.questao?.revisada}
        >
          {state.questao.revisada ? 'Revisada' : 'Revisar'}
        </Button>
      </Show>
      <Show condition={showSalvar}>
        <Button onClick={handleClickSalvar}>Salvar</Button>
      </Show>
      <Show condition={showCancelar}>
        <Button type="button" onClick={handleCancelar}>
          Cancelar
        </Button>
      </Show>
      <div style={{ flex: 1 }}></div>
      <Show condition={showExcluir}>
        <Button color="red" type="button" onClick={handleDelete}>
          Excluir Questão
        </Button>
      </Show>
    </Layout>
  )
}
Botoes.Layout = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: 'space-between';
`

interface NenhumTipoSelecionadoType {
  selecionaTipoQuestao: (tipoQuestao: TIPO_QUESTAO) => any
}
const NenhumTipoSelecionado = ({ selecionaTipoQuestao }: NenhumTipoSelecionadoType) => {
  return (
    <div>
      <h3>Qual o tipo de questão deseja criar?</h3>
      <div>
        <button onClick={() => selecionaTipoQuestao(TIPO_QUESTAO.MULTIPLA_ESCOLHA)}>
          Múltipla Escolha
        </button>
        <button onClick={() => selecionaTipoQuestao(TIPO_QUESTAO.RELACIONAR_ITENS)}>
          Relacionar Item (texto ou imagem) A outro item (outro texto ou outra imagem)
        </button>
        <button onClick={() => selecionaTipoQuestao(TIPO_QUESTAO.RELACIONAR_ITEM_A_IMAGEM)}>
          Relacionar item (texto ou imagem) à ponto na imagem
        </button>
        <button onClick={() => selecionaTipoQuestao(TIPO_QUESTAO.MULTIPLO_VERDADEIRO_FALSO)}>
          Múltiplo Verdadeiro ou Falso
        </button>
      </div>
    </div>
  )
}

const Show = ({ condition, children }) => (!condition ? null : children)
