import axios from '../../axios'
import { QuestaoModel, TIPO_QUESTAO } from '../../models/QuestaoModel'
import { QuestaoAlternativaModel } from '../../models/QuestaoAlternativaModel'
import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { toast } from 'react-toastify'
import { Util } from '../Util'

export interface IState {
  [questaoItem: string]: {
    questaoOriginal?: QuestaoModel
    questao?: QuestaoModel
    hasChange?: boolean
    isLoading?: boolean
    erro?: string
  }
}

const reducers = {
  setTipoQuestao(
    state: IState,
    {
      payload: { questaoId, tipoQuestao },
    }: PayloadAction<{ questaoId: string; tipoQuestao: TIPO_QUESTAO }>
  ) {
    const textoQuestao = state[questaoId].questao.textoQuestao || ''
    return changeQuestaoItem(state, questaoId, { textoQuestao, tipoQuestao })
  },
  setModuloId(
    state: IState,
    { payload: { questaoId, moduloId } }: PayloadAction<{ questaoId: string; moduloId: number }>
  ) {
    return changeQuestaoItem(state, questaoId, { moduloId })
  },
  setLocal(
    state: IState,
    { payload: { questaoId, local } }: PayloadAction<{ questaoId: string; local: any }>
  ) {
    return changeQuestaoItem(state, questaoId, { local })
  },
  setEnunciado(
    state: IState,
    {
      payload: { questaoId, textoQuestao },
    }: PayloadAction<{ questaoId: string; textoQuestao: string }>
  ) {
    return changeQuestaoItem(state, questaoId, { textoQuestao })
  },
  setConfiguracaoAdicional(
    state: IState,
    {
      payload: { questaoId, configuracaoAdicional },
    }: PayloadAction<{ questaoId: string; configuracaoAdicional: string }>
  ) {
    return changeQuestaoItem(state, questaoId, { configuracaoAdicional })
  },
  addAlternativa(state: IState, { payload: { questaoId } }: PayloadAction<{ questaoId: string }>) {
    let infoAdicionalAlternativa = {}
    switch (state[questaoId].questao.tipoQuestao) {
      case TIPO_QUESTAO.RELACIONAR_ITEM_A_IMAGEM:
        infoAdicionalAlternativa = { respostaEsperada: '{"top": 50, "left": 50}' }
        break
      case TIPO_QUESTAO.MULTIPLA_ESCOLHA:
        infoAdicionalAlternativa = { respostaEsperada: 'false' }
        break
      default:
        break
    }
    const novaQuestaoAlternativa: QuestaoAlternativaModel = {
      id: Math.random() - 1,
      ...infoAdicionalAlternativa,
    }
    const questoesAlternativas = [
      ...state[questaoId].questao.questoesAlternativas,
      novaQuestaoAlternativa,
    ]
    return changeQuestaoItem(state, questaoId, { questoesAlternativas })
  },
  changeAlternativas(
    state: IState,
    {
      payload: { questaoId, questoesAlternativas },
    }: PayloadAction<{ questaoId: string; questoesAlternativas: QuestaoAlternativaModel[] }>
  ) {
    return changeQuestaoItem(state, questaoId, { questoesAlternativas })
  },
  changeAlternativaById(
    state: IState,
    {
      payload: { questaoId, alternativa },
    }: PayloadAction<{ questaoId: string; alternativa: QuestaoAlternativaModel }>
  ) {
    const questoesAlternativas = state[questaoId].questao.questoesAlternativas.map((x) =>
      x.id === alternativa.id ? alternativa : x
    )
    return changeQuestaoItem(state, questaoId, { questoesAlternativas })
  },
  changeConteudoAlternativa(state: IState, { payload }: any) {
    const { questaoId, conteudoAlternativa, alternativaId } = payload
    state[questaoId].questao.questoesAlternativas.find(
      (x) => x.id === alternativaId
    ).conteudoAlternativa = conteudoAlternativa
  },
  changeRespostaEsperada(
    state: IState,
    { payload }: PayloadAction<{ questaoId; respostaEsperada; alternativaId }>
  ) {
    const { questaoId, respostaEsperada, alternativaId } = payload
    state[questaoId].questao.questoesAlternativas.find(
      (x) => x.id === alternativaId
    ).respostaEsperada = respostaEsperada
  },
  setFeedback(state: IState, { payload }: PayloadAction<{ alternativaId; questaoId; feedback }>) {
    const { questaoId, alternativaId, feedback } = payload
    state[questaoId].questao.questoesAlternativas.find((x) => x.id === alternativaId).feedback =
      feedback
  },
  deleteAlternativaById(
    state: IState,
    {
      payload: { questaoId, alternativaId },
    }: PayloadAction<{ questaoId: string; alternativaId: number }>
  ) {
    const questoesAlternativas = state[questaoId].questao.questoesAlternativas.filter(
      (x) => x.id !== alternativaId
    )
    return changeQuestaoItem(state, questaoId, { questoesAlternativas })
  },
  resetQuestao(state: IState, { payload: { questaoId } }: PayloadAction<{ questaoId: string }>) {
    return changeQuestaoItem(
      state,
      questaoId,
      {},
      { isLoading: false, hasChange: false, questao: state[questaoId].questaoOriginal }
    )
  },
  setSubmittedFalse(
    state: IState,
    { payload: { questaoId } }: PayloadAction<{ questaoId: string }>
  ) {
    return changeQuestaoItem(state, questaoId, {}, { submitted: false })
  },
  pushQuestaoItem(state: IState, { payload: { questaoId } }: PayloadAction<{ questaoId: string }>) {
    return {
      ...state,
      questaoId,
      [questaoId]: { questao: { questoesAlternativas: [] }, isLoading: false, hasChange: false },
    }
  },
  clearQuestaoItem(
    state: IState,
    { payload: { questaoId } }: PayloadAction<{ questaoId: string }>
  ) {
    const newState = { ...state }
    delete newState[questaoId]
    return newState
  },

  questaoFetchStarted(
    state: IState,
    { payload: { questaoId } }: PayloadAction<{ questaoId: string }>
  ) {
    return changeQuestaoItem(state, questaoId, {}, { isLoading: true })
  },
  questaoFetchSuccess(
    state: IState,
    { payload: { questaoId, questao } }: PayloadAction<{ questaoId: string; questao: QuestaoModel }>
  ) {
    return changeQuestaoItem(state, questaoId, questao, { isLoading: false, erro: undefined })
  },
  questaoFetchError(
    state: IState,
    { payload: { questaoId, erro } }: PayloadAction<{ questaoId: string; erro: string }>
  ) {
    return changeQuestaoItem(state, questaoId, {}, { isLoading: false, erro })
  },
  questaoCreateOrUpdateStarted(
    state: IState,
    { payload: { questaoId } }: PayloadAction<{ questaoId: string }>
  ) {
    return changeQuestaoItem(state, questaoId, {}, { isLoading: true })
  },
  questaoCreateOrUpdateSuccess(
    state: IState,
    { payload: { questaoId, questao } }: PayloadAction<{ questaoId: string; questao: QuestaoModel }>
  ) {
    return changeQuestaoItem(state, questaoId, questao, {
      isLoading: false,
      erro: undefined,
      submitted: true,
    })
  },
  questaoCreateOrUpdateError(
    state: IState,
    { payload: { questaoId, erro } }: PayloadAction<{ questaoId: string; erro: string }>
  ) {
    toast(erro)
    return changeQuestaoItem(state, questaoId, {}, { isLoading: false, erro })
  },
  questaoDeleteSuccess(
    state: IState,
    { payload: { questaoId } }: PayloadAction<{ questaoId: string }>
  ) {
    return changeQuestaoItem(state, questaoId, null, { isLoading: false })
  },
  questaoDeleteError(
    state: IState,
    { payload: { questaoId } }: PayloadAction<{ questaoId: string }>
  ) {
    return changeQuestaoItem(state, questaoId, {}, { isLoading: false })
  },
}

const { reducer: questaoEditorReducer, actions } = createSlice({
  name: 'questaoEditor',
  initialState: {},
  reducers,
})

function changeQuestaoItem(
  state: IState,
  questaoId: string,
  novaQuestao: Partial<QuestaoModel>,
  novaQuestaoItem: any = { hasChange: true }
): IState {
  const questao = { ...state[questaoId].questao, ...novaQuestao }
  const questaoItem = { ...state[questaoId], questao, ...novaQuestaoItem }
  return { ...state, [questaoId]: questaoItem }
}

const questaoEditorActions = {
  ...actions,
  fetchQuestao(questaoId: string) {
    return async (dispatch) => {
      if (!questaoId) return
      dispatch(actions.questaoFetchStarted({ questaoId }))
      try {
        const questao = await axios.Questoes.getById(+questaoId)
        dispatch(actions.questaoFetchSuccess({ questaoId, questao }))
      } catch (error) {
        dispatch(actions.questaoFetchError({ questaoId, erro: error.message }))
      }
    }
  },
  createOrUpdateQuestao(questao: QuestaoModel, questaoId: string, done) {
    return async (dispatch) => {
      if (!questao) return
      const retornoValidacao = validaQuestao(questao)
      if (!retornoValidacao.valid)
        return dispatch(
          actions.questaoCreateOrUpdateError({ questaoId, erro: retornoValidacao.message })
        )
      dispatch(actions.questaoCreateOrUpdateStarted({ questaoId }))
      try {
        const questaoCreatedOrUpdated = await axios.Questoes.createOrUpdate(questao)
        dispatch(
          actions.questaoCreateOrUpdateSuccess({ questaoId, questao: questaoCreatedOrUpdated })
        )
        done?.(questaoCreatedOrUpdated)
        toast('Questão atualizada com sucesso')
      } catch (error) {
        dispatch(actions.questaoCreateOrUpdateError({ questaoId, erro: error }))
        toast(error?.message || 'Erro ao atualizar questão')
      }
    }
  },
  deleteQuestao(questaoId: string, done) {
    return async (dispatch, getState) => {
      try {
        await axios.Questoes.delete(questaoId)
        dispatch(actions.questaoDeleteSuccess({ questaoId }))
        dispatch(actions.resetQuestao({ questaoId }))
        done?.()
        toast('Questão excluida')
      } catch (error) {
        dispatch(actions.questaoDeleteError({ questaoId }))
        toast(error?.message || 'Erro ao excluir questão')
      }
    }
  },
}

function validaQuestao(questao: QuestaoModel): { valid: boolean; message?: string } {
  if (!questao.tipoQuestao)
    return { valid: false, message: 'É necessário informar o tipo da questão' }
  if (!questao.local)
    return {
      valid: false,
      message: 'É necessário informar se essa é uma questão de avaliação ou do contéudo',
    }
  if (!questao.moduloId)
    return { valid: false, message: 'É necessário selecionar o módulo dessa questão' }

  if (!questao.questoesAlternativas || !questao.questoesAlternativas.length) {
    return { valid: false, message: 'É necessário informar alternativas' }
  }
  if (questao.questoesAlternativas.length < 2) {
    return { valid: false, message: 'É necessário informar ao menos duas alternativas' }
  }

  switch (questao.tipoQuestao) {
    case TIPO_QUESTAO.MULTIPLA_ESCOLHA:
      return validaQuestaoMultiplaEscolha(questao)
    case TIPO_QUESTAO.RELACIONAR_ITENS:
      return validaQuestaoRelacionarItens(questao)
    case TIPO_QUESTAO.RELACIONAR_ITEM_A_IMAGEM:
      return validaQuestaoRelacionarItemImagem(questao)
    case TIPO_QUESTAO.MULTIPLO_VERDADEIRO_FALSO:
      return validaMultiploVerdadeiroFalso(questao)
    default:
      return { valid: false, message: 'É necessário selecionar um tipo de questão' }
  }
}
function validaQuestaoMultiplaEscolha(questao: QuestaoModel) {
  if (!questao.textoQuestao.trim()) {
    return { valid: false, message: 'É necessário informar o enunciado da questão' }
  }
  if (questao.questoesAlternativas.filter((x) => '' + x.respostaEsperada === 'true').length !== 1) {
    return { valid: false, message: 'É necessário selecionar uma alternativa correta' }
  }
  if (questao.questoesAlternativas.filter((x) => '' + x.respostaEsperada === 'false').length <= 0) {
    return { valid: false, message: 'É necessário que exista ao menos uma alternativa incorreta' }
  }
  if (
    questao.questoesAlternativas.filter(
      (x) => '' + x.respostaEsperada !== 'false' && '' + x.respostaEsperada !== 'true'
    ).length > 0
  ) {
    return {
      valid: false,
      message:
        'Existem alternativas cujas respostas estão configuradas erroneamente. Entre em contato com o suporte',
    }
  }

  return { valid: true }
}
function validaQuestaoRelacionarItens(questao: QuestaoModel) {
  if (questao.questoesAlternativas.filter((x) => !x.conteudoAlternativa).length) {
    return { valid: false, message: 'Todas as alternativas precisam ser preenchidas' }
  }
  return { valid: true }
}
function validaQuestaoRelacionarItemImagem(questao: QuestaoModel) {
  return { valid: true }
}
function validaMultiploVerdadeiroFalso(questao: QuestaoModel) {
  if (questao.questoesAlternativas.filter((x) => !['V', 'F'].includes(x.respostaEsperada)).length) {
    return {
      valid: false,
      message: 'É necessário informar em todas as alternativas se são verdadeiras ou falsas',
    }
  }
  if (questao.questoesAlternativas.filter((x) => !x.conteudoAlternativa).length) {
    return { valid: false, message: 'Todas as alternativas precisam ser preenchidas' }
  }

  return { valid: true }
}

export { questaoEditorReducer, questaoEditorActions }
