import React from 'react'
import Croppr from 'croppr'
import { DisplayFlex, Flex } from './Styles'
import { filesUrl } from '..'
import { Button } from 'semantic-ui-react'

interface IProps {
  src?: string
  onRemoveFoto?: () => void
}

interface IState {
  src: string
  hasNewImage: boolean
}

export class ImgCropper extends React.Component<IProps, IState> {
  cropperInstance: Croppr
  cropperElement: any
  inputFileElement: any
  reader = new FileReader()

  constructor(props) {
    super(props)
    this.cropperInstance = null as any
    this.state = {
      src: '',
      hasNewImage: false,
    }
  }

  componentDidMount() {
    if (this.props.src) this.setState({ src: `${filesUrl}/${this.props.src}` })
    this.reader.onload = (e: any) => {
      this.setState({ src: e.target.result })
    }
  }

  setCropper = () => {
    if (!this.state.hasNewImage) return

    this.cropperInstance = new Croppr(this.cropperElement, {
      aspectRatio: 1,
    })
  }

  public getCroppedImage = () => {
    const canvas = document.createElement('canvas')

    const { x, y, width, height } = this.cropperInstance.getValue()

    canvas.width = width
    canvas.height = height

    const ctx = canvas.getContext('2d')
    if (ctx) {
      ctx.drawImage(this.cropperElement, x, y, width, height, 0, 0, width, height)
    }

    return canvas.toDataURL('image/jpg', 85)
  }

  public hasNewImage = () => {
    return this.state.hasNewImage
  }

  handleChange = (e: any) => {
    if (!e.target.files || !e.target.files[0]) return
    this.reader.readAsDataURL(e.target.files[0])
    this.setState({ src: this.state.src, hasNewImage: true })
  }

  handleEscolherFoto = () => {
    this.inputFileElement.click()
  }

  handleRemoverFoto = () => {
    this.setState({ src: '', hasNewImage: false })
    if (this.props.onRemoveFoto) {
      this.props.onRemoveFoto()
    }
  }

  render() {
    return (
      <DisplayFlex marginBetween={12} key={this.state.src}>
        <Flex self="center">
          {!this.state.src && (
            <img
              src={require('../assets/avatar_1.png')}
              style={{ borderRadius: '50%', maxWidth: '100%' }}
            />
          )}
          <img
            onLoad={this.setCropper}
            ref={(e) => (this.cropperElement = e)}
            src={this.state.src}
            style={{ borderRadius: '50%', maxWidth: '100%' }}
          />
        </Flex>
        <Flex self="center">
          <input
            type="file"
            ref={(e) => (this.inputFileElement = e)}
            onChange={(e) => this.handleChange(e)}
            hidden
          />
          <Button variant="contained" size="large" type="button" onClick={this.handleEscolherFoto}>
            Escolher Foto
          </Button>
          <br />
          <br />
          <Button
            variant="contained"
            size="large"
            type="button"
            disabled={!this.state.src}
            onClick={this.handleRemoverFoto}
          >
            Remover Foto
          </Button>
        </Flex>
      </DisplayFlex>
    )
  }
}
