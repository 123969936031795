import { Button, Icon } from 'semantic-ui-react'
import { Table } from '../../components/Styles'
import { useDispatch, useSelector } from 'react-redux'
import { toast } from 'react-toastify'
import React, { useEffect } from 'react'

import { bindActionCreators } from 'redux'

import { IStateRedux } from '../../ReduxStore'
import { Usuario, TIPO_USUARIO } from './Usuario'
import { Pagination } from '../../components/Pagination'
import { usuariosActions } from './UsuariosCursosStore'
import { useHistory, useParams } from 'react-router'
import { NavEstrutural } from '../../components/NavEstrutural'
import axios, { ICounter } from '../../axios'
import { UsuarioModel } from '../../models/UsuarioModel'
import Axios from 'axios'
// import { createSimpleStore } from '../../components/SimpleReducer'
import { createSimpleStore } from '../../components/SimpleReducer'

interface UsuariosCursosViewProps {
  cursoId: number
  componentId: string
  papel: string
}

interface UsuarioCursoViewParams {}

const Store = createSimpleStore(
  {
    cursoId: 0,
    papel: '',
    usuarios: [] as UsuarioModel[],
    usuariosCount: 0,
    fetching: false,
    query: { limit: 10, offset: 0 },
    deletingUserId: null as number | null,
  },
  {
    init(state, payload: { cursoId; papel }) {
      state.cursoId = payload.cursoId
      state.papel = payload.papel
    },
    usuariosCreateOrUpdateSuccess(state, usuario: UsuarioModel) {
      const edicao = state.usuarios.some((u) => u.id === usuario.id)
      if (!edicao) {
        state.usuariosCount = state.usuariosCount + 1
        state.usuarios.push(usuario)
      } else {
        state.usuarios = state.usuarios.map((u) => (usuario.id === u.id ? usuario : u))
      }
    },
    usuariosDeleteSuccess(state, usuarioId: number) {
      state.usuarios = state.usuarios.filter((c) => +c.id !== usuarioId)
      state.usuariosCount = state.usuariosCount - 1
    },
    setQueryParams(state, query: { limit?: number; offset?: number }) {
      state.query = { ...state.query, ...query }
    },
    getUsuariosByCursoPapelStarted(state) {
      state.fetching = true
    },
    getUsuariosByCursoPapelSuccess(state, responseUsuarios: ICounter<UsuarioModel>) {
      state.usuarios = responseUsuarios.rows
      state.usuariosCount = responseUsuarios.count
      state.fetching = false
    },
    getUsuariosByCursoPapelError(state) {
      state.fetching = false
    },
    deleteUsuarioStarted(state, usuarioId: number) {
      state.deletingUserId = usuarioId
    },
    deleteUsuarioSuccess(state, usuarioId: number) {
      state.usuarios = state.usuarios.filter((u) => u.id !== usuarioId)
      state.usuariosCount = state.usuariosCount - 1
      state.deletingUserId = null
    },
    deleteUsuarioError(state) {
      state.deletingUserId = null
    },
  },
  {
    thunks: {
      getUsuariosByCursoPapel({ cursoId, papel }) {
        return async (dispatch, getState) => {
          try {
            dispatch(Store.actions.getUsuariosByCursoPapelStarted())
            const usuarios = await axios.UsuariosCursos.get({
              query: getState().query,
              cursoId,
              papel,
            })
            dispatch(Store.actions.getUsuariosByCursoPapelSuccess(usuarios))
          } catch (error) {}
        }
      },
      deleteUsuario({ usuarioId, cursoId, papel }) {
        return async (dispatch) => {
          dispatch(Store.actions.deleteUsuarioStarted(usuarioId))
          try {
            await axios.UsuariosCursos.deletePermissao(usuarioId, cursoId, papel)
            dispatch(Store.actions.usuariosDeleteSuccess(usuarioId))
          } catch (error) {
            dispatch(Store.actions.deleteUsuarioError())
          }
        }
      },
    },
  }
)

export const UsuariosCursosView = ({ cursoId, papel }: UsuariosCursosViewProps) => {
  const init = React.useCallback(
    (dispatch: ReturnType<typeof Store.useDispatch>) => {
      dispatch(Store.actions.init({ cursoId, papel }))
      dispatch(Store.thunks.getUsuariosByCursoPapel({ cursoId, papel }))
    },
    [cursoId, papel]
  )
  return (
    <Store.Provider init={init}>
      <Tabela>{(usuario) => <TuplaUsuario key={usuario.id} usuario={usuario} />}</Tabela>
      <Paginacao />
      <NovoUsuario />
    </Store.Provider>
  )
}

const Tabela = (props) => {
  const { children } = props
  const { usuarios } = Store.useState()
  return (
    <Table>
      <table>
        <thead>
          <tr>
            <th>Id</th>
            <th>Nome</th>
            <th></th>
          </tr>
        </thead>
        <tbody>{usuarios.map((usuario) => children(usuario))}</tbody>
      </table>
    </Table>
  )
}

const TuplaUsuario = ({ usuario }: { usuario: UsuarioModel }) => {
  const { cursoId } = Store.useState()
  const history = useHistory()
  function handleClickUsuario(usuario) {
    history.push(`/cursos/${cursoId}/usuarios/${usuario.id}/acompanhamento`)
  }

  return (
    <tr key={usuario.id} onClick={() => handleClickUsuario(usuario)}>
      <td>{usuario.id}</td>
      <td>{usuario.nome}</td>
      <td>
        <DeleteButton usuarioId={+usuario.id} />
      </td>
    </tr>
  )
}

const Paginacao = () => {
  const { query, usuariosCount } = Store.useState()
  const dispatch = Store.useDispatch()
  return (
    <Pagination
      onChangePage={(e) => dispatch(Store.actions.setQueryParams(e))}
      limit={query.limit}
      offset={query.offset}
      count={usuariosCount}
    />
  )
}

const DeleteButton = (usuarioId) => {
  const { cursoId, papel } = Store.useState()
  const dispatch = Store.useDispatch()
  return (
    <Button
      secondary
      onClick={(e) => {
        e.stopPropagation()
        dispatch(Store.thunks.deleteUsuario({ usuarioId, cursoId, papel }))
      }}
    >
      <Icon name="delete" />
    </Button>
  )
}

const NovoUsuario = () => {
  const history = useHistory()
  const { cursoId, papel } = Store.useState()
  if (!papel) return null
  return (
    <Button
      primary
      fluid
      onClick={() => history.push('/usuarios-cursos/editar', { cursoId, perfil: papel })}
    >
      Criar novo {TIPO_USUARIO[papel].toLowerCase()}
    </Button>
  )
}
