import React, { useState, useContext, useEffect } from 'react'
import { ChatContactListView } from './ChatContactListView'
import { GlobalStoreValues, GlobalStoreCtx } from '../../GlobalStore'
import axios, { ICounter } from '../../axios'
import { UsuarioModel } from '../../models/UsuarioModel'

const useChatContactListStore = () => {
  const { setErrorMessage } = useContext<GlobalStoreValues>(GlobalStoreCtx)

  const [contatos, _setContatos] = useState<ICounter<UsuarioModel>>({ count: 0, rows: [] })
  const [redirectTo, _setRedirectTo] = useState('')

  useEffect(() => {
    _getAlunos()
  }, [])

  async function _getAlunos() {
    try {
      _setContatos(await axios.Chat.getAlunos())
    } catch (error) {
      setErrorMessage(error.message)
    }
  }

  async function loadOrCreateChat(alunoId: number) {
    try {
      const loadedOrCreatedChat = await axios.Chat.loadOrCreateChat(alunoId)
      _setRedirectTo(`${loadedOrCreatedChat.id}`)
    } catch (error) {
      setErrorMessage(error.message)
    }
  }

  return { contatos, redirectTo, loadOrCreateChat }
}

export type ChatContactListStoreValues = ReturnType<typeof useChatContactListStore>
export const ChatContactListStoreCtx = React.createContext(null)
export const ChatContactList = () => {
  const Values = useChatContactListStore()
  return (
    <ChatContactListStoreCtx.Provider value={Values}>
      <ChatContactListView />
    </ChatContactListStoreCtx.Provider>
  )
}
