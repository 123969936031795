import * as React from 'react'
import styled from '@emotion/styled'
import { Button } from 'semantic-ui-react'

export const ButtonLoader = ({ loading, ...props }: any) => (
  <Wrapper>
    <Button disabled={loading} {...props}>
      {props.children}
    </Button>
  </Wrapper>
)

const Wrapper = styled('div')(() => ({
  position: 'relative',
}))
