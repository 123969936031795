import { Button, Container, Icon, Segment as SegmentComp, SemanticCOLORS } from 'semantic-ui-react'
import { FormikProvider, useFormik } from 'formik'
import { toast } from 'react-toastify'
import { useHistory, useLocation } from 'react-router'
import React, { useEffect, useState } from 'react'
import * as Yup from 'yup'
import axios from '../../axios'
import useAxios from 'axios-hooks'

import * as _ from 'lodash'
import { TextField, MaskedTextField, SelectField } from '../../components/FormikInput'
const Segment = SegmentComp as any

export const AlunosMatricular = () => {
  const history = useHistory()
  const location = useLocation()

  const { state } = location
  const [submited, setSubmited] = useState(false)

  if (submited) {
    setTimeout(() => setSubmited(false), 1)
    return null
  }
  return (
    <Container>
      <BotaoVoltar handler={() => history.goBack()} />
      <Formulario setSubmited={setSubmited} cursoId={_.get(state, 'cursoId', null)} />
    </Container>
  )
}

interface FormularioProps {
  cursoId: number
  setSubmited: (state: boolean) => any
  usuarioId?: number
}
export const Formulario: React.FC<FormularioProps> = ({
  cursoId,
  setSubmited,
  usuarioId = null,
}) => {
  const [cursoActive, setCursoActive] = useState(null)
  const [cursos, setCursos] = useState([])
  const [aluno, setAluno] = useState(null)

  const [{ data: alunoCriado }, matricularAluno] = useAxios(
    {
      url: `cursos/${cursoId}/matriculas`,
      method: 'POST',
    },
    { manual: true }
  )

  const [{ data: alunoConsultado }, getAlunos] = useAxios(
    {
      url: `cursos/${cursoId}/matriculas`,
      method: 'GET',
    },
    { manual: true }
  )

  useEffect(() => {
    if (usuarioId) {
      getAlunoById(usuarioId)
    }
  }, [])

  useEffect(() => {
    if (cursoId && !cursoActive) {
      axios.Cursos.get(null, cursoId).then((curso) => {
        setCursoActive(curso.rows[0])
        setCursos(curso.rows)
      })
    }
    if (!cursoId) {
      axios.Cursos.get(null, cursoId).then((cursos) => {
        setCursos(cursos.rows)
      })
    }
  }, [cursos, cursoActive])

  const getAlunoByCpf = async (e) => {
    const response = await getAlunos({
      params: {
        cpf: e.target.value,
      },
    })
    setAluno(response.data)
  }

  const getAlunoById = async (id) => {
    const response = await getAlunos({
      params: {
        usuarioId: id,
      },
    })
    setAluno(response.data)
  }

  const FormularioSchema = Yup.object().shape({
    nome: Yup.string().required('O nome é obrigatório'),
    cpf: Yup.string().min(14, 'CPF inválido').required('O cpf é obrigatório'),
    celular: Yup.string().required('Celular é obrigatório'),
    cursoId: Yup.number().moreThan(0).required('O curso é obrigatório'),
    email: Yup.string().email('Email inválido').required('O Email é obrigatório'),
  })

  useEffect(() => {
    console.log({ alunoT: aluno })
    console.log({ telefone: aluno?.telefone })
  }, [aluno])

  console.log({ telefone2: aluno?.telefone })

  const formik = useFormik({
    initialValues: {
      id: '' || aluno?.id,
      nome: '' || aluno?.nome,
      cpf: '' || aluno?.cpf,
      rg: '' || aluno?.rg,
      dataNascimento: '' || aluno?.dataNascimento,
      email: '' || aluno?.email,
      celular: '(62) 98122-4223' || aluno?.telefone.replace(/[()-]/g, ''),
      cpfInstrutor: '',
      cursoId: cursoId,
    },
    enableReinitialize: true,
    validationSchema: FormularioSchema,
    onSubmit: async (values) => {
      const usuario = {
        id: values.id,
        nomeAluno: values.nome,
        cpfAluno: values.cpf,
        dataNascimentoAluno: values.dataNascimento,
        cpfInstrutor: values.cpfInstrutor,
        emailAluno: values.email,
        celularAluno: values.celular,
      } as any
      const retorno = await matricularAluno({
        data: {
          ...usuario,
        },
      })
      toast.success('Aluno matriculado!')
      setSubmited(true)
    },
  })

  return (
    <BlocoConteudo usuarioId={usuarioId} color="purple">
      <h3>{usuarioId ? 'Atualizar dados do Aluno' : 'Matricular Aluno'}</h3>
      <FormikProvider value={formik}>
        <form onSubmit={formik.handleSubmit}>
          <TextField
            label="ID Aluno"
            fluid
            disabled
            name="id"
            placeholder="id"
            className="input-uppercase"
          />
          <br />
          <MaskedTextField
            label="CPF"
            mask="cpf"
            onBlur={getAlunoByCpf}
            fluid
            name="cpf"
            placeholder="Cpf do aluno"
          />
          <br />
          <TextField
            label="Nome"
            fluid
            name="nome"
            placeholder="Nome"
            className="input-uppercase"
          />
          <br />
          <TextField label="RG" fluid name="rg" placeholder="RG do aluno" />
          <br />
          <TextField
            label="Data de nascimento"
            fluid
            name="dataNascimento"
            placeholder="Data de nascimento do aluno"
          />
          <br />
          <TextField fluid label="E-mail" name="email" placeholder="E-mail" />
          <br />
          <MaskedTextField
            label="Celular"
            mask="celular"
            fluid
            name="celular"
            placeholder="Celular"
          />
          <br />
          <MaskedTextField
            label="CPF Instrutor"
            mask="cpf"
            fluid
            name="cpfInstrutor"
            placeholder="Cpf do instrutor"
          />
          <br />
          <SelectField
            onChange={async (_, { name, value }) => await formik.setFieldValue(name, value)}
            fluid
            disabled={!!cursoId}
            label="Curso"
            name="cursoId"
            options={cursos
              .map((c) => ({ key: c.id, value: c.id, text: c.nome }))
              .concat([{ key: 0, value: 0, text: 'Escolha o curso do usuário' }])}
          />
          <br />
          <div style={{ display: 'table', width: '100%' }}>
            <div style={{ display: 'inline-block', float: 'right' }}>
              <Button fluid primary type="submit">
                {usuarioId ? 'Salvar' : 'Matricular Aluno'}
              </Button>
            </div>
          </div>
        </form>
      </FormikProvider>
    </BlocoConteudo>
  )
}

interface BlocoConteudoProps {
  color: SemanticCOLORS
  usuarioId: number
  children
}
const BlocoConteudo: React.FC<BlocoConteudoProps> = ({ color, children, usuarioId }) => (
  <Segment color={color} style={usuarioId ? { marginTop: '25px' } : { margin: '25px' }}>
    {children || null}
  </Segment>
)

const BotaoVoltar = ({ handler }) => (
  <Button
    secondary
    basic
    style={{ marginTop: '25px', marginLeft: '25px' }}
    onClick={handler}
    circular
  >
    <Icon name="arrow left" />
    Voltar
  </Button>
)
