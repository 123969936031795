import React from 'react'
import { Container, Button, Icon, Segment as SegmentComp } from 'semantic-ui-react'
import { NavEstrutural } from '../../components/NavEstrutural'
import { Formik } from 'formik'
import { useHistory, useParams } from 'react-router-dom'
const Segment = SegmentComp as any

export const CuponsEditar = () => {
  const history = useHistory()
  const backToCupons = () => history.push('/cupons')

  return (
    <Container>
      <BotaoVoltar handler={backToCupons} />
      <NavEstrutural />
    </Container>
  )
}

const BotaoVoltar = ({ handler }) => (
  <Button style={{ marginTop: 25 }} onClick={handler} circular>
    <Icon name="arrow left" />
    Voltar
  </Button>
)

const DadosCupom = () => {
  return (
    <Segment color="purple" style={{ marginTop: 25 }}>
      <h2>Dados do cupom</h2>
    </Segment>
  )
}
