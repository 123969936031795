import React, { useEffect, useRef } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { IStateRedux } from '../../../ReduxStore'
import { bindActionCreators } from 'redux'
import { questaoEditorActions } from '../QuestaoEditorStore'
import styled from '@emotion/styled'
import { CustomEditor } from '../../CustomEditor/CustomEditor'
import { Icon, Button } from 'semantic-ui-react'
import { QuestaoAlternativaModel } from '../../../models/QuestaoAlternativaModel'

export const MultiploVerdadeiroFalso = ({ questaoId }) => {
  const dispatch = useDispatch()
  const questao = useSelector((s: IStateRedux) =>
    s.questaoEditor[questaoId] ? s.questaoEditor[questaoId].questao : { questoesAlternativas: [] }
  )
  const actions = bindActionCreators(questaoEditorActions, dispatch)

  const letras = 'ABCDEFGHIJKLMNO'.split('')

  function handleNovaAlternativaClick(e) {
    e.stopPropagation()
    addAlternativa()
  }

  function addAlternativa() {
    actions.addAlternativa({ questaoId })
  }

  function handleClickOpcao(alternativaId: number) {
    const novasAlternativas: QuestaoAlternativaModel[] = questao.questoesAlternativas.map((x) =>
      x.id === alternativaId
        ? { ...x, respostaEsperada: 'true' }
        : { ...x, respostaEsperada: 'false' }
    )
    actions.changeAlternativas({ questaoId, questoesAlternativas: novasAlternativas })
  }

  return (
    <div>
      <div>Alternativas:</div>
      <div>
        {questao.questoesAlternativas.map((alternativa, index) => (
          <Alternativa
            dispatch={dispatch}
            key={alternativa.id}
            id={alternativa.id}
            alternativa={alternativa}
            questaoId={questaoId}
            letraAlternativa={letras[index]}
            handleClickOpcao={(alternativaId) => handleClickOpcao(alternativaId)}
            shouldSetFocus={true}
          />
        ))}
        <div style={{ textAlign: 'right' }}>
          <Styles.AdicionarAlternativa onClick={() => actions.addAlternativa({ questaoId })}>
            <Icon name="plus circle" /> Adicionar alternativa
          </Styles.AdicionarAlternativa>
        </div>
      </div>
    </div>
  )
}

const Alternativa = (props) => {
  const dispatch = useDispatch()
  const actions = bindActionCreators(questaoEditorActions, dispatch)
  const alternativa = props.alternativa
  const questaoId = props.questaoId

  function handleBlur(e) {
    if (!actions || !actions.changeAlternativaById) return
    actions.changeAlternativaById({
      questaoId,
      alternativa: { ...alternativa, conteudoAlternativa: e.currentTarget.innerHTML },
    })
  }

  function handleDeleteClick() {
    actions.deleteAlternativaById({ questaoId, alternativaId: props.id })
  }

  return (
    <Styles.ContainerAlternativa>
      <Styles.Letra>{props.letraAlternativa}</Styles.Letra>
      <VerdadeiroFalso alternativa={alternativa} questaoId={questaoId} />
      <Styles.ContainerEditorAlternativa>
        <CustomEditor
          text={alternativa.conteudoAlternativa || ''}
          onBlur={handleBlur}
          onFocus={props.onFocus}
        />
      </Styles.ContainerEditorAlternativa>
      <Button basic icon="trash" onClick={handleDeleteClick} tabIndex={-1} />
    </Styles.ContainerAlternativa>
  )
}

const VerdadeiroFalso = ({ questaoId, alternativa }) => {
  const dispatch = useDispatch()
  const actions = bindActionCreators(questaoEditorActions, dispatch)
  function handleClickVF(respostaEsperada) {
    actions.changeRespostaEsperada({
      questaoId,
      alternativaId: alternativa.id,
      respostaEsperada,
    })
  }
  return (
    <div>
      <Button
        onClick={() => handleClickVF('V')}
        color={alternativa.respostaEsperada === 'V' ? 'blue' : 'grey'}
      >
        V
      </Button>
      <Button
        onClick={() => handleClickVF('F')}
        color={alternativa.respostaEsperada === 'F' ? 'blue' : 'grey'}
      >
        F
      </Button>
    </div>
  )
}

const NovaAlternativa = ({ children, handleClick }) => {
  return <Styles.NovaAlternativa onClick={handleClick}>{children}</Styles.NovaAlternativa>
}

const Styles = {
  NovaAlternativa: styled('div')`
    opacity: 0.5;
    position: relative;
    .overlay {
      position: absolute;
      top: 0;
      left: 0;
      bottom: 0;
      right: 0;
    }
  `,
  ContainerAlternativa: styled('div')`
    display: flex;
    padding: 8px;
  `,
  Letra: styled('div')((props: { opacity?: number }) => {
    return {
      background: '#999',
      borderRadius: '50%',
      width: '36px',
      height: '36px',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      color: 'white',
      fontWeight: 'bold',
      opacity: props.opacity || 1,
    }
  }),
  ContainerEditorAlternativa: styled('div')`
    flex: 1;
    margin-left: 8px;
  `,
  AlternativaContainer: styled('div')`
    width: 36px;
    height: 36px;
    border-radius: 50%;
    border: 1px solid #999;
    background: #ccc;
    margin: 0 8px;
  `,
  AdicionarAlternativa: styled('div')`
    padding: 12px;
    cursor: pointer;
    border-radius: 4px;
    display: inline-block;
    &:hover {
      background: #f2f2f2;
    }
  `,
}
