/* eslint-disable react/no-find-dom-node */
import * as React from 'react'
import * as ReactDOM from 'react-dom'
import * as Inputmask from 'inputmask'

export interface IProps {
  mask: 'cpf' | 'horas' | any
  onChange?: (e: Event) => null
  children: any
}

export class InputMask extends React.Component<IProps, object> {
  componentDidMount() {
    const domNode = ReactDOM.findDOMNode(this)
    let mask

    switch (this.props.mask) {
      case 'cpf':
        mask = '999.999.999-99'
        break
      case 'horas':
        mask = '99:99'
        break
      case 'celular':
        mask = ['(99) 9999-9999', '(99) 99999-9999']
        break
      default:
        mask = this.props.mask
        break
    }
    if (domNode) Inputmask(mask).mask((domNode as Element).querySelector('input'))
  }

  componentWillUnmount() {
    const domNode = ReactDOM.findDOMNode(this)
    if (domNode) Inputmask.remove((domNode as Element).querySelector('input'))
  }

  render() {
    return this.props.children
  }
}
