import React, { useContext } from 'react'
import { EsqueceuSenhaStoreValues, EsqueceuSenhaStoreCtx } from './EsqueceuSenha'
import { GlobalStoreValues, GlobalStoreCtx } from '../../GlobalStore'
import { Container, styles } from '../../components/Styles'
import styled from '@emotion/styled'
import { Formik, Form } from 'formik'
import { Yup } from '../../components/Yup'
import { SimpleInput } from '../../components/SimpleInput'
import { Button } from 'semantic-ui-react'
import { Redirect } from 'react-router'

interface IValues {
  email: string
}

export const EsqueceuSenhaView = () => {
  const { isLoading } = useContext<GlobalStoreValues>(GlobalStoreCtx)
  const { redirectTo, doRecuperaSenha } =
    useContext<EsqueceuSenhaStoreValues>(EsqueceuSenhaStoreCtx)

  if (redirectTo) return <Redirect to={redirectTo} />

  return (
    <Container>
      <div style={{ margin: '0 auto', maxWidth: '800px' }}>
        <Info>
          Informe seu endereço de <strong>e-mail</strong> e enviaremos os passos para resetar sua
          senha
        </Info>

        <div style={{ margin: '12px' }}>
          <Formik
            initialValues={{ email: '' }}
            onSubmit={async (values: IValues) => await doRecuperaSenha(values.email)}
            validationSchema={Yup.object().shape({
              email: Yup.string().required().email(),
            })}
            render={() => (
              <Form>
                <SimpleInput
                  name="email"
                  placeholder="Informe seu e-mail"
                  label="E-mail"
                  type="email"
                />

                <div style={{ marginTop: '12px' }}>
                  <Button type="submit" fluid disabled={isLoading} loading={isLoading}>
                    Enviar
                  </Button>
                </div>
              </Form>
            )}
          />
        </div>
      </div>
    </Container>
  )
}

const Info = styled('div')`
  background: ${styles.color.bg};
  color: ${styles.color.white};
  padding: 12px;
  margin: 12px;
  font-size: 2em;
  line-height: 1.4em;
`
