import { Button, Icon, Segment as SegmentComp } from 'semantic-ui-react'
import { useDispatch, useSelector } from 'react-redux'
import React, { useEffect, useState } from 'react'

import { bindActionCreators } from 'redux'

import { CursoModel } from '../../models/CursoModel'
import axios from '../../axios'
import { IStateRedux } from '../../ReduxStore'
import { Pagination } from '../../components/Pagination'
import { Table, styles } from '../../components/Styles'
import { cursosActions } from './CursosStore'
import { NavEstrutural } from '../../components/NavEstrutural'
const Segment = SegmentComp as any

export const CursosView: React.FC = (props: any) => {
  const dispatch = useDispatch()
  const [cursos, setCursos] = useState([])
  const [cursosCount, setCursosCount] = useState(0)
  const [query, setQuery] = useState({ offset: 0, limit: 100 })

  useEffect(() => {
    axios.Cursos.get(query)
      .then((data) => {
        setCursos(data.rows)
        setCursosCount(data.count)
        dispatch(cursosActions.cursosGetSuccess(data))
      })
      .catch((error) => console.log(error))
  }, [])

  return (
    <div className="ui container">
      <NavEstrutural />
      <br />
      <a href="/certificados/pendentes">Certificados pendentes</a>
      <Segment color="purple" style={{ marginTop: 25 }}>
        <h4> Cursos </h4>
        <Table>
          <table>
            <thead>
              <tr>
                <th>Id</th>
                <th>Nome</th>
                <th></th>
              </tr>
            </thead>
            <tbody>
              {cursos.length === 0 ? (
                <tr>
                  <td>
                    <span>Não há nenhum curso cadastrado ainda...</span>
                  </td>
                </tr>
              ) : null}
              {cursos.map((curso: CursoModel) => (
                <tr key={curso.id} onClick={() => props.history.push(`/cursos/${curso.id}/editar`)}>
                  <td>{curso.id}</td>
                  <td>{curso.nome}</td>
                  <td>
                    <Button
                      secondary
                      onClick={(e) => {
                        e.stopPropagation()
                        // deleteCurso
                      }}
                      icon
                    >
                      <Icon name="delete" />
                    </Button>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
          <Pagination
            onChangePage={() => null /* change page */}
            limit={query.limit}
            offset={query.offset}
            count={cursosCount}
          />
        </Table>
        <Button primary fluid onClick={() => props.history.push('/cursos/novo/')}>
          Criar novo curso
        </Button>
      </Segment>
    </div>
  )
}
