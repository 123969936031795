import React from 'react'
import { UsuarioModel } from '../models/UsuarioModel'
import styled from '@emotion/styled'

const Avatar = ({
  usuario = {} as UsuarioModel,
  tamanho = 120,
  circular = false,
  className = '',
}) => {
  const iniciais = usuario.avatar
    ? ''
    : usuario.nome
        .split(' ')
        .map((x) => x.charAt(0))
        .slice(0, 2)
        .join('')
        .toUpperCase()

  return (
    <AvatarWrapper
      backgroundImage={usuario.avatar}
      background={usuario.cor}
      className={className}
      tamanho={tamanho}
      circular={circular}
    >
      {iniciais && iniciais}
    </AvatarWrapper>
  )
}

const AvatarWrapper = styled('div')<{ backgroundImage; background; tamanho; circular }>(
  (props) => `
  display: flex;
  justify-content: center;
  align-items: center;
  background: ${props.background};
  background-image: ${props.backgroundImage ? `url(${props.backgroundImage})` : ''};
  background-size: cover;
  width: 100%;
  height: 100%;
  color: white;
  width: ${props.tamanho}px;
  height: ${props.tamanho}px;
  border-radius: ${props.circular ? '50%' : ''};
  font-size: calc(0.025em * ${props.tamanho});
  `
)

export { Avatar }
