import React, { useEffect, useMemo, useState } from 'react'
import styled from '@emotion/styled'
import { Button } from 'semantic-ui-react'

export const Paginacao = ({
  total,
  limit,
  page,
  handlePageActive,
  ...props
}: {
  total: number
  limit: number
  page: number
  handlePageActive: (pageSelected, newLimit?) => void
}) => {
  const { Layout, ContainerButtons } = Paginacao
  const handleActivePage = ({
    pageSelected,
    newLimit,
  }: {
    pageSelected: number
    newLimit?: number
  }) => {
    handlePageActive({ pageSelected, newLimit })
  }

  // if (!total) return null

  return (
    <Layout {...props}>
      <SelectLimitPage limit={limit} handleActivePage={handleActivePage} />
      <ContainerButtons>
        <NewStep
          position="left"
          primaryStep={() => {
            handleActivePage({ pageSelected: 1 })
          }}
          secondaryStep={() => {
            if (page === 1) return
            handleActivePage({ pageSelected: page - 1 })
          }}
        />
        <ContentPages total={total} limit={limit} page={page} handleActivePage={handleActivePage} />
        <NewStep
          position="right"
          primaryStep={() => {
            const totalPages = Math.ceil(total / limit)
            handleActivePage({ pageSelected: totalPages })
          }}
          secondaryStep={() => {
            const totalPages = Math.ceil(total / limit)
            if (page === totalPages) return
            handleActivePage({ pageSelected: page + 1 })
          }}
        />
      </ContainerButtons>
    </Layout>
  )
}

Paginacao.Layout = styled.div`
  display: flex;
  padding: 3px 0px;
  justify-content: center;
`

const NewStep = ({
  position,
  primaryStep,
  secondaryStep,
  ...props
}: {
  position: string
  primaryStep: () => void
  secondaryStep: () => void
}) => {
  const { Layout, PrimaryStep, SecondaryStep } = NewStep

  return (
    <Layout {...props} position={position}>
      <PrimaryStep position={position} onClick={primaryStep} />
      <SecondaryStep position={position} onClick={secondaryStep} />
    </Layout>
  )
}

NewStep.Layout = styled.div<{ position: string }>(
  ({ position }) => `
  display: flex;
  gap: 10px;
  flex-direction: ${position === 'left' ? 'row' : position === 'right' ? 'row-reverse' : 'row'};
`
)

NewStep.PrimaryStep = styled.div<{ position: string }>(
  ({ position }) => `
  width: 11px;
  height: 11px;
  border-left: 3px solid #494D50;
  border-bottom: 3px solid #494D50;
  -webkit-transform: rotate(${
    position === 'left' ? '45deg' : position === 'right' ? '-135deg' : '0deg'
  });
  -moz-transform: rotate(${
    position === 'left' ? '45deg' : position === 'right' ? '-135deg' : '0deg'
  });
  cursor: pointer;
  position: relative;
  ::after {
  content: '';
  position: absolute;
  top: 5px;
  left: -7px;
  width: 10px;
  height: 10px;
  border-left: 3px solid #494D50;
  border-bottom: 3px solid #494D50;
  -webkit-transform: rotate(${
    position === 'left' ? '0deg' : position === 'right' ? '-0deg' : '0deg'
  });
  -moz-transform: rotate(${position === 'left' ? '0deg' : position === 'right' ? '-0deg' : '0deg'});
  }
`
)

NewStep.SecondaryStep = styled.div<{ position: string }>(
  ({ position }) => `
  width: 11px;
  height: 11px;
  border-left: 3px solid #494D50;
  border-bottom: 3px solid #494D50;
  -webkit-transform: rotate(${
    position === 'left' ? '45deg' : position === 'right' ? '-135deg' : '0deg'
  });
  -moz-transform: rotate(${
    position === 'left' ? '45deg' : position === 'right' ? '-135deg' : '0deg'
  });
  cursor: pointer;
  position: relative;
`
)

const SelectLimitPage = ({ limit, handleActivePage }) => {
  const { Layout, Select } = SelectLimitPage
  return (
    <Layout>
      <Select
        value={limit}
        onChange={(e) => {
          handleActivePage({ newLimit: e.target.value, pageSelected: 1 })
        }}
      >
        <option value={15}>15</option>
        <option value={25}>25</option>
        <option value={50}>50</option>
        <option value={100}>100</option>
      </Select>
    </Layout>
  )
}

SelectLimitPage.Layout = styled.div`
  padding: 10px;
  min-width: 100px;
`
SelectLimitPage.Select = styled.select`
  background: #ffffff;
  border: 1px solid #d4d4d4;
  max-width: 100px;
  padding: 3px 5px;
`
Paginacao.ContainerButtons = styled.div`
  display: flex;
  align-items: center;
  gap: 5px;
`

const ContentPages = ({ page, handleActivePage, total, limit }) => {
  const { Layout } = ContentPages
  const totalPages = Math.ceil(total / limit)

  return (
    <Layout>
      {Array.from({ length: totalPages }, (_, index) => (
        <Page
          key={index}
          page={page}
          handleActivePage={handleActivePage}
          pageSelected={index + 1}
          finalPage={page === Math.ceil(total / limit)}
        />
      ))}
    </Layout>
  )
}

ContentPages.Layout = styled.div`
  display: flex;
  gap: 3px;
  max-width: 180px;
  overflow: hidden;
  @media (max-width: 600px) {
    max-width: 70px;
  }
`

const Page = ({
  page,
  handleActivePage,
  pageSelected,
  finalPage,
}: {
  pageSelected: number
  handleActivePage
  page
  finalPage
}) => {
  const { Layout } = Page
  const isMobile = false
  const makeAMargin = useMemo(() => {
    const widthContentPage = isMobile ? 70 : 180
    const widthPage = 33
    const limitContentPage = Math.floor(widthContentPage / widthPage)
    const marginLeft = (page - limitContentPage + 1) * widthPage
    if (finalPage) return `margin-left: -${marginLeft - widthPage}px;`
    if (page < limitContentPage) return 'margin-left: 0px;'
    if (page >= limitContentPage) {
      return `margin-left: -${marginLeft}px;`
    }
  }, [page])

  return (
    <Layout
      onClick={() => handleActivePage({ pageSelected })}
      active={page === pageSelected}
      makeAMargin={makeAMargin}
    >
      {pageSelected}
    </Layout>
  )
}

Page.Layout = styled.div<{ active: boolean; makeAMargin }>(
  ({ active, makeAMargin }) => `
  min-width: 30px;
  height: 30px;
  padding: 3px;
  cursor: pointer;
  background-color: #FFFFFF;
  color: var(--primary-color);
  border: 1px solid var(--primary-color);
  border-radius: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: 0.26s ease-in-out;
  &:first-child {
    ${makeAMargin}
  }
  ${
    active &&
    `
    background-color: var(--primary-color);
    color: #FFFFFF;
    font-weight: bold;
  `
  }
`
)
