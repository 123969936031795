import { Route, Switch } from 'react-router-dom'
import React from 'react'
import { CuponsView } from './CuponsView'
import { CuponsEditar } from './CuponsEditar'

export const Cupons = () => {
  return (
    <Switch>
      <Route path="/cupons/:cursoId/editar/" component={CuponsEditar} />
      <Route path="/cupons/novo" component={CuponsEditar} />
      <Route component={CuponsView} />
    </Switch>
  )
}
