import React, { useContext } from 'react'
import { Formik, Form } from 'formik'
import { Yup } from '../../components/Yup'
import { SimpleInput } from '../../components/SimpleInput'
import { Button } from 'semantic-ui-react'
import { Fieldset, DisplayFlex } from '../../components/Styles'
import { AlteraSenhaStoreValues, AlteraSenhaStoreCtx } from './AlteraSenha'
import { GlobalStoreValues, GlobalStoreCtx } from '../../GlobalStore'

export interface IValues {
  senhaAtual: string
  novaSenha: string
  confirmaNovaSenha: string
}

export const AlteraSenhaView = () => {
  const { isLoading } = useContext<GlobalStoreValues>(GlobalStoreCtx)
  const { updateSenha, cancelar } = useContext<AlteraSenhaStoreValues>(AlteraSenhaStoreCtx)

  return (
    <div>
      <Fieldset>
        <legend>Mudança de senha</legend>
        <Formik
          initialValues={{ senhaAtual: '', novaSenha: '', confirmaNovaSenha: '' }}
          onSubmit={async (values: IValues) => await updateSenha(values)}
          validationSchema={Yup.object().shape({
            senhaAtual: Yup.string().required().min(6, 'Informe pelo menos 6 caracteres'),
            novaSenha: Yup.string().required().min(6, 'Informe pelo menos 6 caracteres'),
            confirmaNovaSenha: Yup.string()
              .required()
              .min(6, 'Informe pelo menos 6 caracteres')
              .oneOf([Yup.ref('novaSenha'), null], 'As senhas não coincidem'),
          })}
          render={() => (
            <Form>
              <SimpleInput name="senhaAtual" placeholder="Senha Atual" type="password" />
              <SimpleInput name="novaSenha" placeholder="Nova Senha" type="password" />
              <SimpleInput
                name="confirmaNovaSenha"
                placeholder="Confirma Nova Senha"
                type="password"
              />

              <DisplayFlex marginBetween={8} style={{ marginTop: '12px' }}>
                <Button type="submit" fluid disabled={isLoading} loading={isLoading}>
                  Enviar
                </Button>
                <Button type="button" fluid onClick={cancelar}>
                  Cancelar
                </Button>
              </DisplayFlex>
            </Form>
          )}
        />
      </Fieldset>
    </div>
  )
}
