import styled from '@emotion/styled'
import React from 'react'
export const ListaCheckBoxContainer = ({
  names,
  handleCheck,
}: {
  names: {}
  handleCheck: (checked, name) => void
}) => {
  const { Layout } = ListaCheckBoxContainer

  return (
    <Layout>
      {Object.keys(names).map((name, index) => (
        <CheckBox key={index} name={name} checked={names[name]} handleCheck={handleCheck} />
      ))}
    </Layout>
  )
}

ListaCheckBoxContainer.Layout = styled.div`
  background: #ffffff;
  width: 275px;
  display: grid;
  gap: 20px;
  border: 1px solid #a4a4a4;
  box-shadow: 0px 3px 5px rgba(0, 0, 0, 0.25);
  padding: 20px 30px;
  border-radius: 7px;
  max-height: 330px;
  overflow-y: auto;
`

const CheckBox = ({
  name,
  checked,
  handleCheck,
}: {
  name: string
  checked: boolean
  handleCheck: (checked, name) => void
}) => {
  const { Layout, Label } = CheckBox
  const normalize = (name) => {
    return name.replace(/([A-Z])/g, ' $1').replace(/^./, (str) => str.toUpperCase())
  }

  return (
    <Layout>
      <input
        type="checkbox"
        name={name}
        checked={checked}
        onChange={(e) => handleCheck(e.target.checked, e.target.name)}
      />
      <Label>{normalize(name)}</Label>
    </Layout>
  )
}

CheckBox.Layout = styled.div`
  display: flex;
  gap: 10px;
  input {
    cursor: pointer;
    transform: scale(1.3);
  }
`
CheckBox.Label = styled.span`
  font-size: 16px;
  line-height: 1.6;
  color: #585858;
`
