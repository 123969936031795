const colors = {
  primary: '#482D6C',
  secondary: '#FB523D',
}

const text = {
  color: 'white',
}

export { colors, text }
