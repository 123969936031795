import { configureStore, getDefaultMiddleware } from '@reduxjs/toolkit'
import { save, load } from 'redux-localstorage-simple'

import { authReducer, IState as IStateAuth } from './pages/Auth/AuthReducer'
import { modulosReducer, IState as IStateModulos } from './pages/Modulos/ModulosStore'
import {
  questaoEditorReducer,
  IState as IStateQuestaoEditor,
} from './components/QuestaoEditor/QuestaoEditorStore'
import { questoesReducer, IState as IStateQuestoes } from './pages/Questoes/QuestoesStore'
import {
  usuariosReducer,
  IState as IStateUsuarios,
} from './pages/UsuariosCurso/UsuariosCursosStore'
import { forunsReducer, IState as IStateForuns } from './pages/Foruns/ForunsStore'
import { cursosReducer, IState as IStateCursos } from './pages/Cursos/CursosStore'

export interface IStateRedux {
  questaoEditor: IStateQuestaoEditor
  questoes: IStateQuestoes
  modulos: IStateModulos
  usuarios: IStateUsuarios
  auth: IStateAuth
  foruns: IStateForuns
  cursos: IStateCursos
}

export const store = configureStore({
  reducer: {
    auth: authReducer,
    questaoEditor: questaoEditorReducer,
    questoes: questoesReducer,
    modulos: modulosReducer,
    usuarios: usuariosReducer,
    foruns: forunsReducer,
    cursos: cursosReducer,
  },
  middleware: [...getDefaultMiddleware(), save()],
  preloadedState: load(),
})
