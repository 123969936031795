import React, { useState, useMemo } from 'react'
import decode from 'jwt-decode'
import { UsuarioModel } from './models/UsuarioModel'
import { Util } from './components/Util'

let _timeout: any

const useGlobalStore = () => {
  const [token, setToken] = useState(window.localStorage.getItem('token'))
  const [message, _setMessage] = useState({ show: false, message: '', type: 'error' })
  const [isLoading, setIsLoading] = useState(false)
  const [firstPage] = useState('/inicio')

  function closeMessage() {
    _setMessage({ ...message, show: false })
  }

  function setErrorMessage(m: string, timeToHide: number = 3000) {
    if (message.message === m && message.show) Util.confirm(m)
    _setMessage({ type: 'error', message: m, show: true })
    if (timeToHide) _hideMessage(timeToHide)
  }
  function setInfoMessage(m: string, timeToHide: number = 3000) {
    _setMessage({ type: 'info', message: m, show: true })
    if (timeToHide) _hideMessage(timeToHide)
  }
  function setSuccessMessage(m: string, timeToHide: number = 3000) {
    _setMessage({ type: 'success', message: m, show: true })
    if (timeToHide) _hideMessage(timeToHide)
  }

  function _hideMessage(timeToHide: number) {
    if (_timeout) clearTimeout(_timeout)
    _timeout = setTimeout(() => _setMessage({ ...message, show: false }), timeToHide)
  }

  function clearTokenConta() {
    setToken(undefined)
    window.localStorage.removeItem('conta')
    window.localStorage.removeItem('token')
  }

  const usuario = useMemo<UsuarioModel>(() => {
    return token ? decode(token) : ({} as UsuarioModel)
  }, [token])

  return {
    setToken,
    usuario,
    clearTokenConta,
    message,
    closeMessage,
    setErrorMessage,
    setInfoMessage,
    setSuccessMessage,
    isLoading,
    setIsLoading,
    firstPage,
  }
}
export type GlobalStoreValues = ReturnType<typeof useGlobalStore>
export const GlobalStoreCtx = React.createContext(null)
export const GlobalStore = (props: any) => {
  const values = useGlobalStore()
  return <GlobalStoreCtx.Provider value={values}>{props.children}</GlobalStoreCtx.Provider>
}
