import { Route, Switch } from 'react-router-dom'
import React from 'react'

import { UsuariosCursosEditar } from './UsuariosCursosEditar'
import { UsuariosCursosView } from './UsuariosCursosView'

export const UsuariosCursos = () => {
  return (
    <Switch>
      <Route path="/usuarios-cursos/editar/:id?" component={UsuariosCursosEditar} />
      <Route component={UsuariosCursosView} />
    </Switch>
  )
}
