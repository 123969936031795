export const formatCurrency = (amount: number | null | undefined) => {
  if (!amount) return null
  return new Intl.NumberFormat('pt-BR', {
    style: 'currency',
    currency: 'BRL',
  }).format(amount)
}

export function removeAccents(str) {
  return str?.normalize('NFD')?.replace(/[\u0300-\u036f]/g, '')
}
