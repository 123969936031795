import React from 'react'
import styled from '@emotion/styled'
import AnimateHeight from 'react-animate-height'
import { useSpring, animated } from 'react-spring'
import { QuestaoModel } from '../../../../models/QuestaoModel'
import { QuestaoAlternativaModel } from '../../../../models/QuestaoAlternativaModel'

export const MultiploVerdadeiroFalso = ({ questao }: { questao: QuestaoModel }) => {
  const questoesAlternativas = questao.questoesAlternativas || []
  return (
    <>
      <Styles.AlternativaContainer>
        {questoesAlternativas.map((alternativa, index) => {
          return <Alternativa key={alternativa.id} alternativa={alternativa} index={index} />
        })}
      </Styles.AlternativaContainer>
    </>
  )
}
function Alternativa({ alternativa = {} as QuestaoAlternativaModel, index }) {
  const status = alternativa.respostaEsperada === 'V' ? 'marcada-verdadeira' : 'marcada-falsa'

  const { styleVerdadeiro, styleFalso } = _getStylesVerdadeiroFalso(status)

  return (
    <>
      <Styles.Alternativa odd={index % 2 === 0}>
        <div className="v-f-container">
          <Styles.ButtonVF style={styleVerdadeiro}>Verdadeiro</Styles.ButtonVF>
          <Styles.ButtonVF style={styleFalso}>Falso</Styles.ButtonVF>
        </div>
        <div className="descricao-container">
          <Styles.Descricao
            dangerouslySetInnerHTML={{ __html: alternativa.conteudoAlternativa || '' }}
          />
        </div>
      </Styles.Alternativa>
    </>
  )
}

function _getStatus(
  hasRespostaCorreta,
  respostaEsperada,
  showRespostaIncorreta,
  respostaInformada
):
  | 'verdadeira-marcada-verdadeira'
  | 'verdadeira-marcada-falsa'
  | 'falsa-marcada-falsa'
  | 'falsa-marcada-verdadeira'
  | 'marcada-falsa'
  | 'marcada-verdadeira'
  | '' {
  if (hasRespostaCorreta) {
    if (respostaEsperada === 'V') return 'verdadeira-marcada-verdadeira'
    return 'falsa-marcada-falsa'
  }

  if (showRespostaIncorreta) {
    if (respostaEsperada === 'V') return 'verdadeira-marcada-falsa'
    return 'falsa-marcada-verdadeira'
  }

  if (respostaInformada === 'V') return 'marcada-verdadeira'
  if (respostaInformada === 'F') return 'marcada-falsa'
  return ''
}

function _getStylesVerdadeiroFalso(status) {
  const green = '#27AE60'
  const white = 'white'
  const red = '#EB5757'
  let styleVerdadeiro: any = {
    background: white,
    borderColor: '#E0E0E0',
    color: '#4D4D4D',
    borderLeftWidth: 2,
  }
  let styleFalso: any = {
    background: white,
    borderColor: '#E0E0E0',
    color: '#4D4D4D',
    borderLeftWidth: 2,
  }

  switch (status) {
    case 'falsa-marcada-falsa':
      styleFalso = { background: green, borderColor: green, color: white }
      break
    case 'falsa-marcada-verdadeira':
      styleVerdadeiro = { background: red, borderColor: red, color: white }
      break
    case 'verdadeira-marcada-falsa':
      styleFalso = { background: red, borderColor: red, color: white }
      break
    case 'verdadeira-marcada-verdadeira':
      styleVerdadeiro = { background: green, borderColor: green, color: white }
      break
    case 'marcada-falsa':
      styleFalso = { borderColor: '#4D4D4D', borderLeftWidth: 8 }
      break
    case 'marcada-verdadeira':
      styleVerdadeiro = { borderColor: '#4D4D4D', borderLeftWidth: 8 }
      break
  }
  return { styleVerdadeiro, styleFalso }
}

const Styles = {
  AlternativaContainer: styled('div')`
    margin-bottom: 40px;
    @media (max-width: 425px) {
      margin-left: -16px;
      margin-right: -16px;
    }
  `,
  Alternativa: styled('div')<{ odd }>(
    (props) => `
    background: ${props.odd ? '#F7F7F7' : 'white'};
    padding: 16px 24px;
    display: grid;
    grid-template-columns: 290px 1fr;
    grid-template-rows: auto;
    align-items: center;
    grid-template-areas: "v-f descricao";
    .v-f-container {
      display: flex;
      grid-area: v-f;
    }
    .descricao-container {
      grid-area: descricao;
    }
    @media (max-width: 630px) {
      grid-template-columns: 1fr;
      grid-template-rows: auto auto;
      grid-row-gap: 16px;
      grid-template-areas:
        "descricao"
        "v-f";
    }
    @media (max-width: 425px) {
      padding: 8px 16px;
    }
  `
  ),
  ButtonVF: styled(animated.div)`
    width: 118px;
    height: 40px;
    background: white;
    border: 2px solid #e0e0e0;
    box-sizing: border-box;
    box-shadow: 4px 4px 4px rgba(0, 0, 0, 0.08);
    border-radius: 3px;
    font-family: Rubik;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 19px;
    display: flex;
    align-items: center;
    text-align: center;
    color: #4d4d4d;
    justify-content: center;
    &:last-child {
      margin-left: 16px;
    }
    user-select: none;

    cursor: pointer;
    &:hover {
      background: #f7f7f7;
    }
  `,
  Feedback: styled('div')<{ correto }>(
    (props) => `
    font-family: Rubik;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 19px;
    margin-top: 8px;
    color: ${props.correto ? '#27AE60' : '#EB5757'};
    @media (max-width: 425px) {
      font-size: 14px;
    }
  `
  ),
  Descricao: styled('div')`
    font-family: Rubik;
    font-style: normal;
    font-weight: normal;
    font-size: 18px;
    line-height: 21px;
    color: #4d4d4d;
  `,
  Letra: styled('div')`
    border-radius: 50%;
    min-width: 39px;
    width: 39px;
    height: 39px;
    display: flex;
    font-weight: bold;
    justify-content: center;
    align-items: center;
    color: #afafaf;
    font-size: 1.1em;
    box-shadow: 1px 1px 2px rgba(0, 0, 0, 0.25);
    margin: calc(var(--margin) * 0.66);
    margin-left: 0;
    border: 2px solid #afafaf;
  `,
  BotoesContainer: styled('div')`
    text-align: center;
    ion-button {
      height: 48px;
      --background: var(--primary-color);
      --background-focused: var(--primary-color);
      --color: var(--primary-color-contrast, #fff);
      --background-activated: var(--primary-color-shade, #fff);
    }
    ion-button.envia-resposta {
      --background: white;
      --background-focused: #f7f7f7;
      --background-activated: #f7f7f7;
      --color: var(--primary-color);
      --color-activated: var(--primary-color-shade);
      --color-focused: var(--primary-color);
      --border-width: 2px;
      --border-color: var(--primary-color);
      --border-style: solid;
    }
  `,
}
