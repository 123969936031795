import React, { useEffect } from 'react'
import styled from '@emotion/styled'
import { MultiplaEscolha } from './MultiplaEscolha'
import { RelacionarItens } from './RelacionarItens'
import { MultiploVerdadeiroFalso } from './MultiploVerdadeiroFalso'
import { animated, useSpring } from 'react-spring'
import { Show } from '../../../../components/Basic'
import { QuestaoModel, TIPO_QUESTAO } from '../../../../models/QuestaoModel'
import useAxios from 'axios-hooks'
import { EditorPreview } from '../../../../components/CustomEditor/CustomEditor'

export const QuestaoVisualizacao = ({ questaoId }) => {
  const [{ data: questao, loading }] = useAxios(`/questoes/${questaoId}`, { useCache: false })

  if (loading) return <>Carregando ...</>

  return (
    <>
      <Styles.ContainerQuestao>
        <Enunciado questao={questao} />
        <InstrucoesDeResposta questao={questao} />
        <Show condition={questao?.tipoQuestao === 'MULTIPLA_ESCOLHA'}>
          <MultiplaEscolha questao={questao} />
        </Show>
        <Show condition={questao?.tipoQuestao === 'RELACIONAR_ITENS'}>
          <RelacionarItens questao={questao} />
        </Show>
        <Show condition={questao?.tipoQuestao === 'MULTIPLO_VERDADEIRO_FALSO'}>
          <MultiploVerdadeiroFalso questao={questao} />
        </Show>
      </Styles.ContainerQuestao>
    </>
  )
}

function Enunciado({ questao }: { questao: QuestaoModel }) {
  const textoQuestao = questao.textoQuestao || ''
  const { Layout } = Enunciado
  return <Layout html={textoQuestao} />
}
Enunciado.Layout = styled(EditorPreview)`
  color: #4d4d4d;
  font-family: Rubik;
  font-style: normal;
  font-weight: 500;
  font-size: 24px;
  line-height: 28px;
  margin-bottom: 8px;
  @media screen and (max-width: 425px) {
    font-size: 20px;
    line-height: 23px;
    margin-bottom: 6px;
  }
`

function InstrucoesDeResposta({ questao }: { questao: QuestaoModel }) {
  const tipoQuestao = questao.tipoQuestao
  let instrucao = ''
  if (tipoQuestao === TIPO_QUESTAO.MULTIPLA_ESCOLHA)
    instrucao = 'Selecione a única alternativa abaixo que está correta.'
  if (tipoQuestao === TIPO_QUESTAO.RELACIONAR_ITENS)
    instrucao = 'Relacione as alternativas da esquerda com as da direita.'
  if (tipoQuestao === TIPO_QUESTAO.RELACIONAR_ITEM_A_IMAGEM) instrucao = ''
  if (tipoQuestao === TIPO_QUESTAO.MULTIPLO_VERDADEIRO_FALSO)
    instrucao = 'Assinale se cada alternativa é Verdadeira ou Falsa.'
  return <Styles.Instrucao>{instrucao}</Styles.Instrucao>
}

const Styles = {
  ContainerQuestao: styled('div')`
    display: block;
  `,
  Enunciado: styled('div')``,
  Instrucao: styled('div')`
    font-family: Rubik;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 19px;
    color: #88898c;
    margin-bottom: 32px;
    @media screen and (max-width: 425px) {
      font-size: 14px;
      line-height: 16px;
      margin-bottom: 24px;
    }
  `,
}
