import React, { useRef, useEffect, useState } from 'react'
import styled from '@emotion/styled'

const VideoHls = ({ url }) => {
  const ref = useRef<HTMLVideoElement>(null)
  const hls = useRef<any>(null)
  const [showOverlay, setShowOverlay] = useState(true)
  useEffect(() => {
    if (ref.current) startHls()
  }, [ref.current])

  function startHls() {
    const Hls = window.Hls
    if (!Hls) return setTimeout(() => startHls())
    hls.current = new Hls({
      autoLevelEnabled: true,
      maxMaxBufferLength: 120,
    })
    hls.current.attachMedia(ref.current)
    hls.current.on(Hls.Events.MEDIA_ATTACHED, function () {
      hls.current.loadSource(url)
      hls.current.on(Hls.Events.MANIFEST_PARSED, function (event, data) {
        setTimeout(() => {
          hls.current.stopLoad()
        }, 1000)
      })
    })
  }

  function handlePause(e) {
    hls.current.stopLoad()
    setShowOverlay(true)
  }

  function handlePlay(e) {
    const target = e.target
    setShowOverlay(false)
    setTimeout(() => {
      target.play()
    })
    hls.current.startLoad()
  }
  return (
    <Styles.Container showOverlay={showOverlay}>
      <video controls onPause={handlePause} onPlay={handlePlay} ref={ref} />
      <div
        className="overlay-control"
        onClick={async () => await ref.current?.play()}
        onDoubleClick={() => null}
      >
        <div className="backdrop"></div>
        <PlayIcon color="white" />
      </div>
    </Styles.Container>
  )
}

const PlayIcon = ({ color }) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
      <defs>
        <filter id="f1" x="0" y="0" width="200%" height="200%">
          <feOffset result="offOut" in="SourceAlpha" dx="20" dy="20" />
          <feColorMatrix
            result="matrixOut"
            in="offOut"
            type="matrix"
            values="0.2 0 0 0 0 0 0.2 0 0 0 0 0 0.2 0 0 0 0 0 1 0"
          />
          <feGaussianBlur result="blurOut" in="matrixOut" stdDeviation="10" />
          <feBlend in="SourceGraphic" in2="blurOut" mode="normal" />
        </filter>
      </defs>
      <path
        d="M256 48C141.2 48 48 141.2 48 256s93.2 208 208 208 208-93.2 208-208S370.8 48 256 48zm-41.6 301.6V162.4L339.2 256l-124.8 93.6z"
        filter="url(#f1)"
        fill={color}
      />
    </svg>
  )
}

const Styles = {
  Container: styled('div')<{ showOverlay }>(
    (props) => `
    position: relative;
    width: 100%;
    video {
      width: 100%;
      ${props.showOverlay ? 'filter: blur(2px);' : ''}
    }
    .overlay-control {
      position: absolute;
      width: 100%;
      height: 100%;
      top: 0;
      ${props.showOverlay ? 'display: flex;' : 'display: none;'}
      justify-content: center;
      align-items: center;
      .backdrop {
        opacity: .4;
        background: #999;
        width: 100%;
        height: 100%;
        position: absolute;
        z-index: 1;
      }
      svg {
        cursor: pointer;
        height: 50%;
        z-index: 2;
      }
  `
  ),
}

export { VideoHls }
