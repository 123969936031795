import React, { useRef, useEffect } from 'react'
import { questaoEditorActions } from '../QuestaoEditorStore'
import { CustomEditor } from '../../CustomEditor/CustomEditor'
import styled from '@emotion/styled'
import { Icon, Button } from 'semantic-ui-react'
import { QuestaoAlternativaModel } from '../../../models/QuestaoAlternativaModel'
import { useDispatch, useSelector } from 'react-redux'
import { bindActionCreators } from 'redux'
import { IStateRedux } from '../../../ReduxStore'
import { DisplayFlex } from '../../Styles'

export const MultiplaEscolha = ({ questaoId }: { questaoId: string }) => {
  const { FeedbackInput, ContainerAlternativa, AdicionarAlternativa } = MultiplaEscolha
  const dispatch = useDispatch()
  const questao = useSelector((s: IStateRedux) =>
    s.questaoEditor[questaoId] ? s.questaoEditor[questaoId].questao : { questoesAlternativas: [] }
  )
  const actions = bindActionCreators(questaoEditorActions, dispatch)

  const letras = 'ABCDEFGHIJKLMNO'.split('')

  function handleNovaAlternativaClick(e) {
    e.stopPropagation()
    addAlternativa()
  }

  function addAlternativa() {
    actions.addAlternativa({ questaoId })
  }

  function handleClickOpcao(alternativaId: number) {
    const novasAlternativas: QuestaoAlternativaModel[] = questao.questoesAlternativas.map((x) =>
      x.id === alternativaId
        ? { ...x, respostaEsperada: 'true' }
        : { ...x, respostaEsperada: 'false' }
    )
    actions.changeAlternativas({ questaoId, questoesAlternativas: novasAlternativas })
  }

  function handleFeedbackChange(e, alternativa) {
    actions.setFeedback({
      alternativaId: alternativa.id,
      questaoId,
      feedback: e.currentTarget.innerText,
    })
  }

  return (
    <div>
      <div>Alternativas:</div>
      <div>
        {questao.questoesAlternativas.map((alternativa, index) => (
          <ContainerAlternativa key={alternativa.id}>
            <Alternativa
              dispatch={dispatch}
              id={alternativa.id}
              alternativa={alternativa}
              questaoId={questaoId}
              letraAlternativa={letras[index]}
              handleClickOpcao={(alternativaId) => handleClickOpcao(alternativaId)}
            />
            <DisplayFlex center={true}>
              <span style={{ width: 190 }}>Feedback da alternativa:</span>
              <FeedbackInput
                style={{ width: '100%' }}
                text={alternativa.feedback || ''}
                onBlur={(e) => handleFeedbackChange(e, alternativa)}
                textOnly={true}
              />
            </DisplayFlex>
          </ContainerAlternativa>
        ))}
        <div style={{ textAlign: 'right' }}>
          <AdicionarAlternativa onClick={() => actions.addAlternativa({ questaoId })}>
            <Icon name="plus circle" /> Adicionar alternativa
          </AdicionarAlternativa>
        </div>

        {/* <NovaAlternativa handleClick={handleNovaAlternativaClick}>
          <Alternativa letraAlternativa={letras[questao.questoesAlternativas.length]} alternativa={{}}
            onFocus={handleFocusNovaAlternativa} />
          <div className="overlay"></div>
        </NovaAlternativa> */}
      </div>
    </div>
  )
}
MultiplaEscolha.ContainerAlternativa = styled.div`
  border-bottom: 1px solid #ccc;
  margin-bottom: 8px;
  padding-bottom: 8px;
`
MultiplaEscolha.FeedbackInput = styled(CustomEditor)`
  background: #f2f2f2;
  border: none;
`
MultiplaEscolha.AdicionarAlternativa = styled.div`
  padding: 12px;
  cursor: pointer;
  border-radius: 4px;
  display: inline-block;
  &:hover {
    background: #f2f2f2;
  }
`

const Alternativa = (props) => {
  const dispatch = useDispatch()
  const actions = bindActionCreators(questaoEditorActions, dispatch)
  const alternativa = props.alternativa
  const questaoId = props.questaoId

  function handleBlur(e) {
    if (!actions || !actions.changeAlternativaById) return
    actions.changeAlternativaById({
      questaoId,
      alternativa: { ...alternativa, conteudoAlternativa: e.currentTarget.innerHTML },
    })
  }

  function handleDeleteClick() {
    actions.deleteAlternativaById({ questaoId, alternativaId: props.id })
  }

  return (
    <Styles.ContainerAlternativa>
      <Styles.Letra>{props.letraAlternativa}</Styles.Letra>
      <OpcaoCorreta
        correto={alternativa.respostaEsperada === 'true'}
        onClick={() => props.handleClickOpcao(props.id)}
      />
      <Styles.ContainerEditorAlternativa>
        <CustomEditor
          text={alternativa.conteudoAlternativa || ''}
          onBlur={handleBlur}
          onFocus={props.onFocus}
          textOnly={true}
        />
      </Styles.ContainerEditorAlternativa>
      <Button basic icon="trash" onClick={handleDeleteClick} tabIndex={-1} />
    </Styles.ContainerAlternativa>
  )
}

const OpcaoCorreta = React.memo(({ correto, onClick }: any) => {
  return (
    <Styles.AlternativaContainer onClick={onClick}>
      {correto ? (
        <Styles.Letra>
          <Icon name="checkmark" />
        </Styles.Letra>
      ) : null}
    </Styles.AlternativaContainer>
  )
})

const NovaAlternativa = ({ children, handleClick }) => {
  return <Styles.NovaAlternativa onClick={handleClick}>{children}</Styles.NovaAlternativa>
}

const Styles = {
  NovaAlternativa: styled('div')`
    opacity: 0.5;
    position: relative;
    .overlay {
      position: absolute;
      top: 0;
      left: 0;
      bottom: 0;
      right: 0;
    }
  `,
  ContainerAlternativa: styled('div')`
    display: flex;
    padding: 8px;
  `,
  Letra: styled('div')((props: { opacity?: number }) => {
    return {
      background: '#999',
      borderRadius: '50%',
      width: '36px',
      height: '36px',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      color: 'white',
      fontWeight: 'bold',
      opacity: props.opacity || 1,
    }
  }),
  ContainerEditorAlternativa: styled('div')`
    flex: 1;
    margin-left: 8px;
  `,
  AlternativaContainer: styled('div')`
    width: 36px;
    height: 36px;
    border-radius: 50%;
    border: 1px solid #999;
    background: #ccc;
    margin: 0 8px;
  `,
}
