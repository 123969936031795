import React, { useContext } from 'react'
import { Formik, Form } from 'formik'
import { Yup } from '../../components/Yup'
import { SimpleInputContainer, SimpleInput } from '../../components/SimpleInput'
import { UsuarioModel } from '../../models/UsuarioModel'
import { InputMask } from '../../components/Inputmask'
import { ImgCropper } from '../../components/ImgCropper'
import { Fieldset, Padding, DisplayFlex } from '../../components/Styles'
import axios from '../../axios'
import { PerfilStoreValues, PerfilStoreCtx } from './Perfil'
import { GlobalStoreValues, GlobalStoreCtx } from '../../GlobalStore'
import { ButtonLoader } from '../../components/ButtonLoader'
import { Redirect } from 'react-router'
import { Button } from 'semantic-ui-react'
import { Util } from '../../components/Util'

let imgCropper: any

export const PerfilView = () => {
  const { isLoading } = useContext<GlobalStoreValues>(GlobalStoreCtx)
  const { usuario, updatePerfil, cancelar, setLoading, redirectTo } =
    useContext<PerfilStoreValues>(PerfilStoreCtx)

  const handleSubmit = async (values: UsuarioModel) => {
    const newValues = { ...values }
    if (imgCropper.hasNewImage()) {
      newValues.avatar = (await uploadFile()).filepath
    }

    updatePerfil(newValues)
  }

  const uploadFile = async () => {
    setLoading()
    const formData = new FormData()
    formData.append('file', getFileFromDataURL(imgCropper.getCroppedImage()))
    const path = await axios.Files.upload(formData)
    return path
  }

  const getFileFromDataURL = (dataURL: string): Blob => {
    const blobBin = atob(dataURL.split(',')[1])
    const array = []
    for (let i = 0; i < blobBin.length; i++) {
      array.push(blobBin.charCodeAt(i))
    }

    return new Blob([new Uint8Array(array)], { type: 'image/jpg' })
  }

  if (redirectTo) return <Redirect to={redirectTo} push />

  return (
    <div>
      <Formik
        initialValues={Util.getInitialValues(usuario, ['nome', 'celular', 'cpf', 'email'])}
        onSubmit={async (values: UsuarioModel) => await handleSubmit(values)}
        enableReinitialize={true}
        validationSchema={Yup.object().shape({
          nome: Yup.string().required(),
          cpf: (Yup as any).string().required().cpf(),
          email: Yup.string().email().required(),
        })}
        render={({ setFieldValue }) => (
          <Fieldset>
            <legend>Editar Perfil</legend>
            <Form>
              <SimpleInput {...{ name: 'nome', placeholder: 'Nome' }} className="input-uppercase" />

              <SimpleInputContainer
                field={{ value: usuario.cpf || '' }}
                name="cpf"
                placeholder="CPF"
                disabled={true}
              />

              <InputMask mask="celular">
                <SimpleInput {...{ name: 'celular', placeholder: 'Celular' }} />
              </InputMask>

              <SimpleInput {...{ name: 'email', placeholder: 'E-mail', type: 'email' }} />

              <Padding vertical={true}>
                <ImgCropper
                  ref={(e) => {
                    imgCropper = e
                  }}
                  src={usuario.avatar}
                  onRemoveFoto={() => setFieldValue('Avatar', '')}
                />
              </Padding>

              <DisplayFlex marginBetween={8}>
                <ButtonLoader
                  variant="contained"
                  type="submit"
                  disabled={isLoading}
                  loading={isLoading}
                >
                  Enviar
                </ButtonLoader>
                <Button variant="contained" type="button" onClick={cancelar}>
                  Cancelar
                </Button>
              </DisplayFlex>
            </Form>
          </Fieldset>
        )}
      />
    </div>
  )
}
