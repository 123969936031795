import { Button, Card } from 'semantic-ui-react'
import { FormikProvider, useFormik } from 'formik'
import { toast } from 'react-toastify'
import React, { useState } from 'react'
import * as Yup from 'yup'

import { TextField, MaskedTextField } from '../../components/FormikInput'
import axios from '../../axios'
import styled from '@emotion/styled'

const NDButton = styled(Button)`
  width: 100% !important;
  color: white !important;
  background-color: #f06f30 !important;
`
const Titulo = styled('h2')`
  text-align: center;
`

interface FormularioProps {
  setSubmited: (state: boolean) => any
  setBoleto: (boleto: { url: string }) => any
}
const Formulario: React.FC<FormularioProps> = (props) => {
  const { setSubmited, setBoleto } = props
  const [loading, setLoading] = React.useState(false)

  const FormularioSchema = Yup.object().shape({
    nome: Yup.string().required('O nome é obrigatório'),
    cpf: Yup.string().min(14, 'CPF inválido').required('O cpf é obrigatório'),
    dataNascimento: Yup.string().required('A data de nascimento é obrigatória'),
    celular: Yup.string().required('Celular é obrigatório'),
    email: Yup.string().email('Email inválido').required('O Email é obrigatório'),
    senha: Yup.string().min(8, 'Senha muito curta').required('A Senha é obrigatória'),
    senhac: Yup.string()
      .min(8, 'Confirmação de senha muito curta')
      .oneOf([Yup.ref('senha'), null], 'As senhas precisam ser iguais')
      .required('A Confirmação de Senha é obrigatória'),
  })

  const formik = useFormik({
    initialValues: {
      nome: '',
      cpf: '',
      dataNascimento: '',
      email: '',
      celular: '',
      senha: '',
      senhac: '',
    },
    validationSchema: FormularioSchema,
    onSubmit: async (values) => {
      const dataNascimentoRegex = /(\d{2})\/(\d{2})\/(\d{4})/g
      const [, dia, mes, ano] = dataNascimentoRegex.exec(values.dataNascimento)
      const usuario = {
        nome: values.nome,
        cpf: values.cpf,
        dataNascimento: `${ano}-${mes}-${dia}`,
        email: values.email,
        senha: values.senha,
        celular: values.celular,
      } as any

      try {
        setLoading(true)
        const result = await axios.UsuariosCursos.postMatricular(usuario)
        setLoading(false)
        toast.success('Aluno matriculado com sucesso')
        setSubmited(true)
        console.log({ result })
        setBoleto(result.boleto)
      } catch (e) {
        setLoading(false)
        toast.warn(e.message)
      }
    },
  })

  return (
    <>
      <Titulo>Matricular Aluno</Titulo>
      <FormikProvider value={formik}>
        <form onSubmit={formik.handleSubmit}>
          <TextField
            label="Nome"
            fluid
            name="nome"
            placeholder="Nome"
            className="input-uppercase"
          />
          <br />
          <MaskedTextField label="CPF" mask="cpf" fluid name="cpf" placeholder="CPF" />
          <br />
          <MaskedTextField
            label="Data de nascimento"
            mask="data"
            fluid
            name="dataNascimento"
            placeholder="Data de nascimento"
          />
          <br />
          <TextField fluid label="E-mail" name="email" placeholder="E-mail" />
          <br />
          <MaskedTextField
            label="Celular"
            mask="celular"
            fluid
            name="celular"
            placeholder="Celular"
          />
          <br />
          <TextField type="password" fluid label="Senha" name="senha" placeholder="Senha" />
          <br />
          <TextField
            type="password"
            fluid
            label="Confirmar senha"
            name="senhac"
            placeholder="Digite novamenteo sua senha"
          />
          <br />
          <div style={{ display: 'table', width: '100%' }}>
            <div style={{ display: 'inline-block', float: 'right' }}>
              <NDButton fluid type="submit" loading={loading}>
                Matricular
              </NDButton>
            </div>
          </div>
        </form>
      </FormikProvider>
    </>
  )
}

const DownloadBoleto = ({ boleto }) => {
  const onClick = () => window.open(boleto.url)
  return (
    <>
      <Titulo> Aluno Matriculado </Titulo>
      <div>
        <NDButton fluid onClick={onClick}>
          Baixar Boleto
        </NDButton>
      </div>
    </>
  )
}

const BackgroundGrandient = styled('div')`
  background: hsla(32, 100%, 57%, 1);
  background: linear-gradient(90deg, hsla(32, 100%, 57%, 1) 0%, hsla(20, 86%, 56%, 1) 100%);
  background: -moz-linear-gradient(90deg, hsla(32, 100%, 57%, 1) 0%, hsla(20, 86%, 56%, 1) 100%);
  background: -webkit-linear-gradient(90deg, hsla(32, 100%, 57%, 1) 0%, hsla(20, 86%, 56%, 1) 100%);
  filter: progid: DXImageTransform.Microsoft.gradient( startColorstr="#FF9722", endColorstr="#F06F30", GradientType=1 );
  width: 100%;
  height: 100vh;
  position: absolute;
`

const FormCard = styled(Card)`
  width: 550px !important;
  margin-top: 100px !important;
`

export const MatricularAluno: React.FC = () => {
  const [submited, setSubmited] = useState(false)
  const [boleto, setBoleto] = useState(null)
  if (submited) {
    return (
      <BackgroundGrandient>
        <FormCard style={{ marginTop: '20px' }} centered>
          <Card.Content>
            <DownloadBoleto boleto={boleto} />
          </Card.Content>
        </FormCard>
      </BackgroundGrandient>
    )
  }

  return (
    <BackgroundGrandient>
      <FormCard style={{ marginTop: '20px' }} centered>
        <Card.Content>
          <Formulario setSubmited={setSubmited} setBoleto={setBoleto} />
        </Card.Content>
      </FormCard>
    </BackgroundGrandient>
  )
}
