import { MatriculaModel } from './MatriculaModel'
import { PermissaoModel } from './PermissaoModel'

export class UsuarioModel {
  id: number
  nome: string
  cpf: string
  avatar: string
  email: string
  perfil: string
  cor: string
  betaTester: boolean
  permissoes: PermissaoModel[]
  rg: string
  dataNascimento: Date
  telefone: string
  senha: string
  token: string
  matriculas: MatriculaModel[]
  userDeletionId: number
  ignorarValidacaoSerpro: boolean
  dataPrimeiraHabilitacao: Date
  estado: string
  municipio: string
  cnh: string
  categoriaCnh: string
}
