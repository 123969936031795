import { Button, Icon, Input, Loader, Modal, Popup } from 'semantic-ui-react'
import { Formik } from 'formik'
import { useParams } from 'react-router'
import InputMask from 'react-input-mask'
import React, { useContext, useState } from 'react'
import * as fns from 'date-fns'
import styled from '@emotion/styled'
import { toast } from 'react-toastify'

import { ConteudoItemModel } from '../../models/ConteudoItemModel'
import { CustomEditor } from '../../components/CustomEditor/CustomEditor'
import { Fieldset, DisplayFlex, Flex } from '../../components/Styles'
import { QuestaoEditor } from '../../components/QuestaoEditor/QuestaoEditor'
import { QuestaoModel } from '../../models/QuestaoModel'
import { SimpleInput } from '../../components/SimpleInput'
import { TIPOS_CONTEUDO } from '../../components/Constants'
import { filesUrl } from '../..'
import { EditorConteudoStore } from './EditorConteudoStore'
import axios from '../../axios'
import 'react-toastify/dist/ReactToastify.css'
import { QuestaoVisualizacao } from './elements/questao-visualizacao/QuestaoVisualizacao'
import { VideoHls } from './elements/VideoHls'
import { useDecorateBlobWithSas } from '../../components/UseDecorateBlobWithSas'
import { Util } from '../../components/Util'

interface IProps {
  conteudo: ConteudoItemModel
}

const SEMANA = ['Dom', 'Seg', 'Ter', 'Qua', 'Qui', 'Sex', 'Sab']

export const ConteudoVisualizacao = ({ conteudo }: IProps): any => {
  const { paginaAtiva } = EditorConteudoStore.useState()
  const dispatch = EditorConteudoStore.useDispatch()

  async function handleDoubleClick() {
    if (!(await Util.confirm('Deseja entrar no modo de edição?'))) return
    dispatch(
      EditorConteudoStore.thunks.novaPaginaRascunho(
        paginaAtiva.moduloId,
        paginaAtiva.identificadorUnico
      )
    )
  }

  return (
    <div onDoubleClick={handleDoubleClick}>
      {conteudo.tipo === TIPOS_CONTEUDO.TITULO && <Titulo conteudo={conteudo} />}
      {conteudo.tipo === TIPOS_CONTEUDO.SUBTITULO && <Subtitulo conteudo={conteudo} />}
      {conteudo.tipo === TIPOS_CONTEUDO.TEXTO && <Texto conteudo={conteudo} />}
      {conteudo.tipo === TIPOS_CONTEUDO.QUESTAO && <Questao conteudo={conteudo} />}
      {conteudo.tipo === TIPOS_CONTEUDO.IMAGEM && <Imagem conteudo={conteudo} />}
      {conteudo.tipo === TIPOS_CONTEUDO.VIDEO && <Video conteudo={conteudo} />}
      {conteudo.tipo === TIPOS_CONTEUDO.LIVE && <Live conteudo={conteudo} />}
    </div>
  )
}

const Titulo = ({ conteudo = {} as ConteudoItemModel }) => {
  return <Styles.Titulo>{conteudo.conteudo}</Styles.Titulo>
}

const Subtitulo = ({ conteudo = {} as ConteudoItemModel }) => {
  return <Styles.Subtitulo>{conteudo.conteudo}</Styles.Subtitulo>
}

const Texto = ({ conteudo = {} as ConteudoItemModel }) => {
  return <Styles.Texto dangerouslySetInnerHTML={{ __html: conteudo.conteudo }} />
}
const Questao = ({ conteudo = {} as ConteudoItemModel }) => {
  return (
    <Styles.Questao>
      <QuestaoVisualizacao questaoId={conteudo.conteudo} />
    </Styles.Questao>
  )
}

const Imagem = ({ conteudo = {} as ConteudoItemModel }) => {
  const imagem = useDecorateBlobWithSas({ blobPath: conteudo.conteudo })
  return (
    <Styles.Imagem>
      <img src={imagem} />
    </Styles.Imagem>
  )
}

const Video = ({ conteudo = {} as ConteudoItemModel }) => {
  return (
    <Styles.Video>
      <VideoHls url={conteudo.conteudo} />
    </Styles.Video>
  )
}

const Live = ({ conteudo = {} as ConteudoItemModel }) => {
  return <Styles.Live>{conteudo.conteudo}</Styles.Live>
}

const Styles = {
  Titulo: styled('div')`
    font-size: 35pt;
    line-height: 1.5em;
    font-family: Rubik;
    font-weight: bold;
    border-bottom: 3px solid var(--primary-color);
    text-align: left;
    @media (max-width: 425px) {
      font-size: 1.8em;
      line-height: 1.4em;
    }
  `,
  Subtitulo: styled('div')`
    font-size: 24pt;
    line-height: 1.5em;
    font-family: Rubik;
    font-weight: bold;
    @media (max-width: 425px) {
      font-size: 1.3em;
      line-height: 1.2em;
    }
  `,
  Texto: styled('div')`
    font-size: 16pt;
    line-height: 1.5em;
    font-family: 'Noto Serif KR', serif;
    font-family: 'Rubik', serif;
    color: #222;
    text-align: justify;
    li {
      list-style: none;
    }
    li:before {
      content: ' ';
      background: var(--primary-color);
      width: 10px;
      height: 10px;
      display: inline-block;
      border-radius: 50%;
      margin-left: -20px;
      margin-right: 10px;
    }
  `,
  Questao: styled('div')``,
  Imagem: styled('div')`
    img {
      margin: 0 auto;
      max-width: 100%;
      display: block;
    }
  `,
  Video: styled('div')``,
  Live: styled('div')``,
}
