import { Button, Modal } from 'semantic-ui-react'
import { useParams } from 'react-router'
import React, { useEffect, useState } from 'react'
import styled from '@emotion/styled'

import { ConteudoContainer } from './ConteudoContainer'
import { CursoModel } from '../../models/CursoModel'
import { DisplayFlex, Padding, Table, styles } from '../../components/Styles'
import { Hide, Show } from '../../components/Basic'
import { TIPOS_CONTEUDO } from '../../components/Constants'
import { EditorConteudoStore, selectConteudo } from './EditorConteudoStore'
import axios from '../../axios'
import { useDrop } from 'react-dnd'
import { ConteudoItemModel } from '../../models/ConteudoItemModel'

const Pagina = ({ ...props }: React.HTMLAttributes<HTMLDivElement>) => {
  const state = EditorConteudoStore.useState()
  const dispatch = EditorConteudoStore.useDispatch()
  const { moduloId } = useParams<any>()
  const { paginaAtiva } = state
  const conteudos = selectConteudo(state)

  useEffect(() => {
    if (paginaAtiva && !conteudos.length) {
      dispatch(EditorConteudoStore.thunks.getPaginaConteudo(paginaAtiva.id))
    }
  }, [conteudos])

  if (!conteudos.length) return <>Carregando ...</>

  const novoConteudo = (conteudo, tipoConteudo?) => {
    const tipo = tipoConteudo || conteudos[conteudos.length - 1].tipo
    dispatch(EditorConteudoStore.thunks.addConteudo(moduloId, paginaAtiva.id, tipo, conteudo))
  }

  return (
    <Styles.Container {...props} isVisualizacao={paginaAtiva.status === 'PUBLICADO'}>
      {conteudos.map((conteudo) => (
        <React.Fragment key={conteudo.id}>
          <div>
            <ConteudoContainer conteudo={conteudo} />
          </div>
          <div>
            <Droppable conteudo={conteudo} />
          </div>
        </React.Fragment>
      ))}

      <Show
        condition={
          (paginaAtiva.status === 'RASCUNHO' || paginaAtiva.status === 'NOVO') &&
          paginaAtiva.conteudosItens.length >= 1
        }
      >
        <div style={{ textAlign: 'center' }}>
          <DisplayFlex center={true} marginBetween={12}>
            <Button circular icon="plus" onClick={() => novoConteudo('')}></Button>
          </DisplayFlex>
        </div>
      </Show>
    </Styles.Container>
  )
}

const Droppable = ({ conteudo }: { conteudo: ConteudoItemModel }) => {
  const [{ isActive }, drop] = useDrop({
    accept: 'CONTEUDO',
    drop: () => ({ conteudo }),
    collect: (monitor) => ({
      isActive: monitor.canDrop() && monitor.isOver(),
    }),
  })
  return <Styles.Droppable ref={drop} active={isActive} />
}

const Styles = {
  Container: styled('div')<{ isVisualizacao }>(
    (props) => `
    ${
      props.isVisualizacao
        ? `
      padding: 0 16px;
    `
        : ''
    }
  `
  ),
  Droppable: styled('div')<{ active }>(
    (props) => `
    height: 8px;
    padding: 2px 44px 2px 44px;
    &::after {
      content: ' ';
      width: 100%;
      height: 100%;
      display: block;
      ${props.active ? 'background: #29b6f6' : ''}
    }
  `
  ),
}

export { Pagina }
