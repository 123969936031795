import { Modal } from 'antd'

export const ConfirmAlert = ({
  title = 'Confirmação de exclusão',
  content = 'Tem certeza que deseja excluir este item?' as any,
  cancelText = 'Cancelar',
  okText = 'Sim',
}) => {
  return new Promise((resolve, reject) => {
    Modal.confirm({
      title,
      content,
      cancelText,
      okText,
      onOk: () => resolve(true),
      onCancel: () => reject(false),
    })
  })
}
