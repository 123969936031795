import { Button, Icon, Input, Loader, Modal, Popup } from 'semantic-ui-react'
import { Formik } from 'formik'
import { useParams } from 'react-router'
import InputMask from 'react-input-mask'
import React, { useContext, useState, useEffect } from 'react'
import * as fns from 'date-fns'
import styled from '@emotion/styled'
import { toast } from 'react-toastify'

import { ConteudoItemModel } from '../../models/ConteudoItemModel'
import { CustomEditor, EditorPreview } from '../../components/CustomEditor/CustomEditor'
import { Fieldset, DisplayFlex, Flex } from '../../components/Styles'
import { QuestaoEditor } from '../../components/QuestaoEditor/QuestaoEditor'
import { QuestaoModel } from '../../models/QuestaoModel'
import { SimpleInput } from '../../components/SimpleInput'
import { TIPOS_CONTEUDO } from '../../components/Constants'
import { filesUrl } from '../..'
import { EditorConteudoStore } from './EditorConteudoStore'
import axios from '../../axios'
import 'react-toastify/dist/ReactToastify.css'
import useAxios from 'axios-hooks'
import { useDecorateBlobWithSas } from '../../components/UseDecorateBlobWithSas'
import { ContainersArquivos, Util } from '../../components/Util'

interface IProps extends React.HTMLAttributes<HTMLDivElement> {
  conteudo: ConteudoItemModel
}

const SEMANA = ['Dom', 'Seg', 'Ter', 'Qua', 'Qui', 'Sex', 'Sab']

export const ConteudoEdicao = ({ conteudo, ...props }: IProps): any => {
  const { moduloId } = useParams<any>()

  return (
    <div {...props}>
      {(conteudo.tipo === TIPOS_CONTEUDO.TITULO || conteudo.tipo === TIPOS_CONTEUDO.SUBTITULO) && (
        <TituloSubtitulo moduloId={moduloId} conteudo={conteudo} />
      )}
      {conteudo.tipo === TIPOS_CONTEUDO.TEXTO && <Texto moduloId={moduloId} conteudo={conteudo} />}
      {conteudo.tipo === TIPOS_CONTEUDO.QUESTAO && (
        <Questao moduloId={moduloId} conteudo={conteudo} />
      )}
      {conteudo.tipo === TIPOS_CONTEUDO.IMAGEM && (
        <Imagem moduloId={moduloId} conteudo={conteudo} />
      )}
      {conteudo.tipo === TIPOS_CONTEUDO.VIDEO && <Video moduloId={moduloId} conteudo={conteudo} />}
      {conteudo.tipo === TIPOS_CONTEUDO.LIVE && <Live moduloId={moduloId} conteudo={conteudo} />}
    </div>
  )
}

const TituloSubtitulo = (props: { conteudo: ConteudoItemModel; moduloId: string }) => {
  const dispatch = EditorConteudoStore.useDispatch()
  const [value, setValue] = useState(props.conteudo.conteudo)
  const { updateConteudo } = EditorConteudoStore.thunks
  useEffect(() => {
    setValue(props.conteudo.conteudo)
  }, [props.conteudo.conteudo])

  const placeholder =
    props.conteudo.ordenacao <= 1
      ? 'Título obrigatório'
      : `Informe o ${props.conteudo.tipo === 'TITULO' ? 'título' : 'subtítulo'}`

  function handleBlur(e: React.ChangeEvent<HTMLInputElement>) {
    const { value } = e.target
    const conteudoId = props.conteudo.id
    dispatch(updateConteudo(conteudoId, value))
  }

  return (
    <Styles.InputTitulo
      tipo={props.conteudo.tipo}
      placeholder={placeholder}
      value={value}
      onChange={(e) => setValue(e.target.value)}
      onBlur={handleBlur}
    />
  )
}

const Styles = {
  InputTitulo: styled('input')<{ tipo }>(
    (props) => `
    font-size: ${props.tipo === 'TITULO' ? '2.2em' : '1.6em'};
    background-color: #F2F2F2;
    width: 100%;
    padding: 8px 12px;
    border: 0;
  `
  ),
  QuestaoContainer: styled('div')`
    background: #f2f2f2;
    padding: 8px 12px;
    .enunciado {
      font-size: 1.4em;
      margin: 6px 3px;
    }
  `,
}

const Texto = (props: { conteudo: ConteudoItemModel; moduloId: string }) => {
  const dispatch = EditorConteudoStore.useDispatch()
  const { Editor } = Texto
  const { updateConteudo } = EditorConteudoStore.thunks
  const handleBlur = (e: any) => {
    Array.from(document.querySelectorAll('.container-conteudo')).forEach((x) =>
      x.classList.remove('has-inner-focus')
    )
    dispatch(updateConteudo(props.conteudo.id, e.currentTarget.innerHTML))
  }
  const handleFocus = () => {
    Array.from(document.querySelectorAll('.container-conteudo')).forEach((x) =>
      x.classList.add('has-inner-focus')
    )
  }

  return <Editor text={props.conteudo.conteudo || ''} onFocus={handleFocus} onBlur={handleBlur} />
}
Texto.Editor = styled(CustomEditor)`
  border: 0;
  background: #f2f2f2;
  [contenteditable] {
    padding: 8px 12px;
    color: #2d2d2d;
  }
`

const Imagem = (props: { conteudo: ConteudoItemModel; moduloId: string }) => {
  const dispatch = EditorConteudoStore.useDispatch()
  const { updateConteudo } = EditorConteudoStore.thunks

  const imagemPadrao = require('../../assets/imagem-placeholder.jpg')
  const handleAlterarImagem = async () => {
    const imagem: any = await Util.requestUploadImagem({
      containerName: ContainersArquivos.CONTEUDO,
    })
    dispatch(updateConteudo(props.conteudo.id, imagem))
  }
  const imagem = useDecorateBlobWithSas({ blobPath: props.conteudo.conteudo })
  return (
    <ImageContainer>
      <div style={{ textAlign: 'right' }}>
        <Button onClick={handleAlterarImagem} style={{ margin: '0 0 4px 0' }}>
          Alterar Imagem
        </Button>
      </div>
      <img src={imagem} style={{ maxWidth: '100%' }} />
    </ImageContainer>
  )
}

const Questao = (props: { conteudo: ConteudoItemModel; moduloId: string }) => {
  const dispatch = EditorConteudoStore.useDispatch()
  const [{ data: questao }] = useAxios<QuestaoModel>(
    `/questoes/${props.conteudo.conteudo}?lastUpdate=${props.conteudo.updatedAt}`
  )

  if (!questao) return null

  const labelTipoQuestao = {
    RELACIONAR_ITENS: (
      <>
        <strong>Questão</strong> de relacionar uma alternativa a outra
      </>
    ),
    MULTIPLO_VERDADEIRO_FALSO: (
      <>
        <strong>Questão</strong> de verdadeiro ou falso
      </>
    ),
    MULTIPLA_ESCOLHA: (
      <>
        <strong>Questão</strong> de múltipla escolha
      </>
    ),
  }[questao.tipoQuestao]

  function handleClickEditar() {
    dispatch(
      EditorConteudoStore.actions.openQuestaoEdicao({
        tipoQuestao: questao.tipoQuestao,
        questaoId: questao.id,
        conteudoId: props.conteudo.id,
      })
    )
  }

  return (
    <Styles.QuestaoContainer>
      <DisplayFlex center={true} spacing="between">
        <div>
          <div> {labelTipoQuestao} </div>
          <EditorPreview html={questao.textoQuestao} />
          <div>
            {' '}
            Quantidade de alternativas: <strong>{questao.questoesAlternativas?.length}</strong>{' '}
          </div>
        </div>
        <div>
          <Button size="big" icon>
            <Icon name="edit" onClick={handleClickEditar} />
          </Button>
        </div>
      </DisplayFlex>
    </Styles.QuestaoContainer>
  )
}

const Live = (props: { conteudo: ConteudoItemModel; moduloId: string }) => {
  const dispatch = EditorConteudoStore.useDispatch()
  const { updateConteudo } = EditorConteudoStore.thunks
  const { paginaAtiva } = EditorConteudoStore.useState()

  const visualizarLive = () => {
    window.open(props.conteudo.conteudo, '_blank')
  }

  const handleLive = async () => {
    const dados = (await axios.Live.iniciar(props.moduloId, props.conteudo.id)) as any
    window.open(dados.urlAula)
  }

  if (props.conteudo.configuracao) {
    let config = props.conteudo.configuracao as any
    config = JSON.parse(config)
    const data = fns.format(new Date(config.data), 'dd/MM/yyyy')
    const { inicio, fim } = config
    return (
      <div>
        <div>
          <Titulo>Aula ao vivo </Titulo>
          {`${data} ${inicio}-${fim}`}
        </div>
        <div>
          <Button onClick={handleLive}>Iniciar Aula</Button>
        </div>
      </div>
    )
  }

  return (
    <div>
      <ModalLive moduloId={props.moduloId} conteudo={props.conteudo} />
      <Titulo>Aula ao vivo</Titulo>
    </div>
  )
}

const ModalLive = (props) => {
  const dispatch = EditorConteudoStore.useDispatch()
  const [dataAtiva, setDataAtiva] = useState(null)
  const segunda = fns.setDay(new Date(), 1)
  const datas = new Array(8).fill(segunda).map((data, i) => fns.addDays(data, i))

  const tipoDia = {
    indisponivel: {
      background: '#EEEEEE',
      cursor: 'default',
      color: '#A0A4A8',
    },
    disponivel: {
      background: '#FFFFFF',
      color: '#2D2D2D',
    },
    ativo: {
      background: '#8436EB',
      color: '#FFFFFF',
    },
  }

  const handleCancelar = (e) => {
    e.preventDefault()
    dispatch(EditorConteudoStore.thunks.deleteConteudo(props.conteudo.id))
  }

  const handleSubmit = (values) => {
    if (!dataAtiva) {
      toast.warn('Escolha a data data Live')
      return
    }
    if (!values.inicio || !values.fim) {
      toast.warn('Informe o horário da Live')
      return
    }
    dispatch(
      EditorConteudoStore.thunks.addLive(props.moduloId, props.conteudo.id, {
        ...values,
        data: dataAtiva,
      })
    )
  }

  return (
    <Modal closeIcon onClose={handleCancelar} open={!props.conteudo.configuracao}>
      <Modal.Header>Aula ao vivo - live</Modal.Header>
      <Modal.Content>
        <Formik
          initialValues={{
            inicio: '',
            fim: '',
          }}
          onSubmit={handleSubmit}
        >
          {({ values, handleChange, handleSubmit }) => (
            <form onSubmit={handleSubmit}>
              <Container>
                <DescricaoModal>
                  Programe a data e horário que a aula ao vivo acontecerá.
                </DescricaoModal>
                <div>
                  <TituloSessao>Data da aula:</TituloSessao>
                  <DataContainer>
                    {datas.map((data) => {
                      const _data = fns.startOfDay(data)
                      const hoje = fns.startOfDay(new Date())
                      let status = 'disponivel'

                      const isActive = dataAtiva && fns.isEqual(_data, dataAtiva)
                      const isToday = fns.getDate(_data) === fns.getDate(hoje)
                      const isPast = fns.isAfter(hoje, _data)

                      const alinhamentoDia = fns.getDate(_data) < 10 ? '5px' : '0px'
                      const alinhamentoHoje = isToday ? '-5px' : '2px'

                      const background = isPast ? '#EEEEEE' : '#FFFFFF'

                      const label = SEMANA[fns.getDay(_data)]

                      if (isPast) {
                        status = 'indisponivel'
                      }

                      if (isActive) {
                        status = 'ativo'
                      }

                      const onClick = () => {
                        if (!isPast) {
                          setDataAtiva(_data)
                        }
                      }

                      return (
                        <CardData
                          onClick={onClick}
                          key={fns.getDate(_data)}
                          style={tipoDia[status]}
                        >
                          <div>
                            <CardDiaNumero style={{ marginLeft: alinhamentoDia }}>
                              {fns.getDate(_data)}
                            </CardDiaNumero>
                            <CardDiaSemana style={{ marginLeft: alinhamentoHoje }}>
                              {isToday ? 'HOJE' : label}
                            </CardDiaSemana>
                          </div>
                        </CardData>
                      )
                    })}
                  </DataContainer>
                </div>
                <div>
                  <TituloSessao>Duração da aula:</TituloSessao>
                  <InputContainer>
                    <div>
                      <InputLabel> Início: </InputLabel>
                      <InputMask
                        mask="99:99"
                        name="inicio"
                        onChange={handleChange}
                        value={values.inicio}
                      >
                        {(props) => {
                          return (
                            <Input
                              name={props.name}
                              icon={{ name: 'clock outline', className: 'colorPrimary' }}
                            />
                          )
                        }}
                      </InputMask>
                    </div>
                    <div>
                      <InputLabel> Fim: </InputLabel>
                      <InputMask mask="99:99" name="fim" onChange={handleChange} value={values.fim}>
                        {(props) => {
                          return <Input name={props.name} icon="clock outline" />
                        }}
                      </InputMask>
                    </div>
                  </InputContainer>
                </div>
                <ControlsContainer>
                  <Button basic onClick={handleCancelar}>
                    {' '}
                    Cancelar{' '}
                  </Button>
                  <Button primary> Salvar </Button>
                </ControlsContainer>
              </Container>
            </form>
          )}
        </Formik>
      </Modal.Content>
    </Modal>
  )
}

const Video = ({ conteudo, moduloId }: { conteudo: ConteudoItemModel; moduloId: string }) => {
  const dispatch = EditorConteudoStore.useDispatch()
  const [value, setValue] = useState(conteudo?.conteudo ?? '')
  useEffect(() => {
    setValue(conteudo?.conteudo ?? '')
  }, [conteudo?.conteudo])

  useEffect(() => {
    if (conteudo?.duracao === 0) {
      _getDuracaoVideo(conteudo.conteudo).then((duracao) => {
        dispatch(EditorConteudoStore.thunks.updateDuracaoConteudo(conteudo.id, duracao))
      })
    }
  }, [conteudo?.duracao])

  async function _getDuracaoVideo(videoUrl): Promise<number> {
    return await new Promise((resolve) => {
      const h = new window.Hls()
      h.loadSource(videoUrl)
      h.on(window.Hls.Events.MANIFEST_PARSED, () => {
        h.startLoad()
      })
      h.on(window.Hls.Events.LEVEL_LOADED, (_: any, data: any) => {
        h.stopLoad()
        const duracao = +data.details.totalduration
        resolve(Math.round(duracao))
      })
    })
  }

  return (
    <VideoContainer>
      Vídeo
      <DisplayFlex marginBetween={12}>
        <Flex>
          <input
            style={{ width: '100%' }}
            onBlur={(e) => {
              dispatch(EditorConteudoStore.thunks.updateConteudo(conteudo.id, e.target.value))
            }}
            value={value}
            onChange={(e) => setValue(e.target.value)}
          />
        </Flex>
      </DisplayFlex>
      {conteudo.conteudo && (
        <div>Duração (em segundos): {conteudo.duracao || <Loader active inline />}</div>
      )}
    </VideoContainer>
  )
}

const ImageContainer = styled('div')`
  text-align: center;
  background: #f2f2f2;
  padding: 8px;
`

const TituloSessao = styled('span')`
  font-weight: 700;
  margin-bottom: 24px;
`

const DescricaoModal = styled('span')`
  font-size: 14px;
  font-weight: 700;
  color: #a0a4a8;
`

const Container = styled('div')`
  display: grid;
  grid-row-gap: 26px;
`

const DataContainer = styled('div')`
  display: grid;
  grid-template-columns: repeat(8, auto);
  grid-column-gap: 10px;
  cursor: pointer;
`

const ControlsContainer = styled('div')`
  display: grid;
  justify-items: end;
  grid-template-columns: auto max-content max-content;
  grid-column-gap: 10px;
`

const CardData = styled('div')`
  display: grid;
  align-items: center;
  justify-items: center;
  width: 90px;
  height: 89px;
  border: 1px solid #dbdde0;
  border-radius: 4px;
`

const CardDiaNumero = styled('div')`
  font-weight: normal;
  font-size: 24px;
`
const CardDiaSemana = styled('div')`
  font-weight: normal;
  margin-left: 2px;
  font-size: 14px;
`

const InputContainer = styled('div')`
  display: grid;
  grid-template-columns: max-content max-content;
  grid-column-gap: 10px;
`

const InputLabel = styled('span')`
  font-weight: bold;
  margin-right: 5px;
`

const Titulo = styled('span')`
  font-weight: 700;
`

const VideoContainer = styled('div')`
  background-color: #f3f6fd;
  border: '1px solid #EEEEEE';
`
