import * as React from 'react'
import styled from '@emotion/styled'
import { DisplayFlex, Flex } from './Styles'
import { Select as SelectComp, Icon } from 'semantic-ui-react'
import { IQueryParams } from '../axios'
const Select: any = SelectComp

interface IProps {
  onChangePage: (param: IQueryParams) => void
  limit: number
  offset: number
  count: number
}

export class Pagination extends React.Component<IProps, {}> {
  constructor(props: IProps) {
    super(props)
    this.state = { linhasPorPagina: 10 }
  }

  handlePrevPage = () => {
    if (this.props.offset <= 0) return
    this.props.onChangePage({ offset: this.props.offset - this.props.limit })
  }

  handleNextPage = () => {
    if (this.props.limit + this.props.offset >= this.props.count) return
    this.props.onChangePage({ offset: this.props.offset + this.props.limit })
  }

  handleChangePageSize = (_, pro) => {
    this.props.onChangePage({ limit: pro.value, offset: 0 })
  }

  render() {
    const { limit, offset, count } = this.props
    return (
      <Container>
        <DisplayFlex center={true} marginBetween={8} style={{ marginTop: '4px' }}>
          <Flex />
          <div>Linhas por Página</div>
          <Select
            style={{
              minWidth: '60px !important',
              border: 'none !important',
            }}
            options={[
              {
                key: 5,
                value: 5,
                text: 5,
              },
              {
                key: 10,
                value: 10,
                text: 10,
              },
              {
                key: 25,
                value: 25,
                text: 25,
              },
            ]}
            value={this.props.limit}
            onChange={this.handleChangePageSize}
          />
          {count ? offset + 1 : 0} - {Math.min(limit + offset, count)} de {count}
          <ButtonIcon
            style={{ padding: '9px 16px' }}
            disabled={offset <= 0}
            onClick={this.handlePrevPage}
          >
            <Icon name="angle left" />
          </ButtonIcon>
          <ButtonIcon
            style={{ padding: '9px 19px' }}
            disabled={limit + offset >= count}
            onClick={this.handleNextPage}
          >
            <Icon name="angle right" />
          </ButtonIcon>
        </DisplayFlex>
      </Container>
    )
  }
}

const Container = styled('div')`
  padding: 0 8px 8px 0;
`

const ButtonIcon = styled('div')<{ disabled: boolean }>`
  height: 48px;
  width: 48px;
  border-radius: 50%;
  transition: 0.25s background;
  cursor: pointer;
  ${(props: any) => (props.disabled ? 'opacity: .5;' : 'opacity: 1;')}
  &:hover {
    background: rgba(0, 0, 0, 0.085);
  }
  i {
    font-size: 2em;
    line-height: 1em;
    width: 0;
  }
`
