import { createSlice, PayloadAction } from '@reduxjs/toolkit'

import axios, { IQueryParams, ICounter } from '../../axios'
import { toast } from 'react-toastify'
import { UsuarioModel } from '../../models/UsuarioModel'
import { createSimpleStore } from 'react-simple-reducer'
import { MatriculaModel } from '../../models/MatriculaModel'

export const initialState = {
  isencoes: { data: [], totalRecords: 0, totalPages: 0, currentPage: 0, recordsPerPage: 0 },
  loading: false,
  query: { limit: 10, offset: 0 },
  usuariosCount: 0,
}

export const IsencaoStore = createSimpleStore(
  initialState,
  {
    setIsencoes(state, payload: any) {
      state.isencoes = payload
    },
    setLoading(state, payload: boolean) {
      state.loading = payload
    },
  },
  {
    thunks: {
      getIsencoes(valuesFilter) {
        return async (dispatch, getState) => {
          try {
            dispatch(IsencaoStore.actions.setLoading(true))
            const alunos = await axios.Isencao.getIsencoes(valuesFilter)
            dispatch(IsencaoStore.actions.setLoading(false))
            dispatch(IsencaoStore.actions.setIsencoes(alunos))
          } catch (error) {
            dispatch(IsencaoStore.actions.setLoading(false))
          }
        }
      },
    },
  }
)
