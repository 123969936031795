import { useContext } from 'react'
import axios from 'axios'

import { ChatMensagemModel } from './models/ChatMensagemModel'
import { ChatModel } from '../src/models/ChatModel'
import { ConteudoItemModel } from './models/ConteudoItemModel'
import { ConteudoPaginaModel } from './models/ConteudoPaginaModel'
import { CursoModel } from './models/CursoModel'
import { ForumMensagemModel } from './models/ForumMensagemModel'
import { ForumModel } from './models/ForumModel'
import { ModuloModel } from './models/ModuloModel'
import { ProgressoModel } from './models/ProgressoModel'
import { QuestaoModel } from './models/QuestaoModel'
import { UsuarioModel } from './models/UsuarioModel'
import { apiUrl } from './index'
import { DownloadModel } from './models/DownloadModel'

export interface IQueryParams {
  order?: 'ASC' | 'DESC'
  orderBy?: string
  orderByEntity?: string
  offset?: number
  limit?: number
}

export interface ICounter<T> {
  count: number
  rows: T[]
}

const handleErrors = (err: any) => {
  if (err && err.response && err.response.status === 401) {
    _setError('Você não tem permissão para acessar esse recurso')
  }
  if (err.response && err.response.data && err.response.data.message)
    err.message = err.response.data.message
  throw err
}

const responseBody = (res: any) => {
  return res.data
}

const getHeaders = () => {
  const token = localStorage.getItem('token')
  if (token) return { Authorization: `Bearer ${token}` }
  return {}
}
let _setError: any
const requests = {
  delete: async (url: string, options?: any) => {
    return await axios
      .delete(apiUrl + url, { headers: getHeaders(), ...options })
      .then(responseBody)
      .catch(handleErrors)
  },
  get: async (url: string, params?: any) => {
    return await axios
      .get(apiUrl + url, { params, headers: getHeaders() })
      .then(responseBody)
      .catch(handleErrors)
  },
  put: async (url: string, body: any) => {
    return await axios
      .put(apiUrl + url, body, { headers: getHeaders() })
      .then(responseBody)
      .catch(handleErrors)
  },
  post: async (url: string, body: any, upload = false) => {
    const headers: any = getHeaders()
    if (upload) headers['Content-Type'] = 'multipart/form-data'
    return await axios
      .post(apiUrl + url, body, { headers })
      .then(responseBody)
      .catch(handleErrors)
  },
}

const Usuarios = {
  login: async (cpf: string, senha: string): Promise<{ token: string }> =>
    await requests.post('/usuarios/login', { cpf, senha }),
  validateSenha: async (senha: string) =>
    await requests.post('/usuarios/validate-senha', { senha }),
  revalidaToken: async (usuarioId: number): Promise<{ token: string }> =>
    await requests.get(`/usuarios/revalida-token/${usuarioId}`),
  getByConta: async (contaId: number): Promise<UsuarioModel[]> =>
    await requests.get(`/usuarios/conta/${contaId}`),
  createOrUpdate: async (usuario: UsuarioModel): Promise<UsuarioModel> =>
    await requests.post('/usuarios', usuario),
  delete: async (id: string | number): Promise<UsuarioModel[]> =>
    await requests.delete(`/usuarios/${id}`),
  getByCpf: async (cpf: string): Promise<UsuarioModel> =>
    await requests.get(`/usuarios/cpf/${cpf}`),
  updateSenha: async (
    usuarioId: number,
    senha: string,
    { senhaAtual, token }: { senhaAtual?: string; token?: string }
  ): Promise<boolean> =>
    await requests.put('/usuarios/senha', { usuarioId, senha, senhaAtual, token }),
  getByToken: async (token: string): Promise<UsuarioModel> =>
    await requests.get(`/usuarios/token/${token}`),
  createTokenRecuperaSenha: async (email: string): Promise<boolean> =>
    await requests.put('/usuarios/token', { email }),
  deleteFromConta: async (usuarioId: number, contaId: number) =>
    await requests.delete(`/usuarios/${usuarioId}/conta/${contaId}`),
  getAllByPerfil: async (perfil: string, query: IQueryParams): Promise<ICounter<UsuarioModel>> =>
    await requests.get(`/usuarios/perfil/${perfil}`, query),
}

const Files = {
  upload: async (formData: any): Promise<{ filepath: string }> =>
    await requests.post('/files', formData, true),
}

const Modulos = {
  get: async (query?: IQueryParams, cursoId?: number): Promise<ICounter<ModuloModel>> => {
    const params = {
      ...(query || {}),
      ...(cursoId ? { cursoId } : {}),
    }
    return await requests.get('/modulos/table', params)
  },
  post: async (modulo: Partial<ModuloModel>): Promise<ModuloModel> =>
    await requests.post('/modulos', modulo),
  delete: async (id: string | number): Promise<ModuloModel[]> =>
    await requests.delete(`/modulos/${id}`),
}

const UsuariosCursos = {
  get: async ({
    query,
    cursoId,
    papel,
  }: {
    query?: IQueryParams
    cursoId: number
    papel: string
  }): Promise<ICounter<UsuarioModel>> => {
    const params = {
      ...(query || {}),
    }
    return await requests.get(`/cursos/${cursoId}/papel/${papel}`, params)
  },
  post: async (usuario: Partial<UsuarioModel>): Promise<UsuarioModel[]> =>
    await requests.post('/usuarios', usuario),
  postPermissao: async (usuario: Partial<UsuarioModel>): Promise<UsuarioModel[]> =>
    await requests.post('/usuarios/permissoes', usuario),
  postMatricular: async (usuario: Partial<UsuarioModel>): Promise<any> =>
    await requests.post('/usuarios/pe/matricular', usuario),
  delete: async (id: string | number): Promise<UsuarioModel[]> =>
    await requests.delete(`/usuarios/${id}`),
  deletePermissao: async (usuarioId: number, cursoId: number, perfil: string): Promise<boolean> => {
    return await requests.delete('/usuarios/permissoes', {
      params: {
        usuarioId,
        cursoId,
        perfil,
      },
    })
  },
}

const Cursos = {
  get: async (query?: IQueryParams, id?: number): Promise<ICounter<CursoModel>> =>
    await requests.get(`/cursos/${id || ''}`, { ...(query || {}) }),
  post: async (curso: Partial<CursoModel>): Promise<CursoModel> =>
    await requests.post('/cursos', curso),
  delete: async (id): Promise<CursoModel> => await requests.delete(`/cursos/${id}`),
}

const Paginas = {
  novaPagina: async (moduloId: number): Promise<any> =>
    await requests.post(`/conteudos-paginas/nova-pagina/modulo/${moduloId}`, null),
  novaPaginaRascunho: async (paginaId: string, moduloId: number): Promise<any> =>
    await requests.post(
      `/conteudos-paginas/nova-pagina-rascunho/${paginaId}/modulo/${moduloId}`,
      null
    ),
  publicar: async (moduloId: number): Promise<any> =>
    await requests.put(`/conteudos-paginas/publicar/modulo/${moduloId}`, null),
  getByModulo: async (moduloId: number): Promise<ConteudoPaginaModel[]> =>
    await requests.get(`/conteudos-paginas/modulo/${moduloId}`),
  getConteudo: async (paginaId: string): Promise<any> =>
    await requests.get(`/conteudos-paginas/conteudo/${paginaId}`),
  createOrUpdate: async (
    paginas: ConteudoPaginaModel[],
    moduloId: number
  ): Promise<ConteudoPaginaModel[]> =>
    await requests.post('/conteudos-paginas', { paginas, moduloId }),
  getTotalItensPaginas: async (paginas: string[]): Promise<Array<{ id: string; total: number }>> =>
    await requests.post('/conteudos-paginas/get-totais', { paginas }),
  importar: async (moduloId: number, paginas: string[]): Promise<any> =>
    await requests.post(`/conteudos-paginas/importar/modulo/${moduloId}`, { paginas }),
  delete: async (paginaId: string): Promise<any> =>
    await requests.delete(`/conteudos-paginas/${paginaId}`),
  restaurar: async (paginaId: string): Promise<any> =>
    await requests.put(`/conteudos-paginas/restaurar/${paginaId}`, null),
}

const ConteudosItens = {
  create: async (
    moduloId: string,
    paginaId: string,
    tipo: string,
    conteudo: string
  ): Promise<ConteudoItemModel> =>
    await requests.post(`/conteudos-itens/modulo/${moduloId}/pagina/${paginaId}`, {
      conteudo,
      tipo,
    }),
  update: async (conteudoItemId: string, conteudo: any): Promise<ConteudoItemModel> =>
    await requests.put(`/conteudos-itens/conteudo/${conteudoItemId}`, { conteudo }),
  updateDuracao: async (conteudoItemId: string, duracao: number): Promise<ConteudoItemModel> =>
    await requests.put(`/conteudos-itens/duracao/${conteudoItemId}`, { duracao }),
  delete: async (conteudoItemId: string): Promise<boolean> =>
    await requests.delete(`/conteudos-itens/conteudo/${conteudoItemId}`),
  reorder: async ({ paginaId, from, to }): Promise<ConteudoItemModel[]> =>
    await requests.put('/conteudos-itens/reorder', { from, to, paginaId }),
}
const Live = {
  create: async (moduloId, conteudoId, data): Promise<ConteudoItemModel> =>
    await requests.post(`/live/modulo/${moduloId}/conteudo/${conteudoId}`, data),
  iniciar: async (moduloId, conteudoId): Promise<ConteudoItemModel> =>
    await requests.post(`/live/modulo/${moduloId}/conteudo/${conteudoId}/iniciar`, null),
}

const Questoes = {
  get: async (
    query?: IQueryParams,
    moduloId?: number,
    local?: string
  ): Promise<ICounter<QuestaoModel>> =>
    await requests.get('/questoes', { ...query, moduloId, local }),
  getById: async (questaoId: number): Promise<QuestaoModel> =>
    await requests.get(`/questoes/${questaoId}`),
  createOrUpdate: async (questao: QuestaoModel): Promise<QuestaoModel> =>
    await requests.post('/questoes', questao),
  delete: async (questaoId: string): Promise<void> =>
    await requests.delete(`/questoes/${questaoId}`),
}

const Foruns = {
  getAll: async (query?: IQueryParams): Promise<ICounter<ForumModel>> =>
    await requests.get('/usuarios/foruns/tutores', query),
  getForunsMensagensPendentes: async (
    query?: IQueryParams
  ): Promise<ICounter<ForumMensagemModel>> =>
    await requests.get('/foruns/mensagens/pendentes', query),
  getOne: async (forumId: number): Promise<ForumModel> => await requests.get(`/foruns/${forumId}`),
  getForunsMensagensByForum: async (forumId: number): Promise<ForumMensagemModel[]> =>
    await requests.get(`/foruns/mensagens/${forumId}`),
  aprovarMensagem: async (forumMensagemId: number): Promise<boolean> =>
    await requests.put('/foruns/mensagem/aprovar', { forumMensagemId }),
  aprovarForum: async (forumId: number): Promise<boolean> =>
    await requests.put('/foruns/aprovar', { forumId }),
  rejeitar: async (forumMensagemId: number): Promise<boolean> =>
    await requests.put('/foruns/mensagem/rejeitar', { forumMensagemId }),
}

const ForunsMensagens = {
  createOrUpdate: async (forumMensagem: ForumMensagemModel): Promise<ForumMensagemModel> =>
    await requests.post('/foruns/mensagens/', forumMensagem),
}

const Progresso = {
  getByAluno: async (alunoId: number): Promise<ICounter<ProgressoModel>> =>
    await requests.get(`/progressos/aluno/${alunoId}`),
}

const Chat = {
  getChatsByUsuarioLogado: async (): Promise<ICounter<ChatModel>> => await requests.get('/chats'),
  getChatById: async (chatId: number): Promise<ChatModel> => await requests.get(`/chats/${chatId}`),
  getMessagesByChatId: async (chatId: number): Promise<ChatMensagemModel[]> =>
    await requests.get(`/chats/mensagens/${chatId}`),
  getAlunos: async (): Promise<ICounter<UsuarioModel>> => await requests.get('/chats/alunos'),
  sendMessage: async (mensagem: string, chatId: number): Promise<ChatMensagemModel> =>
    await requests.post('/chats/mensagens', { mensagem, chatId }),
  loadOrCreateChat: async (alunoId: number): Promise<ChatModel> =>
    await requests.post('/chats', { alunoId }),
  updateMensagensVisualizadas: async (chatId: number): Promise<boolean> =>
    await requests.put('/chats/mensagem/visualizada', { chatId }),
}

const DownloadsCursos = {
  get: async ({
    query,
    cursoId,
  }: {
    query: IQueryParams
    cursoId: number
  }): Promise<ICounter<DownloadModel>> =>
    await requests.get(`/downloads/curso/${cursoId}`, { ...(query || {}) }),
  delete: (...props) => null,
}

const Certificados = {
  getPendentes: async () => await requests.get(`/certificados/pendentes`),
  updateConfirmacaoEnvio: async (matriculaId: number) =>
    await requests.put(`/certificados/confirma-envio`, { matriculaId }),
}

const Isencao = {
  getIsencoes: (params) => requests.get('/isencoes', params),
  emitirIsencao: (matriculaId: number, motivoIsencao: string) =>
    requests.post('/isencoes', { matriculaId, motivoIsencao }),
}

export default {
  Usuarios,
  UsuariosCursos,
  Files,
  Modulos,
  Cursos,
  Paginas,
  Questoes,
  Foruns,
  ForunsMensagens,
  Progresso,
  Chat,
  ConteudosItens,
  Live,
  DownloadsCursos,
  Certificados,
  Isencao,
}
