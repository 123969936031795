import React from 'react'
import { render, unmountComponentAtNode } from 'react-dom'
import { Confirm, Modal, Input, Button, Dimmer, Loader, Progress } from 'semantic-ui-react'
import axios from '../axios'
import axiosOriginal from 'axios'
import { apiUrl } from '../index'

export enum ContainersArquivos {
  QUESTAO = 'questao',
  CONTEUDO = 'conteudo',
  BIBLIOTECA = 'biblioteca',
  AVATAR = 'avatar',
  EXTRA = 'extra',
}

export class Util {
  static async confirm(msg: string) {
    return await new Promise((resolve) => {
      const handleOk = (e: any) => {
        if (e.key !== 'Enter') return
        e.preventDefault()
        e.stopPropagation()
        resolve(true)
        unregister()
      }
      const unregister = () => {
        window.removeEventListener('keypress', handleOk)
        const element = document.getElementById('modal-confirm-placeholder')
        if (element) unmountComponentAtNode(element)
      }
      window.addEventListener('keypress', handleOk)
      render(
        <Confirm
          open={true}
          onCancel={() => {
            resolve(false)
            unregister()
          }}
          onConfirm={() => {
            resolve(true)
            unregister()
          }}
          content={msg}
        />,
        document.getElementById('modal-confirm-placeholder')
      )
    })
  }

  static async alert(msg: string) {
    return await new Promise((resolve) => {
      render(
        <Modal
          open={true}
          header="Atenção!"
          content={msg}
          actions={['Ok']}
          onClose={() => {
            resolve(true)
            const element = document.getElementById('modal-confirm-placeholder')
            if (element) unmountComponentAtNode(element)
          }}
          onActionClick={() => {
            resolve(true)
            const element = document.getElementById('modal-confirm-placeholder')
            if (element) unmountComponentAtNode(element)
          }}
        />,
        document.getElementById('modal-confirm-placeholder')
      )
    })
  }

  static async requestPassword() {
    return await new Promise((resolve) => {
      render(
        <PasswordValidation resolve={resolve} />,
        document.getElementById('modal-confirm-placeholder')
      )
    })
  }

  static uuid() {
    return 'xxxxxxxx-xxxx-4xxx-xxxx-xxxxxxxxxxxx'.replace(/[x]/g, () =>
      ((Math.random() * 16) | 0).toString(16)
    )
  }

  static async requestUploadImagem(
    { containerName }: { containerName: ContainersArquivos } = {
      containerName: ContainersArquivos.EXTRA,
    }
  ): Promise<string> {
    const element = document.getElementById('upload-placeholder')
    if (element) unmountComponentAtNode(element)
    return await new Promise((resolve) => {
      render(
        <Upload resolve={resolve} containerName={containerName} />,
        document.getElementById('upload-placeholder')
      )
    })
  }

  static getInitialValues = (values: any, initial: string[]) => {
    values = Object.keys(values || {}).reduce(
      (prev, curr) => (values[curr] ? { ...prev, [curr]: values[curr] } : prev),
      {}
    )
    const newInitial = initial.reduce((prev, curr) => ({ ...prev, [curr]: '' }), {})
    return { ...newInitial, ...values }
  }
}

class PasswordValidation extends React.Component<
  { resolve: any },
  { senha: string; isLoading: boolean }
> {
  state = { senha: '', isLoading: false }
  ref: any

  private readonly _cleanUp = () => {
    const element = document.getElementById('modal-confirm-placeholder')
    if (element) unmountComponentAtNode(element)
  }

  private readonly _validatePassword = async () => {
    this.setState({ isLoading: true })
    try {
      this.props.resolve(await axios.Usuarios.validateSenha(this.state.senha))
    } catch (error) {
      setTimeout(async () => await Util.alert('Ocorreu um erro ao validar a senha'))
      this.handleClose()
    }
    this.setState({ isLoading: false }, () => {
      this._cleanUp()
    })
  }

  componentDidMount() {
    this.ref.focus()
  }

  handleClose = () => {
    this.props.resolve(false)
    this._cleanUp()
  }

  handleOk = async () => {
    this._validatePassword()
  }

  handleChange = (e: any) => this.setState({ senha: e.target.value })

  handleSubmit = (e: any) => {
    e.preventDefault()
    this._validatePassword()
  }

  render() {
    return (
      <React.Fragment>
        <Modal open={true} size="tiny" onClose={this.handleClose}>
          <Modal.Header>Informe sua senha para confirmar a ação</Modal.Header>
          <Modal.Content>
            <form onSubmit={this.handleSubmit}>
              <Input
                ref={(e) => {
                  this.ref = e
                }}
                type="password"
                onChange={this.handleChange}
                value={this.state.senha}
              />
            </form>
            {this.state.isLoading && (
              <Dimmer active>
                <Loader />
              </Dimmer>
            )}
          </Modal.Content>
          <Modal.Actions>
            <Button color="green" onClick={this.handleOk}>
              Confirmar
            </Button>
            <Button onClick={this.handleClose}>Cancelar</Button>
          </Modal.Actions>
        </Modal>
      </React.Fragment>
    )
  }
}

class Upload extends React.Component<{ resolve: any; containerName: string }, { percent: number }> {
  fileRef: React.RefObject<HTMLInputElement>
  constructor(props: any) {
    super(props)
    this.fileRef = React.createRef()
    this.state = { percent: 0 }
  }

  componentDidMount() {
    this.fileRef.current.click()
  }

  addImage = (e: any) => {
    const reader = new FileReader()

    reader.addEventListener(
      'load',
      async (loadEvent: any) => {
        try {
          const resizedImage = await this._resizeImage(loadEvent.target.result)
          const fileFromDataUrl = this._getFileFromDataURL(resizedImage)
          const file = await this._sendFile(fileFromDataUrl, this.props.containerName)
          this.props.resolve(`${file.containerName}/${file.blobName}`)
          this._cleanUp()
        } catch (error) {
          this._cleanUp()
        }
      },
      false
    )
    if (e.target.files && e.target.files.length) reader.readAsDataURL(e.target.files[0])
  }

  private readonly _cleanUp = () => {
    const element = document.getElementById('upload-placeholder')
    if (element) unmountComponentAtNode(element)
  }

  private async _resizeImage(imgSrc: any): Promise<string> {
    return await new Promise((resolve) => {
      const img = document.createElement('img')
      img.onload = function () {
        const canvas = document.createElement('canvas')
        const ctx: any = canvas.getContext('2d')
        ctx.imageSmoothingEnabled = true
        ctx.imageSmoothingQuality = 'high'

        const ratio = img.width > 1024 ? 1024 / img.width : 1

        canvas.width = img.width * ratio
        canvas.height = img.height * ratio

        ctx.drawImage(this, 0, 0, canvas.width, canvas.height)

        resolve(canvas.toDataURL('image/jpeg', 1))
      }

      img.src = imgSrc
    })
  }

  private _getFileFromDataURL(dataURL: string): Blob {
    const blobBin = atob(dataURL.split(',')[1])
    const array = []
    for (let i = 0; i < blobBin.length; i++) {
      array.push(blobBin.charCodeAt(i))
    }

    return new Blob([new Uint8Array(array)], { type: 'image/jpeg' })
  }

  private async _sendFile(file: Blob, containerName: string): Promise<any> {
    const formData = new FormData()
    formData.append('file', file)
    formData.append('containerName', containerName)
    return await new Promise((resolve) => {
      axiosOriginal
        .post(`${apiUrl}/arquivos`, formData, {
          onUploadProgress: (progressEvent: ProgressEvent) => {
            this.setState({
              percent: Math.round((progressEvent.loaded * 100) / progressEvent.total),
            })
          },
        })
        .then((r) => r.data)
        .then(resolve)
    })
  }

  render() {
    return (
      <React.Fragment>
        <Modal open={!!this.state.percent}>
          <Modal.Content>
            <Progress percent={this.state.percent} progress />
          </Modal.Content>
        </Modal>
        <input type="file" hidden ref={this.fileRef} onChange={this.addImage} />
      </React.Fragment>
    )
  }
}

export const Show = ({ condition, children }) => (!condition ? null : children)
export const Hide = ({ condition, children }) => (condition ? null : children)
