import React, { useEffect, createContext, useContext, useReducer } from 'react'
import { ConteudoItemModel } from '../../models/ConteudoItemModel'
import axios from '../../axios'
import { ConteudoPaginaModel } from '../../models/ConteudoPaginaModel'
import { toast } from 'react-toastify'
import { createSelector } from 'reselect'
import { differenceInSeconds, max, parseISO, format, isValid } from 'date-fns'
import { TIPOS_CONTEUDO } from '../../components/Constants'
import { TIPO_QUESTAO } from '../../models/QuestaoModel'
import { createSimpleStore } from '../../components/SimpleReducer'

const EditorConteudoStore = createSimpleStore(
  {
    loading: false,
    paginas: [] as ConteudoPaginaModel[],
    paginaAtiva: null as ConteudoPaginaModel | null,
    importarPagina: false,
    questaoEdicao: null as null | { tipoQuestao: TIPO_QUESTAO; questaoId; conteudoId },
  },
  {
    paginaConteudoFetch(state) {
      state.loading = true
    },
    paginasFetch(state) {
      state.loading = true
    },
    publicarSuccess(state, paginas: ConteudoPaginaModel[]) {
      state.paginas = paginas
      state.paginaAtiva = paginas.find((x) => x.id === state.paginaAtiva.id)
      state.loading = false
    },
    importarPaginas(state, paginas: ConteudoPaginaModel[]) {
      state.paginas = paginas
      state.paginaAtiva = state.paginaAtiva || paginas?.[0]
      state.loading = false
    },
    paginasFetchSuccess(state, paginas: ConteudoPaginaModel[]) {
      const _getPaginaAtiva = () => {
        if (state.paginaAtiva) return state.paginaAtiva
        if (historicoPaginaAtiva.get()) {
          const p = paginas.find((x) => x.id === historicoPaginaAtiva.get())
          if (p) return p
        }
        const primeiraPagina = paginas?.[0]
        if (!primeiraPagina) return null
        const rascunhoPrimeiraPagina = paginas.find((x) => {
          return (
            x.identificadorUnico === primeiraPagina.identificadorUnico && x.status === 'RASCUNHO'
          )
        })
        if (rascunhoPrimeiraPagina) return rascunhoPrimeiraPagina
        return primeiraPagina
      }
      state.paginaAtiva = _getPaginaAtiva()
      state.paginas = paginas
      state.loading = false
    },
    paginaConteudoFetchSuccess(state, pagina: ConteudoPaginaModel) {
      state.paginaAtiva = pagina
      state.loading = false
    },
    novaPaginaRascunho(state, pagina: ConteudoPaginaModel) {
      state.paginaAtiva = pagina
      state.loading = false
    },
    restaurarPagina(state, payload: { paginas; paginaAlvo }) {
      state.paginas = payload.paginas
      state.paginaAtiva = payload.paginaAlvo
      state.loading = false
    },
    deletePagina(state, payload: { paginas; paginaAlvo }) {
      state.paginas = payload.paginas
      state.paginaAtiva = payload.paginaAlvo
      state.loading = false
    },
    deleteConteudo(state, conteudoId: string) {
      const conteudosItensDelete = state.paginaAtiva.conteudosItens.filter(
        (p) => p.id !== conteudoId
      )
      state.paginaAtiva = {
        ...state.paginaAtiva,
        conteudosItens: _ordenarConteudo(conteudosItensDelete),
      }
      state.loading = false
    },
    addConteudoSuccess(state, conteudo: ConteudoItemModel) {
      const conteudos = [...state.paginaAtiva.conteudosItens, conteudo]
      state.paginaAtiva = {
        ...state.paginaAtiva,
        conteudosItens: _ordenarConteudo(conteudos),
      }
      state.questaoEdicao = null
      state.loading = false
    },
    updateConteudoSuccess(state, conteudo: ConteudoItemModel) {
      const conteudosItensUpdate = state.paginaAtiva.conteudosItens.map((p) => {
        if (p.id === conteudo.id) return conteudo
        return p
      })
      const pagina = state.paginas.find((p) => p.id === state.paginaAtiva.id)
      pagina.titulo = _ordenarConteudo(conteudosItensUpdate)[0].conteudo

      state.paginaAtiva = {
        ...state.paginaAtiva,
        conteudosItens: _ordenarConteudo(conteudosItensUpdate),
      }
      state.questaoEdicao = null
      state.loading = false
    },
    setImportarPagina(state, param: boolean) {
      state.importarPagina = param
    },
    moveConteudoSuccess(state, conteudosItens: ConteudoItemModel[]) {
      state.paginaAtiva.conteudosItens = conteudosItens
    },
    openQuestaoEdicao(state, payload: { tipoQuestao: TIPO_QUESTAO; questaoId; conteudoId }) {
      state.questaoEdicao = payload
    },
    closeQuestaoEdicao(state) {
      state.questaoEdicao = null
    },
  },
  {
    thunks: {
      getPaginas(moduloId: number) {
        return async (dispatch) => {
          dispatch(EditorConteudoStore.actions.paginasFetch())
          const paginas = await axios.Paginas.getByModulo(moduloId)
          dispatch(EditorConteudoStore.actions.paginasFetchSuccess(paginas))
        }
      },
      getPaginaConteudo(paginaId: string) {
        return async (dispatch) => {
          dispatch(EditorConteudoStore.actions.paginaConteudoFetch())
          const pagina = await axios.Paginas.getConteudo(paginaId)
          dispatch(EditorConteudoStore.actions.paginaConteudoFetchSuccess(pagina))
        }
      },
      addConteudo(moduloId, paginaId, tipoConteudo, conteudo = '') {
        return async (dispatch, getState) => {
          if (getState().paginaAtiva.status === 'PUBLICADO') {
            return toast('Antes de editar uma página, clique no botão EDITAR')
          }
          try {
            const conteudoCriado = await axios.ConteudosItens.create(
              moduloId,
              paginaId,
              tipoConteudo,
              conteudo
            )
            dispatch(EditorConteudoStore.actions.addConteudoSuccess(conteudoCriado))
          } catch (e) {
            console.log({ e })
          }
        }
      },
      addLive(moduloId, conteudoId, data) {
        return async (dispatch) => {
          try {
            const live = await axios.Live.create(moduloId, conteudoId, data)
            dispatch(EditorConteudoStore.actions.updateConteudoSuccess({ conteudo: live } as any))
          } catch (e) {
            console.log({ e })
          }
        }
      },
      iniciarLive(moduloId, conteudoId) {
        return async (dispatch) => {
          try {
            const live = await axios.Live.iniciar(moduloId, conteudoId)
            console.log({ live })
          } catch (e) {
            console.log({ e })
          }
        }
      },
      updateConteudo(conteudoId, conteudo = '') {
        return async (dispatch) => {
          const conteudoAtualizado = await axios.ConteudosItens.update(conteudoId, conteudo)
          dispatch(EditorConteudoStore.actions.updateConteudoSuccess(conteudoAtualizado))
          console.log({ conteudo })
        }
      },
      updateDuracaoConteudo(conteudoId, duracao) {
        return async (dispatch) => {
          const conteudoAtualizado = await axios.ConteudosItens.updateDuracao(conteudoId, duracao)
          dispatch(EditorConteudoStore.actions.updateConteudoSuccess(conteudoAtualizado))
        }
      },
      deleteConteudo(conteudoId: string) {
        return async (dispatch) => {
          const result = await axios.ConteudosItens.delete(conteudoId)
          console.log({ result })
          dispatch(EditorConteudoStore.actions.deleteConteudo(conteudoId))
        }
      },
      novaPagina(moduloId: number) {
        return async (dispatch) => {
          try {
            const novaPagina = await axios.Paginas.novaPagina(moduloId)
            const paginas = await axios.Paginas.getByModulo(moduloId)
            dispatch(EditorConteudoStore.actions.paginasFetchSuccess(paginas))
            dispatch(EditorConteudoStore.actions.paginaConteudoFetchSuccess(novaPagina))
          } catch (e) {
            console.log('Falha ao criar nova página')
          }
        }
      },
      publicar(moduloId: number) {
        return async (dispatch) => {
          try {
            await axios.Paginas.publicar(moduloId)
            const paginas = await axios.Paginas.getByModulo(moduloId)
            const paginasOrdenadas = paginas.sort((a, b) => (a.numero < b.numero ? -1 : 1))
            dispatch(EditorConteudoStore.actions.publicarSuccess(paginasOrdenadas))
          } catch (e) {
            console.log('Falha ao publicar conteudo')
          }
        }
      },
      novaPaginaRascunho(moduloId: number, paginaId: string) {
        return async (dispatch) => {
          try {
            const novaPaginaRascunho = await axios.Paginas.novaPaginaRascunho(paginaId, moduloId)
            const paginas = await axios.Paginas.getByModulo(moduloId)
            dispatch(EditorConteudoStore.actions.paginasFetchSuccess(paginas))
            dispatch(EditorConteudoStore.actions.novaPaginaRascunho(novaPaginaRascunho))
          } catch (e) {
            console.log('Falha ao criar nova página de rascunho')
          }
        }
      },
      setImportarPagina(state: boolean) {
        return async (dispatch) => {
          try {
            dispatch(EditorConteudoStore.actions.setImportarPagina(state))
          } catch (e) {
            console.log({ e })
          }
        }
      },
      importarPaginas(moduloId: number, paginas: any[]) {
        return async (dispatch) => {
          const _paginas = await axios.Paginas.importar(moduloId, paginas)
          try {
            dispatch(EditorConteudoStore.actions.importarPaginas(_paginas))
          } catch (e) {
            console.log({ e })
          }
        }
      },
      deletePagina(paginaId: string) {
        return async (dispatch) => {
          try {
            const paginaAlvo = await axios.Paginas.delete(paginaId)
            const paginas = await axios.Paginas.getByModulo(paginaAlvo.moduloId)
            dispatch(EditorConteudoStore.actions.deletePagina({ paginas, paginaAlvo }))
          } catch (e) {
            console.log({ e })
          }
        }
      },
      restaurarPagina(paginaId: string) {
        return async (dispatch) => {
          try {
            const paginaAlvo = await axios.Paginas.restaurar(paginaId)
            const paginas = await axios.Paginas.getByModulo(paginaAlvo.moduloId)
            dispatch(EditorConteudoStore.actions.restaurarPagina({ paginas, paginaAlvo }))
          } catch (e) {
            console.log({ e })
          }
        }
      },
      moveConteudo({ from, to }) {
        return async (dispatch, getState) => {
          const paginaId = getState().paginaAtiva.id
          const conteudosItens = await axios.ConteudosItens.reorder({ from, to, paginaId })
          dispatch(EditorConteudoStore.actions.moveConteudoSuccess(conteudosItens))
        }
      },
    },
  }
)

const historicoPaginaAtiva = {
  set(paginaAtivaId) {
    localStorage.setItem('EDITOR_CONTEUDO_ULTIMA_PAGINA_ATIVA_ID', paginaAtivaId)
  },
  get() {
    const item = localStorage.getItem('EDITOR_CONTEUDO_ULTIMA_PAGINA_ATIVA_ID')
    if (!item || item == null || item === 'undefined' || item === 'null') return
    return item
  },
}

const _ordenarConteudo = (listaConteudo: ConteudoItemModel[]) => {
  return [...listaConteudo].sort((a, b) => (a.ordenacao < b.ordenacao ? -1 : 1))
}

type IState = ReturnType<typeof EditorConteudoStore.useState>
const selectUltimoSalvamento = createSelector(
  (state: IState) => state.paginaAtiva,
  (state: IState) => state.paginaAtiva?.conteudosItens,
  (paginaAtiva, conteudosItens) => {
    if (!paginaAtiva || paginaAtiva.status === 'PUBLICADO') return null
    const dataSalvamentoItemMaisRecente = max(conteudosItens?.map((x) => parseISO(x.updatedAt)))
    if (!dataSalvamentoItemMaisRecente || !isValid(dataSalvamentoItemMaisRecente)) return null
    return dataSalvamentoItemMaisRecente
  }
)

const selectTituloPagina = createSelector(
  (s: IState & { paginaId }) => s.paginas,
  (s: IState & { paginaId }) => s.paginaAtiva,
  (s: IState & { paginaId }) => s.paginaId,
  (s: IState & { paginaId }) => selectConteudo,
  (paginas, paginaAtiva, paginaId, conteudo) => {
    if (conteudo.length && paginaAtiva?.id === paginaId) {
      return conteudo[0].conteudo
    }
    return paginas?.find((x) => x.id === paginaId)?.titulo
  }
)

const selectPaginas = createSelector(
  (s: IState) => s.paginas,
  (paginas) => {
    const paginasNaoPublicadas = paginas.filter((x) => x.status !== 'PUBLICADO')
    const paginasNaoPublicadasIdsUnicos = paginasNaoPublicadas.map((x) => x.identificadorUnico)
    const paginasSemRascunhos = paginas.filter(
      (x) => !paginasNaoPublicadasIdsUnicos.includes(x.identificadorUnico)
    )
    return [...paginasNaoPublicadas, ...paginasSemRascunhos].sort((a, b) =>
      a.numero > b.numero ? 1 : -1
    )
  }
)

const selectConteudo = createSelector(
  (s: IState) => s.paginaAtiva?.conteudosItens ?? [],
  (conteudosItens) => [...conteudosItens].sort((a, b) => (a.ordenacao < b.ordenacao ? -1 : 1))
)

export {
  EditorConteudoStore,
  historicoPaginaAtiva,
  selectUltimoSalvamento,
  selectTituloPagina,
  selectPaginas,
  selectConteudo,
}
