import {
  Button,
  Container,
  Icon,
  Input,
  Segment as SegmentComp,
  SemanticCOLORS,
} from 'semantic-ui-react'
import { Formik } from 'formik'
import { useHistory, useParams } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import React, { useState } from 'react'

import { bindActionCreators } from 'redux'

import { CursoModel } from '../../models/CursoModel'
import axios from '../../axios'
import { IStateRedux } from '../../ReduxStore'
import { ModulosView } from '../Modulos/ModulosView'
import { UsuariosCursosView } from '../UsuariosCurso/UsuariosCursosView'
import { AlunosView } from '../Alunos'
import { Show } from '../../components/Basic'
import { NavEstrutural } from '../../components/NavEstrutural'
import { cursosActions } from './CursosStore'
import { DownloadsCursosView } from '../Downloads/DownloadsCurso'
const Segment = SegmentComp as any

interface CursosEditarParams {
  cursoId: string
}

export const CursosEditar = () => {
  const history = useHistory()
  const { cursoId } = useParams<CursosEditarParams>()

  const [curso, setCurso] = useState({ nome: '' } as any)

  if (cursoId) {
    if (!curso.id) {
      axios.Cursos.get(null, +cursoId)
        .then((data) => setCurso(data.rows[0]))
        .catch((error) => console.log({ error }))
    }
  }

  const backToCursos = () => history.push('/cursos/')
  const isEditing = !!curso.id

  return (
    <Container>
      <NavEstrutural />
      <BotaoVoltar handler={backToCursos} />
      <DadosCurso isEditing={isEditing} curso={curso} submitCallback={backToCursos} />
      <Show condition={isEditing}>
        <ModulosDoCurso />
        {/* <AlunosDoCurso /> */}
        <TutoresDoCurso />
        <DownloadsDoCurso />
      </Show>
    </Container>
  )
}

interface BlocoConteudoProps {
  color: SemanticCOLORS
  children
}
const BlocoConteudo: React.FC<BlocoConteudoProps> = ({ color, children }) => (
  <Segment color={color} style={{ marginTop: 25 }}>
    {children || null}
  </Segment>
)

const ModulosDoCurso: React.FC = () => {
  const { cursoId } = useParams<CursosEditarParams>()
  return (
    <BlocoConteudo color="orange">
      <h4> Módulos do curso </h4>
      <ModulosView cursoId={+cursoId} />
    </BlocoConteudo>
  )
}

const AlunosDoCurso: React.FC = () => {
  const { cursoId } = useParams<CursosEditarParams>()
  return (
    <BlocoConteudo color="green">
      <h4> Alunos do curso </h4>
      <AlunosView cursoId={+cursoId} />
    </BlocoConteudo>
  )
}

const TutoresDoCurso: React.FC = () => {
  const { cursoId } = useParams<CursosEditarParams>()
  return (
    <BlocoConteudo color="red">
      <h4> Tutores do curso </h4>
      <UsuariosCursosView componentId="TUTORES_CURSO" cursoId={+cursoId} papel="TUTOR" />
    </BlocoConteudo>
  )
}

interface DadosCursoProps {
  submitCallback: () => any
  isEditing: boolean
  curso: Partial<CursoModel>
}

const DadosCurso: React.FC<DadosCursoProps> = ({
  submitCallback,
  isEditing,
  curso,
}: {
  submitCallback?
  isEditing?
  curso
}) => {
  const dispatch = useDispatch()
  return (
    <BlocoConteudo color="purple">
      <h2> Dados do curso </h2>
      <Formik
        enableReinitialize
        initialValues={curso}
        onSubmit={async (values, { setSubmitting }) => {
          try {
            const curso = await axios.Cursos.post(values)
            setSubmitting(false)
            submitCallback()
          } catch (e) {
            console.log(e)
          }
        }}
      >
        {({ values, handleChange, handleSubmit, isSubmitting }) => (
          <form onSubmit={handleSubmit}>
            <div style={{ display: 'block' }}>
              <label>Id</label>
              <br />
              <Input disabled name="id" onChange={handleChange} value={values.id} />
            </div>
            <br />
            <div style={{ display: 'block' }}>
              <label>Nome</label>
              <br />
              <Input
                {...(!isEditing ? { autoFocus: true } : {})}
                fluid
                name="nome"
                onChange={handleChange}
                value={values.nome}
              />
            </div>
            <br />
            <div style={{ display: 'table', width: '100%' }}>
              <div style={{ display: 'inline-block', float: 'right' }}>
                <Button primary type="submit" disabled={values.nome === '' || isSubmitting}>
                  Salvar
                </Button>
              </div>
            </div>
          </form>
        )}
      </Formik>
    </BlocoConteudo>
  )
}

const DownloadsDoCurso: React.FC = () => {
  const { cursoId } = useParams<CursosEditarParams>()
  return (
    <BlocoConteudo color="orange">
      <h4> Downloads do curso </h4>
      <DownloadsCursosView cursoId={+cursoId} />
    </BlocoConteudo>
  )
}

const BotaoVoltar = ({ handler }) => (
  <Button style={{ marginTop: 25 }} onClick={handler} circular>
    <Icon name="arrow left" />
    Voltar
  </Button>
)
