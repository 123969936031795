import React, { useState, useEffect, useContext } from 'react'
import { ChatMensagemView } from './ChatMensagemView'
import { GlobalStoreValues, GlobalStoreCtx } from '../../GlobalStore'
import socket from '../../socket'
import { ChatMensagemModel } from '../../models/ChatMensagemModel'
import { ChatModel } from '../../models/ChatModel'
import axios from '../../axios'

let socketNovaMensagem: any
const useChatMensagemStore = (chatId: number) => {
  const { setErrorMessage, usuario } = useContext<GlobalStoreValues>(GlobalStoreCtx)

  const [chats, _setChats] = useState<ChatModel>(null)
  const [chatMensagens, _setChatMensagens] = useState<ChatMensagemModel[]>([])

  useEffect(() => {
    _getChatMensagens()
  }, [])

  useEffect(() => {
    _startSocket()
    return () => {
      if (socketNovaMensagem) socketNovaMensagem()
    }
  }, [usuario, chats, chatMensagens])

  async function _startSocket() {
    socketNovaMensagem = await socket.Chat.novaMensagem(_addMensagemViaSocket)
  }

  function _addMensagemViaSocket({ chatMensagem }: { chatMensagem: ChatMensagemModel }) {
    if (+chatMensagem.usuarioId !== +usuario.id && +chatMensagem.chatId === +chats.id) {
      _setChatMensagens([...chatMensagens, chatMensagem])
      _scrollToBottom()
    }
  }

  async function _getChatMensagens() {
    try {
      // tslint:disable-next-line:no-shadowed-variable
      const [chatMensagens, chat] = await Promise.all([
        axios.Chat.getMessagesByChatId(chatId),
        axios.Chat.getChatById(chatId),
      ])
      _setChatMensagens(chatMensagens)
      _setChats(chat)
      _scrollToBottom()
    } catch (error) {
      setErrorMessage(error.message)
    }
  }

  function _scrollToBottom() {
    setTimeout(() => {
      const element = document.querySelector('#container-chat-scrollable')
      if (element) element.scrollTo(0, element.scrollHeight)
    })
  }

  async function sendMensagem(mensagem: string) {
    try {
      const mensagemCriada = await axios.Chat.sendMessage(mensagem, chatId)
      _setChatMensagens([...chatMensagens, mensagemCriada])
      _scrollToBottom()
      return true
    } catch (error) {
      setErrorMessage(error.message)
    }
  }

  return {
    chats,
    chatMensagens,
    sendMensagem,
    _getChatMensagens,
  }
}

export type ChatMensagemStoreValues = ReturnType<typeof useChatMensagemStore>
export const ChatMensagemStoreCtx = React.createContext(null)
export const ChatMensagem = ({ match }: any) => {
  const Values = useChatMensagemStore(match.params.chatId)
  return (
    <ChatMensagemStoreCtx.Provider value={Values}>
      <ChatMensagemView />
    </ChatMensagemStoreCtx.Provider>
  )
}
