import React, { useCallback, useEffect, useState } from 'react'
import type { PaginationProps } from 'antd'
import {
  DatePicker,
  Input,
  Spin,
  Table as TableAntd,
  Pagination as PaginationAntd,
  Select,
} from 'antd'
import { IsencaoStore } from './IsencaoStore'
import { ColumnsType } from 'antd/lib/table'
import { formatCurrency, removeAccents } from '../../util/functionsUtils'
import { format } from 'date-fns'
import { ConfigProvider } from 'antd'
import ptBR from 'antd/es/locale/pt_BR'
import { CalendarOutlined } from '@ant-design/icons'
import { CURSOS } from '../../models/CursoModel'

const heightDestopScroll = 'calc(100vh - 210px)'
const widthDestopScroll = 'calc(100vw - 80px)'
const defaultPageSize = 10
const pageNumber = 1
let initialValues = {
  limit: defaultPageSize,
  pageNumber,
  isentadoPor: '',
  referenciaPagamento: '',
  nomeAluno: '',
  cursoId: '',
  createdAtStart: '',
  createdAtEnd: '',
  motivoIsencao: '',
}
let valuesFilter = { ...initialValues }
type TvaluesFilter = typeof valuesFilter

export function IsencaoListar() {
  const init = useCallback((dispatch) => {
    dispatch(IsencaoStore.thunks.getIsencoes(initialValues))
  }, [])

  return (
    <IsencaoStore.Provider init={init}>
      <IsencaoStore.GetState>
        {({ loading }) => (
          <Spin spinning={loading}>
            <Layout />
          </Spin>
        )}
      </IsencaoStore.GetState>
    </IsencaoStore.Provider>
  )
}

function Layout() {
  return (
    <>
      <Table />
      <Pagination />
    </>
  )
}

function Table() {
  const dispatch = IsencaoStore.useDispatch()
  const { isencoes } = IsencaoStore.useState()

  function primeiroNome(str: string) {
    const nomes = str?.replace(/  +/g, ' ')?.split(' ')
    const nome = `${nomes?.[0] ?? ''}`
    return nome
  }

  const columns: ColumnsType<any> = [
    {
      title: 'Cobrança',
      render: (value, record) => record.referenciaPagamento,
      filterSearch: true,
      filterDropdown: ({ confirm, clearFilters, selectedKeys, setSelectedKeys }) => {
        return (
          <InputFilter
            clearFilters={clearFilters}
            confirm={() => {
              getIsencao(dispatch, {
                referenciaPagamento: selectedKeys[0] ? selectedKeys[0] : null,
              })
              return confirm()
            }}
            selectedKeys={selectedKeys}
            setSelectedKeys={setSelectedKeys}
          />
        )
      },
    },
    {
      title: 'Valor',
      render: (value, record) => formatCurrency(record.valorIsento || 0),
    },
    {
      title: 'Isentado Por',
      render: (value, record) => primeiroNome(record?.usuario?.nome),
      filterSearch: true,
      filterDropdown: ({ confirm, clearFilters, selectedKeys, setSelectedKeys }) => (
        <InputFilter
          clearFilters={clearFilters}
          confirm={() => {
            getIsencao(dispatch, { isentadoPor: selectedKeys[0] ? selectedKeys[0] : null })
            return confirm()
          }}
          selectedKeys={selectedKeys}
          setSelectedKeys={setSelectedKeys}
        />
      ),
      onFilter: (value: string, record) => {
        return onFilter({ valueFiltered: value, recordValue: record?.usuario?.nome })
      },
    },
    {
      title: 'Nome Aluno',
      render: (value, record) => record?.matricula?.aluno?.nome,
      filterSearch: true,
      filterDropdown: ({ confirm, clearFilters, selectedKeys, setSelectedKeys }) => (
        <InputFilter
          clearFilters={clearFilters}
          confirm={() => {
            getIsencao(dispatch, { nomeAluno: selectedKeys[0] ? selectedKeys[0] : null })
            return confirm()
          }}
          selectedKeys={selectedKeys}
          setSelectedKeys={setSelectedKeys}
        />
      ),
    },
    {
      title: 'Curso',
      render: (value, record) => record?.matricula?.curso?.nomeReduzido,
      filterSearch: true,
      filterDropdown: ({ confirm, clearFilters, selectedKeys, setSelectedKeys }) => (
        <SelectFilter
          clearFilters={clearFilters}
          confirm={() => {
            getIsencao(dispatch, { cursoId: selectedKeys[0] ? selectedKeys[0] : null })
            return confirm()
          }}
          selectedKeys={selectedKeys}
          setSelectedKeys={setSelectedKeys}
          width={300}
        />
      ),
    },
    {
      title: 'Data Isenção',
      render: (value, record) => format(new Date(record.createdAt), 'dd/MM/yyyy HH:mm'),
      filterDropdown: ({ clearFilters, confirm, selectedKeys, setSelectedKeys }) => (
        <DateFilter
          clearFilters={clearFilters}
          confirm={() => {
            const { startDate, endDate } = (selectedKeys?.[0] as any) ?? {}
            getIsencao(dispatch, {
              createdAtStart: startDate?.['$d'],
              createdAtEnd: endDate?.['$d'],
            })
            return confirm()
          }}
          selectedKeys={selectedKeys}
          setSelectedKeys={setSelectedKeys}
        />
      ),
      onFilter: (value: any, record) => {
        try {
          const { startDate, endDate } = value
          const date = new Date(record.createdAt)
          return date >= startDate['$d'] && date <= endDate['$d']
        } catch (error) {
          console.log(error)
        }
      },
      filterIcon: (filtered) => (
        <CalendarOutlined style={{ color: filtered ? '#1890ff' : undefined }} />
      ),
    },
    {
      title: 'Motivo',
      render: (value, record) => record.motivoIsencao,
      filterSearch: true,
      filterDropdown: ({ confirm, clearFilters, selectedKeys, setSelectedKeys }) => (
        <InputFilter
          clearFilters={clearFilters}
          confirm={() => {
            getIsencao(dispatch, { motivoIsencao: selectedKeys[0] ? selectedKeys[0] : null })
            return confirm()
          }}
          selectedKeys={selectedKeys}
          setSelectedKeys={setSelectedKeys}
        />
      ),
    },
  ]

  const valorIndex = columns.findIndex((column) => column.title === 'Valor')
  const valorTotal = isencoes.data.reduce((prev, curr) => prev + curr.valorIsento, 0)

  return (
    <ConfigProvider locale={ptBR}>
      <TableAntd
        columns={columns}
        dataSource={isencoes.data}
        pagination={false}
        bordered
        size="small"
        rowKey={'id'}
        onChange={(pagination, filters, sorter, extra) => console.log(extra.currentDataSource)}
        scroll={{ y: heightDestopScroll, x: widthDestopScroll }}
        summary={() => {
          const cells: any[] = columns.map((column, index) => {
            if (index === 0) {
              return (
                <TableAntd.Summary.Cell key={index} index={index}>
                  <strong>Total</strong>
                </TableAntd.Summary.Cell>
              )
            }
            if (index === valorIndex) {
              return (
                <TableAntd.Summary.Cell key={index} index={index}>
                  {formatCurrency(valorTotal)}
                </TableAntd.Summary.Cell>
              )
            }

            return <TableAntd.Summary.Cell key={index} index={index}></TableAntd.Summary.Cell>
          })

          return (
            <TableAntd.Summary fixed>
              <TableAntd.Summary.Row>{cells}</TableAntd.Summary.Row>
            </TableAntd.Summary>
          )
        }}
      />
    </ConfigProvider>
  )
}

function getIsencao(dispatch, value: Partial<Record<keyof TvaluesFilter, any>>) {
  valuesFilter = { ...valuesFilter, ...value }
  dispatch(IsencaoStore.thunks.getIsencoes(valuesFilter))
}

function InputFilter({ setSelectedKeys, selectedKeys, confirm, clearFilters }) {
  const inputRef = React.useRef(null)

  useEffect(() => {
    if (inputRef.current) {
      inputRef.current.focus()
    }
  }, [])

  return (
    <div style={{ padding: 8 }}>
      <Input
        ref={inputRef}
        value={selectedKeys[0] || ''}
        onChange={(e) => setSelectedKeys([e.target.value])}
        placeholder="Pesquisar"
      />
      <div style={{ display: 'flex' }}>
        <button type="button" onClick={() => confirm()} style={{ width: '50%', marginRight: 8 }}>
          Ok
        </button>
        <button type="button" onClick={() => clearFilters()} style={{ width: '50%' }}>
          Resetar
        </button>
      </div>
    </div>
  )
}

function SelectFilter({ setSelectedKeys, selectedKeys, confirm, clearFilters, width = 200 }) {
  const inputRef = React.useRef(null)

  useEffect(() => {
    if (inputRef.current) {
      inputRef.current.focus()
    }
  }, [])

  const filterOption = (input: string, option?: { label: string; value: string }) =>
    (option?.label ?? '').toLowerCase().includes(input.toLowerCase())

  return (
    <div style={{ width: '100%', padding: 8 }}>
      <Select
        ref={inputRef}
        showSearch
        value={selectedKeys[0] || ''}
        defaultActiveFirstOption={false}
        showArrow={false}
        filterOption={filterOption}
        onChange={(value) => setSelectedKeys([value])}
        notFoundContent={null}
        placeholder="Pesquisar"
        style={{ width, marginBottom: 8 }}
        options={Object.keys(CURSOS)
          .filter((f: any) => isNaN(f))
          .map((m) => ({ label: m.replace(/_/g, ' '), value: CURSOS[m] }))}
      />
      <div style={{ display: 'flex' }}>
        <button type="button" onClick={() => confirm()} style={{ width: '50%', marginRight: 8 }}>
          Ok
        </button>
        <button type="button" onClick={() => clearFilters()} style={{ width: '50%' }}>
          Resetar
        </button>
      </div>
    </div>
  )
}

function DateFilter({ setSelectedKeys, selectedKeys, confirm, clearFilters }) {
  return (
    <div style={{ padding: 8 }}>
      <DatePicker.RangePicker
        value={[selectedKeys?.[0]?.startDate, selectedKeys?.[0]?.endDate]}
        onChange={(dates) => {
          try {
            const startDate = dates[0]
            const endDate = dates[1]
            const dateRange = { startDate, endDate }
            setSelectedKeys([dateRange])
          } catch (error) {
            console.log(error)
          }
        }}
        style={{ marginBottom: 8, display: 'block' }}
      />
      <button type="button" onClick={() => confirm()} style={{ width: '50%', marginRight: 8 }}>
        Ok
      </button>
      <button type="button" onClick={() => clearFilters()} style={{ width: '50%' }}>
        Resetar
      </button>
    </div>
  )
}

function Pagination() {
  const { isencoes } = IsencaoStore.useState()
  const dispatch = IsencaoStore.useDispatch()

  const onChangePagination: PaginationProps['onChange'] = (pageNumber: number, limit: number) => {
    getIsencao(dispatch, { pageNumber, limit })
  }
  return (
    <div style={{ padding: 10 }}>
      <ConfigProvider locale={ptBR}>
        <PaginationAntd
          showSizeChanger
          defaultPageSize={defaultPageSize}
          onChange={onChangePagination}
          current={isencoes?.currentPage}
          total={isencoes?.totalRecords}
          showTotal={(total, range) => `${range[0]}-${range[1]} de ${total}`}
        />
      </ConfigProvider>
    </div>
  )
}

function onFilter({ valueFiltered, recordValue }: { valueFiltered: string; recordValue: string }) {
  try {
    const normalizedValue = removeAccents(`${valueFiltered}`?.toUpperCase())
    const normalizedCourseName = removeAccents(`${recordValue}`?.toUpperCase() || '')
    return normalizedCourseName.includes(normalizedValue)
  } catch (error) {
    console.log(error)
  }
}
