export const PERMISSOES = {}

export const PERFIS = {
  ADMINISTRADOR: 'ADMINISTRADOR',
  TUTOR: 'TUTOR',
  AUDITOR: 'AUDITOR',
  ALUNO: 'ALUNO',
}

export const TIPOS_CONTEUDO = {
  TITULO: 'TITULO',
  SUBTITULO: 'SUBTITULO',
  TEXTO: 'TEXTO',
  IMAGEM: 'IMAGEM',
  VIDEO: 'VIDEO',
  QUESTAO: 'QUESTAO',
  COLUNAS: 'COLUNAS',
  LIVE: 'LIVE',
}

export const LOCAIS_QUESTOES = {
  AVALIACAO: 'AVALIACAO',
  CONTEUDO: 'CONTEUDO',
}
