import axios from 'axios'
import Axios from '../../axios'
import { UsuarioModel } from '../../models/UsuarioModel'
import { ProgressoModel } from '../../models/ProgressoModel'
import { MatriculaModel } from '../../models/MatriculaModel'
import { ValidacaoFacialModel } from '../../models/ValidacaoFacialModel'
import { toast } from 'react-toastify'
import { EstatisticaEstudoModel } from '../../models/EstatisticaEstudoModel'
import { createSimpleStore } from '../../components/SimpleReducer'

export const ProgressoStore = createSimpleStore(
  {
    matricula: null as MatriculaModel,
    dadosAluno: null as UsuarioModel | null,
    sessoesEstudo: [] as any[],
    progressos: [] as ProgressoModel[],
    validacoesFaciais: [] as ValidacaoFacialModel[],
    estatisticasDeEstudo: [] as EstatisticaEstudoModel[],
    avaliacoes: [] as any[],
    outrosCursos: [] as any,
    token: null as string | null,
    estados: [] as any[],
    municipios: [] as any[],
    loading: false,
  },
  {
    getDadosSuccess(
      state,
      {
        matricula,
        dadosAluno,
        sessoesEstudo,
        estatisticasEstudo,
        validacoesFaciais,
        avaliacoes,
        progressos,
        outrosCursos,
      }
    ) {
      state.matricula = matricula
      state.dadosAluno = dadosAluno
      state.sessoesEstudo = sessoesEstudo
      state.validacoesFaciais = validacoesFaciais
      state.avaliacoes = avaliacoes
      state.estatisticasDeEstudo = estatisticasEstudo
      state.progressos = progressos
      state.outrosCursos = outrosCursos
    },
    getDadosAlunoSuccess(state, dadosAluno) {
      state.dadosAluno = dadosAluno
    },
    updateDadosAluno(state, dadosAluno) {
      state.dadosAluno = { ...state.dadosAluno, ...dadosAluno }
    },
    gerarNovoTokenSuccess(state, token) {
      state.token = token
    },
    reemitirBoletoSuccess(state, newCobranca) {
      state.matricula.cobranca = newCobranca
      state.matricula.referenciaPagamento = newCobranca.id
    },
    getDadosEstados(state, estados) {
      state.estados = estados
    },
    getDadosMunicipio(state, municipio) {
      state.municipios = municipio
    },
    setLoading(state, payload) {
      state.loading = payload
    },
    changePermiteEmissaoCertificado(state, payload: boolean) {
      state.matricula.permiteEmissaoCertificado = payload
    },
  },
  {
    thunks: {
      getDadosAluno() {
        return async (dispatch, getState) => {
          try {
            const dadosAluno = await axios
              .get(`/usuarios/cpf/${getState().dadosAluno.cpf}`)
              .then((rs) => rs.data)
            dispatch(ProgressoStore.actions.getDadosAlunoSuccess(dadosAluno))
          } catch (error) {
            const message = error?.response?.data.message ?? 'Erro ao buscar dados do aluno!'
            toast(message)
          }
        }
      },
      getDados(alunoId: number, matriculaId: number) {
        return async (dispatch) => {
          try {
            const dados = await getDados(alunoId, matriculaId)

            dispatch(
              ProgressoStore.actions.getDadosSuccess({
                matricula: dados.matricula,
                outrosCursos: dados.outrosCursos,
                ...dados.progressos,
              })
            )
          } catch (error) {
            const message = error?.response?.data.message ?? 'Erro ao buscar dados!'
            toast(message)
          }
        }
      },
      updateDadosAluno() {
        return async (dispatch, getState) => {
          try {
            const {
              cpf,
              nome,
              telefone,
              email,
              estado,
              municipio,
              dataPrimeiraHabilitacao,
              ignorarValidacaoSerpro,
              dataNascimento,
              rg,
              categoriaCnh,
            } = getState().dadosAluno

            const matriculaId = getState().matricula.id

            await axios.put(`/usuarios/${cpf}`, {
              nome,
              telefone,
              email,
              estado,
              municipio,
              dataPrimeiraHabilitacao,
              ignorarValidacaoSerpro,
              dataNascimento,
              rg,
              categoriaCnh,
              matriculaId,
            })

            toast('Dados do aluno atualizados com successo!')
          } catch (error) {
            const message = error?.response?.data.message ?? 'Erro ao atualizar dados do aluno!'
            toast(message)
          }
        }
      },
      gerarNovoToken() {
        return async (dispatch, getState) => {
          try {
            const dadosAluno = getState().dadosAluno
            const data = await axios
              .put('/usuarios/cria-token-mudanca-senha', { usuarioId: dadosAluno.id })
              .then((x) => x?.data)
            dispatch(ProgressoStore.actions.gerarNovoTokenSuccess(data))
          } catch (error) {
            const message = error?.response?.data.message ?? 'Erro ao gerar novo token!'
            toast(message)
          }
        }
      },
      reemitirCobranca() {
        return async (dispatch, getState) => {
          try {
            const newCobranca = await axios
              .post(`/matriculas/reemitir-cobranca/${getState().matricula?.id}`)
              .then((rs) => rs?.data)
            dispatch(ProgressoStore.actions.reemitirBoletoSuccess(newCobranca))
            toast('Cobrança reemitida com sucesso!')
          } catch (error) {
            const message = error?.response?.data.message ?? 'Erro ao reemitir Cobrança!'
            toast(message)
          }
        }
      },
      getEstados() {
        return async (dispatch) => {
          try {
            const estados = await axios
              .get(`https://servicodados.ibge.gov.br/api/v1/localidades/estados`, {
                params: {
                  orderBy: 'nome',
                },
              })
              .then((rs) => rs.data)
            dispatch(ProgressoStore.actions.getDadosEstados(estados))
          } catch (error) {
            const message = error?.response?.data.message ?? 'Erro ao buscar os municípios!'
            toast(message)
          }
        }
      },
      getMunicipios({ estados }) {
        return async (dispatch) => {
          try {
            const municipios = await axios
              .get(
                `https://servicodados.ibge.gov.br/api/v1/localidades/estados/${estados}/municipios`
              )
              .then((rs) => rs.data)
            dispatch(ProgressoStore.actions.getDadosMunicipio(municipios))
          } catch (error) {
            const message = error?.response?.data.message ?? 'Erro ao buscar os municípios!'
            toast(message)
          }
        }
      },
      cancelarMatricula(matricula: MatriculaModel) {
        return async (dispatch) => {
          try {
            const cancelarMatricula = await axios
              .put('/matriculas/cancelar-matricula', { matriculaId: matricula.id })
              .then((res) => res.data)

            toast.success(cancelarMatricula?.successMessage ?? 'Matrícula cancelada com sucesso!')

            const dados = await getDados(matricula.alunoId, matricula.id)

            dispatch(
              ProgressoStore.actions.getDadosSuccess({
                matricula: dados.matricula,
                outrosCursos: dados.outrosCursos,
                ...dados.progressos,
              })
            )
          } catch (error) {
            if (error?.response?.data) {
              toast.error(error?.response?.data?.message)
            }
          }
        }
      },
      cancelarConclusaoMatricula(matricula: MatriculaModel) {
        return async (dispatch) => {
          try {
            const cancelarConclusao = await axios
              .put('/matriculas/cancelar-conclusao', { matriculaId: matricula.id })
              .then((res) => res.data)

            toast.success(
              cancelarConclusao?.successMessage ?? 'Conclusão do curso cancelado com sucesso!'
            )

            const dados = await getDados(matricula.alunoId, matricula.id)

            dispatch(
              ProgressoStore.actions.getDadosSuccess({
                matricula: dados.matricula,
                outrosCursos: dados.outrosCursos,
                ...dados.progressos,
              })
            )
          } catch (error) {
            if (error?.response?.data) {
              toast.error(error?.response?.data?.message)
            }
          }
        }
      },
      consultarMatricula(matricula: MatriculaModel) {
        return async (dispatch) => {
          try {
            const validar = await axios
              .post('/matriculas/validar', {
                cpf: matricula.aluno.cpf,
                dataNascimento: matricula.aluno.dataNascimento,
                cnh: matricula.aluno.cnh,
                cursoId: matricula.cursoId,
                renach: matricula.renach,
                uf: matricula.uf,
                dataPrimeiraHabilitacao: matricula.aluno.dataPrimeiraHabilitacao,
                municipio: matricula.aluno.municipio,
                categoriaCnh: matricula.aluno.categoriaCnh,
              })
              .then((res) => res.data)

            toast.success(validar?.successMessage ?? 'Validação feita com sucesso!')

            const dados = await getDados(matricula.alunoId, matricula.id)

            dispatch(
              ProgressoStore.actions.getDadosSuccess({
                matricula: dados.matricula,
                outrosCursos: dados.outrosCursos,
                ...dados.progressos,
              })
            )
          } catch (error) {
            if (error?.response?.data?.message) {
              toast.error(error?.response?.data?.message)
            }
          }
        }
      },
      emitirIsencao({
        alunoId,
        matriculaId,
        motivoIsencao,
      }: {
        alunoId: number
        matriculaId: number
        motivoIsencao: string
      }) {
        return async (dispatch, getState) => {
          try {
            dispatch(ProgressoStore.actions.setLoading(true))
            const emitirIsencao = await Axios.Isencao.emitirIsencao(matriculaId, motivoIsencao)
            const dados = await getDados(alunoId, matriculaId)

            dispatch(
              ProgressoStore.actions.getDadosSuccess({
                matricula: dados.matricula,
                outrosCursos: dados.outrosCursos,
                ...dados.progressos,
              })
            )
            dispatch(ProgressoStore.actions.setLoading(false))
          } catch (error) {
            console.log(error)
            dispatch(ProgressoStore.actions.setLoading(false))
          }
        }
      },
      updatePermissaoCertificado(matriculaId: number, permiteEmissaoCertificado: boolean) {
        return async (dispatch, getState) => {
          try {
            dispatch(ProgressoStore.actions.setLoading(true))
            await axios.put('/matriculas/permissao-certificado', {
              matriculaId,
              permiteEmissaoCertificado,
            })
            toast.success('Permissão de emissão de certificado alterado!')
            dispatch(ProgressoStore.actions.setLoading(false))
          } catch (error: any) {
            dispatch(ProgressoStore.actions.setLoading(false))
            const message = error?.response?.data.message ?? 'Erro ao reemitir Cobrança!'
            toast.error(message)
          }
        }
      },
      reenviarConclusao(matricula: MatriculaModel, modulo: 'TEORICO' | 'PRATICO') {
        return async (dispatch, getState) => {
          try {
            dispatch(ProgressoStore.actions.setLoading(true))
            await axios.post('/matriculas/detran-concluir', {
              matriculaId: matricula.id,
              modulo,
              ignorarFinalizado: true,
            })
            toast.success('Conclusão reenviada com sucesso!')
            getDados(matricula.aluno.id, matricula.id)
          } catch (error: any) {
            const message = error?.response?.data.message ?? 'Erro ao reenviar conclusão!'
            toast.error(message)
          } finally {
            dispatch(ProgressoStore.actions.setLoading(false))
          }
        }
      },
    },
  }
)

const getDados = async (alunoId: number, matriculaId: number) => {
  const [matricula, progressos, outrosCursos] = await Promise.all([
    axios.get(`/matriculas/${matriculaId}`).then((rs) => rs.data),
    axios.get(`/progressos/aluno/${alunoId}`, { params: { matriculaId } }).then((rs) => rs.data),
    axios.get(`/usuarios/cursos/${alunoId}`).then((response) => response.data),
  ])

  return { matricula, progressos, outrosCursos }
}
