import React, { useState, useEffect, useMemo } from 'react'
import { ForunsView } from './ForunsView'
import axios, { ICounter, IQueryParams } from '../../axios'
import { ForumMensagemModel } from '../../models/ForumMensagemModel'
import { ForumModel } from '../../models/ForumModel'

const useForunsStore = () => {
  const [foruns, _setForuns] = useState<ICounter<ForumModel>>({ count: 0, rows: [] })
  const [forunsMensagensPendentes, _setForunsMensagensPendentes] = useState<
    ICounter<ForumMensagemModel>
  >({ count: 0, rows: [] })
  const [forum, _setForum] = useState<ForumModel>(null)
  const [selectedForumMensagem, _setSelectedForumMensagem] = useState<ForumMensagemModel>(null)
  const [forumMensagemEditing, _setForumMensagemEditing] = useState<ForumMensagemModel>(
    new ForumMensagemModel()
  )
  const [queryParams, _setQueryParams] = useState<IQueryParams>({ limit: 25, offset: 0 })
  const [queryParamsPendentes] = useState<IQueryParams>({ limit: 25, offset: 0 })

  useEffect(() => {
    initialize()
  }, [])

  async function initialize() {
    getForuns()
    getMensagensPendentes()
  }

  function setQueryParams(query: IQueryParams) {
    _setQueryParams({ ...queryParams, ...query })
  }

  function setQueryParamsPendentes(query: IQueryParams) {
    setQueryParamsPendentes({ ...queryParamsPendentes, ...query })
  }

  async function getForuns() {
    try {
      const $foruns = await axios.Foruns.getAll(queryParams)
      _setForuns($foruns)
    } catch (error) {
      /* setErrorMessage(error.message) */
    }
  }

  async function getMensagensPendentes() {
    try {
      const forunsPendentes = await axios.Foruns.getForunsMensagensPendentes(queryParamsPendentes)
      _setForunsMensagensPendentes(forunsPendentes)
    } catch (error) {
      /* setErrorMessage(error.message) */
    }
  }

  async function setForum(forumId: number) {
    if (!forumId) {
      _setForum(undefined)
      _setSelectedForumMensagem(undefined)
      return
    }
    try {
      const [$forum, forunsMensagens] = await Promise.all([
        axios.Foruns.getOne(forumId),
        axios.Foruns.getForunsMensagensByForum(forumId),
      ])
      _setForum({ ...$forum, forunsMensagens })
    } catch (error) {
      /* setErrorMessage(error.message) */
    }
  }

  async function setForumMensagem(forumMensagem: ForumMensagemModel) {
    _setSelectedForumMensagem(forumMensagem)
    await setForum(forumMensagem.forumId)
    document.querySelector('.forum-mensagem-selected').scrollIntoView()
  }

  async function aprovar(forumMensagem: ForumMensagemModel) {
    try {
      await axios.Foruns.aprovarForum(forumMensagem.id)
      _setForunsMensagensPendentes(await axios.Foruns.getForunsMensagensPendentes())
      setForum(forumMensagem.forumId)
    } catch (error) {
      /* setErrorMessage(error.message) */
    }
  }

  async function rejeitar(forumMensagem: ForumMensagemModel) {
    try {
      await axios.Foruns.rejeitar(forumMensagem.id)
      _setForunsMensagensPendentes(await axios.Foruns.getForunsMensagensPendentes())
      setForum(forumMensagem.forumId)
    } catch (error) {
      /* setErrorMessage(error.message) */
    }
  }

  function setEditaForumMensagem(forumMensagem: ForumMensagemModel) {
    _setForumMensagemEditing(forumMensagem)
  }

  function setMensagemResposta(e: any) {
    _setForumMensagemEditing({ ...forumMensagemEditing, texto: e.target.value })
  }

  async function enviarMensagemResposta() {
    try {
      forumMensagemEditing.forumId = forum.id
      await axios.ForunsMensagens.createOrUpdate(forumMensagemEditing)
      setForum(forum.id)
      _setForumMensagemEditing(new ForumMensagemModel())
    } catch (error) {
      /* setErrorMessage(error.message) */
    }
  }

  function cancelarMensagemResposta() {
    _setForumMensagemEditing(new ForumMensagemModel())
  }

  function aprovarForum() {
    axios.Foruns.aprovarForum(forum.id)
  }

  const qtdPendenteModeracao = useMemo(() => {
    return forunsMensagensPendentes.count
  }, [forunsMensagensPendentes])

  return {
    foruns,
    forunsMensagensPendentes,
    forum,
    selectedForumMensagem,
    forumMensagemEditing,
    queryParams,
    queryParamsPendentes,
    setQueryParams,
    setQueryParamsPendentes,
    getForuns,
    getMensagensPendentes,
    setForum,
    setForumMensagem,
    aprovar,
    rejeitar,
    setEditaForumMensagem,
    setMensagemResposta,
    enviarMensagemResposta,
    cancelarMensagemResposta,
    qtdPendenteModeracao,
    aprovarForum,
  }
}

export type ForunsStoreValues = ReturnType<typeof useForunsStore>
export const ForunsStoreCtx = React.createContext(null)
export const Foruns = () => {
  const Values = useForunsStore()
  return (
    <ForunsStoreCtx.Provider value={Values}>
      <ForunsView />
    </ForunsStoreCtx.Provider>
  )
}
