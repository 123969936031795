import React from 'react'
import styled from '@emotion/styled'
import { animated, useSpring } from 'react-spring'
import AnimateHeight from 'react-animate-height'
import { QuestaoModel } from '../../../../models/QuestaoModel'
import { RespostaModel } from '../../../../models/RespostaModel'
import { QuestaoAlternativaModel } from '../../../../models/QuestaoAlternativaModel'

const LETRA = 'ABCDEFGHIJKLMNOPQRSTU'.split('')
type TStatusAlternativa = 'correta' | 'incorreta' | 'marcada' | ''

export const MultiplaEscolha = ({ questao }: { questao: QuestaoModel }) => {
  const questoesAlternativas = questao.questoesAlternativas || []
  const alternativaCorreta = questoesAlternativas.find((x) => x.respostaEsperada === 'true')
  const possuiRespostaCorreta = true
  if (!alternativaCorreta) return <div>A Questão não está configurada corretamente</div>
  return (
    <Styles.AlternativasContainer>
      {questoesAlternativas.map((alternativa, index) => {
        const statusAlternativa = alternativa.respostaEsperada === 'true' ? 'marcada' : 'incorreta'
        return (
          <Alternativa
            key={alternativa.id}
            alternativa={alternativa}
            index={index}
            statusAlternativa={statusAlternativa}
            possuiRespostaCorreta={possuiRespostaCorreta}
          />
        )
      })}
    </Styles.AlternativasContainer>
  )
}

const Alternativa = ({
  alternativa = {} as QuestaoAlternativaModel,
  index,
  statusAlternativa,
  possuiRespostaCorreta,
}) => {
  if (possuiRespostaCorreta && statusAlternativa === 'incorreta') {
    statusAlternativa = ''
  }
  const background = {
    '': '#BDBDBD',
    incorreta: '#EB5757',
    correta: '#27AE60',
    marcada: '#4D4D4D',
  }[statusAlternativa]
  const borderColor = {
    '': '#E0E0E0',
    incorreta: '#EB5757',
    correta: '#27AE60',
    marcada: '#4D4D4D',
  }[statusAlternativa]
  const springLetra = useSpring({ background })
  const springDescricao = useSpring({ borderColor })

  return (
    <Styles.AlternativaContainer>
      <Styles.Alternativa>
        <animated.div style={springLetra} className="alternativa-letra">
          {LETRA[index]}
        </animated.div>
        <animated.div
          style={springDescricao}
          className="alternativa-descricao"
          dangerouslySetInnerHTML={{ __html: alternativa.conteudoAlternativa || '' }}
        />
      </Styles.Alternativa>
      <Feedback
        texto={alternativa.feedback}
        statusAlternativa={statusAlternativa}
        possuiRespostaCorreta={possuiRespostaCorreta}
      />
    </Styles.AlternativaContainer>
  )
}

function Feedback({ texto, statusAlternativa, possuiRespostaCorreta }) {
  let showFeedback = false
  let tipo: 'correta' | 'incorreta' | 'neutra' = 'neutra'

  if (possuiRespostaCorreta) {
    if (statusAlternativa === 'correta') tipo = 'correta'
    if (texto) showFeedback = true
  } else {
    if (statusAlternativa === 'incorreta') {
      tipo = 'incorreta'
      if (!texto) texto = 'Resposta errada!'
      showFeedback = true
    }
  }
  return (
    <AnimateHeight duration={500} height={showFeedback ? 'auto' : 0}>
      <Styles.Feedback tipo={tipo}>{texto}</Styles.Feedback>
    </AnimateHeight>
  )
}

const Styles = {
  AlternativasContainer: styled('div')`
    margin-bottom: 40px;
    @media screen and (max-width: 425px) {
      margin-bottom: 20px;
    }
  `,
  AlternativaContainer: styled('div')`
    &:not(:last-child) {
      margin-bottom: 16px;
      @media screen and (max-width: 425px) {
        margin-bottom: 12px;
      }
    }
  `,
  Alternativa: styled('div')`
    display: flex;
    align-items: strech;
    background: white;
    cursor: pointer;
    --border-radius: 3px;
    box-shadow: 4px 4px 4px rgba(0, 0, 0, 0.08);
    .alternativa-descricao {
      border-top: 2px solid;
      border-right: 2px solid;
      border-bottom: 2px solid;
      border-color: #e0e0e0;
      border-top-right-radius: var(--border-radius);
      border-bottom-right-radius: var(--border-radius);
      font-family: Rubik;
      font-style: normal;
      font-weight: normal;
      font-size: 18px;
      line-height: 21px;
      color: #4d4d4d;
      flex: 1;
      padding: 8px 24px 8px 24px;
      display: flex;
      align-items: center;
    }
    .alternativa-letra {
      display: flex;
      font-weight: bold;
      justify-content: center;
      align-items: center;
      width: 64px;
      min-height: 64px;
      background: #bdbdbd;
      border-top-left-radius: var(--border-radius);
      border-bottom-left-radius: var(--border-radius);
      font-family: Rubik;
      font-style: normal;
      font-weight: 500;
      font-size: 32px;
      line-height: 38px;
      display: flex;
      color: #ffffff;
    }
    &:hover {
      background: #f7f7f7;
    }
    @media screen and (max-width: 425px) {
      .alternativa-descricao {
        font-size: 16px;
        line-height: 19px;
        padding: 4px 12px 4px 12px;
      }
      .alternativa-letra {
        width: 52px;
        min-height: 52px;
        font-size: 28px;
        line-height: 34px;
      }
    }
  `,
  Feedback: styled('div')<{ tipo }>(
    (props) => `
    ${props.tipo === 'correta' ? 'color: #27AE60;' : ''};
    ${props.tipo === 'incorreta' ? 'color: #EB5757;' : ''};
    ${props.tipo === 'neutra' ? 'color: #4D4D4D;' : ''};
    font-family: Rubik;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 19px;
    margin-top: 8px;
    padding-bottom: 4px;
    @media screen and (max-width: 425px) {
      font-size: 14px;
      line-height: 17px;
      margin-top: 4px;
      padding-bottom: 2px;
    }
  `
  ),
  BotoesContainer: styled('div')`
    text-align: center;
    ion-button {
      height: 48px;
      --background: var(--primary-color);
      --background-focused: var(--primary-color);
      --color: var(--primary-color-contrast, #fff);
      --background-activated: var(--primary-color-shade, #fff);
    }
    ion-button.envia-resposta {
      --background: white;
      --background-focused: #f7f7f7;
      --background-activated: #f7f7f7;
      --color: var(--primary-color);
      --color-activated: var(--primary-color-shade);
      --color-focused: var(--primary-color);
      --border-width: 2px;
      --border-color: var(--primary-color);
      --border-style: solid;
    }
  `,
}
