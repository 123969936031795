import React, { Component, useRef, useEffect } from 'react'
import { ContainersArquivos, Util } from '../Util'
import { ButtonsContainer } from './ButtonsContainer'
import { CustomEditorView } from './CustomEditorView'
import { ConfigImage } from './ConfigImage'
import styled from '@emotion/styled'

import debounce from 'lodash.debounce'
import { Show } from '../Basic'
import { Icon, Popup } from 'semantic-ui-react'
import Axios from 'axios'

interface IProps extends React.HTMLAttributes<HTMLDivElement> {
  text?: string
  textOnly?: boolean
  toolbarType?: 'NONE' | 'HOVER' | 'FIXED'
  placeholder?: string
  onTab?: (e: any) => void
  onEnter?: (e: any) => void
  onDelete?: (e: any) => void
  onBackspace?: (e: any) => void
  onBlur?: (e: any) => void
  onFocus?: (e: any) => void
  onKeyDown?: (e: any) => void
}

export const Editor = ({ className, style, textOnly, ...props }: IProps) => {
  const { ContentEditable, Container } = Editor
  const ref = useRef<HTMLDivElement>()
  useEffect(() => {
    if (ref.current && props.text !== ref.current.innerHTML) {
      ref.current.innerHTML = props.text
    }
  }, [props.text])
  const debouncedBlur = useRef(debounce(forceUpdate, 500))
  function handlePaste(e) {
    e.preventDefault()
    const text = (e.originalEvent || e).clipboardData.getData('text/plain')
    document.execCommand('insertHTML', false, text)
  }
  function handleBlur(e) {
    props.onBlur?.(e)
  }
  function handleFocus(e) {
    props.onFocus?.(e)
  }
  function handleKeyDown(e) {
    if (e.key === 'Escape') e.target.blur()
    debouncedBlur.current()
    props.onKeyDown?.(e)
  }
  function forceUpdate() {
    if (!ref.current) return
    handleBlur({ currentTarget: ref.current })
  }

  return (
    <Container className={className} style={style}>
      <Show condition={props.toolbarType === 'FIXED'}>
        <Toolbar forceUpdate={forceUpdate} editableRef={ref} />
      </Show>
      <ContentEditable
        contentEditable={textOnly ? ('plaintext-only' as any) : true}
        {...props}
        onPaste={handlePaste}
        onBlur={handleBlur}
        onFocus={handleFocus}
        onKeyDown={handleKeyDown}
        ref={ref}
        toolbarType={props.toolbarType}
      />
    </Container>
  )
}
Editor.Container = styled.div`
  border: 1px solid #c4c4c4;
  border-radius: 4px;
`
Editor.ContentEditable = styled.div<{ toolbarType: 'NONE' | 'HOVER' | 'FIXED' }>(
  (props) => `
  &:empty:before{
    content: attr(placeholder);
    color: #ccc;
  }
  padding: 12px;
  outline: none;
  color: #2D2D2D;
  ${
    props.toolbarType === 'FIXED'
      ? `
      border-top-right-radius: 0;
      border-top-left-radius: 0;
      border-top: 0;
    `
      : ''
  }
`
)

export const EditorPreview = ({ html }) => {
  const { Layout } = EditorPreview
  const ref = React.useRef<HTMLDivElement>(null)

  useEffect(() => {
    if (html && ref.current) {
      ref.current.querySelectorAll('img').forEach((img) => {
        const blobPath = img.getAttribute('data-src')
        Axios.get(`/arquivos/?blobPath=${blobPath}`).then((r) => {
          const blobUrl = r.data.blobUrl
          img.setAttribute('src', blobUrl)
        })
      })
    }
  }, [html, ref])

  return <Layout ref={ref} dangerouslySetInnerHTML={{ __html: html }} />
}
EditorPreview.Layout = styled.div`
  img {
    max-width: 100%;
  }
`

const Toolbar = ({ forceUpdate, editableRef }) => {
  const { Container } = Toolbar

  function execCommand(e: any, command: string) {
    e.preventDefault()
    e.stopPropagation()
    document.execCommand(command, false)
    forceUpdate?.()
  }

  async function openImagePanel() {
    const image = await Util.requestUploadImagem({ containerName: ContainersArquivos.QUESTAO })
    if (!image) return

    const { blobUrl: urlWithSas } = await Axios.get(`/arquivos/?blobPath=${image}`).then(
      (x) => x?.data
    )
    const img = `<img src='${urlWithSas}' data-src='${image}'>`
    editableRef.current.innerHTML += img
    forceUpdate?.()
  }

  return (
    <Container>
      <Button icon="bold" onMouseDown={(e: any) => execCommand(e, 'bold')} tooltip="Negrito" />
      <Button icon="italic" onMouseDown={(e: any) => execCommand(e, 'italic')} tooltip="Itálico" />
      <Button
        icon="strikethrough"
        onMouseDown={(e: any) => execCommand(e, 'strikeThrough')}
        tooltip="Riscar"
      />
      <Button icon="image" onMouseDown={openImagePanel} tooltip="Imagem" />
    </Container>
  )
}
Toolbar.Container = styled.div`
  display: flex;
  height: 38px;
  background: #f2f2f2;
  align-items: center;
  border-top-left-radius: 3px;
  border-top-right-radius: 3px;
  padding: 0 8px;
  border-bottom: 1px solid #c4c4c4;
`

const Button = ({ icon, tooltip, onMouseDown, ...props }) => {
  const { Container } = Button
  return (
    <Popup
      content={tooltip}
      trigger={
        <Container onMouseDown={onMouseDown} {...props}>
          <Icon name={icon} />
        </Container>
      }
    />
  )
}
Button.Container = styled.div`
  i {
    margin: 0;
  }
  padding: 5px;
  border-radius: 4px;
  &:hover {
    background: #ccc;
    cursor: pointer;
  }
  margin-left: 4px;
`

export const CustomEditor = React.memo(Editor)
