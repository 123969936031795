// import React, { useState, useEffect, useContext } from 'react'
// import { ChatMensagemView } from './ChatView'
// import { GlobalStoreValues, GlobalStoreCtx } from '../../GlobalStore'
// import socket from '../../socket'
// import { ChatMensagemModel } from '../../models/ChatMensagemModel'
// import { ChatModel } from '../../models/ChatModel'
// import axios, { ICounter } from '../../axios'

// let socketNovaMensagem: any
// const useChatMensagemStore = (chatId: number) => {
//   const { setErrorMessage, usuario } = useContext<GlobalStoreValues>(GlobalStoreCtx)

//   const [conversas, _setConversas] = useState<ICounter<ChatModel>>({ count: 0, rows: [] })
//   const [chats, _setChats] = useState<ChatModel>(null)
//   const [chatMensagens, _setChatMensagens] = useState<ChatMensagemModel[]>([])

//   useEffect(() => {
//     _getChatMensagens()
//   }, [])

//   useEffect(() => {
//     _startSocket()
//     return () => {
//       if (socketNovaMensagem) socketNovaMensagem()
//     }
//   }, [usuario, chats, chatMensagens])

//   async function _startSocket() {
//     socketNovaMensagem = await socket.Chat.novaMensagem(_addMensagemViaSocket)
//   }

//   function _addMensagemViaSocket({ chatMensagem }: { chatMensagem: ChatMensagemModel }) {
//     if (+chatMensagem.usuarioId !== +usuario.id && +chatMensagem.chatId === +chats.id) {
//       _setChatMensagens([...chatMensagens, chatMensagem])
//       _scrollToBottom()
//     }
//   }

//   async function _getChatMensagens() {
//     try {
//       // tslint:disable-next-line:no-shadowed-variable
//       const [chatMensagens, chat] = await Promise.all([
//         axios.Chat.getMessagesByChatId(chatId),
//         axios.Chat.getChatById(chatId),
//       ])
//       _setChatMensagens(chatMensagens)
//       _setChat(chat)
//       _scrollToBottom()
//     } catch (error) { setErrorMessage(error.message) }
//   }

//   function _scrollToBottom() {
//     setTimeout(() => {
//       const element = document.querySelector('#container-chat-scrollable')
//       if (element) element.scrollTo(0, element.scrollHeight)
//     })
//   }

//   async function sendMensagem(mensagem: string) {
//     try {
//       const mensagemCriada = await axios.Chat.sendMessage(mensagem, chatId)
//       _setChatMensagens([...chatMensagens, mensagemCriada])
//       _scrollToBottom()
//       return true
//     } catch (error) { setErrorMessage(error.message) }
//   }

//   return {
//     chats,
//     chatMensagens,
//     sendMensagem,
//   }
// }

// export type ChatMensagemStoreValues = ReturnType<typeof useChatMensagemStore>
// export const ChatMensagemStoreCtx = React.createContext(null)
// export const ChatMensagem = ({ match }: any) => {
//   const Values = useChatMensagemStore(match.params.chatId)
//   return (
//     <ChatMensagemStoreCtx.Provider value={Values}>
//       <ChatMensagemView />
//     </ChatMensagemStoreCtx.Provider>
//   )
// }

import React, { useState, useEffect, useContext, useRef, useMemo } from 'react'
import { ChatView } from './ChatView'
import { ChatMensagemModel } from '../../models/ChatMensagemModel'
import { GlobalStoreValues, GlobalStoreCtx } from '../../GlobalStore'
import axios, { ICounter } from '../../axios'
import { ChatModel } from '../../models/ChatModel'
import socket from '../../socket'

let socketNovaMensagem: any
const useChatStore = () => {
  const { setErrorMessage } = useContext<GlobalStoreValues>(GlobalStoreCtx)
  const [chatIdAtual, _setChatIdAtual] = useState<number>(null)
  const [chats, _setChats] = useState<ICounter<ChatModel>>({ count: 0, rows: [] })
  const [redirectTo, _setRedirectTo] = useState('')
  const refScrollElement = useRef<HTMLElement>()

  useEffect(() => {
    _getChats()
  }, [])

  useEffect(() => {
    socket.Chat.novaMensagem(_addMensagemViaSocket).then((x) => {
      socketNovaMensagem = x
    })
    return () => (socketNovaMensagem ? socketNovaMensagem() : null)
  }, [chats, chatIdAtual])

  useEffect(() => {
    if (!chatIdAtual || chatMensagens.length >= 20) return
    axios.Chat.updateMensagensVisualizadas(chatIdAtual)
    axios.Chat.getMessagesByChatId(chatIdAtual).then((mensagens) => {
      const newChat = chats.rows.find((x) => x.id === chatIdAtual)
      newChat.chatsMensagens = mensagens
      newChat.pendentes = 0
      _setChats({
        rows: chats.rows.map((x) => (x.id !== chatIdAtual ? x : newChat)),
        count: chats.count,
      })
      _scrollToBottom()
    })
  }, [chatIdAtual])

  function _addMensagemViaSocket({ chatMensagem }: { chatMensagem: ChatMensagemModel }) {
    if (chats.rows.some((x) => x.id === chatMensagem.chatId))
      _addChatMensagem(chatMensagem, chatMensagem.chatId)
    if (chatIdAtual === chatMensagem.chatId) axios.Chat.updateMensagensVisualizadas(chatIdAtual)
  }

  async function _getChats() {
    try {
      _setChats(await axios.Chat.getChatsByUsuarioLogado())
    } catch (error) {
      setErrorMessage(error.message)
    }
  }

  function goToContactList() {
    _setRedirectTo('chat/alunos')
  }

  async function sendMensagem(mensagem: string) {
    try {
      const mensagemCriada = await axios.Chat.sendMessage(mensagem, chatIdAtual)
      _addChatMensagem(mensagemCriada)
      return true
    } catch (error) {
      setErrorMessage(error.message)
    }
  }

  function _addChatMensagem(chatMensagem: ChatMensagemModel, chatId?: number) {
    if (!chatId) chatId = chatIdAtual
    const $chat = chats.rows.find((x) => x.id === chatId)
    const novoChat: ChatModel = {
      ...$chat,
      chatsMensagens: [...$chat.chatsMensagens, chatMensagem],
    }
    if (chatId !== chatIdAtual) novoChat.pendentes++
    _setChats({
      count: chats.count,
      rows: chats.rows.map((chat) => (chat.id !== chatId ? chat : novoChat)),
    })
    _scrollToBottom()
  }

  function setChatIdAtual(chatId: number) {
    _setChatIdAtual(chatId)
    // Run Effect
  }

  function _scrollToBottom() {
    setTimeout(() => {
      refScrollElement.current?.scrollTo(0, 1e10)
    })
  }

  const chatAtual = useMemo(() => {
    return chats.rows.find((x) => x.id === chatIdAtual)
  }, [chatIdAtual, chats])

  const chatMensagens = useMemo(() => {
    if (!chatAtual) return []
    return chatAtual.chatsMensagens
  }, [chatIdAtual, chats])

  return {
    chats,
    redirectTo,
    goToContactList,
    sendMensagem,
    chatMensagens,
    refScrollElement,
    chatAtual,
    setChatIdAtual,
  }
}

export type ChatStoreValues = ReturnType<typeof useChatStore>
export const ChatStoreCtx = React.createContext(null)
export const Chat = () => {
  const Values = useChatStore()
  return (
    <ChatStoreCtx.Provider value={Values}>
      <ChatView />
    </ChatStoreCtx.Provider>
  )
}
