import { createSlice } from '@reduxjs/toolkit'
import { QuestaoModel } from '../../models/QuestaoModel'
import axios, { ICounter, IQueryParams } from '../../axios'
import { ModuloModel } from '../../models/ModuloModel'
import { toast } from 'react-toastify'
import { Util } from '../../components/Util'

export interface IState {
  local?: string
  moduloAtualId?: number
  modulos?: ModuloModel[]
  questoes?: QuestaoModel[]
  totalQuestoes?: number
  isEditingQuestao?: boolean
  selectedQuestao?: QuestaoModel
  query?: IQueryParams
}

export const initialState: IState = {
  modulos: [],
  questoes: [],
  query: { limit: 10, offset: 0 },
  totalQuestoes: 0,
}

const reducers = {
  addQuestaoSuccess(state: IState, { payload }: any) {
    const questoes = state.questoes.find((x) => x.id === payload.id)
      ? state.questoes.map((x) => (x.id === payload.id ? payload : x))
      : [...state.questoes, payload]

    const totalQuestoes = state.totalQuestoes + Math.max(questoes.length - state.questoes.length, 0)
    const selectedQuestao: QuestaoModel = { questoesAlternativas: [] }
    return { ...state, totalQuestoes, questoes, isEditingQuestao: false, selectedQuestao }
  },
  setLocal(state: IState, { payload }: any) {
    return { ...state, local: payload }
  },
  setModuloAtualId(state: IState, { payload }: any) {
    return { ...state, moduloAtualId: payload }
  },
  selectQuestao(state: IState, { payload }: any) {
    return { ...state, selectedQuestao: payload, isEditingQuestao: true }
  },
  setQueryParams(state: IState, { payload }: any) {
    return { ...state, query: { ...state.query, ...payload } }
  },
  addQuestao(state: IState) {
    const selectedQuestao: QuestaoModel = { questoesAlternativas: [] }
    return { ...state, isEditingQuestao: true, selectedQuestao }
  },
  updateQuestaoStarted(state: IState, { payload }: any) {
    return { ...state, ...payload, loading: true }
  },
  updateQuestaoSuccess(state: IState, { payload }: any) {
    return { ...state, ...payload, submitted: true, isEditingQuestao: false }
  },
  updateQuestaoError(state: IState, { payload }: any) {
    return { ...state, erro: payload }
  },
  clearQuestao(state: IState) {
    const selectedQuestao: QuestaoModel = { questoesAlternativas: [] }
    return { ...state, isEditingQuestao: false, selectedQuestao }
  },
  questoesFetchStarted(state: IState) {
    return { ...state }
  },
  questoesFetchSuccess(state: IState, { payload }: any) {
    return { ...state, questoes: payload.rows, totalQuestoes: payload.count }
  },
  questoesFetchError(state: IState, { payload }: any) {
    return { ...state, erro: payload }
  },
  modulosFetchStarted(state: IState) {
    return { ...state }
  },
  modulosFetchSuccess(state: IState, { payload }: any) {
    return { ...state, modulos: payload }
  },
  modulosFetchError(state: IState, { payload }: any) {
    return { ...state, erro: payload }
  },
  reviewQuestaoStarted(state: IState, { payload }: any) {
    return { ...state, loading: true }
  },
  reviewQuestaoSuccess(state: IState, { payload }: any) {
    return { ...state, loading: false }
  },
  reviewQuestaoError(state: IState, { payload }: any) {
    return { ...state, erro: payload }
  },
  deleteQuestaoStarted(state: IState) {
    return { ...state, isLoading: true }
  },
  deleteQuestaoSuccess(state: IState, { payload }: any) {
    const questoes = state.questoes.filter((x) => x.id !== payload)
    const totalQuestoes =
      questoes.length !== state.questoes.length ? state.totalQuestoes - 1 : state.totalQuestoes
    return { ...state, isLoading: false, totalQuestoes }
  },
  deleteQuestaoError(state: IState, { payload }: any) {
    return { ...state, isLoading: false, erro: payload }
  },
}
const { reducer: questoesReducer, actions } = createSlice({
  name: 'questoes',
  initialState: initialState,
  reducers,
})

const questoesActions = {
  ...actions,
  fetchQuestoes({ query, local, moduloAtualId }) {
    return async (dispatch) => {
      dispatch(actions.questoesFetchStarted())
      try {
        const questoes = await axios.Questoes.get(query, moduloAtualId, local)
        dispatch(actions.questoesFetchSuccess(questoes as any))
      } catch (error) {
        dispatch(actions.questoesFetchError(error.message))
      }
    }
  },
  fetchModulos() {
    return async (dispatch) => {
      dispatch(actions.modulosFetchStarted())
      try {
        const modulos = await axios.Modulos.get({})
        dispatch(actions.modulosFetchSuccess(modulos as any))
      } catch (error) {
        dispatch(actions.modulosFetchError(error.message))
      }
    }
  },
  reviewQuestao(questao: QuestaoModel) {
    return async (dispatch) => {
      dispatch(actions.reviewQuestaoStarted(questao.id as any))
      try {
        await axios.Questoes.createOrUpdate({ ...questao, revisada: true })
        dispatch(actions.reviewQuestaoSuccess(questao.id as any))
        toast('Questão revisada com sucesso')
      } catch (error) {
        dispatch(actions.reviewQuestaoError(error.message))
        toast(error.message || 'Erro ao revisar questão')
      }
    }
  },
  updateQuestao(questao: QuestaoModel) {
    return async (dispatch) => {
      dispatch(actions.updateQuestaoStarted(questao as any))
      try {
        await axios.Questoes.createOrUpdate({ ...questao })
        dispatch(actions.updateQuestaoSuccess(questao.id as any))
        toast('Questão atualizada com sucesso')
      } catch (error) {
        dispatch(actions.updateQuestaoError(error.message))
        toast(error.message || 'Erro ao atualizar questão')
      }
    }
  },
  deleteQuestao(questaoId: string) {
    return async (dispatch) => {
      if (!(await Util.confirm('Tem certeza que deseja deletar esta questão'))) return
      dispatch(actions.deleteQuestaoStarted())
      try {
        await axios.Questoes.delete(questaoId)
        dispatch(actions.deleteQuestaoSuccess(questaoId as any))
        toast('Questão deletada com sucesso')
      } catch (error) {
        dispatch(actions.deleteQuestaoError(error.message))
        toast(error.message || 'Erro ao deletar questão')
      }
    }
  },
}

export { questoesReducer, questoesActions }
