import { IStateRedux } from '../../ReduxStore'

import { forunsActions } from './ForunsStore'

import { useSelector, useDispatch } from 'react-redux'
import { bindActionCreators } from 'redux'

import React, { useContext, useEffect } from 'react'
import { Table, Padding, DisplayFlex, Flex, styles } from '../../components/Styles'
import { Tab, Menu, Label, Button, Popup, Icon } from 'semantic-ui-react'
import { format } from 'date-fns'
import styled from '@emotion/styled'
import { ForumMensagemModel } from '../../models/ForumMensagemModel'
import { Pagination } from '../../components/Pagination'
import { UsuarioModel } from '../../models/UsuarioModel'
import { filesUrl } from '../../index'
import * as _ from 'lodash'
import { ForunsStoreValues, ForunsStoreCtx } from './Foruns'

export const ForunsView = () => {
  const { mensagensPendentesCount } = useSelector((s: IStateRedux) => s.foruns)

  return (
    <div>
      <Tab
        menu={{ secondary: true, pointing: true }}
        panes={[
          {
            menuItem: 'Foruns',
            render: () => (
              <Tab.Pane attached={false}>
                <ListaForuns />
              </Tab.Pane>
            ),
          },
          {
            menuItem: (
              <Menu.Item key={'moderacao'}>
                Pendente Moderação <Label>{mensagensPendentesCount}</Label>
              </Menu.Item>
            ),
            render: () => (
              <Tab.Pane attached={false}>
                <PendentesModeracao />
              </Tab.Pane>
            ),
          },
        ]}
      />
      <ForumDetail />
    </div>
  )
}

const ListaForuns = () => {
  const dispatch = useDispatch()
  const actions = bindActionCreators(forunsActions, dispatch)
  const { foruns, forunsCount, queryForuns } = useSelector((s: IStateRedux) => s.foruns)

  useEffect(() => {
    actions.getForuns()
    actions.getMensagensPendentes()
  }, [])

  return (
    <React.Fragment>
      <Table>
        <table>
          <thead>
            <tr>
              <th>Id</th>
              <th>Titulo</th>
              <th>Qtd Respostas</th>
              <th>Pendente Aprovação</th>
            </tr>
          </thead>
          <tbody>
            {foruns.map((forum) => (
              <tr key={forum.id} onClick={() => actions.setForum(forum.id)}>
                <td>{forum.id}</td>
                <td>{forum.titulo}</td>
                <td>{(forum.forunsMensagens || []).length}</td>
                <td>{forum.aguardandoModeracao ? <Icon name="exclamation triangle" /> : ''}</td>
              </tr>
            ))}
          </tbody>
        </table>
        <Pagination
          onChangePage={(e) => actions.paginateForuns(e)}
          limit={queryForuns.limit}
          offset={queryForuns.offset}
          count={forunsCount}
        />
      </Table>
    </React.Fragment>
  )
}

const PendentesModeracao = () => {
  const dispatch = useDispatch()
  const actions = bindActionCreators(forunsActions, dispatch)
  const {
    mensagensPendentes,
    mensagensPendentesCount,
    queryMensagensPendentes,
    selectedForumMensagem,
  } = useSelector((s: IStateRedux) => s.foruns)
  useEffect(() => {
    actions.getMensagensPendentes()
  }, [])

  useEffect(() => {
    if (selectedForumMensagem && selectedForumMensagem.id) {
      const ref = document.querySelector('.forum-mensagem-selected')
      if (ref) {
        ref.scrollIntoView()
      }
    }
  }, [selectedForumMensagem])

  const setForumMensagem = async (forumMensagem) => {
    await actions.setForumMensagem(forumMensagem)
  }

  return (
    <Table>
      <table>
        <thead>
          <tr>
            <th>Id</th>
            <th>Texto</th>
            <th>Enviado Por</th>
            <th>Enviado Em</th>
          </tr>
        </thead>
        <tbody>
          {mensagensPendentes.map((forumMensagem) => (
            <tr key={forumMensagem.id} onClick={async () => await setForumMensagem(forumMensagem)}>
              <td>{forumMensagem.id}</td>
              <td>{forumMensagem.textoPendente}</td>
              <td>{forumMensagem.usuario.nome}</td>
              <td>{format(forumMensagem.createdAt, 'DD/MM/YYYY HH:mm')}</td>
            </tr>
          ))}
        </tbody>
      </table>
      <Pagination
        onChangePage={(e) => actions.paginateMensagensPendentes(e)}
        limit={queryMensagensPendentes.limit}
        offset={queryMensagensPendentes.offset}
        count={mensagensPendentesCount}
      />
    </Table>
  )
}

const ForumDetail = () => {
  const dispatch = useDispatch()
  const actions = bindActionCreators(forunsActions, dispatch)
  const { forumActive, forumMensagemEditing, selectedForumMensagem } = useSelector(
    (s: IStateRedux) => s.foruns
  )

  if (!forumActive || (forumActive && !forumActive.id)) return null
  return (
    <Padding>
      <DisplayFlex>
        <Flex>
          <h2>
            {forumActive.titulo}
            <br />
            <small>{format(forumActive.createdAt, 'DD/MM/YYYY HH:mm')}</small>
          </h2>
        </Flex>
        <div>
          <Button onClick={() => actions.forumActiveClean()}>Fechar</Button>
        </div>
      </DisplayFlex>
      <DisplayFlex>
        <Avatar usuario={forumActive.usuario} />
        <Mensagem>
          <div className="nome-usuario">
            <small>
              <b>{forumActive.usuario.nome}</b>
            </small>
            <br />
          </div>
          <div className="textos">{forumActive.texto}</div>
        </Mensagem>
      </DisplayFlex>
      <DisplayFlex>
        <Flex></Flex>
        <Button onClick={actions.aprovarForum}>Aprovar Fórum</Button>
      </DisplayFlex>
      <div>
        <ForunsMensagens forunsMensagens={forumActive.forunsMensagens} />
      </div>
      {!_.get(forumMensagemEditing, 'id', false) &&
        !_.get(forumMensagemEditing, 'forumMensagemId', false) && (
          <div>
            <div style={{ marginTop: '18px' }}>
              <strong>Enviar Resposta</strong>
            </div>
            <EnviarResposta />
          </div>
        )}
    </Padding>
  )
}

const ForunsMensagens = ({ forunsMensagens }: { forunsMensagens: ForumMensagemModel[] }): any => {
  const dispatch = useDispatch()
  const actions = bindActionCreators(forunsActions, dispatch)
  const { forumMensagemEditing, selectedForumMensagem } = useSelector((s: IStateRedux) => s.foruns)

  return (forunsMensagens || []).map((forumMensagem) => (
    <ContainerForunsMensagens key={forumMensagem.id} hasParent={!!forumMensagem.forumMensagemId}>
      {/* <div className="indicador-vertical"></div> */}
      <Flex>
        <div>
          <ContainerMensagem>
            <Avatar usuario={forumMensagem.usuario} />
            <Mensagem>
              <div className="nome-usuario">
                <small>
                  <b>{forumMensagem.usuario.nome}</b> -{' '}
                  {format(forumMensagem.createdAt, 'DD/MM/YYYY HH:mm')}
                </small>
                <br />
              </div>

              <div className="textos">
                {!!forumMensagem.texto && (
                  <div>
                    {forumMensagemEditing.id === forumMensagem.id ? (
                      <EnviarResposta forumMensagemId={forumMensagem.id} />
                    ) : (
                      <React.Fragment>
                        <Icon
                          name="pencil"
                          onClick={() => {
                            actions.setEditaForumMensagem({ forumMensagem } as any)
                          }}
                        />
                        {forumMensagem.texto}
                      </React.Fragment>
                    )}
                  </div>
                )}

                {!!forumMensagem.textoPendente && (
                  <DisplayFlex marginBetween={8}>
                    <TextoPendente
                      selected={
                        selectedForumMensagem && selectedForumMensagem.id === forumMensagem.id
                      }
                      className={
                        selectedForumMensagem && selectedForumMensagem.id === forumMensagem.id
                          ? 'forum-mensagem-selected'
                          : ''
                      }
                    >
                      {forumMensagem.textoPendente}
                    </TextoPendente>
                    <Button onClick={() => actions.aprovarMensagem(forumMensagem)}>Aprovar</Button>
                    <Popup
                      content="O usuário será informado que a mensagem foi rejeitada"
                      trigger={
                        <Button onClick={() => actions.rejeitarMensagem(forumMensagem)}>
                          Rejeitar
                        </Button>
                      }
                    />
                  </DisplayFlex>
                )}
              </div>
            </Mensagem>
          </ContainerMensagem>
          {!!forumMensagem.forunsMensagens && !!forumMensagem.forunsMensagens.length && (
            <Respostas>
              <ForunsMensagens forunsMensagens={forumMensagem.forunsMensagens} />
            </Respostas>
          )}
        </div>
        {!forumMensagem.forumMensagemId && (
          <EnviarRespostaContainer forumMensagem={forumMensagem} />
        )}
        {/* {!forumMensagem.forumMensagemId && <div className="indicador-horizontal"></div>} */}
      </Flex>
    </ContainerForunsMensagens>
  ))
}

const EnviarRespostaContainer = React.memo(
  ({ forumMensagem }: { forumMensagem?: ForumMensagemModel }) => {
    const dispatch = useDispatch()
    const actions = bindActionCreators(forunsActions, dispatch)
    const { forumMensagemEditing } = useSelector((s: IStateRedux) => s.foruns)

    if (forumMensagem.id === forumMensagemEditing.forumMensagemId && !forumMensagemEditing.id) {
      return (
        <div style={{ width: 'calc(100% - 46px)', marginLeft: '43px' }}>
          <EnviarResposta forumMensagemId={forumMensagem.id} />
        </div>
      )
    }

    return (
      <div style={{ textAlign: 'right' }}>
        <Popup
          trigger={
            <Button
              icon
              onClick={() => {
                const forumMensagemResposta = new ForumMensagemModel()
                forumMensagemResposta.forumMensagemId = forumMensagem.id
                actions.setEditaForumMensagem({ forumMensagem: forumMensagemResposta } as any)
              }}
            >
              <Icon name="pencil" />
            </Button>
          }
          content="Enviar Resposta"
        />
      </div>
    )
  }
)

const EnviarResposta = React.memo(({ forumMensagemId }: { forumMensagemId?: number }) => {
  const dispatch = useDispatch()
  const actions = bindActionCreators(forunsActions, dispatch)
  const { forumMensagemEditing } = useSelector((s: IStateRedux) => s.foruns)
  const { cancelarMensagemResposta } = useContext<ForunsStoreValues>(ForunsStoreCtx)
  return (
    <React.Fragment>
      <textarea
        rows={3}
        onChange={(e) => actions.setMensagemResposta({ texto: e.target.value } as any)}
        value={forumMensagemEditing.texto || ''}
        style={{ width: '100%' }}
      />
      <DisplayFlex marginBetween={8}>
        <Flex />
        <Button onClick={() => actions.enviarMensagemResposta()}>Enviar</Button>
        {!!forumMensagemId && <Button onClick={cancelarMensagemResposta}>Cancelar</Button>}
      </DisplayFlex>
    </React.Fragment>
  )
})

const Avatar = React.memo(({ usuario }: { usuario: UsuarioModel }) => {
  function hashCode(str: any) {
    // java String#hashCode
    let hash = 0
    for (let i = 0; i < str.length; i++) {
      hash = str.charCodeAt(i) + ((hash << 5) - hash)
    }
    return hash
  }

  function intToRGB(i: any) {
    const c = (i & 0x00ffffff).toString(16).toUpperCase()

    return '#' + ('00000'.substring(0, 6 - c.length) + c)
  }

  function getIniciais(nome: string) {
    return nome
      .split(' ')
      .filter((x) => !'do,da,dos,das'.includes(x))
      .map((x) => x.slice(0, 1))
      .join('')
  }

  if (usuario.avatar) return <AvatarContainerImg src={`${filesUrl}/${usuario.avatar}`} />

  const iniciais = getIniciais(usuario.nome)

  return (
    <AvatarContainerDiv bgColor={intToRGB(hashCode(usuario.nome))}>{iniciais}</AvatarContainerDiv>
  )
})

const AvatarContainerImg = styled('img')`
  width: 32px;
  height: 32px;
  border-radius: 50%;
  display: inline-block;
`
const AvatarContainerDiv = styled('div')`
  width: 32px;
  height: 32px;
  border-radius: 50%;
  display: inline-block;
  background-color: ${(props: { bgColor?: string }) => (props.bgColor ? props.bgColor : '')};
  color: white;
  text-shadow: ${styles.textShadow};
  display: flex;
  align-items: center;
  justify-content: center;
`

const TextoPendente = styled('div')((props: { selected?: boolean }) => ({
  background: '#dedede',
  padding: '6px 12px',
  borderRadius: '6px',
  flex: 1,
  borderLeft: props.selected ? '4px solid red' : '',
}))

// border: 1px solid #ccc;
const Respostas = styled('div')`
  padding: 12px 12px 12px 37px;
`

const ContainerForunsMensagens = styled('div')<{ hasParent }>`
  display: flex;
  border-left: 2px solid #ccc;
  ${(props: any) =>
    !props.hasParent
      ? `
  border-bottom: 2px solid #ccc;
  padding-bottom: 12px;
  `
      : ''}
  margin-left: 19px;
`
// .indicador-vertical {
//   width: 2px;
//   background: #dedede;
//   display: block;
//   position: relative;
//   left: 20px;
//   z-index: -1;
//   top 19px;
// }
// .indicador-horizontal {
//   height: 2px;
//   background: #dedede;
//   position: relative;
//   top: -18px;
//   z-index: -1;
//   width: calc(100% - 34px);
//   left: 18px;
// }

// border: 1px solid red;
const ContainerMensagem = styled('div')`
  padding: 3px;
  display: flex;
  position: relative;
  left: -20px;
`

const Mensagem = styled('div')`
  border: 1px solid #ccc;
  border-radius: 6px;
  flex: 1;
  margin-left: 8px;
  .nome-usuario {
    background: #dedede;
    padding: 4px;
  }
  .textos {
    padding: 6px;
  }
`
// .container-inner {
//   margin-right: 8px;
//   display: flex;
//   &:before {
//     width: 2px;
//     background: #dadada;
//     height: calc(100% + 51px);
//     content: ' ';
//     display: inline-table;
//     position: relative;
//     left: -18px;
//     z-index: -1;
//   }
//   .container-avatar-mensagens {
//     display: flex;
//   }

//   .container-mensagens {
//     margin-left: 10px;
//     border: 1px solid #ccc;
//     border-radius: 4px;
//     flex: 1;
//     ${(props: any) => !props.hasParent ? `&:after {
//       height: 2px;
//       content: ' ';
//       display: block;
//       width: calc(100% + 10px);
//       background: gainsboro;
//       position: relative;
//       top: 19px;
//       left: -30px;
//     }` : ``}

//     .container-nome-usuario {
//       background: #dedede;
//       padding: 4px;
//     }

//     .container-textos {
//       padding: 6px;
//       &>*:not(:last-child) {
//       }
//     }
//   }
// }
