import React from 'react'
import * as ReactDOM from 'react-dom'
import { App } from './components/App'
import { GlobalStore } from './GlobalStore'
import { Provider } from 'react-redux'
import { store } from './ReduxStore'
import { DndProvider as DndProviderComp } from 'react-dnd'
import { HTML5Backend } from 'react-dnd-html5-backend'
// import 'semantic-ui-less/semantic.less'
const DndProvider: any = DndProviderComp

declare global {
  interface Window {
    Hls: any
    io: any
  }
}

const apiUrl = process.env.REACT_APP_API_URL
const filesUrl = process.env.REACT_APP_FILES_URL
const socketUrl = process.env.SOCKET_URL
const socketPath = process.env.SOCKET_PATH

// console.log({ reactUrl: process.env })

export { apiUrl, filesUrl, socketUrl, socketPath }

const rootEl = document.getElementById('root')
ReactDOM.render(
  <Provider store={store}>
    <GlobalStore>
      <DndProvider backend={HTML5Backend}>
        <App />
      </DndProvider>
    </GlobalStore>
  </Provider>,
  rootEl
)
