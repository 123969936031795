import {
  Segment as SegmentComp,
  Tab,
  Button,
  Icon,
  Table as TableComp,
  Modal,
  Image,
} from 'semantic-ui-react'
import { format, differenceInMinutes, parseISO } from 'date-fns'
import { useParams, useHistory } from 'react-router'
import React, { useEffect, useState } from 'react'
import useAxios from 'axios-hooks'
import { Formulario } from './AlunosMatricular'
import { Avatar } from '../../components/Avatar'
import { DisplayFlex } from '../../components/Styles'
import { NavEstrutural } from '../../components/NavEstrutural'
import { Show } from '../../components/Basic'
import { UsuarioModel } from '../../models/UsuarioModel'
const Segment: any = SegmentComp
const Table: any = TableComp

const AlunosEditar = () => {
  const { cursoId, usuarioId } = useParams<any>()
  return (
    <div>
      <NavEstrutural>Progresso</NavEstrutural>
      <BotaoVoltar />
      <div style={{ margin: '0px 120px' }}>
        <Formulario setSubmited={() => null} cursoId={cursoId} usuarioId={usuarioId} />
      </div>
      <Segment color="orange" style={{ margin: '25px 120px' }}>
        <h2>Acompanhamento de progresso do aluno</h2>
        <Tab
          panes={[
            { menuItem: 'Sessões de estudo', render: () => <Sessoes /> },
            { menuItem: 'Acompanhamento', render: () => <Acompanhamento /> },
            { menuItem: 'Validações Faciais', render: () => <ValidacoesFaciais /> },
          ]}
        />
      </Segment>
    </div>
  )
}

const Label = ({ label, value }) => {
  return (
    <div>
      <div> {label} </div>
      <div>
        {' '}
        <strong>{value}</strong>{' '}
      </div>
    </div>
  )
}

const Sessoes = () => {
  return (
    <div>
      <Table>
        <thead>
          <tr>
            <th>Id</th>
            <th>Início</th>
            <th>Fim</th>
            <th>Duração</th>
          </tr>
        </thead>
      </Table>
    </div>
  )
}
const Acompanhamento = () => {
  return (
    <div>
      <Table>
        <thead>
          <tr>
            <th>Id</th>
            <th>Data</th>
            <th>Tipo</th>
            <th>Progresso na página</th>
            <th>Progresso no módulo</th>
            <th>Módulo</th>
          </tr>
        </thead>
      </Table>
    </div>
  )
}
const ValidacoesFaciais = () => {
  return (
    <div>
      <Table>
        <thead>
          <tr>
            <th>Id</th>
            <th>Data</th>
            <th>Validador</th>
            <th>Resultado Validação</th>
            <th></th>
          </tr>
        </thead>
      </Table>
    </div>
  )
}

const BotaoVoltar = () => {
  const history = useHistory()
  return (
    <Button style={{ marginTop: '25px', marginLeft: '120px' }} onClick={history.goBack} circular>
      <Icon name="arrow left" />
      Voltar
    </Button>
  )
}

export { AlunosEditar }
