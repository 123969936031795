import { Route, Switch } from 'react-router-dom'
import React from 'react'
import { CursosEditar } from './CursosEditar'
import { CursosView } from './CursosView'

export const Cursos = () => {
  return (
    <Switch>
      <Route path="/cursos/:cursoId/editar/" component={CursosEditar} />
      <Route path="/cursos/novo" component={CursosEditar} />
      <Route component={CursosView} />
    </Switch>
  )
}
