import React, { useRef, useEffect, useState } from 'react'
import { useField } from 'formik'
import Inputmask from 'inputmask'
import styled from '@emotion/styled'
import AnimateHeight from 'react-animate-height'
import searchIcon from '../assets/search.svg'

export const TextFieldInput = React.memo(({ children: Children, ...props }: any) => {
  const ref = useRef<any>(null)
  const [displayErrorMessage, setDisplayErrorMessage] = useState(props.errorMessage)
  useEffect(() => {
    if (!props.errorMessage) setTimeout(() => setDisplayErrorMessage(''), 500)
    else setDisplayErrorMessage(props.errorMessage)
  }, [props.errorMessage])
  useEffect(() => {
    if (props.mask) {
      let mask = props.mask
      if (mask === 'cpf') mask = { mask: '999.999.999-99' }
      else if (mask === 'cnpj') mask = { mask: '99.999.999/9999-99' }
      else if (mask === 'telefone') {
        mask = { mask: ['(99) 9999-9999', '(99) 99999-9999'], keepStatic: true }
      } else mask = { mask }
      Inputmask(mask).mask(ref.current)
    }
  }, [props.mask])
  function handlePaste(e) {
    if (props.onPaste) props.onPaste(e)
    if (props.onChange) props.onChange(e)
  }
  return (
    <Styles.Label hasError={props.errorMessage}>
      {props.label}
      {Children ? (
        React.cloneElement(Children, props)
      ) : (
        <input ref={ref} {...props} onPaste={handlePaste} />
      )}
      <AnimateHeight height={props.errorMessage ? 'auto' : 0}>
        <div className="error-message">{displayErrorMessage || props.errorMessage}</div>
      </AnimateHeight>
    </Styles.Label>
  )
})

export const TextField = React.memo((props: any) => {
  const [field, meta] = useField(props.name)

  if (!props.name) throw new Error('É necessário passar o parâmetro "name" para um "TextField"')
  const errorMessage = meta.touched && meta.error ? meta.error : null

  return (
    <div>
      <TextFieldInput
        {...field}
        {...props}
        onChange={(e) => {
          if (props.onChange) props.onChange(e)
          field.onChange(e)
        }}
        errorMessage={errorMessage}
      />
      {}
    </div>
  )
})

const Styles = {
  Label: styled('label')<{ hasError }>(
    (props) => `
    display: flex;
    flex-direction: column;
    margin-bottom: 2rem;
    input, textarea, select {
      background: #FFFFFF;
      border: 1px solid #EDEDED;
      box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.1), 0 1px 3px 0 rgba(0, 0, 0, 0.08);
      transition: .35s box-shadow;
      border-radius: 3px;
      height: 3em;
      padding-left: 1em;
      width: 100%;
      outline: none;
      transition: border .3s;
      ${
        props.hasError
          ? `
        border-color: red;
      `
          : ''
      }
    }
    .error-message {
      color: red;
      text-align: right;
    }
  `
  ),
}

export const TextFieldSearch = ({ ...props }) => {
  const { Layout, Input } = TextFieldSearch
  return (
    <Layout>
      <img src={searchIcon} />
      <Input {...props} />
    </Layout>
  )
}

TextFieldSearch.Layout = styled.div`
  position: relative;
  display: flex;
  width: 100%;
  img {
    position: absolute;
    top: 12px;
    left: 10px;
  }
`
TextFieldSearch.Input = styled.input`
  all: unset;
  background: #ffffff;
  border: 2px solid #d4d4d4;
  border-radius: 6px;
  padding-left: 30px;
  height: 34px;
  max-width: 500px;
  width: 100%;
`
