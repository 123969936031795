import React, { useContext, useRef } from 'react'
import { Button } from 'semantic-ui-react'
import styled from '@emotion/styled'
import { format, startOfWeek, startOfDay, isAfter, getDay, isBefore } from 'date-fns'
import { Flex, DisplayFlex } from '../../components/Styles'

import { ChatStoreValues, ChatStoreCtx } from './Chat'
import { Redirect } from 'react-router'
import { Avatar } from '../../components/Avatar'
import { GlobalStoreValues, GlobalStoreCtx } from '../../GlobalStore'
import { ChatModel } from '../../models/ChatModel'

export const ChatView = () => {
  const { redirectTo, chats, chatMensagens, sendMensagem } =
    useContext<ChatStoreValues>(ChatStoreCtx)
  const { usuario } = useContext<GlobalStoreValues>(GlobalStoreCtx)
  const inputEl = useRef(null)

  const handleSendMessage = async () => {
    const mensagemAtual = inputEl.current.innerText.trim()
    if (!mensagemAtual) return
    sendMensagem(mensagemAtual)
    inputEl.current.innerHTML = ''
  }

  const handleKeyDownMessage = (e: any) => {
    if (e.keyCode !== 13 || e.shiftKey) return
    e.preventDefault()
    handleSendMessage()
  }

  if (redirectTo) return <Redirect to={redirectTo} push />

  const orderedChats = [...chats.rows].sort((a, b) =>
    isBefore(
      a.chatsMensagens[a.chatsMensagens.length - 1]?.createdAt,
      b.chatsMensagens[b.chatsMensagens.length - 1]?.createdAt
    )
      ? 1
      : -1
  )

  return (
    <div>
      <ContainerGeral>
        <div className="titulo">CHATS</div>
        <div className="conversas">
          {orderedChats.map((chat) => (
            <ChatListItem chat={chat} key={chat.id} />
          ))}
        </div>
        <div
          className="topo"
          style={{ borderLeft: '1px solid #C4C4C4', borderBottom: '1px solid #C4C4C4' }}
        >
          <DisplayFlex marginBetween={12} horizontalCenter={true} style={{ height: '100%' }}>
            <Avatar usuario={usuario} />
            <span>{usuario.nome}</span>
          </DisplayFlex>
        </div>
        <ChatBox id="container-chat-scrollable">
          {chatMensagens.length > 0 &&
            chatMensagens.map((chatMensagem) => {
              return (
                <div key={chatMensagem.id}>
                  <BlocoMensagem mensagemPropria={chatMensagem.usuarioId === usuario.id}>
                    <div>
                      {chatMensagem.mensagem}
                      <br />
                      <small>{format(chatMensagem?.createdAt, 'HH:mm')}</small>
                    </div>
                  </BlocoMensagem>
                </div>
              )
            })}
        </ChatBox>
        <MessageBox marginBetween={12}>
          <Flex>
            <MessageField contentEditable ref={inputEl} onKeyDown={handleKeyDownMessage} />
          </Flex>
          <Button variant="contained" onClick={handleSendMessage}>
            Enviar
          </Button>{' '}
          {/* onClick={sendCurrentMensagem} */}
        </MessageBox>
      </ContainerGeral>
    </div>
  )
}

const ChatListItem = ({ chat }: { chat: ChatModel }) => {
  let createdAt, mensagem
  if (chat.chatsMensagens && chat.chatsMensagens.length) {
    createdAt = chat.chatsMensagens[chat.chatsMensagens.length - 1]?.createdAt
    mensagem = chat.chatsMensagens[chat.chatsMensagens.length - 1]?.mensagem
  }
  const { setChatIdAtual } = useContext<ChatStoreValues>(ChatStoreCtx)

  return (
    <ChatListItemContainer onClick={() => setChatIdAtual(chat.id)}>
      <Avatar usuario={chat.aluno} />
      <div className="conversa-nome">{chat.aluno.nome}</div>
      <div className="conversa-data">
        <ChatDate date={createdAt} />
      </div>
      <div className="conversa-mensagem">
        <div className="conversa-mensagem-texto">{mensagem}</div>
        {!!chat.pendentes && <div className="conversa-mensagens-pendentes">{chat.pendentes}</div>}
      </div>
    </ChatListItemContainer>
  )
}

const ChatDate = React.memo(({ date }: any) => {
  if (!date) return null

  if (isAfter(date, startOfDay(new Date()))) {
    return <React.Fragment>{format(date, 'HH:mm')}</React.Fragment>
  }

  if (isAfter(date, startOfWeek(new Date()))) {
    const weekDays = [
      'Domingo',
      'Segunda-Feira',
      'Terça-Feira',
      'Quarta-Feira',
      'Quinta-Feira',
      'Sexta-Feira',
      'Sábado',
    ]
    return <React.Fragment>{weekDays[getDay(date)]}</React.Fragment>
  }

  return <React.Fragment>{format(date, 'DD/MM/YYYY')}</React.Fragment>
})

const BlocoMensagem = styled('div')((props: { mensagemPropria: boolean }) => {
  return {
    textAlign: props.mensagemPropria ? 'right' : 'left',
    '&>div': {
      maxWidth: '70%',
      display: 'inline-block',
      borderRadius: '3px',
      backgroundColor: props.mensagemPropria ? 'white' : '#FFE0B2',
      margin: '6px',
      padding: '6px 12px 3px 12px',
      lineHeight: '1.3em',
    },
  }
})

const ContainerGeral = styled('div')`
  width: 100%;
  height: 100%;
  display: grid;
  grid-template-areas:
    'titulo topo'
    'conversas chat-box'
    'conversas message-box';

  grid-template-columns: 1fr 3fr;
  grid-template-rows: 69px 1fr auto;
  .titulo {
    grid-area: titulo;
    border-bottom: 1px solid #c4c4c4;
  }
  .conversas {
    grid-area: conversas;
  }
  .topo {
    grid-area: topo;
  }
`

const ChatListItemContainer = styled('div')`
  padding-top: 12px;
  display: grid;
  cursor: pointer;
  height: 70px;
  display: grid;
  border-bottom: 1px solid #c4c4c4;
  padding: 12px;
  grid-template-columns: 50px 1fr auto;
  grid-template-areas:
    'avatar nome data'
    'avatar mensagem mensagem';
  grid-column-gap: 12px;
  align-items: center;
  &:hover,
  &.active {
    background: #e5e5e5;
  }
  Avatar {
    grid-area: avatar;
  }
  .conversa-nome {
    grid-area: nome;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
  .conversa-data {
    grid-area: data;
    font-size: 0.8em;
    color: #444;
    text-align: right;
  }
  .conversa-mensagem {
    grid-area: mensagem;
    display: flex;
    .conversa-mensagem-texto {
      font-size: 0.8em;
      font-style: italic;
      height: 1.1em;
      line-height: 1.1em;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      color: #444;
      flex: 1;
    }
    .conversa-mensagens-pendentes {
      background: green;
      color: white;
      width: 24px;
      min-width: 24px;
      height: 24px;
      display: flex;
      justify-content: center;
      align-items: center;
      font-size: 0.8em;
      border-radius: 50%;
    }
  }
`

const ChatBox = styled('div')`
  grid-area: chat-box;
  background-image: url('https://images3.alphacoders.com/901/thumb-1920-901648.jpg');
  height: auto;
  overflow: auto;
  flex: 1;
`

const MessageField = styled('div')`
  border: 1px solid #c4c4c4;
  padding: 15px 45px 15px 10px;
  font-size: 1.2em;
  height: auto;
  min-height: 56px;
  max-height: 9em;
  overflow: auto;
  box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.1), 0 1px 3px 0 rgba(0, 0, 0, 0.08);
  &:focus {
    outline: 0px solid transparent;
    box-shadow: 0 6px 12px 0 rgba(0, 0, 0, 0.1), 0 1px 3px 0 rgba(0, 0, 0, 0.08);
  }
`
const MessageBox = styled(DisplayFlex)`
  grid-area: message-box;
  padding: 12px;
`
