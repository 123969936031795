import React, { useState, useContext } from 'react'
import { EsqueceuSenhaView } from './EsqueceuSenhaView'
import { GlobalStoreValues, GlobalStoreCtx } from '../../GlobalStore'
import axios from '../../axios'

const useEsqueceuSenhaStore = () => {
  const { setErrorMessage, setSuccessMessage } = useContext<GlobalStoreValues>(GlobalStoreCtx)

  const [redirectTo, _setRedirectTo] = useState(null)

  async function doRecuperaSenha(email: string) {
    try {
      await axios.Usuarios.createTokenRecuperaSenha(email)
      _setRedirectTo('/login')
      setSuccessMessage('Em instantes você receberá um e-mail com instruções', 30000)
    } catch (error) {
      setErrorMessage(error.message)
    }
  }
  return { redirectTo, doRecuperaSenha }
}

export type EsqueceuSenhaStoreValues = ReturnType<typeof useEsqueceuSenhaStore>
export const EsqueceuSenhaStoreCtx = React.createContext(null)
export const EsqueceuSenha = () => {
  const Values = useEsqueceuSenhaStore()
  return (
    <EsqueceuSenhaStoreCtx.Provider value={Values}>
      <EsqueceuSenhaView />
    </EsqueceuSenhaStoreCtx.Provider>
  )
}
