import React, { useContext } from 'react'
import { NovaSenhaStoreValues, NovaSenhaStoreCtx } from './NovaSenha'
import { GlobalStoreValues, GlobalStoreCtx } from '../../GlobalStore'
import { Redirect } from 'react-router'
import { Padding, Container, styles } from '../../components/Styles'
import { Formik, Form } from 'formik'
import { Yup } from '../../components/Yup'
import { SimpleInput } from '../../components/SimpleInput'
import { ButtonLoader } from '../../components/ButtonLoader'
import styled from '@emotion/styled'
import { Button } from 'semantic-ui-react'

export const NovaSenhaView = () => {
  const { isLoading } = useContext<GlobalStoreValues>(GlobalStoreCtx)
  const { usuario, updateSenha, tokenInvalido, setRedirectTo, redirectTo } =
    useContext<NovaSenhaStoreValues>(NovaSenhaStoreCtx)

  if (redirectTo) return <Redirect to={redirectTo} />

  if (tokenInvalido) {
    return (
      <Container>
        <Info>Token Inválido</Info>

        <Padding>
          <Button onClick={() => setRedirectTo('/login')}>Voltar</Button>
        </Padding>
      </Container>
    )
  }

  if (!usuario) return null

  return (
    <Container>
      <InnerContainer>
        <Info>
          Bem vindo <strong>{usuario.nome}</strong>
        </Info>

        <Info>Informe sua nova senha e confirme para continuar</Info>

        <FormContainer>
          <Formik
            key={124454}
            initialValues={{ senha: '', confirmaSenha: '' }}
            onSubmit={async (values: any) => await updateSenha(usuario.id, values.senha)}
            validationSchema={Yup.object().shape({
              senha: Yup.string().required().min(6, 'Informe pelo menos 6 caracteres'),
              confirmaSenha: Yup.string()
                .required()
                .min(6, 'Informe pelo menos 6 caracteres')
                .oneOf([Yup.ref('senha'), null], 'As senhas não coincidem'),
            })}
            render={() => (
              <Form>
                <SimpleInput {...{ name: 'senha', placeholder: 'Nova Senha' }} type="password" />
                <SimpleInput
                  {...{ name: 'confirmaSenha', placeholder: 'Confirma Nova Senha' }}
                  type="password"
                />

                <ButtonContainer>
                  <ButtonLoader type="submit" disabled={isLoading} loading={isLoading}>
                    Enviar
                  </ButtonLoader>
                </ButtonContainer>
              </Form>
            )}
          />
        </FormContainer>
      </InnerContainer>
    </Container>
  )
}

const Info = styled('div')`
  background: ${styles.color.bg};
  color: ${styles.color.white};
  padding: 12px;
  margin: 12px;
  font-size: 2em;
  line-height: 1.4em;
`

const InnerContainer = styled('div')`
  margin: 0 auto;
  max-width: 800px;
`
const FormContainer = styled('div')`
  margin: 12px;
`
const ButtonContainer = styled('div')`
  margin-top: 12px;
`
