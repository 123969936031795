import { socketUrl, socketPath } from './index'
import { ChatMensagemModel } from './models/ChatMensagemModel'

let socket: any
const startSocket = async () => {
  if (!window.io) {
    return await new Promise((resolve) => {
      const interval = setInterval(() => {
        if (socket) {
          clearInterval(interval)
          resolve(true)
          return
        }
        if (window.io) {
          socket = window.io(socketUrl, { path: socketPath })
          clearInterval(interval)
          resolve(true)
        }
      }, 100)
    })
  }
  socket = window.io(socketUrl, { path: socketPath })
  return await new Promise((resolve) => resolve(true))
}

startSocket()

const listen = async (event: string, cb: any) => {
  if (!socket) await startSocket()
  socket.on(event, cb)
  return () => {
    socket.removeListener(event, cb)
  }
}

const Chat = {
  novaMensagem: async (cb) => await listen('NOVA_MENSAGEM', cb),
}

export default {
  Chat,
}
