import React from 'react'
import { ModuloModel } from '../models/ModuloModel'
import { CursoModel } from '../models/CursoModel'
import { ConteudoPaginaModel } from '../models/ConteudoPaginaModel'
import { Link, useParams } from 'react-router-dom'
import styled from '@emotion/styled'
import { useSelector } from 'react-redux'
import { IStateRedux } from '../ReduxStore'
import { styles } from './Styles'
import { Show } from './Basic'

const NavEstrutural = ({
  children,
  curso,
  modulo,
  aula,
}: {
  children?
  curso?: CursoModel
  modulo?: ModuloModel
  aula?: ConteudoPaginaModel
}) => {
  const { cursoId, moduloId, aulaId } = useParams<any>()
  const cursoStore = useSelector((s: IStateRedux) =>
    s.cursos.cursos.find((c) => +c.id === +cursoId)
  )
  if (!curso) curso = cursoStore
  const moduloStore = useSelector((s: IStateRedux) =>
    s.modulos.modulos.find((m) => +m.id === +moduloId)
  )
  if (!modulo) modulo = moduloStore
  return (
    <Styles.Container>
      <Link to="/cursos">Início</Link>
      <Breadcrumb label={curso?.nome} link={`/cursos/${curso?.id}/editar`} />
      <Breadcrumb label={modulo?.nome} link={`/cursos/${curso?.id}/modulos/${modulo?.id}`} />
      <Breadcrumb label={aula ? `Aula N# ${aula.numero}` : null} link={''} />
      <Show condition={!!children}>
        <Styles.Separador>/</Styles.Separador>
        {children}
      </Show>
    </Styles.Container>
  )
}

const Breadcrumb = ({ label, link }) => {
  if (!label) return null
  return (
    <>
      <Styles.Separador>/</Styles.Separador>
      <Link to={link}>{label}</Link>
    </>
  )
}

const Styles = {
  Container: styled('div')`
    box-shadow: inset 0px -1px 0px rgba(208, 201, 214, 0.4);
    height: 60px;
    display: flex;
    align-items: center;
    a {
      color: ${styles.color.bg};
    }
  `,
  Separador: styled('span')`
    margin: 0 4px;
  `,
}

export { NavEstrutural }
